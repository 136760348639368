import { selectCurrentClientID } from './../../core/store/broker/broker.selectors'
import { Injectable, inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, select, Store } from '@ngrx/store'
import {
  concatMap,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'
import { QuoteReinsurerService } from 'src/app/api/quote-reinsurer/quote-reinsurer.service'
import {
  concatMapWithInput,
  mergeMapWithInput,
  rejectError,
  rejectErrorWithInput,
  switchMapWithInput,
} from 'src/app/api/util'
import { AppState } from 'src/app/core/store'
import * as fromQuoteActions from './quote.actions'
import * as fromReinsurerActions from './reinsurer/reinsurer.actions'
import * as fromLayerMetricsActions from '../../analysis/store/metrics/layers-metrics.actions'
import { WhitespaceSyncWarningsComponent } from '../whitespace-sync-warnings/whitespace-sync-warnings.component'
import {
  selectSectionCurrencies,
  selectQuoteCededLayerID,
  selectQuoteSharedLimitID,
  selectQuoteSharedLimitlayers,
  selectQuoteStructureGroupID,
  selectQuoteStructureID,
  selectQuoteStudyID,
  selectQuoteYearID,
  selectSectionDictionary,
  selectSections,
  selectQuoteStructureGroupName,
  selectQuoteSharedLimitName,
  selectQuoteSectionID,
  selectAllExpiringForThisLayer,
  selectReinsurers,
  selectQuoteCededLayerName,
  selectAutoBuildSectionsForSelectedLayer,
  selectBureaus,
  selectWhitespaceSyncWarnings,
  selectQuoteSelectedCompareView,
} from './quote.selectors'
import {
  selectAllGrouperProgramCededLayerStates,
  selectAllQuoteGrouperProgramCededLayersFilteredIDs,
  selectCededDictionary,
  selectCededLayers,
  selectCededLayersFilteredIDs,
  selectCededPortfolioViewLayersViewIDs,
  selectEditorPortfolioSetID,
  selectQuoteCededLayersFilteredIDs,
} from '../../analysis/store/analysis.selectors'
import { selectClientsByID } from 'src/app/core/store/clients.selectors'
import * as fromBroker from '../../core/store/broker/broker.selectors'
import { setCurrentAnalysisProfile } from '../../core/store/broker/broker.actions'
import {
  reinitialize,
  startAnalysis,
} from '../../analysis/store/analysis.actions'
import {
  AssignedLines,
  EXPIRING_REINSURER_NAME,
  QuoteReinsurer,
  RenewedFromRiskAndSection,
  Section,
  createAlID,
  createQuoteID,
  ReinsurerSubjectivity,
  ReinsurerPhases,
} from '../models/quote.model'
import { QuoteExportService } from '../export/quote-export.service'
import { QuoteRiskCodeService } from '../risk-codes/quote-risk-code.service'
import { selectAccountOpportunities } from '../../core/store/accountopportunity.selectors'
import { clone } from 'ramda'
import { selectSelectedStudyReinsurers } from 'src/app/reinsurers/store/reinsurers.selectors'
import { ApiResponse } from 'src/app/api/model/api.model'
import {
  AGENCY_MARKET_USE,
  AgencyDetails,
} from 'src/app/core/model/reinsurer.model'
import { ReinsurerService } from 'src/app/api/reinsurer/reinsurer.service'
import { TerritoryService } from 'src/app/api/territory/territory.service'
import { EMPTY, forkJoin, of } from 'rxjs'
import { SectionState } from './section/section.reducer'
import { Layer, PhysicalLayer } from '../../analysis/model/layers.model'
import {
  selectSharedLimitMembers,
  selectSharedLimits,
} from '../../core/store/auth/auth.selectors'
import { AnalyzreService } from 'src/app/api/analyzere/analyzre.service'
import {
  LogicalPortfolioLayer,
  Metadata,
} from '../../api/analyzere/analyzere.model'
import { convertFromLogicalPortfolioLayers } from 'src/app/analysis/model/layers.converter'
import { fetchStudyReinsurer } from 'src/app/reinsurers/store/study-reinsurers.actions'
import { selectPrograms } from '../../core/store/program/program.selectors'
import { PortfolioSetID } from '../../analysis/model/portfolio-set.model'
import { extractPortfolioSetID } from '../../analysis/model/portfolio-set-id.util'
import { QuoteRiskCodeDialogContainerComponent } from '../risk-codes/risk-code-dialog/quote-risk-code-dialog.container'
import { MatDialog } from '@angular/material/dialog'
import { QuoteAutoBuildDataChangedDialogComponent } from '../quote-auto-build-data-changed-dialog/quote-auto-build-data-changed-dialog.component'
import {
  updateLayer,
  updatePhysicalLayer,
} from '../../analysis/store/ceded-layers/layers.actions'
import { Bureaus } from 'src/app/api/model/quote.model'
import { layerIds } from 'src/app/analysis/model/layer-palette.model'
import { ProgramService } from 'src/app/api/program/program.service'
import { isLayerActualTopAndDrop } from 'src/app/analysis/model/layers.util'
import { QuoteCustomComparisonService } from 'src/app/api/quote-custom-comparison/quote-custom-comparison.service'

@Injectable()
export class QuoteEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private service: QuoteReinsurerService,
    private quoteExportService: QuoteExportService,
    private reService: ReinsurerService,
    private analyzereService: AnalyzreService,
    private territoryService: TerritoryService,
    private riskCodeService: QuoteRiskCodeService,
    private dialog: MatDialog,
    private programService: ProgramService,
    private quoteCompareService: QuoteCustomComparisonService
  ) {}

  fetchOrSaveQuoteData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.fetchOrSaveQuoteData),
      switchMap(data => {
        if (!data.structureID) {
          return []
        }
        return [
          fromQuoteActions.fetchQuoteRiskBystructureID({
            structureID: parseInt(data.structureID, 10),
          }),
        ]
      })
    )
  })

  fetchOrSaveRisk$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.fetchQuoteRiskBystructureID),
      concatMap(action => {
        return this.service.getRiskByStructureID(action.structureID)
      }),
      withLatestFrom(
        this.store.pipe(select(fromBroker.selectCurrentStructureID)),
        this.store.pipe(select(fromBroker.selectCurrentYearID))
      ),
      concatMap(([data, structureID, yearID]) => {
        const action: Action[] = []
        if ((data.error || !data.data) && structureID && yearID) {
          action.push(
            fromQuoteActions.saveQuoteRiskBystructureID({
              risk: {
                id: '',
                structureID: parseInt(structureID, 10),
                fiscalYear: parseInt(yearID, 10),
              },
            })
          )
        } else if (data.data) {
          action.push(
            fromQuoteActions.fetchQuoteRiskBystructureIDSuccess({
              risk: data.data,
            })
          )
        }
        return action
      })
    )
  })

  saveRisk$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.saveQuoteRiskBystructureID),
      concatMap(action => {
        return this.service.postRisk(action.risk)
      }),
      rejectError(error =>
        this.store.dispatch(
          fromQuoteActions.saveQuoteRiskBystructureIDFailure({ error })
        )
      ),
      map(risk => {
        return fromQuoteActions.saveQuoteRiskBystructureIDSuccess({ risk })
      })
    )
  })

  fetchSection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        fromQuoteActions.saveQuoteRiskBystructureIDSuccess,
        fromQuoteActions.fetchQuoteRiskBystructureIDSuccess
      ),
      withLatestFrom(
        this.store.pipe(select(selectQuoteCededLayersFilteredIDs)),
        this.store.pipe(select(selectCededLayers))
      ),
      concatMap(([_, cededIds, cededLayers]) => {
        const action: Action[] = []
        const isMultiVisibleLayer = cededLayers.filter(
          c =>
            c.layer.meta_data.sage_layer_type === layerIds.catMultisection &&
            c.layer.meta_data.sage_layer_subtype === 'visible-layer'
        )
        if (isMultiVisibleLayer && isMultiVisibleLayer.length > 0) {
          isMultiVisibleLayer.forEach(mLayer => {
            const multiLayer = cededLayers.find(
              l1 => l1.layer.meta_data.visible_layer_id === mLayer.layer.id
            )
            const refs = multiLayer?.layer.layerRefs
            if (refs && refs.length > 0) {
              cededIds = [...cededIds, ...refs]
            }
          })
        }
        const actualLayers = cededLayers.filter(c =>
          isLayerActualTopAndDrop(c.layer)
        )
        // Keeping top and drop layers in one order as it creates issues during quote export
        if (actualLayers?.length > 0) {
          actualLayers.forEach(l => {
            const index = cededIds.indexOf(l.layer.id)
            if (index > -1) {
              cededIds.splice(index, 1) // removing hidden layer and readding it to keep them one below other
              cededIds.push(l.layer.id)
            }
            const refs = l.layer.layerRefs ?? []
            if (refs.length > 0) {
              cededIds = [...cededIds, ...refs]
            }
          })
        }
        for (const id in cededIds) {
          /* sections should be loaded for all ceded layers, not just the selected layer */
          if (id) {
            action.push(
              fromQuoteActions.fetchQuoteSectionByLayerRef({
                layerRef: cededIds[id],
              })
            )
          }
        }
        return action
      })
    )
  })

  fetchOrSaveSection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.fetchQuoteSectionByLayerRef),
      concatMapWithInput(({ layerRef }) =>
        this.service.getRiskSectionByLayer(layerRef)
      ),
      withLatestFrom(
        this.store.pipe(select(fromBroker.selectCurrentStructureID)),
        this.store.pipe(select(selectQuoteCededLayerID)),
        this.store.pipe(select(selectCededLayers)),
        this.store.pipe(select(selectSectionDictionary))
      ),
      concatMap(
        ([
          data,
          structureID,
          selectedQuoteCededLayerId,
          cededLayers,
          sectionEntity,
        ]) => {
          const action: Action[] = []
          if (data.error || !data.data || data.data?.[0]?.layerRef === '') {
            const quoteSectionFound = Object.values(sectionEntity).filter(
              s => s?.section.layerRef === data.data?.[1].layerRef
            )[0]
            // Only look for the top and drop layer and use that for Quote Sage
            const topAndDropLayer = cededLayers.find(
              c =>
                c.layer.meta_data.sage_layer_type === 'cat_td' &&
                c.layer.meta_data.sage_layer_subtype === 'virtual'
            )
            let cededLayerFound = cededLayers.find(
              c => c.layer.id === data.data?.[1].layerRef
            )?.layer

            if (topAndDropLayer && !cededLayerFound) {
              cededLayerFound = topAndDropLayer.layer
            }

            if (
              structureID &&
              cededLayerFound &&
              (!quoteSectionFound ||
                cededLayerFound.meta_data.sage_layer_type ===
                  layerIds.catMultisection)
            ) {
              action.push(
                fromQuoteActions.saveQuoteSectionByLayerRef({
                  section: this.getSectionDataByType(
                    structureID,
                    cededLayerFound.id,
                    cededLayerFound.physicalLayer.description || '',
                    cededLayerFound.meta_data.sage_layer_type || '',
                    cededLayerFound.meta_data.sage_layer_subtype || '',
                    cededLayerFound.physicalLayer.franchise.currency
                  ),
                })
              )
            }
          } else {
            const section = data.data[0]
            if (section) {
              if (section.layerRef === selectedQuoteCededLayerId) {
                /* only set section id for selected layer */
                action.push(
                  fromQuoteActions.setSectionID({
                    sectionID: section.id,
                  })
                )
              }
              action.push(
                fromQuoteActions.fetchQuoteSectionByLayerRefSuccess({
                  section,
                })
              )
            }
          }
          return action
        }
      )
    )
  })

  saveSection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.saveQuoteSectionByLayerRef),
      concatMap(({ section }) => this.service.postRiskSection(section)),
      rejectError(error =>
        this.store.dispatch(
          fromQuoteActions.saveQuoteSectionByLayerRefFailure({ error })
        )
      ),
      withLatestFrom(this.store.pipe(select(selectQuoteCededLayerID))),
      concatMap(([section, selectedQuoteCededLayerId]) => {
        const action = []
        if (
          !selectedQuoteCededLayerId ||
          section.layerRef === selectedQuoteCededLayerId
        ) {
          /* only set section id for selected layer or if no section has been set yet */
          action.push(
            fromQuoteActions.setSectionID({
              sectionID: section.id,
            })
          )
        }
        action.push(
          fromQuoteActions.saveQuoteSectionByLayerRefSuccess({ section })
        )
        return action
      })
    )
  })

  fetchReinsurers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        fromQuoteActions.fetchQuoteSectionByLayerRefSuccess,
        fromQuoteActions.saveQuoteSectionByLayerRefSuccess
      ),
      concatMapWithInput(action => {
        return this.service.getRiskReinsurersByLayer(action.section.layerRef)
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromReinsurerActions.fetchQuoteReinsurerByLayerRefFailure({ error })
        )
      ),
      withLatestFrom(
        this.store.pipe(select(selectEditorPortfolioSetID)),
        this.store.pipe(select(selectCededPortfolioViewLayersViewIDs)),
        this.store.pipe(select(selectAutoBuildSectionsForSelectedLayer)),
        this.store.pipe(select(selectQuoteStudyID)),
        this.store.pipe(select(selectCurrentClientID))
      ),
      concatMap(
        ([
          props,
          portfolioSetID,
          layersViewIDs,
          autoBuildSectionsForSelectedLayer,
          studyID,
          currentClientID,
        ]) => {
          const actions: Action[] = []
          const reinsurers = props[0]
          const layerRef = props[1].section.layerRef
          // Exclude reinsurers with group Id
          const re = reinsurers.filter(
            r => r.programGroupID === null && r.sharedLimitID === null
          )
          actions.push(
            fromReinsurerActions.fetchQuoteReinsurerByLayerRefSuccess({
              reinsurers: re,
              layerRef,
            }),
            fromQuoteActions.fetchBureaus()
          )
          if (!autoBuildSectionsForSelectedLayer) {
            actions.push(fromQuoteActions.loadingSuccess()) // only show main quote UI if no auto build data needed
          }
          if (currentClientID && studyID) {
            actions.push(
              fetchStudyReinsurer({ carrierID: currentClientID, studyID })
            )
          }
          const cededLayerIDs: (string | undefined)[] = []
          const layerViewIDs = []
          if (portfolioSetID && layersViewIDs) {
            reinsurers.forEach(reinsurer => {
              if (reinsurer.newCededLayerForViewID) {
                cededLayerIDs.push(reinsurer.newCededLayerForViewID)
              } else {
                cededLayerIDs.push(reinsurer.cededlayerID)
              }
            })
            for (const [key, value] of Object.entries(layersViewIDs)) {
              if (cededLayerIDs.includes(key)) {
                layerViewIDs.push(value)
              }
            }
            layerViewIDs.forEach(viewID => {
              actions.push(
                fromLayerMetricsActions.fetchLayersViewMetrics({
                  ...portfolioSetID,
                  layerViewID: viewID,
                })
              )
            })
          }
          return actions
        }
      )
    )
  })

  fetchExternalVendorByStructureID$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.fetchExternalVendorByStructureID),
      concatMap(action => {
        return this.service.getTemplateDataByStructureId(action.structureID)
      }),
      rejectError(error =>
        this.store.dispatch(
          fromQuoteActions.fetchExternalVendorFailure({ error })
        )
      ),
      map(vendor => {
        return fromQuoteActions.fetchExternalVendorSuccess({ vendor })
      })
    )
  })

  fetchBureaus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.fetchBureaus),
      concatMap(_ => {
        return this.service.getBureau()
      }),
      rejectError(error =>
        this.store.dispatch(fromQuoteActions.fetchBureausFailure({ error }))
      ),
      map(bureaus => {
        return fromQuoteActions.fetchBureausSuccess({ bureaus })
      })
    )
  })

  setAnalysisOrReinitialize$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.setAnalysisOrReinitialize),
      withLatestFrom(
        this.store.pipe(select(selectClientsByID)),
        this.store.pipe(select(fromBroker.selectCurrentClientID)),
        this.store.pipe(select(fromBroker.selectCurrentStudyID)),
        this.store.pipe(select(fromBroker.selectCurrentYearID)),
        this.store.pipe(select(fromBroker.selectCurrentAnalysisProfile))
      ),
      map(([data, clients, clientID, studyID, yearID, analysisProfile]) => {
        if (
          !analysisProfile ||
          analysisProfile.id !== data.portfolioSetID?.analysisProfileID
        ) {
          this.store.dispatch(
            setCurrentAnalysisProfile({
              id: data.portfolioSetID?.analysisProfileID as string,
            })
          )
        }
        if (
          data &&
          clientID &&
          studyID &&
          yearID &&
          data.structureID &&
          data.portfolioSetID &&
          data.portfolioSetID.analysisProfileID &&
          data.portfolioSetID.cededPortfolioID &&
          data.portfolioSetID.grossPortfolioID &&
          data.portfolioSetID.netPortfolioID &&
          data.portfolioSetID.parentGrossPortfolioID
        ) {
          if (Object.keys(clients).length === 0) {
            return reinitialize({
              cededPortfolioID: data.portfolioSetID.cededPortfolioID,
              grossPortfolioID: data.portfolioSetID.grossPortfolioID,
              netPortfolioID: data.portfolioSetID.netPortfolioID,
              parentGrossPortfolioID:
                data.portfolioSetID.parentGrossPortfolioID,
              analysisProfileID: data.portfolioSetID.analysisProfileID,
              clientID,
              studyID,
              yearID,
              structureID: data.structureID,
            })
          } else {
            return startAnalysis({
              cededPortfolioID: data.portfolioSetID.cededPortfolioID,
              grossPortfolioID: data.portfolioSetID.grossPortfolioID,
              netPortfolioID: data.portfolioSetID.netPortfolioID,
              parentGrossPortfolioID:
                data.portfolioSetID.parentGrossPortfolioID,
              analysisProfileID: data.portfolioSetID.analysisProfileID,
              clientID,
              fromSave: false,
              studyID,
              yearID,
              structureID: data.structureID,
            })
          }
        } else {
          return { type: 'No Action' }
        }
      })
    )
  })

  exportAsExcel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.exportAsExcel),
      withLatestFrom(
        this.store.pipe(select(selectSelectedStudyReinsurers)),
        this.store.pipe(select(fromBroker.selectCurrentClient)),
        this.store.pipe(select(fromBroker.selectCurrentStudy)),
        this.store.pipe(select(selectAccountOpportunities)),
        this.store.pipe(select(selectQuoteStructureGroupName))
      ),
      mergeMapWithInput(
        ([
          quoteExport,
          reinsurers,
          _client,
          _program,
          _accountOpportunities,
          _structureGroupName,
        ]) => {
          const actions: Array<ApiResponse<AgencyDetails[]>> = []
          if (reinsurers) {
            for (const re of reinsurers) {
              if (
                (re.reinsurerProgramFactor[0].incumbent ||
                  re.reinsurerProgramFactor[0].target_market) &&
                re.market_use === AGENCY_MARKET_USE &&
                quoteExport.assignedLinesTpRef.find(a => a[0] === re.tpRef) !==
                  undefined &&
                re.tpRef
              ) {
                actions.push(this.reService.getAgencyDetailsByTpRef(re.tpRef))
              }
            }
          }
          if (actions.length > 0) {
            return forkJoin(actions).pipe(
              map(responses => {
                const uniqeData: AgencyDetails[] = []
                for (const response of responses) {
                  if (response.error) {
                    return { error: response.error }
                  } else {
                    if (response.data && response.data.length > 0) {
                      const res = response.data
                      quoteExport.assignedLinesTpRef.forEach(r => {
                        const reData = res.filter(
                          a =>
                            r[0] === a.agencyTPRef && r[1] === a.agencySeqNumber
                        )
                        if (reData !== undefined && reData.length > 0) {
                          uniqeData.push(...reData)
                        }
                      })
                    }
                  }
                }
                const data = uniqeData.filter(
                  (tag, index, array) =>
                    array.findIndex(
                      t =>
                        t.agencySeqNumber === tag.agencySeqNumber &&
                        t.agencyTPRef === tag.agencyTPRef &&
                        t.memberTPRef === tag.memberTPRef &&
                        t.memberSeqNum === tag.memberSeqNum
                    ) === index
                )
                return { data }
              })
            )
          } else {
            const data: AgencyDetails[] = []
            return of({ data })
          }
        }
      ),
      rejectErrorWithInput(error => {
        this.store.dispatch(
          fromReinsurerActions.fetchQuoteReinsurerByLayerRefFailure({ error })
        )
      }),
      withLatestFrom(
        this.store.pipe(select(selectQuoteSharedLimitName)),
        this.store.pipe(select(selectSectionCurrencies)),
        this.store.pipe(select(selectQuoteCededLayerID))
      ),
      switchMap(
        ([
          [
            agencyDetails,
            [
              quoteExport,
              _reinsurers,
              client,
              program,
              accountOpportunities,
              structureGroupName,
            ],
          ],
          sharedLimitName,
          sectionCurrencies,
          layerId,
        ]) => {
          this.quoteExportService.exportToExcel(
            quoteExport,
            client?.name ?? '',
            program?.name ?? '',
            program?.opportunity_id ?? '',
            accountOpportunities,
            structureGroupName ?? '',
            sharedLimitName ?? '',
            agencyDetails,
            sectionCurrencies,
            layerId
          )
          return [fromQuoteActions.exportQuoteSuccess()]
        }
      )
    )
  })

  // Support Groups in Quote Sage
  startGroupQuote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.startGroupQuote),
      map(data => {
        return fromQuoteActions.fetchQuoteRiskByprogramID({
          // tslint:disable-next-line: no-non-null-assertion
          programID: parseInt(data.studyID!, 10),
        })
      })
    )
  })

  fetchOrSaveRiskGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.fetchQuoteRiskByprogramID),
      concatMap(action => {
        return this.service.getRiskByProgramID(action.programID)
      }),
      withLatestFrom(
        this.store.pipe(select(selectQuoteStudyID)),
        this.store.pipe(select(selectQuoteYearID))
      ),
      concatMap(([data, programID, yearID]) => {
        const action = []
        if (data.error || !data.data || !data.data?.programID) {
          action.push(
            fromQuoteActions.saveQuoteRiskByprogramID({
              // tslint:disable-next-line: no-non-null-assertion
              risk: {
                id: '',
                structureID: 0,
                // tslint:disable-next-line: no-non-null-assertion
                programID: parseInt(programID!, 10),
                // tslint:disable-next-line: no-non-null-assertion
                fiscalYear: parseInt(yearID!, 10),
              },
            })
          )
        } else {
          action.push(
            fromQuoteActions.fetchQuoteRiskByprogramIDSuccess({
              risk: data.data,
            })
          )
        }
        return action
      })
    )
  })

  saveRiskGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.saveQuoteRiskByprogramID),
      concatMap(action => {
        return this.service.postRisk(action.risk)
      }),
      rejectError(error =>
        this.store.dispatch(
          fromQuoteActions.saveQuoteRiskByprogramIDFailure({ error })
        )
      ),
      map(risk => {
        return fromQuoteActions.saveQuoteRiskByprogramIDSuccess({ risk })
      })
    )
  })

  fetchSectionGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        fromQuoteActions.saveQuoteRiskByprogramIDSuccess,
        fromQuoteActions.fetchQuoteRiskByprogramIDSuccess
      ),
      withLatestFrom(
        this.store.pipe(
          select(selectAllQuoteGrouperProgramCededLayersFilteredIDs)
        ),
        this.store.pipe(select(selectQuoteStructureGroupID)),
        this.store.pipe(select(selectQuoteSharedLimitID))
      ),
      concatMap(([_, cededIds, groupID, slID]) => {
        const action: any[] = []
        if (groupID) {
          for (const id in cededIds) {
            if (id && groupID) {
              action.push(
                fromQuoteActions.fetchQuoteSectionByLayerRefAndProgramGroupID({
                  // tslint:disable-next-line: no-non-null-assertion
                  layerRef: cededIds[id],
                  programGroupID: groupID,
                })
              )
            }
          }
        } else if (slID) {
          action.push(
            fromQuoteActions.fetchLayersForSharedLimit({
              slID,
            })
          )
        }
        return action
      })
    )
  })

  fetchOrSaveSectionGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.fetchQuoteSectionByLayerRefAndProgramGroupID),
      concatMapWithInput(action => {
        return this.service.getRiskSectionByLayerAndProgramGroupID(
          action.layerRef,
          action.programGroupID
        )
      }),
      withLatestFrom(
        this.store.pipe(select(selectQuoteStudyID)),
        this.store.pipe(select(selectQuoteStructureGroupID)),
        this.store.pipe(select(selectAllGrouperProgramCededLayerStates)),
        this.store.pipe(select(selectSectionDictionary))
      ),
      concatMap(
        ([data, programID, programGroupID, cededLayers, sectionEntity]) => {
          const action = []
          if (data.error || !data.data || data.data?.[0]?.layerRef === '') {
            const sameLayer = Object.values(sectionEntity).filter(
              s =>
                s?.section.layerRef === data.data?.[1].layerRef &&
                // @ts-ignore
                s?.section.programGroupID === programGroupID
            )[0]
            const layerFound: Layer | undefined = cededLayers.find(
              c => c.layer.id === data.data?.[1].layerRef
            )?.layer
            if (sameLayer === undefined && layerFound) {
              action.push(
                fromQuoteActions.saveQuoteSectionByLayerRefAndProgramGroupID({
                  section: this.getSectionDataByType(
                    layerFound.meta_data.structureID || '0',
                    layerFound.id,
                    layerFound.meta_data.layerName || '',
                    layerFound.meta_data.sage_layer_type || '',
                    layerFound.meta_data.sage_layer_subtype || '',
                    layerFound.physicalLayer.franchise.currency,
                    programGroupID,
                    true,
                    // tslint:disable-next-line: no-non-null-assertion
                    programID!
                  ),
                })
              )
            }
          } else {
            const firstSection: SectionState | undefined =
              Object.values(sectionEntity)[0]
            if (firstSection) {
              action.push(
                fromQuoteActions.setSectionID({
                  sectionID: firstSection.section.id,
                })
              )
            }
            action.push(
              fromQuoteActions.fetchQuoteSectionByLayerRefAndProgramGroupIDSuccess(
                {
                  // tslint:disable-next-line: no-non-null-assertion
                  section: data.data[0]!,
                }
              )
            )
          }
          return action
        }
      )
    )
  })

  saveSectionGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.saveQuoteSectionByLayerRefAndProgramGroupID),
      concatMap(action => {
        return this.service.postRiskSection(action.section)
      }),
      rejectError(error =>
        this.store.dispatch(
          fromQuoteActions.saveQuoteSectionByLayerRefAndProgramGroupIDFailure({
            error,
          })
        )
      ),
      withLatestFrom(this.store.pipe(select(selectSections))),
      concatMap(([section, sectionAll]) => {
        const action = []
        if (sectionAll && sectionAll.length > 0) {
          action.push(
            fromQuoteActions.setSectionID({
              sectionID: sectionAll[0].section.id,
            })
          )
        }
        action.push(
          fromQuoteActions.saveQuoteSectionByLayerRefAndProgramGroupIDSuccess({
            section,
          }),
          fromQuoteActions.loadingSuccess()
        )
        return action
      })
    )
  })

  fetchReinsurersGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        fromQuoteActions.fetchQuoteSectionByLayerRefAndProgramGroupIDSuccess
      ),
      concatMap(action => {
        if (action.section.programGroupID) {
          return this.service.getRiskReinsurersByLayerAndProgramGroupID(
            action.section.layerRef,
            action.section.programGroupID
          )
        } else {
          return this.service.getRiskReinsurersByLayer(action.section.layerRef)
        }
      }),
      rejectError(error =>
        this.store.dispatch(
          fromReinsurerActions.fetchQuoteReinsurerByLayerRefFailure({ error })
        )
      ),
      concatMap(reinsurers => {
        const actions = []
        actions.push(
          fromReinsurerActions.fetchQuoteReinsurerByLayerRefSuccess({
            reinsurers,
          }),
          fromQuoteActions.fetchBureaus(),
          fromQuoteActions.loadingSuccess()
        )
        return actions
      })
    )
  })

  startSLQuote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.startSLQuote),
      map(data => {
        return fromQuoteActions.fetchQuoteRiskByprogramID({
          // tslint:disable-next-line: no-non-null-assertion
          programID: parseInt(data.studyID!, 10),
        })
      })
    )
  })

  fetchLayersForSharedLimit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.fetchLayersForSharedLimit),
      withLatestFrom(
        this.store.pipe(select(selectSharedLimits)),
        this.store.pipe(select(selectSharedLimitMembers))
      ),
      concatMap(([action, shareLimits, sharedLimitMembers]) => {
        const id: string[] = []
        const slLayer = shareLimits.find(
          (sl: { id: string }) => sl.id === action.slID
        )
        if (slLayer && slLayer.sl_layer_id) {
          id.push(slLayer.sl_layer_id)
          sharedLimitMembers.forEach(slm => {
            if (slm.sl_id === slLayer.id) {
              if (slm.layer_id) {
                id.push(slm.layer_id)
              }
            }
          })
        }
        return this.analyzereService.fetchLayers<LogicalPortfolioLayer>(id)
      }),
      rejectError(error =>
        this.store.dispatch(
          fromQuoteActions.fetchLayersForSharedLimitFailure({ error })
        )
      ),
      withLatestFrom(
        this.store.pipe(select(fromBroker.selectCurrentClientID)),
        this.store.pipe(select(fromBroker.selectCurrentStudyID)),
        this.store.pipe(select(selectPrograms))
      ),
      concatMap(([logicalLayers, clientID, studyID, programs]) => {
        const actions = []
        const layers: Layer[] = convertFromLogicalPortfolioLayers(logicalLayers)
        const filteredLayers = layers.filter(l =>
          logicalLayers.find(lo => lo.id === l.id)
        )
        const filterProgram = programs.find(
          p => filteredLayers[0].meta_data.structureID === p.id
        )
        let slPortfolioID: PortfolioSetID | null = null
        if (filterProgram) {
          slPortfolioID = extractPortfolioSetID(filterProgram)
        }
        actions.push(
          fromQuoteActions.fetchLayersForSharedLimitSuccess({
            sharedLimitLayers: filteredLayers,
            sharedLimitPortfolioID: slPortfolioID,
          })
        )
        if (clientID && studyID) {
          actions.push(
            fetchStudyReinsurer({
              carrierID: clientID,
              studyID,
            })
          )
        }
        return actions
      })
    )
  })

  fetchSectionSL$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.fetchLayersForSharedLimitSuccess),
      withLatestFrom(this.store.pipe(select(selectQuoteSharedLimitID))),
      concatMap(([layers, slID]) => {
        const action: any[] = []
        if (layers.sharedLimitLayers && layers.sharedLimitLayers.length > 0) {
          const layerSort = clone(layers.sharedLimitLayers)
          layerSort.sort((a, b) => {
            const nA = a.meta_data.layerName || ''
            const nB = b.meta_data.layerName || ''
            if (nA < nB) {
              return -1
            }
            if (nA > nB) {
              return 1
            }
            return 0
          })
          for (const layer of layerSort) {
            if (layer && layer.id && slID) {
              action.push(
                fromQuoteActions.fetchQuoteSectionByLayerRefAndSharedLimitID({
                  // tslint:disable-next-line: no-non-null-assertion
                  layerRef: layer.id,
                  sharedLimitID: slID,
                })
              )
            }
          }
        }
        return action
      })
    )
  })

  fetchOrSaveSectionSL$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.fetchQuoteSectionByLayerRefAndSharedLimitID),
      concatMapWithInput(action => {
        return this.service.getRiskSectionByLayerAndSharedLimitID(
          action.layerRef,
          action.sharedLimitID
        )
      }),
      withLatestFrom(
        this.store.pipe(select(selectQuoteStudyID)),
        this.store.pipe(select(selectQuoteSharedLimitID)),
        this.store.pipe(select(selectQuoteSharedLimitlayers)),
        this.store.pipe(select(selectSectionDictionary))
      ),
      concatMap(
        ([data, programID, sharedLimitID, cededLayers, sectionEntity]) => {
          const action = []
          if (data.error || !data.data || data.data?.[0]?.layerRef === '') {
            const sameLayer = Object.values(sectionEntity).filter(
              s =>
                s?.section.layerRef === data.data?.[1].layerRef &&
                // @ts-ignore
                s?.section.sharedLimitID === sharedLimitID
            )[0]
            const layerFound: Layer | undefined = cededLayers?.find(
              c => c.id === data.data?.[1].layerRef
            )
            if (sameLayer === undefined && layerFound) {
              action.push(
                fromQuoteActions.saveQuoteSectionByLayerRefAndSharedLimitID({
                  section: this.getSectionDataByType(
                    layerFound.meta_data.structureID || '0',
                    layerFound.id,
                    layerFound.meta_data.layerName || '',
                    layerFound.meta_data.sage_layer_type || '',
                    layerFound.meta_data.sage_layer_subtype || '',
                    layerFound.physicalLayer.franchise.currency,
                    sharedLimitID,
                    false,
                    // tslint:disable-next-line: no-non-null-assertion
                    programID!
                  ),
                })
              )
            }
          } else {
            const firstSection: SectionState | undefined =
              Object.values(sectionEntity)[0]
            if (firstSection) {
              action.push(
                fromQuoteActions.setSectionID({
                  sectionID: firstSection.section.id,
                })
              )
            }
            action.push(
              fromQuoteActions.fetchQuoteSectionByLayerRefAndSharedLimitIDSuccess(
                {
                  // tslint:disable-next-line: no-non-null-assertion
                  section: data.data[0]!,
                }
              )
            )
          }
          return action
        }
      )
    )
  })

  saveSectionSL$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.saveQuoteSectionByLayerRefAndSharedLimitID),
      concatMap(action => {
        return this.service.postRiskSection(action.section)
      }),
      rejectError(error =>
        this.store.dispatch(
          fromQuoteActions.saveQuoteSectionByLayerRefAndSharedLimitIDFailure({
            error,
          })
        )
      ),
      withLatestFrom(this.store.pipe(select(selectSections))),
      concatMap(([section, sectionAll]) => {
        const action = []
        if (sectionAll && sectionAll.length > 0) {
          action.push(
            fromQuoteActions.setSectionID({
              sectionID: sectionAll[0].section.id,
            })
          )
        }
        action.push(
          fromQuoteActions.saveQuoteSectionByLayerRefAndSharedLimitIDSuccess({
            section,
          }),
          fromQuoteActions.loadingSuccess()
        )
        return action
      })
    )
  })

  fetchReinsurersSL$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        fromQuoteActions.fetchQuoteSectionByLayerRefAndSharedLimitIDSuccess
      ),
      concatMap(action => {
        if (action.section.sharedLimitID) {
          return this.service.getRiskReinsurersByLayerAndSharedLimitID(
            action.section.layerRef,
            action.section.sharedLimitID
          )
        } else {
          return this.service.getRiskReinsurersByLayer(action.section.layerRef)
        }
      }),
      rejectError(error =>
        this.store.dispatch(
          fromReinsurerActions.fetchQuoteReinsurerByLayerRefFailure({ error })
        )
      ),
      concatMap(reinsurers => {
        const actions = []
        actions.push(
          fromReinsurerActions.fetchQuoteReinsurerByLayerRefSuccess({
            reinsurers,
          }),
          fromQuoteActions.fetchBureaus(),
          fromQuoteActions.loadingSuccess()
        )
        return actions
      })
    )
  })

  checkIfProgramHasRenewedFrom$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.checkIfProgramHasRenewedFrom),
      withLatestFrom(
        this.store.pipe(select(fromBroker.selectCurrentYearStudies)),
        this.store.pipe(select(selectAccountOpportunities))
      ),
      concatMap(([props, programs, accountOpportunities]) => {
        const selectedProgram = programs.find(p => p.id === props.programId)
        const opportunity = accountOpportunities?.find(
          opp => opp.id === selectedProgram?.opportunity_id
        )
        const actions: Action[] = []
        if (
          opportunity &&
          opportunity.opportunityRenewedFrom &&
          opportunity.opportunityRenewedFrom !== null
        ) {
          actions.push(
            fromQuoteActions.fetchDetailsForRenewedOpportunity({
              opportunityRenewedFrom: {
                salesforceOppId: opportunity.opportunityRenewedFrom,
              },
            })
          )
        }
        return actions
      })
    )
  })

  fetchDetailsForRenewedOpportunity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.fetchDetailsForRenewedOpportunity),
      concatMap(action => {
        return this.service.getRenewedOpportunityRiskSections(
          action.opportunityRenewedFrom
        )
      }),
      rejectError(error => {
        this.store.dispatch(
          fromQuoteActions.fetchDetailsForRenewedOpportunityFailure({ error })
        )
      }),
      map(renewedFromRisks => {
        return fromQuoteActions.fetchDetailsForRenewedOpportunitySuccess({
          renewedFromRisks,
        })
      })
    )
  })

  checkIfShouldFetchAutoBuildExpiring$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        fromReinsurerActions.fetchQuoteReinsurerByLayerRefSuccess,
        fromQuoteActions.fetchAutoBuildExpiring
      ),
      withLatestFrom(
        this.store.pipe(select(selectAutoBuildSectionsForSelectedLayer)),
        this.store.pipe(select(selectQuoteCededLayerName)),
        this.store.pipe(select(selectQuoteCededLayerID)),
        this.store.pipe(select(selectQuoteSectionID)),
        this.store.pipe(select(selectReinsurers))
      ),
      concatMap(
        ([
          props,
          selectedAutoBuildSections,
          selectedLayerName,
          selectedQuoteCededLayerId,
          selectedSectionId,
          quoteReinsurers,
        ]) => {
          const isSelectedLayer = props.layerRef === selectedQuoteCededLayerId
          if (!isSelectedLayer) {
            /* autobuild check should only proceed for selected layer */
            return []
          }
          const doesQuoteReinsurerAlreadyExist = !!quoteReinsurers.find(
            quote =>
              quote?.reinsurer.reinsurerSectionId === Number(selectedSectionId)
          )
          const shouldFetchAutoBuild =
            selectedAutoBuildSections &&
            !doesQuoteReinsurerAlreadyExist &&
            !!selectedSectionId
          return shouldFetchAutoBuild
            ? [
                fromQuoteActions.fetchAutoBuildExpiringDetails({
                  layerName: selectedLayerName,
                }),
              ]
            : [fromQuoteActions.loadingSuccess()]
        }
      )
    )
  })

  fetchAutoBuildExpiringDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.fetchAutoBuildExpiringDetails),
      withLatestFrom(
        this.store.pipe(select(selectAutoBuildSectionsForSelectedLayer))
      ),
      concatMap(([_, selectedAutoBuildSections]) => {
        if (!selectedAutoBuildSections?.sectionNums) {
          return [fromQuoteActions.loadingSuccess()]
        }
        return selectedAutoBuildSections.sectionNums.map((sectionNum, i) => {
          return fromQuoteActions.fetchQuoteFieldsForSelectedRiskAndSection({
            selectedRiskAndSectionAndAB: {
              selectedRiskAndSection: {
                riskRef: selectedAutoBuildSections.riskRef,
                sectionNum,
                sectionNarrative: selectedAutoBuildSections.sectionNarrative,
              },
              lastAutoBuild:
                i + 1 === selectedAutoBuildSections.sectionNums.length,
            },
          })
        })
      })
    )
  })

  fetchQuoteFieldsForSelectedRiskAndSection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.fetchQuoteFieldsForSelectedRiskAndSection),
      map(({ type, ...props }) => props),
      concatMapWithInput(props => {
        const requestDetails: RenewedFromRiskAndSection = {
          riskRef:
            props.selectedRiskAndSectionAndAB.selectedRiskAndSection.riskRef,
          sectionNum:
            props.selectedRiskAndSectionAndAB.selectedRiskAndSection.sectionNum,
        }
        return this.service.getQuoteReinsurerForRenewedOpportunity(
          requestDetails
        )
      }),
      rejectError(error => {
        this.store.dispatch(
          fromQuoteActions.fetchQuoteFieldsForSelectedRiskAndSectionFailure({
            error,
          })
        ),
          this.store.dispatch(fromQuoteActions.loadingSuccess())
      }),
      withLatestFrom(
        this.store.pipe(select(selectQuoteCededLayerID)),
        this.store.pipe(select(selectQuoteSectionID)),
        this.store.pipe(select(selectAllExpiringForThisLayer)),
        this.store.pipe(select(selectBureaus)),
        this.store.pipe(select(selectReinsurers))
      ),
      concatMap(
        ([
          propsAndResult,
          selectedLayerRef,
          selectedSectionId,
          expiredForThisLayer,
          bureaus,
          quoteReinsurers,
        ]) => {
          const quoteReinsurer = propsAndResult[0] as QuoteReinsurer
          const props = propsAndResult[1]
          const assignedLinesMappedWithId = this.getAssignedLinesWithTempId(
            quoteReinsurer,
            bureaus
          )
          let isLinkExpiring
          let returnQuoteAction
          // coming from 'Link Expiring' button, only this section to be rendered, can set "Is Loading..." to false
          if (props.selectedRiskAndSectionAndAB.lastAutoBuild === undefined) {
            isLinkExpiring = true
            returnQuoteAction =
              fromQuoteActions.fetchQuoteFieldsForSelectedRiskAndSectionSuccess(
                { quoteReinsurer }
              )
          }
          // coming from auto build, last section to be rendered, can set "Is Loading..." to false
          else if (props.selectedRiskAndSectionAndAB.lastAutoBuild === true) {
            returnQuoteAction = fromQuoteActions.noMoreAutoBuiltSections()
          }
          // coming from auto build, more sections still to be rendered, do not set "Is Loading..." to false
          else {
            returnQuoteAction = fromQuoteActions.moreAutoBuiltSections()
          }
          const isFirstExpiring = !quoteReinsurers
            .filter(q => q.reinsurer.cededlayerID === selectedLayerRef)
            .find(
              q => q.reinsurer.quoteReinsurerName === EXPIRING_REINSURER_NAME
            )
          return [
            returnQuoteAction,
            fromReinsurerActions.saveLayerClassPerSection(),
            fromReinsurerActions.addOrUpdateQuoteReinsurer({
              reinsurer: {
                ...quoteReinsurer,
                id: createQuoteID(),
                quoteReinsurerName: EXPIRING_REINSURER_NAME,
                reinsurerPhase: ReinsurerPhases.Expiring,
                reinsurerPhaseVersion: `${expiredForThisLayer.length + 1}`,
                reinsurerPhaseLabel: `${
                  props.selectedRiskAndSectionAndAB.selectedRiskAndSection
                    .sectionNum
                } : ${props.selectedRiskAndSectionAndAB.selectedRiskAndSection.sectionNarrative?.slice(
                  0,
                  20
                )}`,
                cededlayerID: selectedLayerRef ?? '',
                reinsurerSectionId: parseInt(selectedSectionId ?? '0', 10),
                riskAssignedLinesLink: assignedLinesMappedWithId,
                exportToggle: true,
                isPreferred: isFirstExpiring,
              },
              isLastAutoBuildSection:
                props.selectedRiskAndSectionAndAB.lastAutoBuild,
              isLinkExpiring,
            }),
          ]
        }
      )
    )
  })

  fetchSignedLinesFromWS$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.fetchSignedLinesFromWS),
      withLatestFrom(
        this.store.pipe(select(selectCededLayersFilteredIDs)),
        this.store.pipe(select(selectQuoteStructureID)),
        this.store.pipe(select(selectReinsurers))
      ),
      concatMapWithInput(([_, cededLayers, structureId]) => {
        return this.service.getSignedLinesFromWhitespace(
          structureId,
          cededLayers
        )
      }),
      rejectError(error => {
        this.store.dispatch(
          fromQuoteActions.fetchSignedLinesFromWSFailure({ error })
        )
      }),
      withLatestFrom(
        this.store.pipe(select(selectReinsurers)),
        this.store.pipe(select(selectCededLayersFilteredIDs)),
        this.store.pipe(select(selectCededDictionary))
      ),
      map(([[res, [_]], reinsurers, cededLayers, cededDictionary]) => {
        if (res && res.length > 0) {
          const resAsignedLines: AssignedLines[] = res.map(e => {
            return {
              id: e.riskAssignedLines.id.toString(),
              brokerage: e.riskAssignedLines.brokerage ?? 0,
              brokerageRe: e.riskAssignedLines.brokerageRe ?? 0,
              brokerageCoBrokerShare:
                e.riskAssignedLines.brokerageCoBrokerShare ?? 0,
              brokerageReCoBrokerShare:
                e.riskAssignedLines.brokerageReCoBrokerShare ?? 0,
              bureaus: e.riskAssignedLines.bureaus ?? '',
              coBroker: e.riskAssignedLines.co_broker ?? '',
              coBrokerRef: e.riskAssignedLines.co_broker_ref ?? '',
              contract: e.riskAssignedLines.contract ?? '',
              layer: e.riskAssignedLines.layerRef ?? '',
              layerRef: e.riskAssignedLines.layerRef ?? '',
              leadMarket: e.riskAssignedLines.leadMarket ?? '',
              marketTpRef: e.riskAssignedLines.marketTpRef ?? '',
              placedThrough: e.riskAssignedLines.placedThrough ?? '',
              recommended: e.riskAssignedLines.recommended ?? 0,
              reinsurer: e.riskAssignedLines.reinsurer ?? '',
              relationSeqNumber: e.riskAssignedLines.relationSeqNumber ?? 0,
              riskRef: e.riskAssignedLines.riskRef ?? '',
              signed: e.riskAssignedLines.signed ?? 0,
              signedOfAuthorized: e.riskAssignedLines.signedOfAuthorized ?? 0,
              subjectivity: e.riskAssignedLines.subjectivity ?? '',
              underwriterRef: e.riskAssignedLines.underwriterRef ?? '',
              whiteSpaceSyncedAt: e.riskAssignedLines.whiteSpaceSyncedAt ?? '',
              whiteSpaceSyncedBy: e.riskAssignedLines.whiteSpaceSyncedBy ?? '',
              whiteSpaceWSetID: e.riskAssignedLines.whiteSpaceWSetID ?? '',
              written: e.riskAssignedLines.written ?? 0,
              whiteSpaceImpressionIdx:
                e.riskAssignedLines.whiteSpaceImpressionIdx,
              whiteSpaceBusinessUnit:
                e.riskAssignedLines.whiteSpaceBusinessUnit ?? '',
            }
          })
          cededLayers.forEach((l, _index) => {
            const selectReinsurer = reinsurers.find(
              r =>
                r.reinsurer.cededlayerID === l &&
                r.reinsurer.reinsurerPhase === 'FOT'
            )
            let currentSelectedID: string | null = null
            currentSelectedID = selectReinsurer?.reinsurer.id as string
            const filteredAssignedLines = resAsignedLines.filter(
              r => r.layer === l
            )
            if (selectReinsurer) {
              const assignedLinesSaved =
                selectReinsurer?.reinsurer.riskAssignedLinesLink
              // Subjectivities ↓
              const currentSubjectivity =
                selectReinsurer.reinsurer.riskSubjectivityLink ?? []
              const updateSubjectivity: ReinsurerSubjectivity[] = []
              res.forEach(responseItem => {
                responseItem.riskSubjectivityLink.forEach(link => {
                  if (link.riskReinsurerId?.toString() === currentSelectedID) {
                    const loadedReinsuereSubjectivity: ReinsurerSubjectivity = {
                      riskReinsurerId: link.riskReinsurerId.toString(),
                      riskSubjectivityId: link.riskSubjectivityId?.toString(),
                      riskSubjectivity: {
                        id: link.riskSubjectivity.id?.toString() ?? '0',
                        type: link.riskSubjectivity.subjectivity ?? '',
                        riskRef: link.riskSubjectivity.riskRef ?? '',
                        marketTpRef: link.riskSubjectivity.marketTpRef ?? '',
                        brokerComments:
                          link.riskSubjectivity.brokerComments ?? '',
                        reinsurerResponse:
                          link.riskSubjectivity.reinsurerResponse,
                        acceptedLineCond:
                          link.riskSubjectivity.acceptedLineCond,
                        acceptedContractWord:
                          link.riskSubjectivity.acceptedContractWord,
                        clientAccepted:
                          link.riskSubjectivity.clientAccepted ?? '',
                        reinsCommentary:
                          link.riskSubjectivity.reinsCommentary ?? '',
                        reinsResponseStatus:
                          link.riskSubjectivity.reinsResponseStatus,
                        riskReinsurerId:
                          link.riskSubjectivity.riskReinsurerId ?? 0,
                        reinsurer:
                          link.riskSubjectivity.reinsurer ??
                          responseItem.riskAssignedLines.reinsurer ??
                          '',
                        deadline: link.riskSubjectivity.deadline,
                        ralId: link.riskSubjectivity.ralId ?? 0,
                        vendorIdentifier:
                          link.riskSubjectivity.vendorIdentifier,
                        applyToStructureForSameMarket:
                          link.riskSubjectivity.applyToStructureForSameMarket ??
                          false,
                        vendorName: link.riskSubjectivity.vendorName,
                      },
                    }
                    updateSubjectivity.push(loadedReinsuereSubjectivity)
                  }
                })
              })
              currentSubjectivity.forEach(sub => {
                const exists = updateSubjectivity.find(
                  s => s.riskSubjectivityId === sub.riskSubjectivityId
                )
                if (!exists) {
                  updateSubjectivity.push(sub)
                }
              })
              this.store.dispatch(
                fromReinsurerActions.updateSubjectivitiesArray({
                  id: currentSelectedID as string,
                  subjectivity: updateSubjectivity,
                  bypassFOT: true,
                })
              )
              // Assigned lines ↓
              const updatedAssignedLines: AssignedLines[] = []
              filteredAssignedLines.forEach(line => {
                updatedAssignedLines.push(line)
              })
              // Checking if user manually added a line then synced the same line from WS
              const assignedLinesSavedFiltered = assignedLinesSaved?.filter(
                line => {
                  const exists = updatedAssignedLines.find(
                    iteratorLine =>
                      iteratorLine.marketTpRef === line.marketTpRef &&
                      line.id !== iteratorLine.id &&
                      iteratorLine.marketTpRef
                  )
                  if (exists) {
                    this.store.dispatch(
                      fromReinsurerActions.addWhitespaceAssignedLinesSyncWarning(
                        {
                          warning: {
                            warningType: 'overwrite',
                            layer:
                              cededDictionary[l]?.layer.physicalLayer
                                .description ?? '',
                            reinsurer: line.reinsurer ?? '',
                            warning: 'Line was overwritten by whitespace',
                          },
                        }
                      )
                    )
                  }
                  return !exists
                }
              )

              assignedLinesSavedFiltered?.forEach(line => {
                // Checking if whitespace line is already synced and if not then add it to the updated assigned line list
                const exists = updatedAssignedLines.find(
                  updateLine => updateLine.id === line.id?.toString()
                )
                if (!exists) {
                  updatedAssignedLines.push({
                    ...line,
                    id: line.id?.toString(),
                  })
                }
              })
              this.store.dispatch(
                fromReinsurerActions.updateAssignedLinesArray({
                  id: currentSelectedID as string,
                  assignedLines: updatedAssignedLines,
                })
              )
            }
          })
        }
      }),
      map(_ => {
        return fromQuoteActions.fetchSignedLinesFromWSSuccess()
      })
    )
  })

  postWhitespaceSyncWarnings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.fetchSignedLinesFromWSSuccess),
      withLatestFrom(
        this.store.pipe(select(selectReinsurers)),
        this.store.pipe(select(selectCededDictionary)),
        this.store.pipe(select(selectWhitespaceSyncWarnings))
      ),
      map(([_, reinsurers, cededLayerDict, whitespaceSyncWarnings]) => {
        const duplicateRiskRefs = new Map<string, Set<string>>()
        const overflowSigned: string[] = []
        reinsurers.forEach(re => {
          if (re.reinsurer.reinsurerPhase !== 'FOT') {
            // Don't consider non-FOT reinsurers
            return
          }
          const currentLayerName =
            cededLayerDict[re.reinsurer.cededlayerID || '']?.layer.meta_data
              .layerName || ''
          let cumulativeSigned = 0
          re.reinsurer.riskAssignedLinesLink?.forEach(line => {
            const underWriterRiskRef = line.underwriterRef || ''
            if (!duplicateRiskRefs.has(underWriterRiskRef)) {
              duplicateRiskRefs.set(underWriterRiskRef, new Set<string>())
            }
            duplicateRiskRefs.get(underWriterRiskRef)?.add(
              JSON.stringify({
                layer: currentLayerName,
                line: line.reinsurer || '',
              })
            )
            cumulativeSigned += line.signed || 0
          })
          if (cumulativeSigned > 1) {
            overflowSigned.push(currentLayerName)
          }
        })
        duplicateRiskRefs.forEach((value, key) => {
          if (value.size === 1) {
            duplicateRiskRefs.delete(key)
          }
        })
        if (
          duplicateRiskRefs.size > 0 ||
          overflowSigned.length > 0 ||
          (whitespaceSyncWarnings && whitespaceSyncWarnings.length > 0)
        ) {
          this.dialog.open(WhitespaceSyncWarningsComponent, {
            data: {
              duplicateRiskRefs,
              overflowSigned,
              whitespaceSyncWarnings,
            },
          })
        }
        return fromReinsurerActions.postedWhitespaceSyncWarnings()
      })
    )
  })

  fetchTerritories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.fetchTerritories),
      concatMap(() => this.territoryService.getTerritoriesData()),
      rejectError(error =>
        this.store.dispatch(fromQuoteActions.fetchTerritoriesFailure({ error }))
      ),
      map(territories => {
        return fromQuoteActions.fetchTerritoriesSuccess({
          territories,
        })
      })
    )
  })

  setFotAndQuoteCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.setFotAndQuoteCount),
      mergeMap(({ structure, fotCount, quoteCount }) =>
        this.programService.update(structure.id, {
          ...structure,
          fotCount,
          quoteCount,
        })
      ),
      rejectError(error =>
        this.store.dispatch(
          fromQuoteActions.setFotAndQuoteCountFailure({ error })
        )
      ),
      map(({ id, fotCount, quoteCount }) =>
        fromQuoteActions.setFotAndQuoteCountSuccess({
          id,
          fotCount: fotCount || 0,
          quoteCount: quoteCount || 0,
        })
      )
    )
  })

  private getSectionDataByType(
    structureID: string,
    layerRef: string,
    layerName: string,
    layerType: string,
    layerSubType: string,
    layerCurrency: string,
    programGroupID?: string | null,
    isGroup?: boolean,
    programID?: string
  ): Section {
    let section: Section = {
      id: '',
      layerRef,
      structureID: parseInt(structureID, 10),
      occurrenceLimitToggle: true,
      occurrenceAttachmentToggle: false,
      riskLimitToggle: false,
      riskAttachmentToggle: false,
      franchiseDeductibleToggle: false,
      reinstatementsToggle: false,
      aggregateLimitToggle: false,
      aggregateAttachmentToggle: false,
      cedingCommissionToggle: false,
      reinsurerExpenseProvisionToggle: false,
      profitCommissionToggle: false,
      rolPercentageToggle: false,
      rateOnLineSubjectToggle: false,
      pmpmToggle: false,
      premiumToggle: false,
      effectiveDateToggle: false,
      expirationDateToggle: false,
      expiryDateToggle: false,
      payoutToggle: false,
      triggerToggle: false,
      nthToggle: false,
      underwriterToggle: false,
      maolLimitToggle: false,
      terrorismAggSubLimitToggle: false,
      lossRatioCapToggle: false,
      lossRatioCapPercentageToggle: false,
      lossCapApplicationToggle: false,
      limitApplicationToggle: false,
      laeCapToggle: false,
      laeTreatmentToggle: false,
      adjustmentBasisToggle: false,
      ecoCoveragePctToggle: false,
      xplCoveragePctToggle: false,
      subjectPremiumToggle: true,
      minimumPremiumPercentageToggle: false,
      depositPremiumPercentageToggle: false,
      minimumPremiumToggle: false,
      clashPremiumToggle: false,
      profitShareCommissionToggle: false,
      profitShareMinRateToggle: false,
      profitShareMaxRateToggle: false,
      brokerageCommissionToggle: true,
      brokerageTypeToggle: true,
      brokerageRIPCommissionToggle: false,
      orderPercentToggle: false,
      otherFeaturesToggle: false,
      coverageBasisToggle: false,
      indexationtextToggle: false,
      // quoteSignedPercentageToggle: false,
      quoteMinPercentageToggle: false,
      quoteOfferedPercentageToggle: true,
      quoteOfferedLimitToggle: false,
      xplEcoDropdownToggle: false,
      xplEcoConditionsToggle: false,
      quoteDepositPremiumToggle: true,
      quoteMinRateSubjectToggle: false,
      quoteMaxRateSubjectToggle: false,
      quoteMinRatePmpmToggle: false,
      quoteMaxRatePmpmToggle: false,
      quoteSwingRateToggle: false,
      quoteSwingBasisToggle: false,
      quoteIndexationToggle: false,
      quoteFixedIndexValueToggle: false,
      quoteSicOrFranchiseToggle: false,
      quoteExpectedCededLossToggle: true,
      quoteLossOnLineToggle:true,
      quoteExpectedCededPremiumToggle: true,
      quoteDepositPremiumCalcToggle: true,
      slidingCommToggle: false,
      quoteCessionsBasedPremiumToggle: false,
      structureFXToggle: false,
      premiumFXToggle: false,
      territorialScopeToggle: false,
      vendorToggle: false,
      modelVersionToggle: false,
      feeOrBrokerageToggle: false,
      layerCategoryToggle: false,
      layerClassToggle: false,
      excludeFromPricingCurveToggle: false,
      perilsToggle: false,
      lossImpactedFromPreviousYearToggle: false,
      quoteProbabilityOfAttachToggle: false,
      quoteProbabilityOfExhaustToggle: false,
      quoteTopOccurrenceAttachmentToggle: false,
      quoteTopOccurrenceLimitToggle: false,
      quoteDropOccurrenceAttachmentToggle: false,
      quoteDropOccurrenceLimitToggle: false,
      quoteAggregateLimitTopToggle: false,
      quoteAggregateLimitDropToggle: false,
      cedingCommissionBasisToggle: false,
      feeToggle: false,
      rebateToggle: false,
      quoteIndexToggle: false,
      layerName,
      layerType,
      layerSubType,
      layerCurrency,
    }
    if (
      layerType === layerIds.catXl ||
      layerType === layerIds.noncatXl ||
      layerType === layerIds.ahlXl ||
      layerType === layerIds.catFhcf
    ) {
      section = {
        ...section,
        occurrenceAttachmentToggle: true,
        reinstatementsToggle: true,
        premiumToggle: true,
        minimumPremiumPercentageToggle: true,
        depositPremiumPercentageToggle: true,
        minimumPremiumToggle: true,
        brokerageRIPCommissionToggle: true,
        // quoteSignedPercentageToggle: false,
        slidingCommToggle: false,
      }
    }
    if (
      layerType === layerIds.catQs ||
      layerType === layerIds.noncatQs ||
      layerType === layerIds.ahlQs
    ) {
      section = {
        ...section,
        riskLimitToggle: true,
        subjectPremiumToggle: true,
        occurrenceLimitToggle: true,
        cedingCommissionToggle: true,
        lossRatioCapToggle: true,
        lossRatioCapPercentageToggle: true,
        lossCapApplicationToggle: true,
        quoteOfferedPercentageToggle: true,
        limitApplicationToggle: true,
        // quoteSignedPercentageToggle: false,
        brokerageCommissionToggle: true,
        brokerageTypeToggle: true,
        slidingCommToggle: false,
        premiumToggle: true
      }
    }
    if (layerType === layerIds.catCa) {
      section = {
        ...section,
        occurrenceAttachmentToggle: true,
        franchiseDeductibleToggle: true,
        reinstatementsToggle: true,
        premiumToggle: true,
        minimumPremiumPercentageToggle: true,
        depositPremiumPercentageToggle: true,
        minimumPremiumToggle: true,
        brokerageRIPCommissionToggle: true,
        // quoteSignedPercentageToggle: false,
        slidingCommToggle: false,
      }
    }
    if (
      layerType === layerIds.catAg ||
      layerType === layerIds.noncatAg ||
      layerType === layerIds.ahlAg ||
      layerType === layerIds.ilwAg ||
      layerType === layerIds.catTd ||
      layerType === layerIds.drop
    ) {
      section = {
        ...section,
        occurrenceAttachmentToggle: true,
        aggregateLimitToggle: true,
        aggregateAttachmentToggle: true,
        reinstatementsToggle: true,
        premiumToggle: true,
        minimumPremiumPercentageToggle: true,
        depositPremiumPercentageToggle: true,
        minimumPremiumToggle: true,
        brokerageRIPCommissionToggle: true,
        // quoteSignedPercentageToggle: false,
        slidingCommToggle: false,
      }
    }
    if (layerType === layerIds.noncatRisk) {
      section = {
        ...section,
        riskLimitToggle: true,
        riskAttachmentToggle: true,
        reinstatementsToggle: true,
        premiumToggle: true,
        minimumPremiumPercentageToggle: true,
        depositPremiumPercentageToggle: true,
        minimumPremiumToggle: true,
        brokerageRIPCommissionToggle: true,
        // quoteSignedPercentageToggle: false,
        slidingCommToggle: false,
      }
    }
    if (
      layerType === layerIds.noncatXl ||
      layerType === layerIds.noncatIndxl ||
      layerType === layerIds.noncatAg ||
      layerType === layerIds.noncatRisk
    ) {
      section = {
        ...section,
        rolPercentageToggle: false,
        rateOnLineSubjectToggle: true,
      }
    }
    if (
      layerType === layerIds.ahlAg ||
      layerType === layerIds.ahlXl ||
      layerType === layerIds.ahlQs
    ) {
      section = {
        ...section,
        rolPercentageToggle: false,
        rateOnLineSubjectToggle: false,
        pmpmToggle: true,
      }
    }
    if (
      layerType === layerIds.catXl ||
      layerType === layerIds.catCa ||
      layerType === layerIds.catFhcf ||
      layerType === layerIds.catTd ||
      layerType === layerIds.catAg ||
      layerType === layerIds.ilwAg
    ) {
      section = {
        ...section,
        rolPercentageToggle: true,
        rateOnLineSubjectToggle: false,
      }
    }
    if (layerType === layerIds.catTd || layerType === layerIds.drop) {
      section = {
        ...section,
        quoteAggregateLimitTopToggle: true,
        quoteAggregateLimitDropToggle: true,
        quoteOfferedPercentageToggle: true,
        brokerageRIPCommissionToggle: true,
        quoteDepositPremiumToggle: true,
        depositPremiumPercentageToggle: true,
        quoteExpectedCededLossToggle: false,
        quoteLossOnLineToggle:false,
        quoteExpectedCededPremiumToggle: false,
      }
    }
    if (layerType === layerIds.noncatSwing || layerType === layerIds.ahlSwing) {
      section = {
        ...section,
        subjectPremiumToggle: true,
        occurrenceLimitToggle: true,
        occurrenceAttachmentToggle: true,
        aggregateLimitToggle: true,
        aggregateAttachmentToggle: true,
        quoteSwingRateToggle: true,
        quoteSwingBasisToggle: true,
        quoteOfferedPercentageToggle: true,
        brokerageCommissionToggle: true,
        brokerageRIPCommissionToggle: true,
      }
      if (layerType === layerIds.noncatSwing) {
        section = {
          ...section,
          quoteMinRateSubjectToggle: true,
          quoteMaxRateSubjectToggle: true,
        }
      } else {
        section = {
          ...section,
          quoteMinRatePmpmToggle: true,
          quoteMaxRatePmpmToggle: true,
        }
      }
    }
    if (layerType === layerIds.noncatIndxl) {
      section = {
        ...section,
        subjectPremiumToggle: true,
        occurrenceLimitToggle: true,
        occurrenceAttachmentToggle: true,
        quoteIndexationToggle: true,
        quoteFixedIndexValueToggle: true,
        quoteSicOrFranchiseToggle: true,
        rateOnLineSubjectToggle: true,
        premiumToggle: true,
        reinstatementsToggle: true,
        minimumPremiumPercentageToggle: true,
        depositPremiumPercentageToggle: true,
        minimumPremiumToggle: true,
        quoteOfferedPercentageToggle: true,
        brokerageCommissionToggle: true,
        brokerageRIPCommissionToggle: true,
      }
    }
    if (
      layerType === layerIds.catMultisection ||
      layerType === layerIds.noncatMultisection
    ) {
      if (layerSubType !== 'section-layer') {
        section = {
          ...section,
          occurrenceAttachmentToggle: false,
          rateOnLineSubjectToggle: false,
          premiumToggle: true,
          minimumPremiumPercentageToggle: true,
          minimumPremiumToggle: true,
          reinstatementsToggle: true,
          brokerageCommissionToggle: true,
          brokerageRIPCommissionToggle: true,
          aggregateLimitToggle: true,
          aggregateAttachmentToggle: true,
          rolPercentageToggle: true,
          depositPremiumPercentageToggle: true,
          layerClassToggle: true,
          quoteExpectedCededLossToggle: false,
          quoteLossOnLineToggle:false,
          quoteExpectedCededPremiumToggle: false,
          quoteOfferedPercentageToggle: false,
        }
      } else {
        section = {
          ...section,
          occurrenceAttachmentToggle: true,
          aggregateLimitToggle: true,
          aggregateAttachmentToggle: true,
          subjectPremiumToggle: false,
          quoteDepositPremiumToggle: false,
          quoteExpectedCededLossToggle: false,
          quoteLossOnLineToggle:false,
          brokerageCommissionToggle: false,
          quoteExpectedCededPremiumToggle: false,
          quoteDepositPremiumCalcToggle: false,
          reinstatementsToggle: false,
          brokerageTypeToggle: false,
          layerClassToggle: false,
        }
      }
    }
    if (layerType === layerIds.ilwBin) {
      section = {
        ...section,
        subjectPremiumToggle: false,
        occurrenceLimitToggle: false,
        payoutToggle: true,
        triggerToggle: true,
        nthToggle: true,
        rolPercentageToggle: true,
        premiumToggle: true,
        quoteOfferedPercentageToggle: false,
        brokerageCommissionToggle: true,
        brokerageRIPCommissionToggle: true,
        effectiveDateToggle: true,
        expirationDateToggle: true,
        territorialScopeToggle: true,
        perilsToggle: true,
        quoteDepositPremiumToggle: false,
      }
    }
    if (layerType === layerIds.ilwProRata) {
      section = {
        ...section,
        subjectPremiumToggle: false,
        occurrenceLimitToggle: true,
        payoutToggle: true,
        triggerToggle: true,
        rolPercentageToggle: true,
        premiumToggle: true,
        reinstatementsToggle: true,
        aggregateAttachmentToggle: true,
        aggregateLimitToggle: true,
        effectiveDateToggle: true,
        expirationDateToggle: true,
        quoteOfferedPercentageToggle: false,
        brokerageCommissionToggle: true,
        brokerageRIPCommissionToggle: true,
        territorialScopeToggle: true,
        perilsToggle: true,
        quoteDepositPremiumToggle: false,
      }
    }
    if (layerType === layerIds.ilwAg) {
      section = {
        ...section,
        payoutToggle : true,
        effectiveDateToggle: true,
        expirationDateToggle: true,
      }
    }
    if (isGroup && programID) {
      section = {
        ...section,
        programID: parseInt(programID, 10),
      }
    }
    if (isGroup && programGroupID) {
      section = {
        ...section,
        programGroupID: parseInt(programGroupID, 10),
      }
    } else if (!isGroup && programGroupID) {
      section = {
        ...section,
        sharedLimitID: parseInt(programGroupID, 10),
      }
    }
    return section
  }

  private getAssignedLinesWithTempId(
    quoteReinsurer: QuoteReinsurer,
    bureaus: Bureaus[]
  ): AssignedLines[] {
    return quoteReinsurer.riskAssignedLinesLink
      ? quoteReinsurer.riskAssignedLinesLink.map(al => {
          const bureausStamp = bureaus.find(
            ({ name }) => name === al.reinsurer
          )?.bureau_stamp
          return {
            ...al,
            id: createAlID(),
            bureaus: bureausStamp,
          }
        })
      : []
  }

  openLayerDataChangedDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromQuoteActions.openLayerDataChangedDialog),
      withLatestFrom(
        this.store.pipe(select(selectQuoteCededLayerID)),
        this.store.pipe(select(selectCededLayers))
      ),
      switchMap(([_, currentCededLayerID, cededLayers]) => {
        this.dialog.open(QuoteAutoBuildDataChangedDialogComponent, {
          data: { id: currentCededLayerID },
        })
        if (!currentCededLayerID) {
          return []
        }
        const currentCededLayer = cededLayers.find(
          l => l.layer.id === currentCededLayerID
        )
        const changeMetadata: Partial<Metadata> = { isChangedInDesign: false }
        const changePhysical: Partial<PhysicalLayer> = {
          meta_data: {
            ...currentCededLayer?.layer.physicalLayer.meta_data,
            ...changeMetadata,
          },
        }
        const changeLayer: Partial<Layer> = {
          meta_data: {
            ...currentCededLayer?.layer.physicalLayer.meta_data,
            ...changeMetadata,
          },
        }
        return [
          updatePhysicalLayer({
            id: currentCededLayerID,
            change: changePhysical,
          }),
          updateLayer({
            id: currentCededLayerID,
            change: changeLayer,
          }),
          fromQuoteActions.patchLayerMetadataFromQuote({
            layerId: currentCededLayerID,
            change: changeMetadata,
          }),
        ]
      })
    )
  )

  patchLayerDataFromQuote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.patchLayerMetadataFromQuote),
      withLatestFrom(this.store.select(selectCededLayers)),
      switchMap(([props, cededLayers]) => {
        const layerToPatch = cededLayers.find(
          l => l.layer.id === props.layerId
        )?.layer
        const layerToPatchPhysical = layerToPatch?.physicalLayer
        if (!layerToPatch || !layerToPatchPhysical) {
          return EMPTY
        }
        const changeLayer: Partial<Layer> = {
          ...layerToPatch,
          meta_data: {
            ...layerToPatch.meta_data,
            ...props.change,
          },
        }
        const changePhysical: Partial<PhysicalLayer> = {
          ...layerToPatchPhysical,
          meta_data: {
            ...layerToPatchPhysical.meta_data,
            ...props.change,
          },
        }
        const observables = [
          this.analyzereService.patchLayer<Layer>({
            id: layerToPatch.id,
            change: changeLayer,
          }),
          this.analyzereService.patchLayer<PhysicalLayer>({
            id: layerToPatchPhysical.id,
            change: changePhysical,
          }),
        ]
        return forkJoin(observables)
      }),
      map(() => fromQuoteActions.patchLayerDataFromQuoteSuccess())
    )
  })

  openRiskCodeDialog$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromQuoteActions.openRiskCodeDialog),
        map(({ type, ...props }) => props),
        concatMapWithInput(() => {
          return this.riskCodeService.getRiskCodes()
        }),
        rejectErrorWithInput((error, _) =>
          this.store.dispatch(
            fromQuoteActions.fetchSavedRiskCodesByProgramFailure({
              error,
            })
          )
        ),
        concatMap(([result, props]) => {
          return this.riskCodeService
            .getProgramRiskCodeMappings(parseInt(props.programId, 10))
            .pipe(
              map(res => {
                return {
                  riskCodeMappings: res?.data ? res?.data : [],
                  riskCodes: result,
                  props,
                }
              })
            )
        })
      )
      .pipe(
        withLatestFrom(this.store.pipe(select(selectQuoteCededLayerID))),
        map(([{ riskCodeMappings, riskCodes, props }, currentLayerId]) => {
          const expiringDialogId = 'app-quote-expiring-details-dialog'
          const riskCodeDialogId = 'app-risk-code-dialog'

          this.dialog.open(QuoteRiskCodeDialogContainerComponent, {
            id: riskCodeDialogId,
            width: '30vw',
            panelClass: [expiringDialogId, 'app-dialog-with-sidebar'],
            backdropClass: 'backdropBackground',
            data: {
              riskCodeMappings,
              riskCodes,
              currentLayerId,
              currentProgramId: props.programId,
            },
            autoFocus: false,
          })
          return fromQuoteActions.openRiskCodeDialogSuccess()
        })
      )
  })

  saveSelectedRiskCodes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromQuoteActions.saveSelectedRiskCodesByProgram),
      map(({ type, ...props }) => props),
      concatMapWithInput(props => {
        return this.riskCodeService.postProgramRiskCodeMappings(props.mappings)
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromQuoteActions.saveSelectedRiskCodesByProgramFailure({
            error,
          })
        )
      ),
      map(() => {
        return fromQuoteActions.saveSelectedRiskCodesByProgramSuccess()
      })
    )
  })

  fetchQuoteComparisons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromQuoteActions.setSectionID,
        fromQuoteActions.saveQuoteComparisonSuccess,
        fromQuoteActions.deleteQuoteComparisonSuccess,
        fromQuoteActions.updateQuoteComparisonsFromRemoveDialogSuccess
      ),
      withLatestFrom(this.store.pipe(select(selectQuoteSectionID))),
      switchMap(([_, sectionID]) =>
        this.quoteCompareService.getCustomComparisonsBySectionId(
          Number(sectionID)
        )
      ),
      rejectError(error =>
        this.store.dispatch(
          fromQuoteActions.fetchQuoteComparisonsBySectionIdFailure({ error })
        )
      ),
      map(quoteComparisons =>
        fromQuoteActions.fetchQuoteComparisonsBySectionIdSuccess({
          quoteComparisons,
        })
      )
    )
  )

  saveQuoteComparison$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromQuoteActions.saveQuoteComparison),
      withLatestFrom(this.store.pipe(select(selectQuoteSelectedCompareView))),
      switchMap(([_, quoteCompareView]) => {
        if (quoteCompareView.id) {
          return this.quoteCompareService.putCustomComparison(quoteCompareView)
        } else {
          return this.quoteCompareService.postCustomComparison(quoteCompareView)
        }
      }),
      rejectError(error =>
        fromQuoteActions.saveQuoteComparisonFailure({ error })
      ),
      switchMap(view => [
        fromQuoteActions.saveQuoteComparisonSuccess(),
        fromQuoteActions.updateSelectedCompareView({ view }),
      ])
    )
  )

  deleteQuoteComparison$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromQuoteActions.deleteQuoteComparison),
      switchMap(({ id }) =>
        this.quoteCompareService.deleteCustomComparison(id)
      ),
      rejectError(error =>
        fromQuoteActions.deleteQuoteComparisonFailure({ error })
      ),
      map(() => fromQuoteActions.deleteQuoteComparisonSuccess())
    )
  )

  updateQuoteComparisonsFromRemoveDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromQuoteActions.updateQuoteComparisonsFromRemoveDialog),
      switchMapWithInput(({ updates }) =>
        this.quoteCompareService.bulkDeleteComparisons(
          updates.delete.map(({ id }) => id)
        )
      ),
      rejectErrorWithInput(error =>
        fromQuoteActions.updateQuoteComparisonsFromRemoveDialogFailure({
          error,
        })
      ),
      switchMapWithInput(([, { updates }]) => {
        const updatedViews = updates.remove.map(view => {
          const newView = clone(view)
          const memberIndex = view.members.indexOf(updates.reinsurerId)
          if (memberIndex >= 0) {
            newView.members.splice(memberIndex, 1)
          }
          return newView
        })
        return this.quoteCompareService.putManyCustomComparisons(updatedViews)
      }),
      rejectErrorWithInput(error =>
        fromQuoteActions.updateQuoteComparisonsFromRemoveDialogFailure({
          error,
        })
      ),
      switchMap(([, [, {updates}]]) => ([
        fromQuoteActions.updateQuoteComparisonsFromRemoveDialogSuccess(),
        fromReinsurerActions.deleteQuoteReinsurer({id: String(updates.reinsurerId)})
      ]))
    )
  )
}
