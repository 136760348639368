import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { AppState } from '../../../../core/store'
import { SortTableColumnDef } from '../../../../shared/sort-table/sort-table.model'
import { LayerDetailsExport } from '../../../layer-details/layer-details.model'
import { LayerView, LayerViewValues } from '../../../model/layer-view'
import { exportLayerDetailsAsCsv } from '../../../store/grouper/program-group/program-group.actions'
import { LayerDetailsColumnDefsService } from '../../../layer-details/layer-details-column-defs.service'
import { StudyResponse } from 'src/app/api/model/backend.model'

export interface GroupLayerDetailsDialogComponentData {
  views: LayerView[]
  groupName: string
  studyIDs: string[]
  studies: StudyResponse[]
  groupID: string
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-group-layer-details-dialog',
  styleUrls: ['./group-layer-details-dialog.component.scss'],
  templateUrl: './group-layer-details-dialog.component.html',
})
export class GroupLayerDetailsDialogComponent implements OnInit {
  ColumnDefs: SortTableColumnDef<LayerViewValues>[] = []
  rows: LayerViewValues[] = []
  name: string
  updatedColumnDefs: SortTableColumnDef<LayerViewValues>[] = []

  constructor(
    public dialogRef: MatDialogRef<GroupLayerDetailsDialogComponent>,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: GroupLayerDetailsDialogComponentData,
    private layerDetailsColumnDefsService: LayerDetailsColumnDefsService
  ) {
    this.rows = this.data.views.map(e => e.values)
    this.name = this.data.groupName
  }

  ngOnInit() {
    const alreadyUsedColumns = new Set<string>()

    this.data.studyIDs.forEach(id => {
      this.layerDetailsColumnDefsService.returnColumnDefs(
        id,
        this.data.studies
      ).forEach(col => {
        if (!alreadyUsedColumns.has(col.id)){
          this.ColumnDefs.push(col)
          alreadyUsedColumns.add(col.id)
        }
      })
    })

    this.updatedColumnDefs = JSON.parse(JSON.stringify(this.ColumnDefs))
    this.updatedColumnDefs.map(c => {
      if (c.addNewLayer || c.editable) {
        c.addNewLayer = false
        c.editable = false
      }
      if (c.categoryTemplateID) {
        c.categoryTemplateID = undefined
      }
    })
  }

  onExportClick() {
    const layerDetailsExport: LayerDetailsExport = {
      rows: this.rows,
      columns: this.updatedColumnDefs.map(c => ({
        ...c,
        label: c.category
        ? c.category + ' ' + c.label
        : c.label
      })),
      groupID: this.data.groupID,
      groupName: this.data.groupName
    }
    this.store.dispatch(exportLayerDetailsAsCsv(layerDetailsExport))
  }
}
