import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { catchError, map } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { environment as ENV } from '../../../environments/environment'
import { ApiResponse } from '../model/api.model'
import { catchAndHandleError, mapToMaybeData } from '../util'
import { convertAnimatedScenariosData } from '../../credit/utils/credit.utils'
import {
  CreditModelScenarioMapping,
  CreditPortfolio,
} from '../../credit/model/credit-portfolio.model'
import {
  CreditStructure,
  CreditCalculationStructure,
  CreditStructureOptimizationInput,
  CreditAdminCalculationStructure,
} from './../../credit/model/credit-structure.model'
import {
  CreditStructureGroup,
  CreditStructureGroupMember,
} from './../../credit/model/credit-structure-group.model'
import { CreditCompareMetricResponse } from './../../credit/model/credit-compare.model'
import { CreditMetricSetting } from './../../credit/model/credit-metric.model'
import {
  CreditResults,
  StructureResultGroup,
} from '../../credit/model/credit-results.model'
import { CashFlowsData } from '../../credit/model/credit-cash-flows.model'
import {
  AnimatedScenariosResults,
  AnimatedScenariosResultsData,
} from '../../credit/model/credit-animated-scenarios.model'
import {
  StratificationAxisField,
  StratificationResults,
} from '../../credit/model/credit-stratification.model'
import { CreditOptimizationResponse } from '../../credit/model/credit-optimization.model'
import {
  PerformanceField,
  PerformanceResults,
} from '../../credit/model/credit-performance.model'
import {
  CreditSubmissionAuthorizationRequest,
  CreditSubmissionReinsurer,
  CreditSubmissionStatusCombined,
  CreditSubmissionStatus,
  CreditSubmissionStructure,
  CreditSubmissionStructureWithProgramData,
  CreditSubmissionUserTranche,
} from '../../credit/model/credit-submission.model'
import {
  CreditFileType,
  CreditPortfolioUploadResults,
} from './../../admin/model/credit-admin.model'
import {
  CreditIssueFot,
  CreditQuoteAssignedLineRow,
  CreditQuoteAuditTable,
  CreditQuoteNewAssignedLine,
  CreditQuoteOverview,
  CreditQuoteSubmissionOverview,
  CreditTrancheQuoteList,
} from './../../credit/model/credit-quote.model'
import addDays from 'date-fns/esm/addDays/index'
import { ErrorComponent } from 'src/app/error/error.component'
import { errorPayload } from 'src/app/error/model/error'
import { throwError } from 'rxjs'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ConfirmationDialogService } from '@shared/services/confirmation-dialog.service'

const API = ENV.internalApi

enum CreditRequests {
  Portfolio = 'Credit Portfolio(s)',
  SelectedPortfolio = 'Selected Credit Portfolio',
  CreditStructure = 'Credit Structure(s)',
  CreditStructureAdmin = 'Credit Admin Structure(s)',
  SelectedCreditStructure = 'Selected Credit Structure',
  CalculationStructure = 'Credit Calculation Structure(s)',
  SelectedCalculationStructure = 'Selected Credit Calculation Structure',
  CreditResults = 'Credit Results',
  SimulatedResults = 'Credit Simulated Results',
  DeterministicResults = 'Credit Deterministic Results',
  CashFlows = 'Credit Cash Flows',
  AnimatedScenarios = 'Credit Animated Scenarios Data',
  CreditStructureGroup = 'Credit Structure Group(s)',
  SelectedCreditStructureGroup = 'Selected Credit Structure Group',
  CreditStructureGroupMember = 'Credit Structure Group Member(s)',
  SelectedCreditStructureGroupMember = 'Selected Credit Structure Group Member',
  GroupCalculationStructure = 'Group Credit Calculation Structure(s)',
  CreditStructureOptimize = 'Credit Structure Optimize',
  CreditOptimizerResults = 'Credit Optimization Results',
  CompareMetrics = 'Credit Compare Metrics',
  MetricSettings = 'Credit Metric Setting(s)',
  CarrierMetricConfigurations = 'Carrier Metric Configuration(s)',
  Stratification = 'Credit Stratification',
  Performance = 'Credit Performance',
  Metrics = 'Credit Metrics',
  CreditSubmissionStructure = 'Credit Submission Structure(s)',
  SelectedCreditSubmissionStructure = 'Selected Credit Submission Structure',
  CreditSubmissionReinsurer = 'Credit Submission Reinsurer(s)',
  CreditSubmissionReinsurerStatus = 'Credit Submission Reinsurer Status',
  CreditSubmissionUserTranche = 'Credit Submission User Tranche(s)',
  CreditQuoteSubmissionAuthorization = 'Credit Quote Submission Authorization',
  CreditQuoteAuditTable = 'Credit Quote Audit Table View',
  CreditQuoteOverviewTable = 'Credit Quote Overview Table View',
  CreditQuoteAssignedLines = 'Credit Quote Assigned Lines',
  Upload = 'Upload',
  CreditModelScenarioMapping = 'Credit Model Scenario Mapping(s)',
  CreditIssueFot = 'Credit Issue FOT',
  CreditIssueAuthorization = 'Credit Issue Authorization',
  CreditUpdateAssignedLine = 'Credit Put Assigned Line',
  GetCreditReinsurers = 'Credit Get Credit Reinsurers',
  CreditQuotePostAssignedLine = 'Credit Post Assigned Line',
  CreditQuoteUploadAuthDoc = 'Credit Quote Upload Authorization Change Documentation',
  CreditQuoteDownloadAuthDoc = 'Credit Quote Download Authorization Change Documentation',
  CreditQuoteDeleteAssignedLine = 'Credit Delete Assigned Line',
  CreditQuoteInformReinsurersOfSignedPercentage = 'Credit Quote Inform Reinsurers of Signed %',
}

enum CreditApiRoutes {
  Portfolio = '/portfolio',
  Structure = '/structure',
  Admin = '/admin',
  LayerResults = '/layerResults',
  Stratifications = '/stratification',
  SimulatedCreditResults = '/simulatedCreditResults',
  DeterministicCreditResults = '/deterministicCreditResults',
  CashFlows = '/cashFlows',
  AnimatedScenarios = '/animatedScenarios',
  CompareMetrics = '/compareMetrics',
  Performance = '/performance',
  Upload = '/upload',
  Submission = '/submission',
  AssignedLines = '/assigned-lines',
  Notify = '/notify',
  Signed = '/signed',
  Reinsurers = '/reinsurers',
  Tranche = '/tranche',
  Summary = '/summary',
  Audit = '/audit',
  Overview = '/overview',
  IssueFot = '/issue-fot',
  IssueAuthorization = '/issue-authorization',
  Group = '/group',
  Carrier = '/carrier',
  Docs = '/docs',
  Status = '/status',
}

enum CreditApiParams {
  PortfolioId = 'portfolioId',
  ProgramId = 'programId',
  Years = 'years',
  GroupId = 'groupId',
  TransactionId = 'transactionId',
  Period = 'period',
  GrossLossScenarioId = 'grossLossScenarioId',
  ColType = 'colType',
  RowType = 'rowType',
  States = 'states',
  Field = 'field',
  GrossLossScenarioIds = 'grossLossScenarioIds',
  ActualLossScenarioIds = 'actualLossScenarioIds',
  ActualGLSIds = 'actualGLSIds',
  IsActual = 'isActual',
  IsSubmission = 'isSubmission',
  FileType = 'fileType',
  SubmissionId = 'submissionId',
  UserId = 'userId',
  CarrierId = 'carrierId',
  MetricConfigurationName = 'name',
  Statuses = 'statuses',
}

@Injectable({
  providedIn: 'root',
})
export class CreditService {
  constructor(
    private http: HttpClient,
    private snackbar: MatSnackBar,
    private confirmation: ConfirmationDialogService
  ) {}

  /* using locktonre-sage-crd-api -> retrieves from mongo db database */
  getPortfolios(): ApiResponse<CreditPortfolio[]> {
    const endpoint = `${API.creditPortfolio}`
    return this.getEntity<CreditPortfolio[]>(endpoint, CreditRequests.Portfolio)
  }
  getPortfolioById(portfolioId: string): ApiResponse<CreditPortfolio> {
    const endpoint = `${API.creditPortfolio}/${portfolioId}`
    return this.getEntity<CreditPortfolio>(
      endpoint,
      CreditRequests.SelectedPortfolio
    )
  }
  postPortfolio(portfolio: CreditPortfolio): ApiResponse<CreditPortfolio> {
    const endpoint = `${API.creditPortfolio}`
    return this.postEntity<CreditPortfolio>(
      portfolio,
      endpoint,
      CreditRequests.Portfolio
    )
  }
  putPortfolio(portfolio: CreditPortfolio): ApiResponse<CreditPortfolio> {
    const endpoint = `${API.creditPortfolio}/${portfolio._id}`
    return this.putEntity<CreditPortfolio>(
      portfolio,
      endpoint,
      CreditRequests.Portfolio
    )
  }
  uploadPortfolioData(
    fileType: CreditFileType,
    file: FormData
  ): ApiResponse<CreditPortfolioUploadResults> {
    const queryParam = this.buildQueryParam(CreditApiParams.FileType, fileType)
    const url = `${API.base}${API.creditPortfolio}${CreditApiRoutes.Upload}?${queryParam}`
    return this.http
      .post<CreditPortfolioUploadResults>(url, file)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError(`POST ${CreditRequests.Upload}`)
      )
  }
  getCalculationStructures(): ApiResponse<CreditCalculationStructure[]> {
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Structure}`
    return this.getEntity<CreditCalculationStructure[]>(
      endpoint,
      CreditRequests.CalculationStructure
    )
  }
  getCreditAdminStructures(): ApiResponse<CreditAdminCalculationStructure[]> {
    const endpoint = `${API.creditStructure}${CreditApiRoutes.Admin}`
    return this.getEntity<CreditAdminCalculationStructure[]>(
      endpoint,
      CreditRequests.CreditStructure
    )
  }
  deleteCreditStructureById(id: string): ApiResponse<string> {
    const endpoint = `${API.creditStructure}/${id}`
    return this.deleteEntity(endpoint, CreditRequests.CreditStructure)
  }
  getCalculationStructureById(
    id: string
  ): ApiResponse<CreditCalculationStructure> {
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Structure}/${id}`
    return this.getEntity<CreditCalculationStructure>(
      endpoint,
      CreditRequests.SelectedCalculationStructure
    )
  }
  postCalculationStructure(
    structure: CreditCalculationStructure
  ): ApiResponse<CreditCalculationStructure> {
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Structure}`
    return this.postEntity<CreditCalculationStructure>(
      structure,
      endpoint,
      CreditRequests.CalculationStructure
    )
  }
  putCalculationStructure(
    structure: CreditCalculationStructure
  ): ApiResponse<CreditCalculationStructure> {
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Structure}/${structure._id}`
    return this.putEntity<CreditCalculationStructure>(
      structure,
      endpoint,
      CreditRequests.CalculationStructure
    )
  }
  postGroupCalculationStructure(
    structure: CreditCalculationStructure
  ): ApiResponse<CreditCalculationStructure> {
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Group}`
    return this.postEntity<CreditCalculationStructure>(
      structure,
      endpoint,
      CreditRequests.GroupCalculationStructure
    )
  }
  putGroupCalculationStructure(
    structure: CreditCalculationStructure
  ): ApiResponse<CreditCalculationStructure> {
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Group}/${structure._id}`
    return this.putEntity<CreditCalculationStructure>(
      structure,
      endpoint,
      CreditRequests.GroupCalculationStructure
    )
  }
  getCreditResults(
    structureId: string,
    grossLossScenarioId: string
  ): ApiResponse<CreditResults> {
    const queryParam = this.buildQueryParam(
      CreditApiParams.GrossLossScenarioId,
      grossLossScenarioId
    )
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Structure}/${structureId}${CreditApiRoutes.LayerResults}`
    return this.getEntity<CreditResults>(
      endpoint,
      CreditRequests.CreditResults,
      queryParam
    )
  }
  getCreditStratificationResults(
    portfolioId: string,
    rowType: StratificationAxisField,
    colType: StratificationAxisField,
    states?: string[]
  ): ApiResponse<StratificationResults> {
    const rowsAndCols: { key: string; value: string | number }[] = [
      { key: CreditApiParams.RowType, value: rowType },
      { key: CreditApiParams.ColType, value: colType },
    ]
    if (states) {
      rowsAndCols.push({
        key: CreditApiParams.States,
        value: states.join(','),
      })
    }
    const queryParams = this.buildQueryParams(rowsAndCols)
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Portfolio}/${portfolioId}${CreditApiRoutes.Stratifications}`
    return this.getEntity<StratificationResults>(
      endpoint,
      CreditRequests.Stratification,
      queryParams
    )
  }
  getPerformanceResults(
    structureId: string,
    field: PerformanceField,
    grossLossIds: string[],
    actualLossIds: string[]
  ): ApiResponse<PerformanceResults> {
    const rowsAndCols: { key: string; value: string | number }[] = [
      { key: CreditApiParams.Field, value: field },
      {
        key: CreditApiParams.GrossLossScenarioIds,
        value: grossLossIds.join(','),
      },
      {
        key: CreditApiParams.ActualLossScenarioIds,
        value: actualLossIds.join(','),
      },
    ]
    const queryParams = this.buildQueryParams(rowsAndCols)
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Structure}/${structureId}${CreditApiRoutes.Performance}`

    const params = queryParams ? `?${queryParams}` : ''
    const url = `${API.base}${endpoint}${params}`

    return this.http
      .get<PerformanceResults>(url)
      .pipe(
        mapToMaybeData(),
        catchError(error => {
          const details = error.detail as string
          if (details) {
            if (details.toLowerCase().includes("actual loan data not found")) {
              this.confirmation.open({
                message: "Missing actuals data. Upload actual data to view this graph with actual data.",
                submitLabel: "Ok",
                hideCancel: true
              })
            }
            this.snackbar.openFromComponent(ErrorComponent  , {
              data: errorPayload(details),
              panelClass: 'app-error',
              duration: 10000,
            })
          }
          return throwError(() => error)
        })
      )
  }
  getSimulatedExceedanceProbabilities(
    structureId: string,
    grossLossScenarioId: string,
    years: number
  ): ApiResponse<StructureResultGroup> {
    const kvps = [
      {
        key: CreditApiParams.GrossLossScenarioId,
        value: grossLossScenarioId,
      },
      { key: CreditApiParams.Period, value: years },
    ]
    const queryParams = this.buildQueryParams(kvps)
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Structure}/${structureId}${CreditApiRoutes.SimulatedCreditResults}`
    return this.getEntity<StructureResultGroup>(
      endpoint,
      CreditRequests.SimulatedResults,
      queryParams
    )
  }
  getDeterministicExceedanceProbabilities(
    structureId: string,
    grossLossScenarioId: string
  ): ApiResponse<StructureResultGroup> {
    const queryParam = this.buildQueryParam(
      CreditApiParams.GrossLossScenarioId,
      grossLossScenarioId
    )
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Structure}/${structureId}${CreditApiRoutes.DeterministicCreditResults}`
    return this.getEntity<StructureResultGroup>(
      endpoint,
      CreditRequests.DeterministicResults,
      queryParam
    )
  }
  getCreditCashFlows(
    structureId: string,
    grossLossScenarioId: string,
    transactionId: string,
    isActual: boolean,
    isSubmission: boolean
  ): ApiResponse<CashFlowsData> {
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Structure}/${structureId}${CreditApiRoutes.CashFlows}`
    const kvps = [
      {
        key: CreditApiParams.GrossLossScenarioId,
        value: grossLossScenarioId,
      },
      { key: CreditApiParams.TransactionId, value: transactionId },
      { key: CreditApiParams.IsActual, value: String(isActual) },
      {
        key: CreditApiParams.IsSubmission,
        value: String(isSubmission),
      },
    ]
    const queryParams = this.buildQueryParams(kvps)

    const params = queryParams ? `?${queryParams}` : ''
    const url = `${API.base}${endpoint}${params}`

    return this.http
      .get<CashFlowsData>(url)
      .pipe(
        mapToMaybeData(),
        catchError(error => {
          const details = error.detail as string
          if (details) {
            if (details.toLowerCase().includes("actual loan data not found")) {
              this.confirmation.open({
                message: "Missing actuals data. Upload actual data to view this graph with actual data.",
                submitLabel: "Ok",
                hideCancel: true
              })
            }
            this.snackbar.openFromComponent(ErrorComponent  , {
              data: errorPayload(details),
              panelClass: 'app-error',
              duration: 10000,
            })
          }
          return throwError(() => error)
        })
      )
  }

  getAnimatedScenariosData(
    structureId: string | undefined,
    grossLossScenarioIds: string,
    actualGLSIds: string
  ): ApiResponse<AnimatedScenariosResults[]> {
    const params = [
      {
        key: CreditApiParams.GrossLossScenarioIds,
        value: grossLossScenarioIds,
      },
      { key: CreditApiParams.ActualGLSIds, value: actualGLSIds },
    ]
    const queryParams = this.buildQueryParams(params)
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Structure}/${structureId}${CreditApiRoutes.AnimatedScenarios}`
    return this.getEntityWithMapping<
      AnimatedScenariosResults[],
      AnimatedScenariosResultsData
    >(
      endpoint,
      CreditRequests.AnimatedScenarios,
      convertAnimatedScenariosData,
      queryParams
    )
  }
  getCompareMetricsForStructure(
    structureId: string,
    carrierId: number,
    name: string
  ): ApiResponse<CreditCompareMetricResponse> {
    const params = [
      {
        key: CreditApiParams.CarrierId,
        value: carrierId,
      },
      { key: CreditApiParams.MetricConfigurationName, value: name },
    ]
    const queryParams = this.buildQueryParams(params)
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Structure}/${structureId}${CreditApiRoutes.CompareMetrics}`
    return this.getEntity<CreditCompareMetricResponse>(
      endpoint,
      CreditRequests.CompareMetrics,
      queryParams
    )
  }

  /* using locktonre-sage-services-standalone -> retrieves from SAGE sql server database */
  getCreditStructureByCalculationStructureId(
    structureId: string
  ): ApiResponse<CreditStructure> {
    const endpoint = `${API.creditStructure}/${structureId}`
    return this.getEntity<CreditStructure>(
      endpoint,
      CreditRequests.SelectedCreditStructure
    ) /* by credit_calculation_structure_id */
  }
  getCreditStructuresByProgramId(
    programId: number
  ): ApiResponse<CreditStructure[]> {
    const endpoint = `${API.creditStructure}`
    const queryParam = this.buildQueryParam(
      CreditApiParams.ProgramId,
      programId
    )
    return this.getEntity<CreditStructure[]>(
      endpoint,
      CreditRequests.CreditStructure,
      queryParam
    )
  }
  postCreditStructure(
    structure: CreditStructure
  ): ApiResponse<CreditStructure> {
    const endpoint = `${API.creditStructure}`
    return this.postEntity<CreditStructure>(
      structure,
      endpoint,
      CreditRequests.CreditStructure
    )
  }
  putCreditStructure(structure: CreditStructure): ApiResponse<CreditStructure> {
    const endpoint = `${API.creditStructure}/${structure.id}`
    return this.putEntity<CreditStructure>(
      structure,
      endpoint,
      CreditRequests.CreditStructure
    )
  }
  getCreditStructureGroupByCalculationStructureId(
    structureId: string
  ): ApiResponse<CreditStructureGroup> {
    const endpoint = `${API.creditStructureGroup}/${structureId}`
    return this.getEntity<CreditStructureGroup>(
      endpoint,
      CreditRequests.SelectedCreditStructureGroup
    ) /* by credit_calculation_structure_id */
  }
  getCreditStructureGroupsByProgramId(
    programId: number
  ): ApiResponse<CreditStructureGroup[]> {
    const endpoint = `${API.creditStructureGroup}`
    const queryParam = this.buildQueryParam(
      CreditApiParams.ProgramId,
      programId
    )
    return this.getEntity<CreditStructureGroup[]>(
      endpoint,
      CreditRequests.CreditStructureGroup,
      queryParam
    )
  }
  postCreditStructureGroup(
    structureGroup: CreditStructureGroup
  ): ApiResponse<CreditStructureGroup> {
    const endpoint = `${API.creditStructureGroup}`
    return this.postEntity<CreditStructureGroup>(
      structureGroup,
      endpoint,
      CreditRequests.CreditStructureGroup
    )
  }
  putCreditStructureGroup(
    structureGroup: CreditStructureGroup
  ): ApiResponse<CreditStructureGroup> {
    const endpoint = `${API.creditStructureGroup}/${structureGroup.id}`
    return this.putEntity<CreditStructureGroup>(
      structureGroup,
      endpoint,
      CreditRequests.CreditStructureGroup
    )
  }
  deleteCreditStructureGroup(
    structureGroup: CreditStructureGroup
  ): ApiResponse<CreditStructureGroup> {
    const endpoint = `${API.creditStructureGroup}/${structureGroup.id}`
    return this.deleteEntity<CreditStructureGroup>(
      endpoint,
      CreditRequests.CreditStructureGroup
    )
  }
  getCreditStructureGroupMembersByGroupId(
    groupId: number
  ): ApiResponse<CreditStructureGroupMember[]> {
    const endpoint = `${API.creditStructureGroupMember}`
    const queryParam = this.buildQueryParam(CreditApiParams.GroupId, groupId)
    return this.getEntity<CreditStructureGroupMember[]>(
      endpoint,
      CreditRequests.SelectedCreditStructureGroupMember,
      queryParam
    ) /* by credit_structure_group_id'*/
  }
  postCreditStructureGroupMember(
    structureGroupMember: CreditStructureGroupMember
  ): ApiResponse<CreditStructureGroupMember> {
    const endpoint = `${API.creditStructureGroupMember}`
    return this.postEntity<CreditStructureGroupMember>(
      structureGroupMember,
      endpoint,
      CreditRequests.CreditStructureGroupMember
    )
  }
  putCreditStructureGroupMember(
    structureGroupMember: CreditStructureGroupMember
  ): ApiResponse<CreditStructureGroupMember> {
    const endpoint = `${API.creditStructureGroupMember}/${structureGroupMember.id}`
    return this.putEntity<CreditStructureGroupMember>(
      structureGroupMember,
      endpoint,
      CreditRequests.CreditStructureGroupMember
    )
  }
  getCreditMetricConfigurationNamesByCarrierId(
    carrierId: number
  ): ApiResponse<string[]> {
    // TODO: update API to be metrics, not compare metrics
    const endpoint = `${API.creditCompareMetrics}${CreditApiRoutes.Carrier}/${carrierId}`
    return this.getEntity<string[]>(
      endpoint,
      CreditRequests.CarrierMetricConfigurations
    )
  }
  getCreditMetricSettingsByCarrierAndName(
    carrierId: number,
    name: string
  ): ApiResponse<CreditMetricSetting[]> {
    const params = [
      {
        key: CreditApiParams.CarrierId,
        value: carrierId,
      },
      { key: CreditApiParams.MetricConfigurationName, value: name },
    ]
    const queryParams = this.buildQueryParams(params)
    const endpoint = `${API.creditCompareMetrics}`
    return this.getEntity<CreditMetricSetting[]>(
      endpoint,
      CreditRequests.MetricSettings,
      queryParams
    )
  }
  saveCreditMetricSettings(
    metricSettings: CreditMetricSetting[]
  ): ApiResponse<CreditMetricSetting[]> {
    const endpoint = `${API.creditCompareMetrics}`
    return this.postEntity<CreditMetricSetting[]>(
      metricSettings,
      endpoint,
      CreditRequests.MetricSettings
    )
  }
  updateCreditMetricSettings(
    metricSettings: CreditMetricSetting[]
  ): ApiResponse<CreditMetricSetting[]> {
    const endpoint = `${API.creditCompareMetrics}`
    return this.putEntity<CreditMetricSetting[]>(
      metricSettings,
      endpoint,
      CreditRequests.MetricSettings
    )
  }
  deleteCreditMetricSetting(id: string): ApiResponse<CreditMetricSetting> {
    const endpoint = `${API.creditCompareMetrics}/${id}`
    return this.deleteEntity(endpoint, CreditRequests.MetricSettings)
  }
  // TODO: change any to string (processId)
  postCalculateOptimizedStructure(
    creditStructureOptimizationInput: CreditStructureOptimizationInput,
    carrierId: number,
    metricConfigurationName: string
  ): ApiResponse<any> {
    const params = [
      {
        key: CreditApiParams.CarrierId,
        value: carrierId,
      },
      {
        key: CreditApiParams.MetricConfigurationName,
        value: metricConfigurationName,
      },
    ]
    const queryParams = this.buildQueryParams(params)
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Structure}${API.creditStructureOptimize}`
    return this.postEntity<CreditStructureOptimizationInput>(
      creditStructureOptimizationInput,
      endpoint,
      CreditRequests.CreditStructureOptimize,
      queryParams
    )
  }
  getOptimizationResponse(
    processId: string
  ): ApiResponse<CreditOptimizationResponse> {
    const endpoint = `${API.creditCalculation}${API.creditOptimizer}/${processId}`
    return this.getEntity<CreditOptimizationResponse>(
      endpoint,
      CreditRequests.CreditOptimizerResults
    )
  }
  getCreditSubmissionStructureBySubmissionStructureId(
    structureId: string
  ): ApiResponse<CreditSubmissionStructure> {
    const endpoint = `${API.creditSubmissionStructure}/${structureId}`
    return this.getEntity<CreditSubmissionStructure>(
      endpoint,
      CreditRequests.SelectedCreditSubmissionStructure
    ) /* by credit_submission_structure_id */
  }
  getCreditSubmissionStructures(
    years: string[],
    status: CreditSubmissionStatusCombined[] = [CreditSubmissionStatus.Released]
  ): ApiResponse<CreditSubmissionStructureWithProgramData[]> {
    const endpoint = `${API.creditSubmissionStructure}`
    const kvps = [
      {
        key: CreditApiParams.Years,
        value: years.join(','),
      },
      { key: CreditApiParams.Statuses, value: status.join(',') },
    ]
    const queryParams = this.buildQueryParams(kvps)
    return this.getEntity<CreditSubmissionStructureWithProgramData[]>(
      endpoint,
      CreditRequests.CreditSubmissionStructure,
      queryParams
    )
  }
  postCreditSubmissionStructure(
    structure: CreditSubmissionStructure
  ): ApiResponse<CreditSubmissionStructure> {
    const endpoint = `${API.creditSubmissionStructure}`
    return this.postEntity<CreditSubmissionStructure>(
      structure,
      endpoint,
      CreditRequests.CreditSubmissionStructure
    )
  }
  putCreditSubmissionStructure(
    structure: CreditSubmissionStructure
  ): ApiResponse<CreditSubmissionStructure> {
    const endpoint = `${API.creditSubmissionStructure}/${structure.id}`
    return this.putEntity<CreditSubmissionStructure>(
      structure,
      endpoint,
      CreditRequests.CreditSubmissionStructure
    )
  }
  getCreditSubmissionResults(
    structureId: string,
    grossLossScenarioId: string,
    isActual: boolean
  ): ApiResponse<CreditResults> {
    const kvps = [
      {
        key: CreditApiParams.GrossLossScenarioId,
        value: grossLossScenarioId,
      },
      { key: CreditApiParams.IsActual, value: String(isActual) },
    ]
    const queryParams = this.buildQueryParams(kvps)
    // TODO: update frontend endpoint layerResults and other camelCase routes to hyphen separated
    const endpoint = `${API.creditSubmissionStructure}/${structureId}/layer-results`
    return this.getEntity<CreditResults>(
      endpoint,
      CreditRequests.CreditResults,
      queryParams
    )
  }
  createSubmissionStructure(
    structure: CreditCalculationStructure
  ): ApiResponse<CreditCalculationStructure> {
    const endpoint = `${API.creditSubmissionStructure}${CreditApiRoutes.Submission}`
    return this.postEntity<CreditCalculationStructure>(
      structure,
      endpoint,
      CreditRequests.CalculationStructure
    )
  }
  updateSubmissionStructure(
    structure: CreditCalculationStructure
  ): ApiResponse<CreditCalculationStructure> {
    const endpoint = `${API.creditSubmissionStructure}${CreditApiRoutes.Submission}/${structure._id}`
    return this.putEntity<CreditCalculationStructure>(
      structure,
      endpoint,
      CreditRequests.CalculationStructure
    )
  }
  getCreditSubmissionReinsurersBySubmissionId(
    submissionId: number
  ): ApiResponse<CreditSubmissionReinsurer[]> {
    const endpoint = `${API.creditSubmissionReinsurer}`
    const queryParam = this.buildQueryParam(
      CreditApiParams.SubmissionId,
      submissionId
    )
    return this.getEntity<CreditSubmissionReinsurer[]>(
      endpoint,
      CreditRequests.CreditSubmissionReinsurer,
      queryParam
    )
  }
  createCreditSubmissionReinsurer(
    submissionReinsurer: CreditSubmissionReinsurer
  ): ApiResponse<CreditSubmissionReinsurer> {
    const endpoint = `${API.creditSubmissionReinsurer}`
    return this.postEntity<CreditSubmissionReinsurer>(
      submissionReinsurer,
      endpoint,
      CreditRequests.CreditSubmissionReinsurer
    )
  }
  updateCreditSubmissionReinsurer(
    submissionReinsurer: CreditSubmissionReinsurer
  ): ApiResponse<CreditSubmissionReinsurer> {
    const endpoint = `${API.creditSubmissionReinsurer}/${submissionReinsurer.id}`
    return this.putEntity<CreditSubmissionReinsurer>(
      submissionReinsurer,
      endpoint,
      CreditRequests.CreditSubmissionReinsurer
    )
  }
  updateCreditSubmissionReinsurerStatus(
    submissionReinsurer: CreditSubmissionReinsurer
  ): ApiResponse<CreditSubmissionReinsurer> {
    const endpoint = `${API.creditSubmissionReinsurer}${CreditApiRoutes.Status}`
    return this.putEntity<CreditSubmissionReinsurer>(
      submissionReinsurer,
      endpoint,
      CreditRequests.CreditSubmissionReinsurerStatus
    )
  }
  getCreditSubmissionUserTranchesBySubmissionAndUser(
    submissionId: number
  ): ApiResponse<CreditSubmissionUserTranche[]> {
    const endpoint = `${API.creditSubmissionUserTranche}`
    const queryParam = this.buildQueryParam(
      CreditApiParams.SubmissionId,
      submissionId
    )
    return this.getEntity<CreditSubmissionUserTranche[]>(
      endpoint,
      CreditRequests.CreditSubmissionUserTranche,
      queryParam
    )
  }
  saveCreditSubmissionUserTranche(
    userTranche: CreditSubmissionUserTranche
  ): ApiResponse<CreditSubmissionUserTranche> {
    const endpoint = `${API.creditSubmissionUserTranche}`
    return this.postEntity<CreditSubmissionUserTranche>(
      userTranche,
      endpoint,
      CreditRequests.CreditSubmissionUserTranche
    )
  }
  submitQuoteAuthorization(
    structure: CreditSubmissionAuthorizationRequest
  ): ApiResponse<CreditSubmissionAuthorizationRequest> {
    const endpoint = `${API.creditQuote}`
    return this.postEntity<CreditSubmissionAuthorizationRequest>(
      structure,
      endpoint,
      CreditRequests.CreditQuoteSubmissionAuthorization
    )
  }
  getQuoteSummary(submissionId: number): ApiResponse<CreditTrancheQuoteList[]> {
    const endpoint = `${API.creditQuote}${CreditApiRoutes.Submission}/${submissionId}${CreditApiRoutes.Summary}`
    return this.getEntity<CreditTrancheQuoteList[]>(
      endpoint,
      CreditRequests.CreditSubmissionUserTranche
    )
  }
  getQuoteAuditByTrancheId(
    trancheId: string
  ): ApiResponse<CreditQuoteAuditTable> {
    const endpoint = `${API.creditQuote}${CreditApiRoutes.Audit}${CreditApiRoutes.Tranche}/${trancheId}`
    return this.getEntity<CreditQuoteAuditTable>(
      endpoint,
      CreditRequests.CreditQuoteAuditTable
    )
  }
  getQuoteOverviewByTrancheId(
    trancheId: string
  ): ApiResponse<CreditQuoteOverview> {
    const endpoint = `${API.creditQuote}${CreditApiRoutes.Overview}${CreditApiRoutes.Tranche}/${trancheId}`
    return this.getEntity<CreditQuoteOverview>(
      endpoint,
      CreditRequests.CreditQuoteOverviewTable
    )
  }
  getQuotesForReinsurer(
    reinsurerId: number
  ): ApiResponse<CreditQuoteSubmissionOverview> {
    const endpoint = `${API.creditQuote}${CreditApiRoutes.Submission}/${reinsurerId}`
    return this.getEntity<CreditQuoteSubmissionOverview>(
      endpoint,
      CreditRequests.CreditQuoteOverviewTable
    )
  }

  getAllModelScenarioMappings(): ApiResponse<CreditModelScenarioMapping[]> {
    const endpoint = `${API.creditModelScenario}`
    return this.getEntity<CreditModelScenarioMapping[]>(
      endpoint,
      CreditRequests.CreditModelScenarioMapping
    )
  }
  postIssueFot(data: CreditIssueFot): ApiResponse<CreditIssueFot> {
    const url = `${API.creditQuote}${CreditApiRoutes.IssueFot}`
    return this.postEntity<CreditIssueFot>(
      data,
      url,
      CreditRequests.CreditIssueFot
    )
  }
  postIssueAssignedLines(
    trancheId: string,
    authorized: number
  ): ApiResponse<Record<string, any>> {
    const url = `${API.creditQuote}${CreditApiRoutes.Submission}${CreditApiRoutes.IssueAuthorization}/${trancheId}/`
    return this.postEntity(
      {
        authorized_percent: authorized,
      },
      url,
      CreditRequests.CreditIssueAuthorization
    )
  }
  issueAuthorization(
    trancheId: string,
    authorized: number
  ): ApiResponse<Record<string, any>> {
    const url = `${API.creditQuote}${CreditApiRoutes.Submission}${CreditApiRoutes.IssueAuthorization}/${trancheId}/`
    return this.postEntity(
      {
        authorized_percent: authorized,
      },
      url,
      CreditRequests.CreditIssueAuthorization
    )
  }
  getAssignedLines(
    trancheId: string
  ): ApiResponse<CreditQuoteAssignedLineRow[]> {
    const endpoint = `${API.creditQuote}${CreditApiRoutes.AssignedLines}${CreditApiRoutes.Tranche}/${trancheId}`
    return this.getEntity<CreditQuoteAssignedLineRow[]>(
      endpoint,
      CreditRequests.CreditQuoteAssignedLines
    )
  }
  putAssignedLine(
    line: CreditQuoteAssignedLineRow
  ): ApiResponse<CreditQuoteAssignedLineRow> {
    const endpoint = `${API.creditQuote}${CreditApiRoutes.AssignedLines}`
    return this.putEntity<CreditQuoteAssignedLineRow>(
      line,
      endpoint,
      CreditRequests.CreditUpdateAssignedLine
    )
  }
  getCreditReinsurers(
    submissionId: string
  ): ApiResponse<Record<number, string>> {
    const endpoint = `${API.creditQuote}${CreditApiRoutes.Reinsurers}/${submissionId}`
    return this.getEntity<Record<number, string>>(
      endpoint,
      CreditRequests.GetCreditReinsurers
    )
  }
  postAssignedLine(
    line: CreditQuoteNewAssignedLine,
    trancheId: string
  ): ApiResponse<CreditQuoteNewAssignedLine> {
    const endpoint = `${API.creditQuote}${CreditApiRoutes.AssignedLines}/${trancheId}`
    return this.postEntity<CreditQuoteNewAssignedLine>(
      line,
      endpoint,
      CreditRequests.CreditQuotePostAssignedLine
    )
  }
  deleteAssignedLine(lineId: number): ApiResponse<number> {
    const endpoint = `${API.creditQuote}${CreditApiRoutes.AssignedLines}/${lineId}`
    return this.deleteEntity<number>(
      endpoint,
      CreditRequests.CreditQuoteDeleteAssignedLine
    )
  }
  uploadAssignedLinesDocs(data: Blob): ApiResponse<string> {
    const endpoint = `${API.creditQuote}${CreditApiRoutes.AssignedLines}${CreditApiRoutes.Docs}`
    const url = `${environment.internalApi.base}${endpoint}`

    return this.http
      .post<string>(url, data)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError(`POST ${CreditRequests.CreditQuoteUploadAuthDoc}`)
      )
  }
  downloadAssignedLinesDocs(id: string): ApiResponse<string> {
    const endpoint = `${API.creditQuote}${CreditApiRoutes.AssignedLines}${CreditApiRoutes.Docs}/${id}`
    return this.getEntity<string>(
      endpoint,
      CreditRequests.CreditQuoteUploadAuthDoc
    )
  }
  informReinsurersOfSignedPercentage(id: string): ApiResponse<void> {
    const endpoint = `${API.creditQuote}${CreditApiRoutes.Notify}${CreditApiRoutes.Signed}/${id}`
    return this.getEntity(
      endpoint,
      CreditRequests.CreditQuoteInformReinsurersOfSignedPercentage
    )
  }

  private getEntity<T>(
    endpoint: string,
    requestType: CreditRequests,
    queryParams?: string
  ): ApiResponse<T> {
    const params = queryParams ? `?${queryParams}` : ''
    const url = `${API.base}${endpoint}${params}`
    return this.http
      .get<T>(url)
      .pipe(mapToMaybeData(), catchAndHandleError(`GET ${requestType}`))
  }
  /**
   * @param T type getting mapped to
   * @param U type getting mapped from
   */
  private getEntityWithMapping<T, U>(
    endpoint: string,
    requestType: CreditRequests,
    mapCustom: (data: U) => T,
    queryParams?: string
  ): ApiResponse<T> {
    const params = queryParams ? `?${queryParams}` : ''
    const url = `${API.base}${endpoint}${params}`
    return this.http.get<U>(url).pipe(
      map((data: U) => mapCustom(data)),
      mapToMaybeData(),
      catchAndHandleError(`GET ${requestType}`)
    )
  }
  private postEntity<T>(
    entity: T,
    endpoint: string,
    requestType: CreditRequests,
    queryParams?: string
  ): ApiResponse<T> {
    const params = queryParams ? `?${queryParams}` : ''
    const url = `${API.base}${endpoint}${params}`
    return this.http
      .post<T>(url, entity)
      .pipe(mapToMaybeData(), catchAndHandleError(`POST ${requestType}`))
  }
  private putEntity<T>(
    entity: T,
    endpoint: string,
    requestType: CreditRequests
  ): ApiResponse<T> {
    const url = `${API.base}${endpoint}`
    return this.http
      .put<T>(url, entity)
      .pipe(mapToMaybeData(), catchAndHandleError(`PUT ${requestType}`))
  }
  private deleteEntity<T>(
    endpoint: string,
    requestType: CreditRequests
  ): ApiResponse<T> {
    const url = `${API.base}${endpoint}`
    return this.http
      .delete<T>(url)
      .pipe(mapToMaybeData(), catchAndHandleError(`DELETE ${requestType}`))
  }
  private buildQueryParams(
    kvps: { key: string; value: string | number }[]
  ): string {
    return kvps.map(kvp => this.buildQueryParam(kvp.key, kvp.value)).join('&')
  }
  private buildQueryParam(key: string, value: string | number): string {
    return `${key}=${value}`
  }
}
