import { Selection } from 'd3-selection'

interface ChartMargin {
  top: number
  right: number
  bottom: number
  left: number
}
export default function createChartLabels(
  chart: Selection<SVGGElement, unknown, null, undefined>,
  xLabel: string,
  yLabel: string,
  chartLabel: string,
  margin: ChartMargin,
  width: number,
  height: number,
  min: boolean,
  reverseAxis?: boolean,
  revenueDashboard?: boolean,
  lightChartMode?: boolean,
  tightXLabel?: boolean,
  contribution?: boolean
): void {
  if (xLabel && !min && !revenueDashboard) {
    const xHeight = reverseAxis 
      ? 50 
      : tightXLabel
      ? 75 
      : 110

    chart
      .append('text')
      .attr('text-anchor', 'center')
      .style('fill', lightChartMode ? 'black' : 'var(--body)')
      .style('font-size', '20px')
      .attr('x', width * 0.5 - margin.left)
      .attr('y', height + xHeight)
      .text(xLabel)
  }

  if ((yLabel && !min) || (yLabel && revenueDashboard)) {
    chart
      .append('text')
      .attr('text-anchor', 'center')
      .style('fill', lightChartMode ? 'black' : 'var(--body)')
      .style('font-size', revenueDashboard ? '16px' : '20px')
      .attr(
        'x',
        revenueDashboard ? -(height * 0.6) : -(height * 0.8 + yLabel.length * 2)
      )
      .attr('y', revenueDashboard ? -25 : -(reverseAxis ? 120 : 60))
      .attr('transform', 'rotate(-90)')
      .text(yLabel)
  }

  if ((chartLabel && !min) || (chartLabel && revenueDashboard)) {
    chart
      .append('text')
      .attr('class', 'chart-title')
      .attr('text-anchor', 'top')
      .attr(
        'x',
        revenueDashboard
          ? chartLabel === 'Global'
            ? width / 2 - 20
            : chartLabel === 'US'
            ? width / 2 - 12
            : width / 2 - 42
          : 0
      )
      .attr('y', -25)
      .style('fill', lightChartMode ? 'black' : 'var(--body)')
      .style('font-size', revenueDashboard ? '18px' : '20px')
      .text(chartLabel)
  }
}
