import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { AppState } from 'src/app/core/store'
import { PricingCurveService } from '../../api/pricingcurve/pricing-curve.service'
import * as fromActions from './pricing-curve.actions'
import * as fromSelectors from './pricing-curve.selectors'
import { switchMap, withLatestFrom, map, filter } from 'rxjs/operators'
import { rejectError } from 'src/app/api/util'
import {
  CombinedSelectors,
  CreditCurveLayer,
  PricingCurveLayer,
} from '../model/pricing-curve.model'
import {
  getKeyValuePairs,
  getSavedCurveSelectorsKvpFromSelectors,
} from '../pricing-curve.utils'
import { of } from 'rxjs'
import {
  PricingCurveExportPage,
  PricingCurvePageTypes,
} from '../export/pricing-curve-export.model'
import { PricingCurveExportService } from '../export/pricing-curve-export.service'
import { waitFor } from '@shared/observables'
import { TechnicalPremiumSyncService } from 'src/app/analysis/technical-premium/technical-premium-sync.service'
import { selectPrograms } from 'src/app/core/store/program/program.selectors'
import { selectClients } from 'src/app/core/store/clients.selectors'
import { selectCurrentClientID } from 'src/app/core/store/broker/broker.selectors'
import { flatten, pipe, pluck, uniq } from 'ramda'
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable()
export class PricingCurveEffects {
  private actions$ = inject(Actions)

  constructor(
    private service: PricingCurveService,
    private store: Store<AppState>,
    private exportService: PricingCurveExportService,
    private tpSyncService: TechnicalPremiumSyncService,
    private snackBar: MatSnackBar
  ) {}

  addDataDialogOpened$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addDataDialogOpened),
      withLatestFrom(
        this.store.select(fromSelectors.selectNextLocalGraphId),
        this.store.select(fromSelectors.selectNextGraphColor)
      ),
      switchMap(([, id, graphColorClass]) => {
        const actions: Action[] = [
          fromActions.filtersChanged({}),
          fromActions.fetchSavedCurves({ useSavedCurveSelectors: false }),
          fromActions.updateWorkingCurveData({
            curveData: { id, graphColorClass },
          }),
        ]
        return actions
      })
    )
  )

  filtersChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.filtersChanged,
        fromActions.cleanFilters,
        fromActions.clearFilter,
        fromActions.clearDateInterval,
        fromActions.updateDateIntervalValues
      ),
      withLatestFrom(
        this.store.select(fromSelectors.selectPricingCurveContext),
        this.store.select(fromSelectors.selectWorkingPricingCurveData)
      ),
      switchMap(([, context, curveData]) => {
        const actions: Action[] = []
        if (!curveData) {
          return actions
        }
        actions.push(
          fromActions.fetchLayers({
            layerSplitView: curveData.layerSplitView,
          })
        )

        // Only fetch selectors for pricing curve, not credit
        if (context === 'pricing-curve') {
          actions.push(
            fromActions.fetchSelectors({
              layerSplitView: curveData.layerSplitView,
            })
          )
        }
        return actions
      })
    )
  )

  fetchSelectorData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.fetchSelectors),
      withLatestFrom(
        this.store.select(fromSelectors.selectFilters),
        this.store.select(fromSelectors.selectWorkingDateIntervals),
        this.store.select(fromSelectors.selectWorkingCombinedSelectors)
      ),
      switchMap(
        ([{ layerSplitView }, filters, intervals, combinedSelectors]) => {
          return this.service.getSelectors(
            getKeyValuePairs(intervals, filters, layerSplitView),
            combinedSelectors
          )
        }
      ),
      rejectError(error =>
        this.store.dispatch(fromActions.fetchSelectorsFailure({ error }))
      ),
      switchMap(combinedSelectors => {
        const actions: Action[] = [
          fromActions.fetchSelectorsSuccess({ combinedSelectors }),
        ]

        return actions
      })
    )
  )

  fetchLayerData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.fetchLayers),
      withLatestFrom(
        this.store.select(fromSelectors.selectFilters),
        this.store.select(fromSelectors.selectWorkingDateIntervals),
        this.store.select(fromSelectors.selectPricingCurveContext)
      ),
      switchMap(([{ layerSplitView }, filters, intervals, context]) => {
        return this.service.getLayers(
          getKeyValuePairs(intervals, filters, layerSplitView),
          context
        )
      }),
      rejectError(error =>
        this.store.dispatch(fromActions.fetchLayersFailure({ error }))
      ),
      withLatestFrom(
        this.store.select(fromSelectors.selectPricingCurveContext)
      ),
      map(([data, context]) => {
        const isCredit = context === 'credit'
        return fromActions.fetchLayersSuccess({
          creditLayers: isCredit ? (data as CreditCurveLayer[]) : undefined,
          layers: !isCredit ? (data as PricingCurveLayer[]) : undefined,
        })
      })
    )
  )

  submitCreditCurve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.submitCreditCurve),
      filter(({ curve }) => !!curve.creditPredictionColumns),
      withLatestFrom(this.store.select(selectClients)),
      switchMap(([{ curve, saveCurve }, clients]) => {
        return this.service.getCreditPredictions(curve, saveCurve, clients)
      }),
      rejectError(error =>
        this.store.dispatch(fromActions.submitCreditCurveFailure({ error }))
      ),
      map(curveData => fromActions.addCurveToGraph({ curveData }))
    )
  )

  saveCurve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.savePricingCurve),
      withLatestFrom(
        this.store.select(fromSelectors.selectPricingCurveContext),
        this.store.select(selectClients)
      ),
      switchMap(([{ curve }, context, clients]) => {
        if (curve.id >= 0) {
          return this.service.updateSavedPricingCurve(
            curve.id,
            curve,
            context,
            clients
          )
        } else {
          return this.service.postPricingCurve(curve, context, clients)
        }
      }),
      rejectError(error =>
        this.store.dispatch(fromActions.savePricingCurveFailure({ error }))
      ),
      switchMap(curveData => [
        fromActions.addCurveToGraph({ curveData }),
        fromActions.fetchSavedCurves({ useSavedCurveSelectors: false }),
        fromActions.savePricingCurveSuccess(),
      ])
    )
  )

  updateIsSavingLayers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.savePricingCurve),
      withLatestFrom(
        this.store.select(fromSelectors.selectWorkingPricingCurveData)
      ),
      waitFor(this.actions$, [fromActions.savePricingCurveSuccess]),
      switchMap(([, { id }]) => {
        return this.tpSyncService.doesPricingCurveNeedToSaveLayers(id).pipe(
          rejectError(error =>
            this.store.dispatch(fromActions.savePricingCurveFailure({ error }))
          ),
          map(newValue => fromActions.updateIsSavingLayers({ newValue }))
        )
      })
    )
  )

  updateTechnicalPremiumForLayersUsingCurve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.savePricingCurve),
      withLatestFrom(
        this.store.select(fromSelectors.selectWorkingPricingCurveData),
        this.store.select(selectPrograms),
        this.store.select(fromSelectors.selectSavedCurves)
      ),
      waitFor(this.actions$, [fromActions.savePricingCurveSuccess]),
      switchMap(([, { id }, programs, savedCurves]) => {
        return this.tpSyncService.updateLayersFromPricingCurve(
          id,
          programs,
          savedCurves
        )
      }),
      rejectError(error =>
        this.store.dispatch(fromActions.savePricingCurveFailure({ error }))
      ),
      map(() => fromActions.updateIsSavingLayers({ newValue: false }))
    )
  )

  savedPricingCurveFiltersChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.savedCurveFiltersChanged,
        fromActions.savedCurveIntervalFilterChanged,
        fromActions.clearSavedCurveDateInterval,
        fromActions.clearSavedCurveFilter,
        fromActions.toggleCurveImmutabilitySuccess
      ),
      withLatestFrom(
        this.store.select(fromSelectors.selectSavedCurveSelectors),
        this.store.select(fromSelectors.selectSavedCurveCreatorNameMap),
        this.store.select(fromSelectors.selectPricingCurveContext)
      ),
      switchMap(([_, filters, nameMap, context]) =>
        this.service.getPricingCurves(
          getSavedCurveSelectorsKvpFromSelectors(filters, nameMap),
          context
        )
      ),
      rejectError(error =>
        this.store.dispatch(fromActions.fetchSavedCurvesFailure({ error }))
      ),
      switchMap(data => {
        const activeFilters = data
          .map(dataSet => dataSet.active_filters)
          .filter((dataSet): dataSet is CombinedSelectors => !!dataSet)
        const actions: Action[] = [
          fromActions.fetchFilteredSavedCurvesSuccess({ data }),
          fromActions.initSavedCurveSelectors({
            selectors: activeFilters,
            names: data.map(entry => entry.fullName),
          }),
        ]

        return actions
      })
    )
  )

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initPricingCurveData),
      map(({ filterByCarrier }) =>
        fromActions.fetchSavedCurves({
          useSavedCurveSelectors: false,
          filterByCarrier,
        })
      )
    )
  )

  initCreditCarriers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initPricingCurveData),
      filter(({ context }) => context === 'credit'),
      switchMap(({ context }) => this.service.getLayers({}, context)),
      rejectError(error =>
        this.store.dispatch(fromActions.initCreditCarriersFailure({ error }))
      ),
      map(response => {
        const uniqueOrgs: string[] = pipe(
          pluck('organization'),
          flatten,
          uniq
        )((response as CreditCurveLayer[]) ?? [])
        return fromActions.initCreditCarriersSuccess({ carriers: uniqueOrgs })
      })
    )
  )

  triggerLoadSavedPricingCurve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.removePricingCurveFromGraph,
        fromActions.resetSavedCurveSelectors,
        fromActions.addDataDialogClosed
      ),
      map(() => fromActions.fetchSavedCurves({ useSavedCurveSelectors: false }))
    )
  )

  loadSavedPricingCurves$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.fetchSavedCurves),
      withLatestFrom(
        this.store.select(fromSelectors.selectSavedCurveSelectors),
        this.store.select(fromSelectors.selectSavedCurveCreatorNameMap),
        this.store.select(fromSelectors.selectPricingCurveContext),
        this.store.select(selectCurrentClientID)
      ),
      switchMap(
        ([
          { useSavedCurveSelectors, filterByCarrier },
          filters,
          nameMap,
          context,
          clientId,
        ]) => {
          if (!filterByCarrier) {
            return this.service.getPricingCurves(
              useSavedCurveSelectors
                ? getSavedCurveSelectorsKvpFromSelectors(filters, nameMap)
                : {},
              context
            )
          } else {
            return this.service.getPricingCurvesForCarrier(Number(clientId))
          }
        }
      ),
      rejectError(error =>
        this.store.dispatch(fromActions.fetchSavedCurvesFailure({ error }))
      ),
      switchMap(data => {
        const activeFilters = data
          .map(dataSet => dataSet.active_filters)
          .filter((dataSet): dataSet is CombinedSelectors => !!dataSet)
        const actions: Action[] = [
          fromActions.fetchSavedCurvesSuccess({ data }),
          fromActions.initSavedCurveSelectors({
            selectors: activeFilters,
            names: data.map(entry => entry.fullName),
          }),
        ]

        return actions
      })
    )
  )

  deleteSavedPricingCurve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSavedPricingCurve),
      withLatestFrom(
        this.store.select(fromSelectors.selectPricingCurveContext)
      ),
      switchMap(([action, context]) =>
        this.service.deletePricingCurve(action.id, context)
      ),
      rejectError(error =>
        this.store.dispatch(
          fromActions.deleteSavedPricingCurveFailure({ error })
        )
      ),
      map(() => fromActions.fetchSavedCurves({ useSavedCurveSelectors: false }))
    )
  )

  loadSavedPricingCurve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadSavedPricingCurve),
      withLatestFrom(
        this.store.select(fromSelectors.selectPricingCurveContext)
      ),
      switchMap(([{ id }, context]) =>
        this.service.getPricingCurve(id, context)
      ),
      rejectError(error =>
        this.store.dispatch(fromActions.loadSavedPricingCurveFailure({ error }))
      ),
      withLatestFrom(this.store.select(fromSelectors.selectNextGraphColor)),
      map(([curveData, graphColorClass]) =>
        fromActions.addCurveToGraph({
          curveData: {
            ...curveData,
            graphColorClass,
          },
        })
      )
    )
  )

  exportPricingCurveState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.exportPricingCurveState),
        switchMap(action =>
          of(action).pipe(
            withLatestFrom(
              this.store.select(
                fromSelectors.selectPricingCurvesByIds(action.includedCurves)
              )
            )
          )
        ),

        map(([{ exportLabel, graphBase64, options }, pricingCurves]) => {
          const pages: PricingCurveExportPage[] = []
          pages.push(
            ...pricingCurves.map((data, index) => ({
              id: index + 1,
              pageType: data.isLayerSet
                ? PricingCurvePageTypes.MANUAL_POINTS
                : PricingCurvePageTypes.CURVE,
              data,
            }))
          )

          this.exportService.export(exportLabel, options, pages, graphBase64)
        })
      ),
    { dispatch: false }
  )

  toggleCurveImmutability$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.toggleCurveImmutability),
      withLatestFrom(
        this.store.select(fromSelectors.selectIsUserPricingCurveAdmin),
        this.store.select(fromSelectors.selectPricingCurveContext)
      ),
      filter(([_, isAdmin]) => isAdmin),
      switchMap(([{ id }, _, context]) =>
        this.service.getPricingCurve(id, context)
      ),
      rejectError(error =>
        this.store.dispatch(fromActions.loadSavedPricingCurveFailure({ error }))
      ),
      withLatestFrom(
        this.store.select(selectClients),
        this.store.select(fromSelectors.selectPricingCurveContext)
      ),
      switchMap(([curve, clients, context]) =>
        this.service.updateSavedPricingCurve(
          curve.id,
          {
            ...curve,
            isImmutable: !curve.isImmutable,
          },
          context,
          clients
        )
      ),
      rejectError(error =>
        this.store.dispatch(fromActions.savePricingCurveFailure({ error }))
      ),
      map(({ isImmutable }) => {
        this.snackBar.open(
          `Successfully changed curve immutability to: ${isImmutable}`,
          'X',
          { duration: 2000 }
        )
        return fromActions.toggleCurveImmutabilitySuccess()
      })
    )
  )
}
