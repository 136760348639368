<div class="count-panels">
  <div *ngFor="let count of dataCountValues">
    <div class="count-panel" matTooltip="{{ count.tooltip }}">
      <span class="title"> {{ count.label }}: </span>
      <span class="count">
        {{ getCountValue(count) | number: '1.0-0' | emptyValue }}
      </span>
    </div>
  </div>
</div>
