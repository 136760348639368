import { Numeric, scaleTime } from 'd3'
import { quadtree as d3Quadtree, Quadtree } from 'd3-quadtree'
import { ScaleLinear, scaleLinear } from 'd3-scale'
import { select, Selection } from 'd3-selection'
import { annotationCallout } from 'd3-svg-annotation'
import { zoom as d3Zoom, ZoomBehavior } from 'd3-zoom'
import * as fc from 'd3fc'
import { pipe, sortBy } from 'ramda'
import { Subject } from 'rxjs'
import { debounceTime, takeUntil } from 'rxjs/operators'
import {
  calculateAggregateRegressionLines,
  CalculateAggregateRegressionLinesOptions,
} from '../api/benchmark/data/calculate-aggregate-lines'
import { toArray } from '@shared/util/operators'
import { resolveValueFn, ValueFn } from '@shared/util/value-fn'

import { decoratePointCircleSegment } from './utils/circle-segment'
import { applyExtentLimit, Coord, CoordExtents, GraphingScaleType, Rect, ScalarScale, } from './utils/coord'
import createZoomMultiAxisDecorator, { ZoomMultiAxisDecorator, } from './utils/create-zoom-multi-axis-decorator'
import { decorateClass, decorateFillWithOpacityAndDefault, } from './utils/graphing-decorators'

import {
  D3SeriesDatumWithAnnotations,
  getGraphLineDatumValue,
  GraphingLineData,
  GraphingLineDatum,
  GraphingMetricExtents,
} from './models/graphing.model'
import { seriesSvgAnnotation } from './utils/series-annotation'
import { D3SeriesAnnotation } from './models/series-annotation.model'

export interface DefaultBubbleDatum {
  x: number
  y: number
  size?: number
}

export interface BubbleChartOptions<T = DefaultBubbleDatum, TLine = Coord> {
  crossValue: (d: T) => number | Numeric
  mainValue: (d: T) => number
  size: ValueFn<number, T, number>
  label?: (d: T) => string | undefined
  /* If `useCanvas` is true, should return an RGB color object */
  colorClass: (d: T, i: number) => string | undefined
  fillColor?: (d: T) => string | undefined
  strokeColor?: (d: T) => string | undefined
  strokeWidth?: (d: T) => number | undefined
  fillOpacity?: ValueFn<string | number, T>
  useAggregateStyle?: ValueFn<boolean, T>
  segmentIndex?: ValueFn<number, T>
  segmentCount?: ValueFn<number, T>
  segmentOffset?: ValueFn<number, T>
  lineCrossValue: (d: TLine) => number | Numeric
  lineMainValue: (d: TLine) => number
  lineColorClass: ValueFn<string, TLine[]>
  lineStrokeWidth: ValueFn<number, TLine[]>
  lineStrokeDashArray: ValueFn<string, TLine[]>
  lineStrokeOpacity: ValueFn<number, TLine[]>
  chartLabel?: string
  xScaleType: GraphingScaleType
  yScaleType: GraphingScaleType
  xLabel?: string
  yLabel?: string
  xTicks?: number
  yTicks?: number
  xTickFormat: (n: number) => string | number
  yTickFormat: (n: number) => string | number
  yAxisWidth?: string
  extents?: GraphingMetricExtents
  forceExtents?: boolean
  regressionOptions?: CalculateAggregateRegressionLinesOptions
  annotationBuilder?: (d: T) => D3SeriesAnnotation
  defaultColor: string
  zoomMin: number
  zoomMax: number
  /** Set initial x scale from the x% to 1 - x% of the data.
   *
   * For instance, if set to `10`, x-min will be the value at the 10th
   * percentile, and x-max will be the 90th percentile.
   */
  xScalePercentile?: number
  /** Set initial y scale from the y% to 1 - y% of the data.
   *
   * For instance, if set to `10`, y-min will be the value at the 10th
   * percentile, and y-max will be the 90th percentile.
   */
  yScalePercentile?: number
  /** Pads the initial x/y scale by a percent on the difference between the
   * min and max determined by the `zoomPercentile`.
   */
  zoomPaddingPercent: number | Rect
  ensureOrigin: boolean
  bubbleMin: number
  bubbleMax: number
  /** Padding in milliseconds for time scale, domain for linear scale */
  xPadding: number
  useMultiZoom: boolean
  useCanvas?: boolean
  onZoom?: () => void
  hideXTicks?: boolean
  scatter?: boolean
  quote?: boolean
  max?: boolean
  optimization?: boolean
  colors?: string[]
}

const defaultCrossValue = <T = DefaultBubbleDatum>(d: T): number => (d as any).x
const defaultMainValue = <T = DefaultBubbleDatum>(d: T): number => (d as any).y
const defaultSize = <T = DefaultBubbleDatum>(d: T): number => (d as any).size
const defaultColorClass = <T = DefaultBubbleDatum>(_d: T): string | undefined =>
  undefined
const defaultTickFormat = (n: number): number => n

const withDefaults = <T = DefaultBubbleDatum, TLine = Coord>(
  opts: Partial<BubbleChartOptions<T, TLine>>
): BubbleChartOptions<T, TLine> => ({
  crossValue: defaultCrossValue,
  mainValue: defaultMainValue,
  size: defaultSize,
  colorClass: defaultColorClass,
  lineCrossValue: d => (d as unknown as Coord).x,
  lineMainValue: d => (d as unknown as Coord).y,
  lineColorClass: 'app-palette-1',
  lineStrokeWidth: 3,
  lineStrokeDashArray: '10, 5',
  lineStrokeOpacity: 1,
  xScaleType: 'linear',
  yScaleType: 'linear',
  xTickFormat: defaultTickFormat,
  yTickFormat: defaultTickFormat,
  defaultColor: '#ffffff',
  zoomMin: 0.5,
  zoomMax: 10,
  zoomPaddingPercent: { top: 35, right: 20, bottom: 5, left: 3 },
  ensureOrigin: true,
  bubbleMin: 50,
  bubbleMax: 1000,
  xPadding: 0,
  useMultiZoom: true,
  ...opts,
})

export class BubbleChart<T = DefaultBubbleDatum, TLine = Coord> {
  private options: BubbleChartOptions<T, TLine>
  private data: T[] = []
  private xLines: GraphingLineDatum[] = []
  private yLines: GraphingLineDatum[] = []
  private secondaryLines: TLine[][] = []
  private chart: any
  private xScale: ScalarScale
  private yScale: ScaleLinear<number, number>
  private xScaleOriginal: ScalarScale
  private yScaleOriginal: ScaleLinear<number, number>
  private zoom: ZoomMultiAxisDecorator
  private annotations: D3SeriesAnnotation[] = []
  private quadtree?: Quadtree<T>
  private extents: CoordExtents
  private chartExtents?: GraphingMetricExtents
  private regress$ = new Subject<any>()
  private destroy$ = new Subject<void>()

  constructor(
    private container: HTMLElement,
    options?: Partial<BubbleChartOptions<T, TLine>>
  ) {
    this.options = withDefaults(options || {})

    this.chart = null
    select(container).selectAll('*').remove()

    this.data = []
    this.extents = new CoordExtents(this.options.zoomPaddingPercent, {
      ensureOrigin: this.options.ensureOrigin,
      enableRescale:
        this.options.xScalePercentile != null ||
        this.options.yScalePercentile != null,
    })

    this.regress$
      .pipe(takeUntil(this.destroy$), debounceTime(250))
      .subscribe(sel => this.calculateRegressions(sel))
  }

  draw(data?: T[], opts?: GraphingLineData<TLine>): void {
    if (data) {
      data.forEach(d => {
        this.extents.addCoord({
          x: this.options.crossValue(d).valueOf(),
          y: this.options.mainValue(d),
        })
      })

      this.data = this.data.concat(data)
    }

    if (opts?.xLines) {
      const xs = toArray(opts.xLines)
      xs.forEach(x => this.extents.addX(getGraphLineDatumValue(x)))
      this.xLines = this.xLines.concat(xs)
    }

    if (opts?.yLines) {
      const ys = toArray(opts.yLines)
      ys.forEach(y => this.extents.addY(getGraphLineDatumValue(y)))
      this.yLines = this.yLines.concat(ys)
    }

    if (opts?.secondaryLines) {
      this.secondaryLines = this.secondaryLines.concat(opts.secondaryLines)
    }

    const { xScalePercentile, yScalePercentile } = this.options
    if (xScalePercentile != null || yScalePercentile != null) {
      this.extents.rescale(xScalePercentile ?? 100, yScalePercentile ?? 100)
    }

    this.setupChart()
    this.updateCoordScales()
    this.render()
  }

  render(): void {
    if (this.container && this.chart) {
      const seriesData: D3SeriesDatumWithAnnotations<T, TLine> = {
        annotations: this.annotations,
        data: this.data,
        xLines: this.xLines,
        yLines: this.yLines,
      }
      const container = select(this.container)
      container.datum(seriesData).call(this.chart)

      if (this.options.useMultiZoom) {
        this.zoom.fixLeftAxis(container)
      }
    }
  }

  clearAnnotations(): void {
    this.annotations.pop()
    this.refresh()
  }

  refresh(): void {
    const sel = select(this.container).select('d3fc-group')
    const node = sel?.node() as any
    node?.requestRedraw?.()
  }

  private setupChart(): void {
    this.updateCoordScales()
    this.calculateRegressions()

    let sizeScale: ScaleLinear<number, number>
    if (typeof this.options.size !== 'number') {
      const sizeExtent = fc.extentLinear().accessors([this.options.size])
      sizeScale = scaleLinear()
        .range([this.options.bubbleMin, this.options.bubbleMax])
        .domain(sizeExtent(this.data))
    }

    const sizeFn = (d: T) =>
      typeof this.options.size !== 'number'
        ? sizeScale(this.options.size(d))
        : this.options.size

    let pointSeries = this.options.useCanvas
      ? fc.seriesCanvasPoint()
      : fc.seriesSvgPoint()

    pointSeries = pointSeries
      .crossValue(this.options.crossValue)
      .mainValue(this.options.mainValue)
      .size(sizeFn)

    if (this.options.useCanvas) {
      pointSeries = pointSeries.decorate(
        (ctx: CanvasRenderingContext2D, d: T) => {
          ctx.fillStyle = this.options.colorClass(d, 0) ?? this.options.defaultColor
          ctx.strokeStyle = this.options.strokeColor?.(d) ?? '#ffffff10'
          ctx.lineWidth = this.options.strokeWidth?.(d) ?? 1
        }
      )
    } else {
      pointSeries = pointSeries.decorate((sel: any, i: any, _b: any) => {
        const colorClass = (d: T) => this.options.colorClass(d, i)
        decorateFillWithOpacityAndDefault(
          this.options.defaultColor,
          this.options.fillOpacity ?? 1,
          colorClass,
          sel
        )

        sel
          .enter()
          .attr('fill', (d: T) => {
            const prev = sel.attr('fill')
            return resolveValueFn(this.options.fillColor, d) ?? prev
          })
          .attr('stroke', (d: T) => {
            const strokeColor = resolveValueFn(this.options.strokeColor, d)
            return strokeColor ?? 'var(--body-inverse)'
          })
          .attr('stroke-width', (d: T) => {
            return resolveValueFn(this.options.strokeWidth, d)
          })

        decoratePointCircleSegment(
          sizeFn,
          this.options.segmentIndex,
          this.options.segmentCount,
          this.options.segmentOffset,
          sel
        )
      })
    }

    let labels: any
    if (this.options.label) {
      const labelPadding = 6
      const label = fc
        .layoutTextLabel()
        .padding(labelPadding)
        .value(this.options.label)
      const strategy = fc.layoutGreedy()
      labels = fc
        .layoutLabel(strategy)
        .size(
          (_d: D3SeriesDatumWithAnnotations<T>, i: number, series: any[]) => {
            const text = series[i].getElementsByTagName('text')[0]
            const { width, height } = text.getBBox()
            return [width + labelPadding * 2, height + labelPadding * 2]
          }
        )
        .position((d: T) => [
          this.options.crossValue(d),
          this.options.mainValue(d),
        ])
        .component(label)
    }

    const calloutSeries = seriesSvgAnnotation()
      .notePadding(15)
      .type(annotationCallout)

    let gridlines = fc
      .annotationSvgGridline()
      .xTickValues([])
      .xTickValues([])
      .yTicks(this.options.yTicks)

    if (this.options.hideXTicks) {
      gridlines = gridlines.xTickValues([])
      gridlines = gridlines.yTicks(this.options.yTicks)
    }
    if (this.options.hideXTicks) {
      gridlines = gridlines.xTickValues([])
      gridlines = gridlines.yTicks(this.options.yTicks)
    }
    const xOriginLine = fc
      .annotationSvgLine()
      .orient('vertical')
      .decorate(decorateClass('origin transparent-text'))

    const yOriginLine = fc
      .annotationSvgLine()
      .decorate(decorateClass('origin transparent-text'))

    const xLines = fc
      .annotationSvgLine()
      .orient('vertical')
      .value(getGraphLineDatumValue)
      .label(pipe(getGraphLineDatumValue, this.options.xTickFormat))
      .decorate(
        (
          sel: Selection<HTMLElement, GraphingLineDatum[], null, undefined>
        ) => {
          sel
            .enter()
            .select('line')
            .classed(`median ${this.options.lineColorClass}`, true)
          sel
            .enter()
            .select('g.top-handle')
            .classed(`median ${this.options.lineColorClass}`, true)
            .select('text')
            .attr('text-anchor', 'start')
            .attr('x', 150)
        }
      )

    const yLines = fc
      .annotationSvgLine()
      .value(getGraphLineDatumValue)
      .label(pipe(getGraphLineDatumValue, this.options.yTickFormat))
      .decorate(
        (
          sel: Selection<HTMLElement, GraphingLineDatum[], null, undefined>
        ) => {
          sel
            .enter()
            .select('line')
            .classed(`median ${this.options.lineColorClass}`, true)
          sel
            .enter()
            .select('g.right-handle')
            .classed(`median ${this.options.lineColorClass}`, true)
            .select('text')
            .attr('text-anchor', 'end')
            .attr('x', -5)
            .attr('y', -12)
        }
      )

    const secondaryLines = this.secondaryLines.map((_, i) =>
      fc
        .seriesSvgLine()
        .crossValue(this.options.lineCrossValue)
        .mainValue(this.options.lineMainValue)
        .decorate(
          (
            sel: Selection<HTMLElement, TLine[], null, undefined>
          ) => {
            sel
              .enter()
              .attr('class', ds => {
                const curr = sel.attr('class')
                let cl = this.options.lineColorClass
                if (this.options.optimization && this.options.colors) {
                  cl = this.options.colors[i]
                }
                const next = resolveValueFn(cl, ds, i)
                return `${curr} ${next}`
              })
              .classed(`point`, true)
              .attr('stroke', 'rgba(var(--rgb), var(--alpha))')
              .attr('stroke-width', ds =>
                resolveValueFn(this.options.lineStrokeWidth, ds, i)
              )
              .attr('stroke-dasharray', ds =>
                resolveValueFn(this.options.lineStrokeDashArray, ds, i)
              )
              .attr('stroke-opacity', ds =>
                resolveValueFn(this.options.lineStrokeOpacity, ds, i)
              )
          }
        )
    )

    const svgSeries = [
      gridlines,
      xOriginLine,
      yOriginLine,
      xLines,
      yLines,
      ...secondaryLines,
    ]

    if (!this.options.useCanvas) {
      svgSeries.push(pointSeries)
    }

    if (this.options.label) {
      svgSeries.push(labels)
    }

    svgSeries.push(calloutSeries)

    const svgMultiSeries = fc
      .seriesSvgMulti()
      .series(svgSeries)
      .mapping(
        (d: D3SeriesDatumWithAnnotations<T>, i: number, series: any[]) => {
          const regressionIndex = secondaryLines.indexOf(series[i])
          if (regressionIndex >= 0) {
            return this.secondaryLines[regressionIndex]
          }
          switch (series[i]) {
            case pointSeries:
            case labels:
              return d.data
            case calloutSeries:
              return d.annotations
            case xLines:
              return d.xLines || []
            case yLines:
              return d.yLines || []
            case gridlines:
            case xOriginLine:
            case yOriginLine:
            default:
              return [0]
          }
        }
      )

    let canvasSeries: any
    if (this.options.useCanvas) {
      canvasSeries = fc
        .seriesCanvasMulti()
        .series([pointSeries])
        .mapping((d: D3SeriesDatumWithAnnotations<T>) => d.data)
    }

    let zoom:
      | ZoomBehavior<Element, D3SeriesDatumWithAnnotations<T[]>>
      | undefined

    if (this.options.useMultiZoom) {
      this.zoom = createZoomMultiAxisDecorator({
        min: this.options.zoomMin,
        max: this.options.zoomMax,
        xScale: this.xScale,
        yScale: this.yScale,
        xScaleOriginal: this.xScaleOriginal,
        yScaleOriginal: this.yScaleOriginal,
        onZoom: this.options.onZoom,
      })
    } else {
      zoom = d3Zoom<Element, D3SeriesDatumWithAnnotations<T[]>>()
        .scaleExtent([this.options.zoomMin, this.options.zoomMax])
        .on('zoom', (event) => {
          // Update the scales based on current zoom
          if (this.options.xScaleType === 'linear') {
            this.xScale.domain(
              event.transform.rescaleX(this.xScaleOriginal).domain()
            )
          }
          if (this.options.yScaleType === 'linear') {
            this.yScale.domain(
              event.transform.rescaleY(this.yScaleOriginal).domain()
            )
          }
          this.render()
          this.options.onZoom?.()
        })
    }

    const pointer = fc.pointer()

    this.chart = fc
      .chartCartesian({
        xScale: this.xScale,
        yScale: this.yScale,
        // The ZoomMultiAxisDecorator causes an issue where the left
        // axis is treated as the default right axis. This fixes that
        // along with the fix applied in the draw method above.
        yAxis: { left: fc.axisLeft, right: fc.axisLeft },
      })
      .xTicks(this.options.xTicks)
      .yTicks(this.options.yTicks)
      .xTickFormat(this.options.xTickFormat)
      .yTickFormat(this.options.yTickFormat)
      .yOrient('left')
      .chartLabel(this.options.chartLabel)
      .xLabel(this.options.xLabel)
      .yLabel(this.options.yLabel)
      .yAxisWidth(
        this.options.yAxisWidth ?? (this.extents.ymax > 10 ? '4em' : '3em')
      )
      .xAxisHeight(this.options.quote && this.options.max ? '150px' : '3em')
      .svgPlotArea(svgMultiSeries)
      .canvasPlotArea(this.options.useCanvas ? canvasSeries : null)
      .decorate((sel: any) => {
        if (this.options.useMultiZoom) {
          this.zoom?.decorate(sel, () => {
            this.updateQuadtree()
            this.onRangeChange(sel)
          })
        }

        if (this.options.hideXTicks) {
          this.chart = this.chart.xTickValues([])
        }

        if (this.options.quote && this.options.max) {
          this.chart = this.chart.xDecorate((selection: any) => {
            selection
              .select('text')
              .attr('transform', 'rotate(-15)')
              .attr('text-anchor', 'end')
          })
        }

        pointer.on('point', ([coord]: readonly [Coord?]) => {
          this.onMouseMove(coord, () => {
            sel.node()?.requestRedraw()
          })
        })

        const plot = sel.enter().select('d3fc-svg.plot-area')
        plot.call(pointer)

        if (!this.options.useMultiZoom && zoom) {
          plot
            .on('measure.range', (event: any) => {
              // {TO: JB} Upon research using any IS ACTUALLY the only thing to use here based on existing documentation....
              const { width, height } = event.detail

              if (this.options.xScaleType === 'linear') {
                this.xScaleOriginal.range([0, width])
              } else {
                this.xScale.range([0, width])
              }

              if (this.options.yScaleType === 'linear') {
                this.yScaleOriginal.range([height, 0])
              } else {
                this.yScale.range([height, 0])
              }

              this.updateQuadtree()
              this.onRangeChange(sel)

              const ctx = this.container
                .querySelector('canvas')
                ?.getContext('2d')
              pointSeries.context(ctx)
            })
            .call(zoom)
        }
      })
  }

  private updateCoordScales(): void {
    if (this.options.xScaleType === 'time') {
      const xPad = this.options.xPadding
      const isTimeScale = this.options.xScaleType === 'time'
      const scale = isTimeScale ? fc.extentTime() : fc.extentLinear()
      const extent: (series: T[]) => [number, number] = scale
        .accessors([this.options.crossValue])
        .pad([xPad, xPad])
        .padUnit('domain')
      const xExtentLimit = this.options.extents?.x
      let data = this.data
      if (this.options.xScalePercentile != null) {
        const n = data.length
        const p = this.options.xScalePercentile / 100
        const min = Math.floor(n * (1 - p))
        const max = Math.floor(n * p)
        data = sortBy(d => this.options.crossValue(d).valueOf(), data)
        data = [data[min], data[max]]
      }
      const xExtent = applyExtentLimit(extent(data), xExtentLimit)

      if (!this.xScale) {
        this.xScale = scaleTime().domain(xExtent)
      } else {
        this.xScale.domain(xExtent)
      }
    } else {
      const [xmin, xmax] = this.extents.x
      const _xmin = this.options.ensureOrigin ? Math.min(xmin, 0) : xmin
      const xExtentLimit = this.options.extents?.x
      const xExtent = this.options.forceExtents
        ? xExtentLimit ?? [_xmin, xmax]
        : applyExtentLimit([_xmin, xmax], xExtentLimit)

      if (!this.xScale) {
        this.xScale = scaleLinear().domain(xExtent)
      } else {
        this.xScale.domain(xExtent)
      }
    }

    this.xScaleOriginal = this.xScale.copy()

    const [ymin, ymax] = this.extents.y
    const _ymin = this.options.ensureOrigin ? Math.min(ymin, 0) : ymin
    const yExtentLimit = this.options.extents?.y
    const yExtent = this.options.forceExtents
      ? yExtentLimit ?? [_ymin, ymax]
      : applyExtentLimit([_ymin, ymax], yExtentLimit)

    if (!this.yScale) {
      this.yScale = scaleLinear().domain(yExtent)
    } else {
      this.yScale.domain(yExtent)
    }
    this.yScaleOriginal = this.yScale.copy()
  }

  private updateQuadtree(): void {
    this.quadtree = d3Quadtree<T>()
      .x(d => this.xScale(this.options.crossValue(d)))
      .y(d => this.yScale(this.options.mainValue(d)))
      .addAll(this.data)
  }

  private onMouseMove(coord: Coord | undefined, callback: () => void): void {
    this.annotations.pop()

    if (!coord || !this.quadtree || !this.options.annotationBuilder) {
      return
    }

    // Find the closest datapoint to the pointer
    const { x, y } = coord
    const radius = 16
    const closestDatum = this.quadtree.find(x, y, radius)

    // If the closest point is found, show the annotation
    if (closestDatum) {
      const annotation = this.options.annotationBuilder(closestDatum)
      const xmax = this.xScale.range()[1]
      if (x > xmax - 150) {
        annotation.dx = -annotation.dx
      }
      const ymin = this.yScale.range()[1]
      if (y < ymin + 200) {
        annotation.dy = -annotation.dy
      }
      this.annotations[0] = annotation
    }

    callback()
  }

  private onRangeChange(sel: any): void {
    const x = this.xScale.domain() as [number, number]
    const y = this.yScale.domain() as [number, number]
    const currExtents = { x, y }
    const xDelta = (x[1] - x[0]) * 0.1
    const yDelta = (y[1] - y[0]) * 0.1
    const xPrev = this.chartExtents?.x
    const yPrev = this.chartExtents?.y
    if (
      !xPrev ||
      !yPrev ||
      Math.abs(x[0] - xPrev[0]) > xDelta ||
      Math.abs(x[1] - xPrev[1]) > xDelta ||
      Math.abs(y[0] - yPrev[0]) > yDelta ||
      Math.abs(y[1] - yPrev[1]) > yDelta
    ) {
      this.chartExtents = currExtents
      this.regress$.next(sel)
    }
  }

  private filterDataInExtents(): T[] {
    const _extents = this.chartExtents ?? this.options.extents
    const [xmin, xmax] = _extents?.x ?? [-Infinity, Infinity]
    const [ymin, ymax] = _extents?.y ?? [-Infinity, Infinity]
    const [xFn, yFn] = [this.options.crossValue, this.options.mainValue]
    const result = this.data.filter(d => {
      const [x, y] = [xFn(d), yFn(d)]
      return Number(x) >= xmin && Number(x) <= xmax && y >= ymin && y <= ymax
      return Number(x) >= xmin && Number(x) <= xmax && y >= ymin && y <= ymax
    })
    return result
  }

  private calculateRegressions(sel?: any) {
    if (this.options.regressionOptions) {
      const data = this.filterDataInExtents()
      const opts = this.options.regressionOptions
      this.secondaryLines = calculateAggregateRegressionLines(
        data as any,
        opts
      ) as any
      sel?.node()?.requestRedraw()
    }
  }
}
