export interface Auth {
  username: string
  name: string
  redirectURL: string
  token: string
}

export interface UserPermission {
  sage_functionality: string
  permission: string
  route: string | null
}

export interface UserPermissions {
  [key: string]: Array<UserPermission>
}

export const LOCKTON_COM = 'lockton.com'
export const PERFICIENT_COM = 'perficient.com'
