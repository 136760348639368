<div class="clone-dialog">
    <ng-container *ngIf="!hasError">
        <h1 mat-dialog-title>Input Ids</h1>
        <mat-dialog-content>
            <mat-form-field class="full-width" appearance="fill" subscriptSizing="dynamic">
                <input matInput required placeholder="Analysis Profile ID" [(ngModel)]="analysisProfileID" />
            </mat-form-field>
            <mat-form-field class="full-width" appearance="fill" subscriptSizing="dynamic">
                <input matInput required placeholder="Parent Gross Portfolio ID" [(ngModel)]="parentGrossPortfolioID" />
            </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button appButton border (click)="onSave()">{{ title }}</button>
            <button appButton border (click)="onClose()">Close</button>
        </mat-dialog-actions>
    </ng-container>
    <ng-container *ngIf="hasError">
        <h1 mat-dialog-title>Shared Limit Layer Error</h1>
        <mat-dialog-content>
            <div class="error-text">
                A shared limit layer has been detected that references a structure ID that is not
                included in the selected structures.
            </div>
            <div class="error-text">To swap loss sets please select the following additional structure(s) and try again:
            </div>
            <div 
                *ngFor="let el of sharedLimitErrorMessageElements" 
                class="error-element"
            >
                {{ el }}
            </div>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button appButton border (click)="onClose()">Close</button>
        </mat-dialog-actions>
    </ng-container>
</div>