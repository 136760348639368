import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store, select } from '@ngrx/store'
import {
  catchError,
  concatAll,
  concatMap,
  defaultIfEmpty,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators'
import {  EMPTY, from, lastValueFrom, throwError, timer } from 'rxjs'
import { ProgramService } from '../../../api/program/program.service'
import { AppState } from '../index'
import {
  mergeMapWithInput,
  rejectError,
  rejectErrorWithInput,
} from '../../../api/util'
import {
  saveTowerPreferences,
  saveTowerPreferenesFailure,
  saveTowerPreferenesSuccess,
  setProgramNameAndDescription,
  setProgramNameAndDescriptionFailure,
  setProgramNameAndDescriptionSuccess,
  updateProgramIndex,
  updateProgramIndexFailure,
  updateProgramIndexSuccess,
  updateTailMetrics,
  updateTailMetricsFailure,
  updateTailMetricsSuccess,
  importBulkLossSets,
  importBulkLossSetsSuccess,
  handleSwapBulkLossSets,
  swapBulkLossSets,
  swapBulkLossSetsSuccess,
  updateProgramIndexes,
  updateProgramIndexesFailure,
  updateProgramIndexesSuccess,
  updateFolderID,
  updateFolderIDFailure,
  updateFolderIDSuccess,
  updateCounts,
  updateCountsFailure,
  updateCountsSuccess,
  swapBulkLossSetsProcessingComplete,
  swapBulkLossSetsIntermediateSuccess,
} from './program.actions'
import { AnalyzreService } from '../../../api/analyzere/analyzre.service'
import { forkJoin, Observable, of } from 'rxjs'
import {
  Update,
  LogicalPortfolioLayer,
  Metadata,
  PhysicalPortfolioLayer,
  LossSetLayer,
} from '../../../api/analyzere/analyzere.model'
import { selectCurrentStudyPrograms } from './program.selectors'
import { layerIds } from 'src/app/analysis/model/layer-palette.model'
import { Layer, LayerRef, NestedLayer, WithLayerId } from 'src/app/analysis/model/layers.model'
import { ReturnPeriodRow } from '../../../analysis/model/metrics.model'
import { selectCurrentStructureID } from '../broker/broker.selectors'
import { analyzereConstants } from '@shared/constants/analyzere'
import { isMultiSectionLayer } from 'src/app/analysis/layers/multi-section-layer'
import { isLayerActualTopAndDrop, isLayerAgg, isLayerAggFeeder, isLayerShared } from 'src/app/analysis/model/layers.util'
import { openCloneDialog } from 'src/app/analysis/store/analysis.actions'
import { CloneDialogComponent, CloneDialogResult } from 'src/app/tier/clone-dialog/clone-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { Program } from '../../model/program.model'
import { SwapLossSetsDialogComponent } from 'src/app/tier/swap-loss-sets-dialog/swap-loss-sets-dialog.component'
import { MessageDialogService } from '@shared/message-dialog.service'


@Injectable()
export class ProgramEffects {
  constructor(
    private actions$: Actions,
    private programService: ProgramService,
    private store: Store<AppState>,
    private service: AnalyzreService,
    private dialog: MatDialog,
    private messageService: MessageDialogService,
  ) { }

  setTowerPreferences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveTowerPreferences),
      switchMap(action => {
        return this.programService.setTowerPreferences(
          action.id,
          action.preferences
        )
      }),
      switchMap(res => {
        const actions = []
        if (res.error) {
          actions.push(saveTowerPreferenesFailure({ error: res.error }))
        } else if (res.data !== undefined) {
          actions.push(saveTowerPreferenesSuccess(res.data))
        }
        return actions
      })
    )
  })

  setProgramNameAndDescription$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setProgramNameAndDescription),
      mergeMap(({ structure, name, description }) =>
        this.programService.update(structure.id, {
          ...structure,
          label: name,
          description,
        })
      ),
      rejectError(error =>
        this.store.dispatch(setProgramNameAndDescriptionFailure({ error }))
      ),
      filter(({ label }) => label != null && label.length > 0),
      map(({ id, label, description }) =>
        setProgramNameAndDescriptionSuccess({ id, name: label, description })
      )
    )
  })

  handleSwapBulkLossSets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(handleSwapBulkLossSets),
      concatMap(({ structures, analysisProfileID, newParentGrossPortfolioID, lossSetMapping }) =>
        from(structures).pipe(
          concatMap((structure, index) => {
            const isLast = index === structures.length - 1
            const oldParentGrossPortfolioID = structure.parentGrossPortfolioID ?? ''
            this.store.dispatch(
              swapBulkLossSets({
                structure,
                analysisProfileID,
                oldParentGrossPortfolioID,
                newParentGrossPortfolioID,
                lossSetMapping,
                isLast,
              })
            )
            return this.actions$.pipe(
              ofType(swapBulkLossSetsSuccess, swapBulkLossSetsIntermediateSuccess),
              filter(action => action.id === structure.id),
              take(1),
              map(() => ({ type: '[Program Properties] Intermediate Processing' }))
            )
          })
        )
      )
    )
  )

  openCloneDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openCloneDialog),
      mergeMap(({ title, selectedStructures, allStructures }) => {
        if (!selectedStructures?.length) {
          return EMPTY
        }
  
        return from(this.checkForSharedLimitLayers(selectedStructures, allStructures)).pipe(
          switchMap(sharedLimitErrorMessageElements => {
            return this.dialog
              .open(CloneDialogComponent, {
                data: {
                  title,
                  sharedLimitErrorMessageElements
                }
              })
              .afterClosed()
          }),
          filter(result => result?.event === 'save'),
          mergeMap((result: CloneDialogResult) => {
            if (title === 'Add/Replace Loss Sets') {
              return this.handleAddLossSets(selectedStructures, result)
            } else if (title === 'Swap Loss Sets') {
              return this.handleSwapLossSets(selectedStructures, result)
            }
            return EMPTY
          }),
          catchError(error => {
            this.messageService.showMessage('Error handling clone dialog', error)
            return EMPTY
          })
        )
      })
    )
  )

  swapBulkLossSets$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(swapBulkLossSets),
      mergeMap(
        ({
          structure,
          oldParentGrossPortfolioID,
          newParentGrossPortfolioID,
          analysisProfileID,
          lossSetMapping,
          isLast,
        }) =>
          this.programService
            .update(structure.id, {
              ...structure,
              libRE: undefined,
              parentGrossPortfolioID: newParentGrossPortfolioID,
              analysisID: analysisProfileID,
            })
            .pipe(
              switchMap(() => {
                return forkJoin([
                  this.service.fetchPortfolio(oldParentGrossPortfolioID),
                  this.service.fetchPortfolio(newParentGrossPortfolioID),
                  this.service.fetchPortfolio(structure.cededPortfolioID),
                ]).pipe(
                  map(
                    ([oldLossSetLayers, newLossSetLayers, cededLayers]: [
                      any,
                      any,
                      any,
                    ]) => ({
                      structure,
                      oldParentGrossPortfolioID,
                      oldLossSetLayers,
                      newLossSetLayers,
                      cededLayers,
                      lossSetMapping,
                      isLast,
                      analysisProfileID
                    })
                  )
                )
              })
            )
      ),
      switchMap(
        ({
          structure,
          oldParentGrossPortfolioID,
          newLossSetLayers,
          cededLayers,
          lossSetMapping,
          isLast,
          analysisProfileID,
        }) => {
          const lossSets = new Set<LossSetLayer>()
          lossSetMapping.forEach((_: LossSetLayer[], key: LossSetLayer) => {
            lossSets.add(key)
          })
          return this.handleSharedLimitLayers(
            cededLayers,
            Array.from(lossSets),
            analysisProfileID,
            this.service
          ).pipe(
            map((slData: any) => ({
              structure,
              oldParentGrossPortfolioID,
              newLossSetLayers,
              cededLayers,
              lossSetMapping,
              isLast,
              slData,
              analysisProfileID
            })),
            defaultIfEmpty({
              structure,
              oldParentGrossPortfolioID,
              newLossSetLayers,
              cededLayers,
              lossSetMapping,
              isLast,
              slData: {},
              analysisProfileID
            })
          )
        }
      ),
      concatMap(
        ({
          structure,
          oldParentGrossPortfolioID,
          newLossSetLayers,
          cededLayers,
          lossSetMapping,
          isLast,
          slData,
          analysisProfileID
        }) => {
          const grossLayerSet = new Set<string>()
          lossSetMapping.forEach((_: LossSetLayer[], key: LossSetLayer) => {
            grossLayerSet.add(key.id)
          })

          const grossLayers = Array.from(grossLayerSet)

          const netLayers: string[] = [...grossLayers]
          cededLayers.data?.layers.forEach((l: { id: string }) => {
            netLayers.push(l.id)
          })
          const backallocatedUpdates: Update<(NestedLayer & WithLayerId)>[] = []
          const complexSourceUpdates: Update<LogicalPortfolioLayer>[] = []
          const cededLayersTable = cededLayers.data?.layers
          const condensedMap = new Map<string, Set<string>>()
          const condensedMapRisk = new Map<string, Set<string>>()
          const condensedMapComplex = new Map<string, Set<string>>()
          let mappedLayerSetRisk: Set<string> | undefined
          let mappedLayerSetShared: Set<string> | undefined

          cededLayersTable.forEach((layer: any) => {
            condensedMap.set(layer.id, new Set<string>())
            let mappedLayerSet = condensedMap.get(layer.id)
            layer.sources.forEach((source: any) => {
              const sourceAndMetaData = !!source && !!source.meta_data

              const isRiskLayer = sourceAndMetaData &&
                (source.meta_data as Metadata).sage_layer_type ===
                layerIds.noncatRisk

              const isSharedLimitLayer = sourceAndMetaData &&
                isLayerShared(source)

              const isIndexOrSwingOrMSLayer = sourceAndMetaData &&
                (
                  source.meta_data.sage_layer_type === 'noncat_swing' ||
                  source.meta_data.sage_layer_type === 'noncat_indxl' ||
                  isMultiSectionLayer(layer)
                )

              const isTopAndDrop = sourceAndMetaData &&
                isLayerActualTopAndDrop(layer) &&
                (
                  source.meta_data.sage_layer_type === 'drop' ||
                  source.meta_data.sage_layer_subtype === 'virtual'
                )

              const complexSourceUpdateIDs: string[] = []
              if (isRiskLayer) {
                condensedMapRisk.set(source.id, new Set<string>())
                mappedLayerSetRisk = condensedMapRisk.get(source.id)
              }
              if (isSharedLimitLayer) {
                const backallocated = slData.backallocatedLayers.find(
                  (bal: any) =>
                    bal.data.meta_data.backAllocatedForID === layer.meta_data.backAllocatedForID
                ).data
                const ogLayers = backallocated.sink.sources
                ogLayers.forEach((og: any) => {
                  og.sources.forEach((ogSource: any) => {
                    complexSourceUpdateIDs.push(ogSource.id)
                  })
                })
                complexSourceUpdates.push({
                  id: layer.id,
                  change: {
                    sources: [{ ref_id: backallocated.id }]
                  }
                })
                backallocatedUpdates.push({
                  id: backallocated.sink.id,
                  change: {
                    meta_data: {
                      ...backallocated.sink.meta_data,
                      analysisProfileID
                    }
                  }
                })
              }
              if (isIndexOrSwingOrMSLayer) {
                const subTypes = ['loss-layer', 'adjustment-layer', 'premium-layer', 'section-layer']
                const subTypeIncluded = subTypes.includes(source.meta_data.sage_layer_subtype)
                const isIndex = layer.meta_data.sage_layer_type === 'noncat_indxl'
                if (subTypeIncluded || isIndex) {
                  complexSourceUpdateIDs.push(source.id)
                  if (
                    isIndex &&
                    layer.meta_data.visible_layer_id &&
                    !complexSourceUpdates.map(update =>
                      update.id).includes(layer.meta_data.visible_layer_id)
                  ) {
                    complexSourceUpdateIDs.push(layer.meta_data.visible_layer_id)
                  }
                }
              }
              if (isLayerAgg(layer) || isLayerAggFeeder(layer)) {
                complexSourceUpdateIDs.push(source.id)
              }
              if (isTopAndDrop) {
                complexSourceUpdateIDs.push(source.id)
                if (source.meta_data.topID) {
                  complexSourceUpdateIDs.push(source.meta_data.topID)
                }
              }
              complexSourceUpdateIDs.forEach(id => {
                const sources = newLossSetLayers.data.layers.map((lsl: any) => ({ ref_id: lsl.id }))
                complexSourceUpdates.push({
                  id: id,
                  change: {
                    sources
                  }
                })
              })
              lossSetMapping.forEach(
                (lossSet: LossSetLayer[], key: LossSetLayer) => {
                  if (isRiskLayer) {
                    if (
                      source.loss_sets &&
                      source.loss_sets[0] &&
                      lossSet.find(
                        (l: LossSetLayer) =>
                          (l.loss_sets[0] as LayerRef).id ===
                          source.loss_sets[0].id
                      )
                    ) {
                      mappedLayerSetRisk?.add((key.loss_sets[0] as LayerRef).id)
                    }
                  } else if (isSharedLimitLayer) {
                    if (
                      source.loss_sets &&
                      source.loss_sets[0] &&
                      lossSet.find(
                        (l: LossSetLayer) =>
                          (l.loss_sets[0] as LayerRef).id ===
                          source.loss_sets[0].id
                      )
                    ) {
                      mappedLayerSetShared?.add((key.loss_sets[0] as LayerRef).id)
                    }
                  } else {
                    if (lossSet.find(l => l.id === source.id)) {
                      mappedLayerSet?.add(key.id)
                    }
                  }
                }
              )
            })
          })
          const updates: Update<LogicalPortfolioLayer>[] = [...complexSourceUpdates]
          const riskUpdates: Update<PhysicalPortfolioLayer>[] = []
          const sharedUpdates: Update<PhysicalPortfolioLayer>[] = []
          const complexUpdates: Update<PhysicalPortfolioLayer>[] = []
          condensedMap.forEach((set, id) => {
            if (set && set.size > 0) {
              updates.push({
                id,
                change: {
                  sources: Array.from(set).map((l: any) => ({ ref_id: l })),
                },
              })
            }
          })
          condensedMapRisk.forEach((set, id) => {
            if (set && set.size > 0) {
              riskUpdates.push({
                id,
                change: {
                  loss_sets: Array.from(set).map((l: any) => ({ ref_id: l })),
                },
              })
            }
          })
          condensedMapComplex.forEach((set, id) => {
            if (set && set.size > 0) {
              complexUpdates.push({
                id,
                change: {
                  loss_sets: Array.from(set).map((l: any) => ({ ref_id: l })),
                },
              })
            }
          })
          return this.service
            .updatePortfolioLayers(structure.grossPortfolioID, grossLayers)
            .pipe(
              concatMap(() => {
                if (backallocatedUpdates && backallocatedUpdates.length > 0) {
                  return backallocatedUpdates.map(update => this.service.patchLayer(update))
                } else {
                  return of(null)
                }
              }),
              concatMap(() => {
                if (updates && updates.length > 0) {
                  return this.service.patchLogicalPortfolioLayers(updates)
                } else {
                  return of(null)
                }
              }),
              concatMap(() => {
                if (riskUpdates && riskUpdates.length > 0) {
                  return this.service.patchPhysicalPortfolioLayers(riskUpdates)
                } else {
                  return of(null)
                }
              }),
              concatMap(() => {
                if (sharedUpdates && sharedUpdates.length > 0) {
                  return this.service.patchPhysicalPortfolioLayers(sharedUpdates)
                } else {
                  return of(null)
                }
              }),
              concatMap(() => {
                if (complexUpdates && complexUpdates.length > 0) {
                  return this.service.patchPhysicalPortfolioLayers(complexUpdates)
                } else {
                  return of(null)
                }
              }),
              concatMap(() => {
                return this.service.updatePortfolioLayers(
                  structure.netPortfolioID,
                  netLayers
                )
              })
            )
            .pipe(
              map(() => {
                if (isLast) {
                  return swapBulkLossSetsSuccess({
                    id: structure.id,
                    parentGrossPortfolioID: oldParentGrossPortfolioID,
                  })
                } else {
                  return swapBulkLossSetsIntermediateSuccess({
                    id: structure.id
                  })
                }
              })
            )
        }
      )
    )
  })

  swapBulkLossSetsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(swapBulkLossSetsSuccess, importBulkLossSetsSuccess),
        map(() => {
          location.reload()
        })
      ),
    { dispatch: false }
  )

  importBulkLossSets$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(importBulkLossSets),
      concatMap(({ structure, parentGrossPortfolioID, analysisProfileID, isLast }) =>
        this.programService
          .update(structure.id, {
            ...structure,
            libRE: undefined,
            parentGrossPortfolioID,
            analysisID: analysisProfileID,
          })
          .pipe(
            switchMap(() => {
              return forkJoin([
                this.service.fetchPortfolio(parentGrossPortfolioID),
                this.service.fetchPortfolio(structure.cededPortfolioID)
              ]).pipe(
                switchMap(([lossSetLayers, cededLayers]: [any, any]) => {
                  const riskVisibleLayerRequests = cededLayers.data.layers
                    .filter(
                      (layer: any) =>
                        layer.meta_data.sage_layer_type === 'noncat_risk'
                    )
                    .map((layer: any) => {
                      if (
                        layer.meta_data.program_name === 'Layer 3 combination'
                      ) {
                        return this.service.fetchLayer(
                          layer.meta_data.riskVisibleLayerID
                        )
                      }
                      return of(null)
                    })
                  return forkJoin(riskVisibleLayerRequests).pipe(
                    map((riskVisibleLayers: any) => {
                      return ({
                        structure,
                        parentGrossPortfolioID,
                        lossSetLayers,
                        cededLayers,
                        riskVisibleLayers: riskVisibleLayers.filter(
                          (layer: any) => layer !== null
                        ),
                        isLast,
                        analysisProfileID
                      })
                    }),
                    defaultIfEmpty({
                      structure,
                      parentGrossPortfolioID,
                      lossSetLayers,
                      cededLayers,
                      riskVisibleLayers: [],
                      isLast,
                      analysisProfileID
                    })
                  )
                }),
                switchMap(
                  ({
                    structure,
                    parentGrossPortfolioID,
                    lossSetLayers,
                    cededLayers,
                    riskVisibleLayers,
                    isLast,
                    analysisProfileID,
                  }) => {
                    return this.handleSharedLimitLayers(
                      cededLayers,
                      lossSetLayers.data.layers,
                      analysisProfileID,
                      this.service
                    ).pipe(
                      map(({ backallocatedLayers }) => ({
                        structure,
                        parentGrossPortfolioID,
                        lossSetLayers,
                        cededLayers,
                        riskVisibleLayers,
                        backallocatedLayers,
                        isLast,
                        analysisProfileID,
                      })),
                      defaultIfEmpty({
                        structure,
                        parentGrossPortfolioID,
                        lossSetLayers,
                        cededLayers,
                        riskVisibleLayers,
                        backallocatedLayers: [],
                        isLast,
                        analysisProfileID
                      })
                    )
                  }
                )
              )
            })
          )
      ),
      switchMap(
        ({
          structure,
          parentGrossPortfolioID,
          lossSetLayers,
          cededLayers,
          riskVisibleLayers,
          isLast,
          analysisProfileID,
          backallocatedLayers
        }) => {
          const updates: Update<LogicalPortfolioLayer>[] = []
          const backallocatedUpdates: Update<(NestedLayer & WithLayerId)>[] = []
          const layers = lossSetLayers.data.layers
          const newLayers: Layer[] = []
          for (const layer of cededLayers.data.layers) {
            if (isLayerShared(layer)) {
              const backallocated = backallocatedLayers.find(
                bal => bal.data.meta_data.backAllocatedForID === layer.meta_data.backAllocatedForID
              ).data
              const ogLayers = backallocated.sink.sources
              const hiddenIDs: string[] = [
                ...ogLayers.map((ogl: any) => ogl.id)
              ]
              updates.push({
                id: layer.id,
                change: {
                  sources: [{ ref_id: backallocated.id }]
                }
              })
              backallocatedUpdates.push({
                id: backallocated.sink.id,
                change: {
                  meta_data: {
                    ...backallocated.sink.meta_data,
                    analysisProfileID
                  }
                }
              })
              hiddenIDs.forEach(id => {
                updates.push({
                  id: id,
                  change: {
                    sources: [...layers.map((l: any) => ({ ref_id: l.id }))],
                  },
                })
              })
            } else if (isLayerActualTopAndDrop(layer)) {
              const sources = lossSetLayers.data.layers
              const hiddenIDs: string[] = []
              layer.sources.forEach((s: any) => {
                if ((s.meta_data.sage_layer_type = 'drop')) {
                  hiddenIDs.push(s.id)
                  if (s.meta_data.topID) {
                    hiddenIDs.push(s.meta_data.topID)
                  }
                }
              })
              layer.sources = layer.sources.map((source: any) => ({
                ...source,
                sources,
              }))
              updates.push(
                ...layer.sources.map((source: any) => ({
                  id: source.id,
                  change: {
                    sources: [...layers.map((l: any) => ({ ref_id: l.id }))],
                  },
                }))
              )
              hiddenIDs.forEach((id: string) => {
                updates.push({
                  id,
                  change: {
                    sources: [...layers.map((l: any) => ({ ref_id: l.id }))],
                  },
                })
              })
            } else if (isLayerAgg(layer) || isLayerAggFeeder(layer)) {
              const sources = lossSetLayers.data.layers
              const hiddenIDs: string[] = []
              layer.sources.map((s: any) => {
                hiddenIDs.push(s.id)
              })
              layer.sources = layer.sources.map((source: any) => ({
                ...source,
                sources,
              }))
              updates.push(
                ...layer.sources.map((source: any) => ({
                  id: source.id,
                  change: {
                    sources: [...layers.map((l: any) => ({ ref_id: l.id }))],
                  },
                }))
              )
              hiddenIDs.forEach((id: string) => {
                updates.push({
                  id,
                  change: {
                    sources: [...layers.map((l: any) => ({ ref_id: l.id }))],
                  },
                })
              })
            }
            else if (
              layer.meta_data.sage_layer_type === 'noncat_swing' ||
              layer.meta_data.sage_layer_type === 'noncat_indxl' ||
              isMultiSectionLayer(layer)
            ) {
              const sources = lossSetLayers.data.layers
              const hiddenIDs = [layer.meta_data.visible_layer_id]
              layer.sources.forEach((s: any) => {
                if (
                  layer.meta_data.sage_layer_subtype === 'loss-layer' ||
                  layer.meta_data.sage_layer_subtype === 'adjustment-layer' ||
                  layer.meta_data.sage_layer_subtype === 'premium-layer' ||
                  layer.meta_data.sage_layer_subtype === 'section-layer' ||
                  layer.meta_data.sage_layer_type === 'noncat_indxl'
                ) {
                  hiddenIDs.push(s.id)
                }
              })
              layer.sources = layer.sources.map((source: any) => ({
                ...source,
                sources,
              }))
              updates.push(
                ...layer.sources.map((source: any) => ({
                  id: source.id,
                  change: {
                    sources: [...layers.map((l: any) => ({ ref_id: l.id }))],
                  },
                }))
              )
              hiddenIDs.forEach(id => {
                updates.push({
                  id,
                  change: {
                    sources: [...layers.map((l: any) => ({ ref_id: l.id }))],
                  },
                })
              })
            } else if (layer.meta_data.sage_layer_type === 'noncat_indxl') {
              const sources = lossSetLayers.data.layers
              const hiddenIDs = [layer.meta_data.visible_layer_id]
              layer.sources.forEach((s: any) => {
                hiddenIDs.push(s.id)
              })
              layer.sources = layer.sources.map((source: any) => ({
                ...source,
                sources,
              }))
              updates.push(
                ...layer.sources.map((source: any) => ({
                  id: source.id,
                  change: {
                    sources: [...layers.map((l: any) => ({ ref_id: l.id }))],
                  },
                }))
              )
              hiddenIDs.forEach(id => {
                updates.push({
                  id,
                  change: {
                    sources: [...layers.map((l: any) => ({ ref_id: l.id }))],
                  },
                })
              })
            } else if (
              layer.meta_data.sage_layer_type === 'noncat_risk' &&
              layer.meta_data.program_name === 'Layer 3 combination'
            ) {
              const sources = lossSetLayers.data.layers.filter(
                (l: any) =>
                  l.meta_data.loss_type === 'large' ||
                  l.meta_data.loss_type === 'cat'
              )
              const layerToAdd = riskVisibleLayers
                .map((l: any) => l?.data)
                .find(
                  (rvl: any) =>
                    rvl && rvl.id === layer.meta_data.riskVisibleLayerID
                )
              if (layerToAdd) {
                layer.physicalLayer = layerToAdd.sink as PhysicalPortfolioLayer

                sources.forEach((l: any) => {
                  const lossSetLayer = {
                    id: l.id,
                    meta_data: l.meta_data,
                    loss_sets: l.loss_sets,
                  }
                  const hiddenLayerMetaData = {
                    ...layerToAdd.meta_data,
                    sage_layer_subtype: 'actual',
                    program_name:
                      l.meta_data.loss_type === 'large' ? 'Layer 2' : 'Layer 1',
                    program_type: 'Riskxl',
                    isRiskLargeHidden: l.meta_data.loss_type === 'large',
                    isRiskVisible: false,
                    isRiskCatHidden: l.meta_data.loss_type === 'cat',
                    isRiskFinal: false,
                    riskActualLayerID: layer.id,
                  }
                  const hiddenLayerPhysicalMetaData = {
                    ...layerToAdd.sink.meta_data,
                    sage_layer_subtype: 'actual',
                    program_name:
                      l.meta_data.loss_type === 'large' ? 'Layer 2' : 'Layer 1',
                    program_type: 'Riskxl',
                    isRiskLargeHidden: l.meta_data.loss_type === 'large',
                    isRiskVisible: false,
                    isRiskCatHidden: l.meta_data.loss_type === 'cat',
                    isRiskFinal: false,
                    riskActualLayerID: layer.id,
                  }
                  const hiddenLayerPhysicalLayer = {
                    ...layerToAdd.sink,
                    participation: Math.abs(layerToAdd.sink.participation),
                    aggregateAttachment: {
                      value: 0,
                      currency:
                        layerToAdd.sink.aggregateAttachment?.currency || 'USD',
                    },
                    aggregateLimit: {
                      value: analyzereConstants.unlimitedValue,
                      currency:
                        layerToAdd.sink.aggregateLimit?.currency || 'USD',
                    },
                    loss_sets: [
                      {
                        id: l.id,
                        meta_data: l.meta_data,
                        loss_sets: l.loss_sets,
                      },
                    ],
                    meta_data: hiddenLayerPhysicalMetaData,
                  }
                  const hiddenLayer: Layer = {
                    ...layerToAdd,
                    lossSetLayers: [lossSetLayer],
                    meta_data: hiddenLayerMetaData,
                    physicalLayer: hiddenLayerPhysicalLayer,
                    sources: [...sources.map((s: any) => ({ ref_id: s.id }))],
                    sink: { ref_id: hiddenLayerPhysicalLayer.id },
                  }
                  newLayers.push(hiddenLayer)
                })
              }
            } else {
              updates.push({
                id: layer.id,
                change: {
                  sources: [...layers.map((l: any) => ({ ref_id: l.id }))],
                },
              })
            }
          }
          const updatesFinal =
            newLayers.length > 0
              ? this.service.postLossSetLayers(newLayers).pipe(
                map(newLayerResponses => {
                  if (newLayerResponses.data) {
                    const layer = cededLayers.data.layers[0]
                    updates.push({
                      id: layer.id,
                      change: {
                        sources: [
                          ...newLayerResponses.data.map((l: any) => ({
                            ref_id: l.id,
                          })),
                        ],
                      },
                    })
                    const rvlToPush = riskVisibleLayers
                      .map((l: any) => l?.data)
                      .find(
                        (rvl: any) =>
                          rvl && rvl.id === layer.meta_data.riskActualLayerID
                      )
                    if (rvlToPush) {
                      updates.push({
                        id: riskVisibleLayers
                          .map((l: any) => l?.data)
                          .find(
                            (rvl: any) =>
                              rvl &&
                              rvl.id === layer.meta_data.riskActualLayerID
                          ).id,
                        change: {
                          sources: [
                            ...newLayerResponses.data.map((l: any) => ({
                              ref_id: l.id,
                            })),
                          ],
                        },
                      })
                    }
                  }
                  return updates
                })
              )
              : of(updates)

          const backallocatedPatches = backallocatedUpdates.length > 0
            ? forkJoin(backallocatedUpdates.map(update => this.service.patchLayer(update)))
            : of([])

          return forkJoin([updatesFinal, backallocatedPatches]).pipe(
            switchMap(([finalUpdates, backallocatedResponses]) => {
              const grossLayers: string[] = layers.map((l: { id: string }) => l.id)
              return this.service.updatePortfolioLayers(structure.grossPortfolioID, grossLayers).pipe(
                switchMap(() => this.service.patchLogicalPortfolioLayers(finalUpdates)),
                switchMap(() => {
                  grossLayers.push(...cededLayers.data.layers.map((l: { id: string }) => l.id))
                  return this.service.updatePortfolioLayers(structure.netPortfolioID, grossLayers)
                }),
                map(() => {
                  if (isLast) {
                    return importBulkLossSetsSuccess({
                      id: structure.id,
                      parentGrossPortfolioID
                    })
                  } else {
                    return { type: 'No Action' }
                  }
                })
              )
            })
          )
        }
      )
    )
  })

  updateProgramIndex$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateProgramIndex),
      mergeMap(({ structure, index }) =>
        this.programService.update(structure.id, {
          ...structure,
          position_index: index,
        })
      ),
      rejectError(error =>
        this.store.dispatch(updateProgramIndexFailure({ error }))
      ),
      map(({ id, position_index }) =>
        // tslint:disable-next-line: no-non-null-assertion
        updateProgramIndexSuccess({ id, index: position_index! })
      )
    )
  })

  updateProgramIndexes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateProgramIndexes),
      mergeMap(({ req }) => {
        return this.programService.updatePositionIndexes(req)
      }),
      rejectError(error =>
        this.store.dispatch(updateProgramIndexesFailure({ error }))
      ),
      map(study => {
        return updateProgramIndexesSuccess({ structures: study })
      })
    )
  })

  updateProgramFolderID$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateFolderID),
      withLatestFrom(this.store.pipe(select(selectCurrentStudyPrograms))),
      mergeMap(([action, structures]) => {
        const { structureId, folderID } = action
        const structure = structures.filter(s => {
          return s.id === structureId
        })[0]
        return this.programService.update(structureId, {
          ...structure,
          folderID,
        })
      }),
      rejectError(error => {
        return this.store.dispatch(updateFolderIDFailure({ error }))
      }),
      map(({ id, folderID }) => {
        return updateFolderIDSuccess({
          structureId: id,
          folderID,
        })
      })
    )
  })

  updateProgramCounts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateCounts),
      withLatestFrom(
        this.store.pipe(select(selectCurrentStructureID)),
        this.store.pipe(select(selectCurrentStudyPrograms))
      ),
      mergeMap(([action, structureId, structures]) => {
        const { fotCount, quoteCount } = action
        const structure = structures.filter(s => {
          return s.id === structureId
        })[0]

        if (structureId) {
          return this.programService.update(structureId, {
            ...structure,
            fotCount,
            quoteCount,
          })
        } else {
          return of([])
        }
      }),
      rejectError(error => {
        return this.store.dispatch(updateCountsFailure({ error }))
      }),
      map(({ id, fotCount, quoteCount }) => {
        return updateCountsSuccess({
          structureId: id,
          fotCount: fotCount ?? 0,
          quoteCount: quoteCount ?? 0,
        })
      })
    )
  })

  updateTailMetrics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateTailMetrics),
      mergeMapWithInput(({ id, tailMetrics }) => {
        const tailMetricsOptions = {
          ...tailMetrics,
          returnPeriodData: [] as ReturnPeriodRow[],
        }
        return this.programService.putTailMetricsOptions(id, tailMetricsOptions)
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(updateTailMetricsFailure({ error }))
      ),
      map(([_, { id, tailMetrics }]) => {
        return updateTailMetricsSuccess({ id, tailMetrics })
      })
    )
  })

  handleSharedLimitLayers(
    cededLayers: any,
    lossSetLayers: any[],
    analysisProfileID: string,
    service: AnalyzreService
  ): Observable<{ backallocatedLayers: any[] }> {
    const sharedLimitLayerRequests = cededLayers.data.layers
      .filter((layer: any) => isLayerShared(layer))
      .map((layer: any) => service.fetchLayer(layer.meta_data.backAllocatedForID))
    return forkJoin(sharedLimitLayerRequests).pipe(
      switchMap((sharedLimitActualLayers: any[]) => {
        const updates = sharedLimitActualLayers.map((layer: any) => ({
          id: layer.data.id,
          change: {
            sources: lossSetLayers.map((l: any) => ({ ref_id: l.id }))
          }
        }))
        const updateReq = service.patchLogicalPortfolioLayers(updates)

        return updateReq.pipe(
          switchMap(() => {
            const layerViewRequests = sharedLimitActualLayers.map(layer =>
              service.postLayerView(layer.data.id, analysisProfileID)
            )
            return forkJoin(layerViewRequests).pipe(
              map(sharedLimitLayerViews => ({
                cededLayers,
                sharedLimitLayerViews,
              }))
            )
          }),
          switchMap(
            ({
              cededLayers,
              sharedLimitLayerViews,
            }) => {
              const sharedLimitLayerReqs = cededLayers.data.layers.filter(
                (layer: any) => isLayerShared(layer)
              )?.map((layer: any) => {
                const backallocated = layer.sources.find((ls: any) => ls.meta_data.sage_layer_subtype === 'backallocated')
                const updatedLayerView = sharedLimitLayerViews.filter(view => {
                  return view.data.layerID === layer.meta_data.backAllocatedForID
                })[0]
                const source_id = updatedLayerView.data.id
                const sink = { ref_id: backallocated.sink.id }
                return this.service.postLayer({
                  ...backallocated,
                  source_id,
                  sink
                })
              })
              return forkJoin(sharedLimitLayerReqs).pipe(
                map((backallocatedLayers: any[]) => ({
                  backallocatedLayers
                }))
              )
            }
          )
        )
      })
    )
  }
  
  async checkForSharedLimitLayers(selectedStructures: Program[], allStructures: Program[]): Promise<string[]> {
    const sharedLimitErrorMessageElements: string[] = []
    const cededPortfolios: any[] = []
    try {
      await Promise.all(
        selectedStructures.map(async ss => {
          const res = await lastValueFrom(
            this.service.fetchPortfolio(ss.cededPortfolioID)
          )
          if (!res.error) {
            cededPortfolios.push(res.data)
          }
        })
      )
  
      const cededLayers: LogicalPortfolioLayer[] = []
      cededPortfolios.forEach(port => {
        port.layers.forEach((layer: any) => {
          cededLayers.push(layer as LogicalPortfolioLayer)
        })
      })
  
      const sharedLimitLayers = cededLayers.filter(layer => isLayerShared(layer))
      if (sharedLimitLayers?.length) {
        const sharedStructureIDs = new Set<string>()
        const selectedStructureIDs = new Set(selectedStructures.map(s => s.id))
        sharedLimitLayers.forEach(layer => {
          layer.sources.forEach(s => {
            const source = s as any
            source.sink.sources.forEach((ss: any) => {
              const structureID = ss?.meta_data?.structureID
              if (structureID) {
                sharedStructureIDs.add(structureID)
              }
            })
          })
        })
  
        const missingSharedStructures = Array.from(sharedStructureIDs)
          .filter(id => !selectedStructureIDs.has(id))
          .map(id => allStructures.find(s => s.id === id))
  
        missingSharedStructures.forEach(structure => {
          sharedLimitErrorMessageElements.push(`${structure.label}`)
        })
      }
    } catch (error) {
      this.messageService.showMessage('Error fetching portfolio(s)', error)
    }
    return sharedLimitErrorMessageElements
  }

  handleAddLossSets(
    selectedStructures: Program[],
    result: CloneDialogResult
  ): Observable<Action> {
    let time = 0
  
    return from(selectedStructures).pipe(
      concatMap((structure, i) => {
        const updatedStructure = structure.description === 'No Modeling' 
          ? { ...structure, description: '' } 
          : structure
  
        const payload = {
          structure: updatedStructure,
          parentGrossPortfolioID: result.parentGrossPortfolioID || '',
          analysisProfileID: result.analysisProfileID || '',
          isLast: i === selectedStructures.length - 1
        }
  
        const actions: Action[] = [
          setProgramNameAndDescription({
            structure: updatedStructure,
            name: updatedStructure.label,
            description: updatedStructure.description
          }),
          importBulkLossSets(payload)
        ]
  
        time += 1200
  
        return timer(time).pipe(map(() => actions))
      }),
      concatAll()
    )
  }

  handleSwapLossSets(
    selectedStructures: Program[],
    result: CloneDialogResult
  ): Observable<Action> {
    const structure = selectedStructures?.[0]
    if (!structure) {
      return throwError(() => new Error('No structure selected'))
    }
  
    return forkJoin([
      this.service.fetchPortfolio(structure.parentGrossPortfolioID || ''),
      this.service.fetchPortfolio(result.parentGrossPortfolioID)
    ]).pipe(
      switchMap(([oldLossSets, newLossSets]) => {
        return this.dialog
          .open(SwapLossSetsDialogComponent, {
            data: [oldLossSets, newLossSets]
          })
          .afterClosed()
          .pipe(
            filter(Boolean),
            map(lossSetMapping =>
              handleSwapBulkLossSets({
                structures: selectedStructures,
                analysisProfileID: result.analysisProfileID || '',
                newParentGrossPortfolioID: result.parentGrossPortfolioID || '',
                lossSetMapping
              })
            )
          )
      })
    )
  }
}
