import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { ApplicationError } from "src/app/error/model/error";
import { CreditIssueFot, CreditQuoteOverview, CreditQuoteAuditRow, CreditQuoteAuditTable, CreditQuoteOverviewRow, CreditQuoteSubmissionOverview, CreditQuoteAssignedLineRow, CreditQuoteNewAssignedLine } from "../../model/credit-quote.model";
import { CreditSubmissionAuthorizationRequest, CreditSubmissionAuthorizeQuoteDialogData } from "../../model/credit-submission.model";


export const CreditQuoteAction = createActionGroup({
  source: 'Credit Results',
  events: {
    'Fetch Quote Audit': props<{ trancheId: string }>(),
    'Fetch Quote Audit Success': props<{ results: CreditQuoteAuditTable }>(),
    'Fetch Quote Audit Failure': props<{ error: ApplicationError }>(),
    'Issue FOT': props<{ data: CreditIssueFot }>(),
    'Issue FOT Success': props<{ data: CreditIssueFot }>(),
    'Issue FOT Failure': props<{ error: ApplicationError }>(),
    'Fetch Quote Overview': props<{ trancheId: string }>(),
    'Fetch Quote Overview Success': props<{ results: CreditQuoteOverview }>(),
    'Fetch Quote Overview Failure': props<{ error: ApplicationError }>(),
    'Fetch Quotes For Reinsurer': props<{ reinsurerId: number }>(),
    'Fetch Quote For Reinsurer Success': props<{ results: CreditQuoteSubmissionOverview }>(),
    'Fetch Quote For Reinsurer Failure': props<{ error: ApplicationError }>(),
    'Fetch Assigned Lines': props<{ trancheId?: string }>(),
    'Fetch Assigned Lines Success': props<{ results: CreditQuoteAssignedLineRow[] }>(),
    'Fetch Assigned Lines Failure': props<{ error: ApplicationError }>(),
    'Issue Authorization': props<{ trancheId: string, authorization: number}>(),
    'Issue Authorization Success': props<{ data: Record<string, string> }>(),
    'Issue Authorization Failure': props<{ error: ApplicationError }>(),
    'Issue Quote': props<{ quote:  CreditSubmissionAuthorizeQuoteDialogData }>(),
    'Issue Quote Success': props<{ quote:  CreditSubmissionAuthorizationRequest }>(),
    'Issue Quote Failure': props<{ error: ApplicationError }>(),
    'Update Assigned Line': props<{ data: CreditQuoteAssignedLineRow }>(),
    'Update Assigned Line Success': props<{ data: CreditQuoteAssignedLineRow }>(),
    'Update Assigned Line Failure': props<{ error: ApplicationError }>(),
    'Get Reinsurers': emptyProps(),
    'Get Reinsurers Success': props<{ data: Record<number, string> }>(),
    'Get Reinsurers Failure': props<{ error: ApplicationError }>(),
    'Add Assigned Line': props<{ data: CreditQuoteNewAssignedLine, trancheId: string }>(),
    'Add Assigned Line Failure': props<{ error: ApplicationError }>(),
    'Add Assigned Line Success': props<{ data: CreditQuoteNewAssignedLine }>(),
    'Delete Assigned Line': props<{ id: number }>(),
    'Delete Assigned Line Failure': props<{ error: ApplicationError }>(),
    'Delete Assigned Line Success': props<{ id: number }>(),
    'Inform Reinsurers of Signed': emptyProps(),
  },
})
