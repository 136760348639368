import { Action, createReducer, on } from '@ngrx/store'
import { initialState, State } from './auth.state.facade'
import { getBlobResponses } from '../../model/blob.converter'
import * as fromAuthActions from './auth.actions'
import * as SharedLimitActions from '../../../analysis/store/grouper/shared-limit/grouper-shared-limit.actions'
import * as fromProgramActions from '../program/program.actions'
import * as fromExploreActions from '../../../analysis/explore/store/explore.actions'
import { createEntityAdapter } from '@ngrx/entity'
import { clone } from 'ramda'

export const adapter = createEntityAdapter<State>()

const authReducer = createReducer(
  initialState,
  on(
    fromAuthActions.identifySuccess,
    (state, { token, username, redirectURL, name }) => {
      return {
        ...state,
        username,
        name,
        token,
        authenticated: true,
        redirectURL,
      }
    }
  ),

  on(fromAuthActions.updateStudySageView, (state, { id, study }) => {
    const updatedStudies = state.studies?.map(x => (x.id === id ? study : x))
    return {
      ...state,
      studies: updatedStudies!,
    }
  }),

  on(
    fromProgramActions.postLossSetMappingLabelsSuccess,
    fromProgramActions.updateLossSetMappingLabelsSuccess,
    (state, { id, mappingLabels }) => {
      const updatedStudies = state.studies?.map(study => {
        const mapping_labels = String(study.id) === id ? mappingLabels : study.mapping_labels;
        return {
          ...study,
          mapping_labels
        }
      })
      return {
        ...state,
        studies: updatedStudies!
      }
    }
  ),
  on(fromAuthActions.updateAuthToken, (state, { token }) => ({
    ...state,
    token,
  })),

  on(fromAuthActions.setAnalyzereToken, (state, { token }) => ({
    ...state,
    analyzeReToken: token,
  })),

  on(fromAuthActions.setMarketToken, (state, { marketToken }) => ({
    ...state,
    marketToken,
  })),

  on(fromAuthActions.setSelectedApp, (state, { selectedApp }) => ({
    ...state,
    selectedApp,
  })),

  on(
    fromAuthActions.identifyPermissionsSuccess,
    (
      state,
      {
        permissions,
        security,
        blobSAS,
        carriers,
        studies,
        marketToken,
        featureFlags,
        sharedLimits,
        sharedLimitMembers,
        compareViews,
        hasWhitespaceAccess,
        usaBasedUser,
        facSageUrl
      }
    ) => ({
      ...state,
      marketToken,
      userPermissions: permissions,
      security,
      blobs: getBlobResponses(blobSAS),
      carriers,
      studies,
      userPermissionsError: null,
      featureFlags,
      sharedLimits,
      sharedLimitMembers,
      compareViews,
      hasWhitespaceAccess,
      usaBasedUser,
      facSageUrl
    })
  ),
  on(fromAuthActions.identifyPermissionsFailure, (state, { error }) => ({
    ...state,
    userPermissionsError: error.message,
  })),
  on(
    fromAuthActions.setAuthenticatingUser,
    (state, { authenticatingUser }) => ({
      ...state,
      authenticatingUser,
    })
  ),
  on(
    fromAuthActions.setSalesforceUserRole,
    (state, { salesforceUserRole }) => ({
      ...state,
      salesforceUserRole,
    })
  ),
  on(fromAuthActions.setSageLogicalRole, (state, { sageLogicalRole }) => ({
    ...state,
    sageLogicalRole,
  })),
  on(
    SharedLimitActions.updateSLState,
    (state, { sharedLimits, sharedLimitMembers }) => {
      return { ...state, sharedLimits, sharedLimitMembers }
    }
  )
)

export function reducer(state: State | undefined, action: Action): State {
  return authReducer(state, action)
}
