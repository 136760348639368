import { BackendService } from 'src/app/api/backend/backend.service'
import { AuthService } from 'src/app/auth.service'
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { Store } from '@ngrx/store'
import {
  interval,
  fromEvent,
  of,
  Subject,
  Subscription,
  throwError,
} from 'rxjs'
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  map,
  takeUntil,
} from 'rxjs/operators'
import { AppState } from './core/store'
import { setWidth, WindowSize } from './core/store/app.actions'
import { NavService } from './nav.service'
import { MatSidenav } from '@angular/material/sidenav'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { BnNgIdleService } from 'bn-ng-idle'
import { MatDialog } from '@angular/material/dialog'
import { TimeoutDialogComponent } from './timeout-dialog.component'
import { environment } from '../environments/environment'
import * as fromAuthActions from './core/store/auth/auth.actions'
import { Router } from '@angular/router'
import { UtilService } from './util.service'

@Component({
  selector: 'app-root',
  styles: [
    `
      .loader-overlay {
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10;
        top: 0;
        left: 0;
        position: fixed;

        .center {
          position: absolute;
          top: 50%;
          left: 50%;
          -moz-transform: translateX(-50%) translateY(-50%);
          -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
        }
      }
      .content-all {
        display: flex;
        flex-direction: column;
        max-height: 100vh;
      }

      .content-outer-container {
        max-height: calc(100vh - 24px);
      }

      .centered {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }

      .app-title {
        margin-bottom: 0;
        margin-top: 0;
      }

      .footer-container {
        height: 24px;
        background: black;
        z-index: 999;
      }

      .footer-content {
        margin: 2px auto;
        text-align: center;
        color: white;
        font-size: small;
        text-align: left;
        padding-left: 10px;
      }
    `,
  ],
  template: `
    <div class="content-all">
      <div class="content-outer-container">
        <mat-sidenav-container style="position: static;">
          <mat-sidenav mode="over" opened="false">
            <app-nav-list-container></app-nav-list-container>
          </mat-sidenav>
          <router-outlet></router-outlet>
        </mat-sidenav-container>
        <app-debug></app-debug>
        <app-svg-defs></app-svg-defs>
        <div class="loader-overlay" *ngIf="spinnerVisible">
          <div class="center">
            <mat-progress-spinner
              mode="indeterminate"
              diameter="50"
              color="accent"
            ></mat-progress-spinner>
          </div>
        </div>
      </div>

      <div class="footer-container">
        <p align="center" class="footer-content">
          <a
            class="footer-content"
            target="_blank"
            href="https://global.lockton.com/re/en/sage-patents"
            >Patent: https://global.lockton.com/re/en/sage-patents</a
          >
        </p>
      </div>
    </div>
  `,
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  authServiceSubscription: Subscription
  authTimerSubscription: Subscription
  private destroy$ = new Subject()
  dialogOpen = false
  authenticated = false
  awaitingToken = false
  lastTimestamp = new Date().getTime()
  spinnerStateChanged: Subscription = new Subscription()
  public spinnerVisible: boolean = false
  @ViewChild(MatSidenav, { static: true }) sidenav: MatSidenav

  constructor(
    private store: Store<AppState>,
    private nav: NavService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private bnIdle: BnNgIdleService,
    public dialog: MatDialog,
    private router: Router,
    private backendService: BackendService,
    private authService: AuthService,
    private utilService: UtilService
  ) {
    const urlParams = new URLSearchParams(window.location.search)
    const action = urlParams.get('action')

    if (action === 'password-reset') {
      urlParams.set('action', 'none')
      window.history.replaceState(null, '', window.location.pathname)
      sessionStorage.clear()
      this.authService.redirectToPasswordReset()
      return
    }

    this.authServiceSubscription =
      this.authService.accessTokenRefreshed.subscribe(token => {
        this.store.dispatch(fromAuthActions.updateAuthToken({ token }))
      })

    const previousUrlLastIndexOf = window.location.pathname
      .toString()
      .substr(window.location.pathname.toString().lastIndexOf('/') + 1)
    if (
      !previousUrlLastIndexOf.includes('login') &&
      !previousUrlLastIndexOf.includes('access_token') &&
      previousUrlLastIndexOf.length > 0
    ) {
      sessionStorage.setItem('reDirectUrl', window.location.pathname)
    }

    const username = sessionStorage.getItem('username')
    const isAfterLoginRedirect =
      window.location.hash && window.location.hash.startsWith('#state=')

    if (isAfterLoginRedirect) {
      this.authService.handleAfterLoginRedirect(username)
    } else if (username) {
      this.authService.trySignIn(username, false).then(() => {
        if (this.authService.account === null) {
          this.router.navigate(['/login'])
        }
      })
    } else {
      this.router.navigate(['/login'])
    }

    this.authTimerSubscription = interval(2000).subscribe(async _val => {
      const currentTimestamp = new Date().getTime()
      if (
        Math.abs(currentTimestamp - this.lastTimestamp) / 360000 >
        environment.timeout / 3600
      ) {
        await this.authService.signOut()
      } else {
        this.lastTimestamp = currentTimestamp
        const token = await this.authService.getAccessToken()
        if (
          token &&
          this.tokenNearingExpiration(token) &&
          !this.awaitingToken
        ) {
          this.awaitingToken = true
          this.backendService
            .postEvent({
              eventType: 'Token Event',
              eventDetails: { type: 'Refreshing Token' },
            })
            .subscribe(err => throwError(err))
          this.authService
            .getAccessToken(true)
            .finally(() => {
              this.awaitingToken = false
            })
            .catch(err => {
              if (err.type !== 'silent_refresh_timeout') {
                console.error('Error refreshing token silently', err)
              }
            })
        }
      }
    })

    this.bnIdle
      .startWatching(environment.timeout)
      .subscribe(async (isTimedOut: boolean) => {
        const token = await this.authService.getAccessToken()
        if (isTimedOut && !this.dialogOpen && token) {
          this.dialogOpen = true
          const dialogRef = this.dialog.open(TimeoutDialogComponent, {
            disableClose: true,
          })
          dialogRef.afterClosed().subscribe(async result => {
            this.dialogOpen = false
            if (result === 'Reset') {
              this.bnIdle.resetTimer()
            } else {
              await this.authService.signOut()
            }
          })
        }
      })
    this.matIconRegistry.addSvgIcon(
      'word',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/word.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'excel',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/excel.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'database',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/database.svg'
      )
    )
    this.matIconRegistry.addSvgIcon(
      'default',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/default_file.svg'
      )
    )
  }

  ngOnInit(): void {
    this.spinnerStateChanged = this.utilService.spinnerState.subscribe(
      data => (this.spinnerVisible = data)
    )
  }

  ngAfterViewInit(): void {
    this.nav.register(this.sidenav)

    // Dispatch initial window width
    of(this.getWindowSize(window))
      .pipe(
        takeUntil(this.destroy$),
        delay(0),
        map(size => setWidth({ size }))
      )
      .subscribe(this.store)

    // Dispatch window width on resize
    fromEvent(window, 'resize')
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(200),
        distinctUntilChanged(),
        map(e => e.target as Window),
        map(window => this.getWindowSize(window)),
        map(size => setWidth({ size }))
      )
      .subscribe(this.store)
  }

  ngOnDestroy(): void {
    this.authServiceSubscription.unsubscribe()
    this.authTimerSubscription.unsubscribe()
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  private getWindowSize(window: Window): WindowSize {
    return { width: window.innerWidth, height: window.innerHeight }
  }

  private tokenNearingExpiration(token: string) {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp
    // tslint:disable-next-line: new-parens
    return Math.floor(new Date().getTime() / 1000) >= expiry - 900
  }
}
