import { createAction, props } from '@ngrx/store'
import { Program } from '../../../core/model/program.model'
import { ApplicationError } from '../../../error/model/error'
import { Layer } from '../../model/layers.model'
import { CompareStructureOptionsDimensionChangeEvent } from '../../model/compare-metrics.model'

export const addProgramToCompare = createAction(
  '[Program Compare] Add Program',
  props<{ program: Program }>()
)

export const addProgramToCompareSuccess = createAction(
  '[Program Compare] Add Program Success',
  props<{ id: string; cededLayers: Layer[] }>()
)

export const addProgramToCompareFailure = createAction(
  '[Program Compare] Add Program Failure',
  props<{ id: string; error: ApplicationError }>()
)

export const removeProgramFromCompare = createAction(
  '[Program Compare] Remove Program',
  props<{ id: string }>()
)

export const setProgramFromCompare = createAction(
  '[Program Compare] Set Show Check Program',
  props<{ id: string; check: boolean; program: Program }>()
)

export const setSelectedProgramFromCompare = createAction(
  '[Program Compare] Set Selected Program',
  props<{ id: string; selected: string; program: Program }>()
)

interface CompareSetExpandedMetricCategoryProps {
  /* Category name to set expanded */
  category: string
  /* Value: true for expand, false for collapse, undefined to toggle */
  value?: boolean
}

export const setCompareExpandedMetricCategory = createAction(
  '[Program Compare] Set Expanded Metric Category',
  props<CompareSetExpandedMetricCategoryProps>()
)

export const setCompareProgramGroup = createAction(
  '[Program Compare] Add Program Group',
  props<{ id: string }>()
)

export const setCompareProgramGroupMembers = createAction(
  '[Program Compare] Add Program Group Members',
  props<{ id: string }>()
)

export const setCompareProgramGroupMembersFailure = createAction(
  '[Program Compare] Add Program Group Members Failure',
  props<{ error: ApplicationError }>()
)

export const setCompareProgramGroupMembersSuccess = createAction(
  '[Program Compare] Add Program Group Members Success',
  props<{ imageSrcGroup: string[]; id: string }>()
)

export const removeCompareProgramGroup = createAction(
  '[Program Compare] Remove Program Group',
  props<{ id: string }>()
)

export const updateCompareGrossSelected = createAction(
  '[Program Compare] Update Gross Selection',
  props<{ grossSelected: boolean }>()
)

// Used for Hide Metric Rank and Expand Change Metric
interface CompareSetHiddenMetricRankProps {
  /* Category and label of metric to hide or show */
  categoryLabel: string
  /* Value: true for hide, false for show, undefined to toggle */
  value?: boolean
}

export const setCompareHiddenMetricRank = createAction(
  '[Program Compare] Set Hidden Metric RAG Rank',
  props<CompareSetHiddenMetricRankProps>()
)

export const setCompareExpandedChangeMetric = createAction(
  '[Program Compare] Set Expanded Change Metric',
  props<CompareSetHiddenMetricRankProps>()
)

export const updateCompareEntityIndex = createAction(
  '[Program Compare] Update Compare Entity Index',
  props<{ indexArray: string[] }>()
)

export const exportCompareAsExcel = createAction(
  '[Program Compare] Export As Excel'
)

export const compareCurrency = createAction(
  '[Compare] Compare Currency',
  props<{ compareCurrency: string | null }>()
)

export const compareConversion = createAction(
  '[Compare] Compare Conversion',
  props<{ compareConversion: string }>()
)

export const restoreCompareStructureOptionsSettings = createAction(
  '[Compare Structure Options] Restore Settings'
)

export const setCompareStructureOptionsDimensionProp = createAction(
  '[Compare Structure Options] Set Dimension Prop',
  props<CompareStructureOptionsDimensionChangeEvent>()
)

export const setTabIndex = createAction(
  '[Compare Structure Options] Set Compare Tab',
  props<{ tabIndex: number }>()
)

export const exportCompareGraph = createAction(
  '[CompareGraph] Export state',
  props<{
    graphBase64: string
  }>()
)
