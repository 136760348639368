import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { LayerRef } from '../../analysis/model/layers.model'
import { LossSetLayer } from '../../analysis/model/loss-set-layers.model'
import { LogicalPortfolioLayer } from '../../api/analyzere/analyzere.model'

export interface CloneDialogResult {
  parentGrossPortfolioID?: string
  analysisProfileID?: string
  layers?: LossSetLayer[] | LayerRef[] | any
  cededLayers?: LogicalPortfolioLayer[] | undefined
  lossSetNames?: string[]
  index?: number
  isClone: boolean
}

@Component({
  selector: 'app-clone-structure-dialog',
  styleUrls: ['./clone-dialog.component.scss'],
  templateUrl: './clone-dialog.component.html',
})
export class CloneDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CloneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title
    this.sharedLimitErrorMessageElements = data.sharedLimitErrorMessageElements
  }
  analysisProfileID: string
  parentGrossPortfolioID: string
  addLossSets: boolean
  title: string
  sharedLimitErrorMessageElements: string[]
  onSaveClick: boolean

  get hasError(): boolean {
    return this.sharedLimitErrorMessageElements && 
    this.sharedLimitErrorMessageElements.length > 0
  }

  onSave() {
    if (this.analysisProfileID && this.parentGrossPortfolioID) {
      this.dialogRef.close({
        analysisProfileID: this.analysisProfileID,
        parentGrossPortfolioID: this.parentGrossPortfolioID,
        event: 'save',
      })
    }
  }
  onClose() {
    this.dialogRef.close({})
  }
}
