<section>
  <div mat-dialog-content>
    <div class="add">
      <mat-form-field class="risk-code">
        <mat-label>Select Risk Code</mat-label>
        <input
          type="text"
          placeholder="Select Risk Code"
          class="risk-code-input"
          matInput
          [formControl]="riskCodeControl"
          [matAutocomplete]="riskCodeAuto"
        />
        <mat-autocomplete
          #riskCodeAuto="matAutocomplete"
          (optionSelected)="onRiskCodeChange($event.option.value)"
          [displayWith]="displayFn"
        >
          <mat-option
            *ngFor="let risk of filterRiskCodes"
            [value]="risk"
            matTooltipPosition="above"
            matTooltip="{{ risk.risk_code_description }}"
          >
            {{ risk.risk_code }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <app-percentage-input
        [value]="getPercentage()"
        (inputChange)="onInputChangeSetPercentage($event)"
        [decimal]="2"
        [isCession]="true"
        name="Percentage"
      >
      </app-percentage-input>
      <button
        class="add-button"
        appButton
        border
        center
        accent
        [disabled]="validateAdd()"
        (click)="onAddClick()"
      >
        Add
      </button>
    </div>
    <div
      class="table-div"
      *ngIf="riskCodeWithPercentArray && riskCodeWithPercentArray.length > 0"
    >
      <table class="tidy-auto-table">
        <tbody>
          <ng-container *ngFor="let risk of riskCodeWithPercentArray">
            <tr>
              <td>{{ risk.risk_code }}</td>
              <td class="row-percentage">{{ risk.percentage * 100 }}%</td>
              <td class="close">
                <mat-icon class="remove-icon" (click)="deletedRiskCode(risk.id)"
                  >close</mat-icon
                >
              </td>
            </tr>
          </ng-container>
          <tr>
            <td>Total</td>
            <td>{{ getTotal() * 100 }}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div mat-dialog-actions>
    <mat-slide-toggle
      class="check-all"
      (change)="onToggleThisLayerChange($event)"
      >Adjust for this layer only</mat-slide-toggle
    >
    <div>
      <button
        appButton
        border
        center
        accent
        (click)="onOKClick()"
        [disabled]="validateOk()"
        mat-dialog-close="2"
      >
        OK
      </button>
      <button appButton link [mat-dialog-close]="false">Cancel</button>
    </div>
  </div>
</section>
