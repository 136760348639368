<div>
  <div class="main-chart">
    <app-pricing-curve-graph
      [pricingCurves]="pricingCurves"
      [status]="status"
      [savedCurves]="savedCurves"
      [context]="context"
      [graphSettings]="graphSettings"
    ></app-pricing-curve-graph>
  </div>
  <div class="curve-details-container">
    <app-pricing-curve-details-container
      [pricingCurves]="pricingCurves"
      [status]="status"
      [context]="context"
      [readonly]="readonly"
      [isAdmin]="isAdmin"
    ></app-pricing-curve-details-container>
  </div>
</div>
