import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { ApplicationError } from '../../../error/model/error'
import {
  ClientSearch,
  CreditSubmissionStructure,
  CreditSubmissionUserTranche,
  CreditSubmissionReinsurer,
} from '../../model/credit-submission.model'

export const CreditSubmissionStatusActions = createActionGroup({
  source: 'Credit Submission Status',
  events: {
    'Send For Approval': emptyProps(),
    'Send For Approval Failure': props<{ error: ApplicationError }>(),
    'Send For Approval Success': props<{
      structure: CreditSubmissionStructure
    }>(),
    'Send To Market': props<{
      structure: CreditSubmissionStructure
    }>(),
    'Send To Market Failure': props<{ error: ApplicationError }>(),
    'Send To Market Success': props<{
      structure: CreditSubmissionStructure
    }>(),
    'Send To Market Reinsurers ': props<{
      structure: CreditSubmissionStructure
    }>(),
    'Send To Market Reinsurers Failure': props<{ error: ApplicationError }>(),
    'Send To Market Reinsurers Success': props<{
      userTranches: CreditSubmissionUserTranche[]
    }>(),
  },
})

export const CreditSubmissionStructureActions = createActionGroup({
  source: 'Credit Submission Structure',
  events: {
    'Reset Selected Submission': emptyProps(),
    'Set Selected Filters': props<{ filters: ClientSearch }>(),
    'Get Submission Structures Failure': props<{ error: ApplicationError }>(),
    'Get Submission Structures Success': props<{
      structures: CreditSubmissionStructure[]
    }>(),
    'Set Selected Submission': props<{
      structure: CreditSubmissionStructure
    }>(),
    'Update Selected Submission': props<{
      structure: CreditSubmissionStructure
    }>(),
    'Update Selected Submission Reinsurers': props<{
      structure: CreditSubmissionStructure
    }>(),
    'Reinitialize Tower Submission': props<{ structureId: string }>(),
    Fetch: props<{ structureId: string }>(),
    'Fetch Success': props<{ structure: CreditSubmissionStructure }>(),
    'Fetch Failure': props<{ error: ApplicationError }>(),
    Put: emptyProps(),
    'Put Success': props<{ structure: CreditSubmissionStructure }>(),
    'Put Failure': props<{ error: ApplicationError }>(),
  },
})

export const CreditSubmissionReinsurerActions = createActionGroup({
  source: 'Credit Submission Reinsurer',
  events: {
    'Get Submission Reinsurers': props<{ submissionId: number }>(),
    'Get Submission Reinsurers Success': props<{
      reinsurers: CreditSubmissionReinsurer[]
    }>(),
    'Get Submission Reinsurers Failure': props<{ error: ApplicationError }>(),
    'Update Viewed Status': emptyProps(),
    'Update Viewed Status Success': props<{
      reinsurer: CreditSubmissionReinsurer
    }>(),
    'Update Viewed Status Failure': props<{ error: ApplicationError }>(),
  },
})

export const CreditSubmissionUserTrancheActions = createActionGroup({
  source: 'Credit Submission User Tranche',
  events: {
    Fetch: props<{ submissionId: number }>(),
    'Fetch Failure': props<{ error: ApplicationError }>(),
    'Fetch Success': props<{ tranches: CreditSubmissionUserTranche[] }>(),
    Update: props<{ tranche: CreditSubmissionUserTranche }>(),
    Save: emptyProps(),
    'Save Failure': props<{ error: ApplicationError }>(),
    'Save Success': props<{ tranches: CreditSubmissionUserTranche[] }>(),
  },
})
