import * as d3 from 'd3'
import { select, Selection } from 'd3-selection'
import {
    formatCurrencyString,
    formatLongNumberLabel,
} from './utils/charts.util'
import addChartLabels from './utils/chart-labels'
import { clone } from 'ramda'

export interface GrossScatterBubbleChartOptions {
    chartLabel?: string
    xLabel?: string
    yLabel?: string
    metricPrimary?: string
    percent?: boolean
    sizeCol?: string
    min: boolean
    lightChartMode?: boolean
    avgX?: number
    avgY?: number
    splitValues?: string[]
}

export interface GrossScatterBubbleDatum {
    x: number
    y: number
    size?: number
    label?: string
}

export class GrossScatterBubbleChart {
    private container: HTMLElement
    private data: GrossScatterBubbleDatum[]
    private margin = { top: 50, right: 5, bottom: 50, left: 90 }
    private options: GrossScatterBubbleChartOptions

    constructor(container: HTMLElement, options: GrossScatterBubbleChartOptions) {
        this.container = container
        this.options = options
        this.data = []
        select(container).selectAll('*').remove()
    }

    draw(data: GrossScatterBubbleDatum[]): void {
        this.data = data
        this.setUpGrossScatterBubbleChart()
    }

    graphingColorPalette = ['#00aeef', '#f68a33', '#01c96d']
    color = d3.scaleOrdinal(this.graphingColorPalette)

    private setUpGrossScatterBubbleChart(): void {
        const percent = this.options.percent ? '%' : ''
        const lightChartMode = this.options.lightChartMode
        const data = this.data
        const margin = this.options.min
            ? this.margin
            : { top: 75, right: 50, bottom: 125, left: 100 }
        const width = this.container.clientWidth - margin.left - margin.right
        const height = this.container.clientHeight - margin.top - margin.bottom

        const avgY = this.options.avgY
        const avgX = this.options.avgX

        const sizeScale = d3
            .scaleLinear()
            .domain([0, d3.max(data, (d) => d.size || 0) || 1])
            .range([this.options.min ? 5 : 10, this.options.min ? 25 : 50])

        const maxX = d3.max(data, (d) => d.x) || 0
        const xScale = d3.scaleLinear().domain([0, maxX * 1.2]).range([0, width])

        const maxY = d3.max(data, (d) => d.y) || 0
        const minY = d3.min(data, (d) => d.y) || 0
        const yScale = d3
            .scaleLinear()
            .domain([minY - maxY * 0.1, maxY * 1.2])
            .range([height, 0])

        const tooltip: Selection<HTMLDivElement, unknown, null, undefined> | undefined = select(this.container)
            .append('div')
            .style('opacity', 0)
            .style('transition', 'all 1s')
            .attr('class', 'custom-tooltip')

        const container = d3
            .select(this.container)
            .append('svg')
            .style('width', '100%')
            .style('height', '100%')
            .style('overflow', 'display')
            .style('position', 'relative')
            .style('background', 'transparent')

        const chart = container
            .append('svg')
            .attr('width', width)
            .attr('height', height)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`)

        chart
            .selectAll('.circle')
            .data(data)
            .enter()
            .append('circle')
            .attr('class', 'bubble')
            .attr('cx', (d) => xScale(d.x))
            .attr('cy', (d) => yScale(d.y))
            .attr('r', (d) => (d.size ? sizeScale(d.size) : this.options.min ? 5 : 10))
            .style('fill', this.color('0'))

        if (avgY) {
            const comparisonLineY = yScale(avgY)
            chart
                .append('line')
                .attr('x1', 0)
                .attr('y1', comparisonLineY)
                .attr('x2', width)
                .attr('y2', comparisonLineY)
                .attr('stroke', '#ef5399')
                .attr('stroke-width', 2)
        }

        if (avgX) {
            const comparisonLineX = xScale(avgX)
            chart
                .append('line')
                .attr('x1', comparisonLineX)
                .attr('y1', 0)
                .attr('x2', comparisonLineX)
                .attr('y2', height)
                .attr('stroke', '#ef5399')
                .attr('stroke-width', 2)
        }

        chart
            .on('mouseover', (event: any) => {
                const d = event.target.__data__
                const tooltipData = data.find(v => d.label && v.label === d.label)
                const showTooltip = tooltipData && tooltipData.x && tooltipData.y && tooltip
                if (showTooltip) {
                    tooltip?.style('opacity', 1)
                    select(event.currentTarget).style('opacity', 1)
                }
            })
            .on('mousemove', (event: any) => {
                const [x, y] = d3.pointer(event)
                const { xLabel, yLabel } = this.options
                const d = event.target.__data__
                const tooltipData = data.find(v => d && v.label === d.label)
                const showTooltip = tooltipData && tooltipData.x && tooltipData.y && tooltip
                if (showTooltip) {
                    tooltip
                        .html(`
                                <div>
                                    <strong>${tooltipData.label}</strong>
                                    <div>${xLabel ?? 'X'}: ${tooltipData.x}${percent}</div>
                                    <div>${yLabel ?? 'Y'}: ${tooltipData.y}${percent}</div>
                                    ${this.options.sizeCol ? `<div>${this.options.sizeCol}: ${formatCurrencyString(tooltipData.size || 0)}</div>` : ''}
                                </div>
                            `)
                        .style('left', `${x + 15}px`)
                        .style('top', `${height / 8 + y}px`)
                }
            })
            .on('mouseleave', () => {
                tooltip?.style('opacity', 0)
            })

        chart
            .append('g')
            .attr('transform', `translate(0,${height})`)
            .call(
                d3
                    .axisBottom(xScale)
                    .ticks(6)
                    .tickValues(d3.ticks(0, Math.round(maxX + 10), 6))
                    .tickFormat((d) => (this.options.min ? '' : `${String(d)}${percent}`))
            )
            .selectAll('text')
            .style('text-anchor', 'end')
            .attr('dx', '-.8em')
            .attr('dy', '.15em')
            .attr('transform', 'rotate(-30)')

        const yAxis = chart
            .append('g')
            .call(
                d3
                    .axisLeft(yScale)
                    .ticks(5)
                    .tickFormat((n: number | { valueOf(): number }) => {
                        const value =
                            typeof n === 'number' ?
                                formatLongNumberLabel(n) :
                                formatLongNumberLabel(n.valueOf())
                        return `${value}${percent}`
                    }
                    )
                    .tickSizeInner(-width)
            )
            .selectAll('line')
            .style('stroke', lightChartMode ? 'black' : 'white')

        yAxis.selectAll('.tick line').style('opacity', '0.3')
        chart.selectAll('text').style('fill', lightChartMode ? 'black' : 'white')

        if (!this.options.min) {
            const simulationData = clone(data).map((d) => ({
                ...d,
                x: xScale(d.x),
                y: yScale(d.y),
              }))
            
            const lines = chart
                .selectAll('.label-line')
                .data(simulationData)
                .enter()
                .append('line')
                .attr('class', 'connector-line')
                .attr('x1', (d) => d.x)
                .attr('y1', (d) => d.y)
                .attr('x2', (d) => d.x)
                .attr('y2', (d) => d.y - sizeScale(d.size))
                .style('stroke', lightChartMode ? 'black' : 'white')
                .style('stroke-width', 1)
                .style('opacity', 0.6)

            const labels = chart
                .selectAll('.label')
                .data(simulationData)
                .enter()
                .append('text')
                .attr('class', 'label')
                .attr('x', (d) => d.x)
                .attr('y', (d) => d.y - sizeScale(d.size))
                .attr('text-anchor', 'middle')
                .text((d) => d.label)
                .style('fill', lightChartMode ? 'black' : 'white')
                .style('font-size', '12px')

            const simulation = d3
                .forceSimulation(simulationData)
                .force('x', d3.forceX((d) => d.x + 15).strength(1))
                .force('y', d3.forceY((d) => d.y - 30).strength(1))
                .force(
                    'collision',
                    d3.forceCollide().radius((_) => 30)
                )
                .on('tick', () => {
                    labels
                        .attr('x', (d) => d.x)
                        .attr('y', (d) => d.y)
                    lines
                    .attr('x1', (d) => d.x)
                    .attr('y1', (d) => d.y)
                    .attr('x2', (_, i) => xScale(data[i].x))
                    .attr('y2', (_, i) => yScale(data[i].y)) 
                })

            simulation.alpha(.9).restart()
        }

        addChartLabels(
            chart,
            this.options.xLabel ?? '',
            this.options.yLabel ?? '',
            this.options.chartLabel ?? '',
            margin,
            width,
            height,
            this.options.min,
            false,
            false,
            lightChartMode,
            true,
            true
        )
    }
}
