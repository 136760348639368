import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { AppState } from '../core/store'
import {
  fetchTracking,
  fetchTrackingDetails,
  filtersChanged,
  inceptionDateChanged,
  saveTrackingPreferences,
  setSelectedUserPreferences,
  updateCompleteProgram,
} from './store/tracking.actions'
import { Observable } from 'rxjs'
import { DataCounts, TrackingData, TrackingSelectors } from './tracking.model'
import { selectInceptionTimeInterval, selectIsDetailsLoading, selectIsLoading, selectTrackingData, selectTrackingSelectors, selectTrackingUserPreferences } from './store/tracking.selectors'
import { BasicControl } from '../management-information/model/management-information.model'
import { NewPreferenceDialogService } from './tracking-new-preference-dialog.service'
import { SelectedUserPreferences } from '../core/model/reinsurer.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tracking-container',
  template: `<app-theme-container
    ><app-tracking
      [trackingData]="trackingData$ | async"
      [selectors]="selectors$ | async"
      [inceptionInterval]="inceptionInterval$ | async"
      [isLoading]="isLoading$ | async"
      [isDetailsLoading]="isDetailsLoading$ | async"
      [userPreferences]="userPreferences$ | async"
      (filtersChanged)="this.onFiltersChanged($event)"
      (fetchTrackingDetails)="this.onFetchTrackingDetailsData($event)"
      (inceptionDateChanged)="this.onInceptionDateChanged($event)"
      (updateComplete)="this.onUpdateComplete($event)"
      (saveTrackingPreferences)="this.onSavePreference()"
      (setTrackingPreferences)="this.setTrackingPreferences($event)"
    ></app-tracking
  ></app-theme-container>`,
})
export class TrackingContainerComponent implements OnInit {
  trackingData$: Observable<TrackingData[] | null>
  selectors$: Observable<TrackingSelectors | null>
  inceptionInterval$: Observable<BasicControl>
  isLoading$: Observable<boolean>
  isDetailsLoading$: Observable<boolean>
  userPreferences$: Observable<SelectedUserPreferences[] | null>
  constructor(
    private store: Store<AppState>,
    private preferenceServiceDialog: NewPreferenceDialogService
  ) {}
  ngOnInit() {
    this.trackingData$ = this.store.pipe(select(selectTrackingData))
    this.selectors$ = this.store.pipe(select(selectTrackingSelectors))
    this.inceptionInterval$ = this.store.pipe(
      select(selectInceptionTimeInterval)
    )
    this.isLoading$ = this.store.pipe(select(selectIsLoading))
    this.isDetailsLoading$ = this.store.pipe(select(selectIsDetailsLoading))
    this.userPreferences$ = this.store.pipe(
      select(selectTrackingUserPreferences)
    )

    setTimeout(() => {
      this.store.dispatch(fetchTracking())
    }, 2000)
  }

  onFiltersChanged(props: { filters: Record<string, string[]> }): void {
    this.store.dispatch(
      filtersChanged({
        filters: props.filters,
      })
    )
  }

  onFetchTrackingDetailsData(programId: number): void {
    this.store.dispatch(
      fetchTrackingDetails({
        programId,
      })
    )
  }

  onInceptionDateChanged($event: {
    minInceptionDate: string
    maxInceptionDate: string
  }): void {
    this.store.dispatch(inceptionDateChanged($event))
  }

  onUpdateComplete(programId: number): void {
    this.store.dispatch(updateCompleteProgram({ programId }))
  }

  onSavePreference(): void {
    this.preferenceServiceDialog
      .open('')
      .afterClosed()
      .subscribe(label => {
        if (label) {
          this.store.dispatch(
            saveTrackingPreferences({
              label,
            })
          )
        }
      })
  }

  setTrackingPreferences($event: {id: number}) : void {
    this.store.dispatch(setSelectedUserPreferences({ id: $event.id }))
  }
}
