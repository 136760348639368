import { createAction, props } from '@ngrx/store'
import { ApplicationError } from '../../../error/model/error'
import { CreditStructure } from '../../model/credit-structure.model'
import { CreditGrossLossScenario } from '../../model/credit-portfolio.model'
import { AnimatedScenariosResults } from '../../model/credit-animated-scenarios.model'

export enum CreditAnimatedScenariosActions {
  CreditAnimatedScenariosExecute = '[Credit Animated Scenarios] Execute',
  CreditAnimatedScenariosExecuteSuccess = '[Credit Animated Scenarios] Execute Success',
  CreditAnimatedScenariosExecuteFailure = '[Credit Animated Scenarios] Execute Failure',
  CreditAnimatedScenariosAddSelectedStructure = '[Credit Animated Scenarios] Add Selected Structure',
  CreditAnimatedScenariosResetEventsAndResults = '[Credit Animated Scenarios] Reset Events And Results',
  CreditAnimatedScenariosSetAnimating = '[Credit Animated Scenarios] Set Animating',
  CreditAnimatedScenariosUpdateOriginations = '[Credit Animated Scenarios] Update Selected Origination Scenarios',
  CreditAnimatedScenariosUpdateActuals = '[Credit Animated Scenarios] Update Selected Actual Scenarios',
  CreditAnimatedScenariosFetchData = '[Credit Animated Scenarios] Fetch Data',
  CreditAnimatedScenariosFetchDataSuccess = '[Credit Animated Scenarios] Fetch Data Success',
  CreditAnimatedScenariosFetchDataFailure = '[Credit Animated Scenarios] Fetch Data Failure',
  CreditSetSliderValue = '[Credit Animated Scenarios] Set Slider Value',
}

export const execute = createAction(
  CreditAnimatedScenariosActions.CreditAnimatedScenariosExecute,
  props<{ grossLossScenarioIds: string; actualGLSIds: string }>()
)
export const executeSuccess = createAction(
  CreditAnimatedScenariosActions.CreditAnimatedScenariosExecuteSuccess
)
export const executeFailure = createAction(
  CreditAnimatedScenariosActions.CreditAnimatedScenariosExecuteFailure,
  props<{ error: ApplicationError }>()
)

export const addSelectedStructure = createAction(
  CreditAnimatedScenariosActions.CreditAnimatedScenariosAddSelectedStructure,
  props<{ structure: CreditStructure }>()
)

export const resetEventsAndResults = createAction(
  CreditAnimatedScenariosActions.CreditAnimatedScenariosResetEventsAndResults
)

export const setAnimating = createAction(
  CreditAnimatedScenariosActions.CreditAnimatedScenariosSetAnimating,
  props<{ value: boolean }>()
)

export const updateOriginations = createAction(
  CreditAnimatedScenariosActions.CreditAnimatedScenariosUpdateOriginations,
  props<{ grossLossScenarios: CreditGrossLossScenario[] }>()
)

export const updateActuals = createAction(
  CreditAnimatedScenariosActions.CreditAnimatedScenariosUpdateActuals,
  props<{ grossLossScenarios: CreditGrossLossScenario[] }>()
)

export const fetchAnimatedScenariosData = createAction(
  CreditAnimatedScenariosActions.CreditAnimatedScenariosFetchData,
  props<{ grossLossScenarioIds: string; actualGLSIds: string }>()
)

export const fetchAnimatedScenariosDataSuccess = createAction(
  CreditAnimatedScenariosActions.CreditAnimatedScenariosFetchDataSuccess,
  props<{ data: AnimatedScenariosResults[] }>()
)

export const fetchAnimatedScenariosDataFailure = createAction(
  CreditAnimatedScenariosActions.CreditAnimatedScenariosFetchDataFailure,
  props<{ error: ApplicationError }>()
)

export const setSliderValue = createAction(
  CreditAnimatedScenariosActions.CreditSetSliderValue,
  props<{ value: number }>()
)
