<div>
  <label
    *ngIf="!hideLabel && !cell"
    [ngClass]="{ 'label-white': whiteLabel }"
    >{{ name }}</label
  >
  <mat-icon
    class="warning-icon"
    *ngIf="displayOverrideTooltip"
    matTooltip="This value has been overwritten and is not connected to modeling. Must be manually updated."
    matTooltipPosition="right"
  >error</mat-icon>
</div>
<div *ngIf="isAutocomplete">
  <ng-container>
    <input
      matInput
      [matAutocomplete]="autoSection"
      type="text"
      [formControl]="autoControl"
    />
    <mat-autocomplete
      #autoSection="matAutocomplete"
      (optionSelected)="onMenuItemSelected($event.option.value)"
      [displayWith]="displayFn"
    >
      <mat-option *ngFor="let item of items" [value]="item">
        {{ item.viewValue }}
      </mat-option>
    </mat-autocomplete>
  </ng-container>
</div>
<div *ngIf="isDropdown" class="dropdown">
  <ng-container>
    <button
      class="button-dropdown"
      appButton
      translucent
      [matMenuTriggerFor]="!readonly ? addMenu : null"
      [disabled]="readonly"
      [ngClass]="{ 'read-only': readonly }"
    >
      <span>
        {{ valueDropdown }}
      </span>
    </button>
    <mat-menu #addMenu="matMenu">
      <ng-template matMenuContent>
        <button
          [disabled]="readonly"
          mat-menu-item
          *ngFor="let item of items; trackBy: trackByDropdownItem"
          panelClass="app-menu-translucent"
          (click)="onMenuItemSelected(item)"
        >
          {{ item.viewValue }}
        </button>
      </ng-template>
    </mat-menu>
  </ng-container>
</div>
<div *ngIf="isMultiselect">
  <app-multiselect-layer-property
    [inputSelectedValues]="value"
    [references]="references"
    [name]="name"
    [additionalValues]="additionalMultiselectValues"
    (setValues)="multiselectInputChange($event)"
  ></app-multiselect-layer-property>
</div>
<div *ngIf="isDependent">
  <app-dependent-layer-property
    [value]="value"
    [name]="name"
    [valueLists]="dependentValueLists"
    (valueChanged)="inputChange.emit($event)"
  >
  </app-dependent-layer-property>
</div>
<mat-checkbox
  *ngIf="isCheckbox"
  [checked]="value"
  (change)="inputChange.emit($event.checked); inputSubmit.emit()"
></mat-checkbox>

<input
  *ngIf="isCurrency"
  #currencyInput
  type="text"
  formatCurrency
  [ngClass]="{ 'read-only': readonly }"
  [input]="transformedValue"
  [currency]="currentCurrency"
  [readonly]="readonly"
  [decimal]="decimal"
  [(ngModel)]="formattedValue"
  (ngModelChange)="inputChangeDebounce$.next(currencyInputChanged($event))"
  (keyup)="inputKeyupDebounce$.next()"
  (keydown.enter)="inputSubmit.emit()"
  (focusout)="onFocusOut()"
  (mousedown)="onMouseDown($event)"
/>

<input
  *ngIf="isNumeric"
  #numericInput
  [ngClass]="{ 'read-only': readonly }"
  [type]="getType()"
  [readonly]="readonly"
  [value]="value"
  [(ngModel)]="value"
  (ngModelChange)="numberAction($event)"
  (keyup)="inputKeyupDebounce$.next()"
  (keydown.enter)="inputSubmit.emit()"
  (focusout)="onFocusOut()"
/>

<input
  *ngIf="isUnformattedNumeric"
  #unformattedNumericInput
  [ngClass]="{ 'read-only': readonly }"
  type="number"
  [readonly]="readonly"
  [value]="value"
  [(ngModel)]="value"
  (ngModelChange)="inputChangeDebounce$.next($event)"
  (keyup)="inputKeyupDebounce$.next()"
  (keydown.enter)="inputSubmit.emit()"
  (focusout)="onFocusOut()"
/>

<input
  *ngIf="isNonDecimalNumeric"
  #nonDecimalNumericInput
  [ngClass]="{ 'read-only': readonly }"
  type="number"
  [readonly]="readonly"
  [value]="value"
  [(ngModel)]="value"
  (ngModelChange)="inputChangeDebounce$.next(nonDecimalNumericChanged($event))"
  (keyup)="inputKeyupDebounce$.next()"
  (keydown.enter)="inputSubmit.emit()"
  (focusout)="onFocusOut()"
/>

<!-- Removing [matTooltip]="formattedValue?.length > 18 ? formattedValue : ''"  bcoz of Prod build errors. -->
<input
  *ngIf="isText"
  #textInput
  type="text"
  [ngClass]="{ 'read-only': readonly }"
  [class.error]="error"
  [matTooltip]="tooltip"
  matTooltipPosition="above"
  [readonly]="readonly"
  [(ngModel)]="formattedValue"
  (ngModelChange)="inputChangeDebounce$.next($event)"
  (keyup)="inputKeyupDebounce$.next()"
  (keydown.enter)="inputSubmit.emit()"
  (focusout)="onFocusOut()"
  (mousedown)="onMouseDown($event)"
/>

<input
  *ngIf="isEmpty"
  type="text"
  [ngClass]="{ 'read-only': readonly }"
  [readonly]="readonly"
  value=""
/>

<textarea
  *ngIf="isTextArea"
  #textareaInput
  [matTooltip]="tooltip"
  [ngClass]="{ 'read-only': readonly }"
  matTooltipPosition="above"
  [readonly]="readonly"
  [(ngModel)]="formattedValue"
  (ngModelChange)="inputChangeDebounce$.next($event)"
  (keyup)="inputKeyupDebounce$.next()"
  (focusout)="onFocusOut()"
></textarea>
<div *ngIf="isNumberList">
  <div class="reinstatements-button">
    <button
      class="button-dropdown"
      appButton
      translucent
      primary
      border
      center
      (click)="addReinstatement()"
    >
      <span class="reinstatement-text-button">Add Reinstatement</span>
    </button>
  </div>
  <div
    class="reinstatements"
    *ngFor="let reinstatement of editReinstatements; let i = index"
  >
    <input
      type="text"
      class="input-reinstatements-counter"
      formatNumber
      [ngClass]="{ 'read-only': readonly }"
      [readonly]="readonly"
      [(ngModel)]="editReinstatements[i].counter"
      (change)="editedReinstatement()"
      (mousedown)="onMouseDown($event)"
      (focusout)="onFocusOut()"
    />
    <span class="at-symbol">{{ '@' }}</span>
    <input
      formatPercentage
      type="text"
      class="input-reinstatements"
      (focusout)="onFocusOut()"
      [isReinstatements]="true"
      [ngClass]="{ 'read-only': readonly }"
      [readonly]="readonly"
      [(ngModel)]="editReinstatements[i].formattedValue"
      (ngModelChange)="
        editReinstatements[i].premium = percentInputChanged($event)
      "
      (change)="editedReinstatement()"
      (mousedown)="onMouseDown($event)"
    />
    <i
      tabindex="1"
      *ngIf="!readonly"
      class="material-icons remove-icon"
      matTooltip="Click to remove Reinstatement."
      matTooltipPosition="above"
      (click)="removeReinstatement(i)"
    >
      remove_circle_outline
    </i>
  </div>

  <mat-spinner *ngIf="submitting" [diameter]="spinnerSize"></mat-spinner>
</div>
<div *ngIf="isDate" class="date-picker">
  <input
    #dateInput
    matInput
    [matDatepicker]="picker"
    [value]="formattedDate"
    [disabled]="readonly"
    [ngClass]="{ 'read-only': readonly }"
    (dateChange)="onDateChange($event.target.value)"
  />
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</div>
