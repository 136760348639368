import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map, switchMap } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import {
  QuoteReinsurer,
  ReinsurerSubjectivity,
  ReinsurerTrackingSubjectivity,
  RenewedFromOpportunity,
  RenewedFromRiskAndSection,
  Risk,
  Section,
  Subjectivity,
} from '../../quote/models/quote.model'
import { Data } from '../analyzere/analyzere.model'
import { parseCsvResponse } from 'src/app/api/animated-scenarios/util'
import { ApiResponse } from '../model/api.model'
import {
  AssignedLinesResponse,
  Bureaus,
  ReinstatementResponse,
  ReinsurerResponse,
  ReinsurerSubjectivityResponse,
  RiskResponse,
  SectionResponse,
  SubjectivityResponse,
  WSAssignedLinesResponse,
  ExternalVendorMapping,
  LayerQuoteStatus,
  PanelCounts,
} from '../model/quote.model'
import {
  catchAndHandleError,
  encodeParams,
  getQueryParamKvps,
  mapToMaybeData,
} from '../util'
import {
  convertReinsurerFromResponse,
  convertReinsurersFromResponse,
  convertReinsurerToRequest,
  convertRiskFromResponse,
  convertRisksFromResponse,
  convertRiskToRequestPost,
  convertSectionsFromResponseToArray,
  convertSectionToRequestPost,
  convertSubjectivitiesFromResponse,
  convertSubjectivityLinkToRequestPost,
  convertSubjectivityToRequest,
  getFirstSectionFromResponseArray,
  toSection,
} from './quote-reinsurer.convertor'
import { of } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class QuoteReinsurerService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      References: 'Expand',
    }),
  }
  constructor(private httpClient: HttpClient) {}
  // for Premium FX to fetch the USD rate list
  fetchDataContent<T>(dataID: string): ApiResponse<T[]> {
    return this.fetchData(dataID).pipe(
      switchMap(response => {
        if (response.error) {
          return of({ error: response.error })
        } else {
          // tslint:disable: no-non-null-assertion
          const content = response.data!.content
          const name = content.substring(content.lastIndexOf('/') + 1)
          return this.fetchContent(name).pipe(parseCsvResponse<T>())
        }
      })
    )
  }
  fetchContent(id: string): ApiResponse<string> {
    const url = `${environment.api.base}/uploads${environment.api.files}/${id}`
    return this.httpClient
      .get(url, { responseType: 'text' })
      .pipe(mapToMaybeData(), catchAndHandleError('Fetch Content'))
  }

  fetchData(id: string): ApiResponse<Data> {
    const url = `${environment.api.base}${environment.api.files}/${id}`
    return this.httpClient
      .get<Data>(url, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Fetch Data'))
  }
  // Risk Reinsurer APIs
  getRiskReinsurersByLayer(layerRef: string): ApiResponse<QuoteReinsurer[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskReinsurer}?layerRef=${layerRef}`
    return this.httpClient
      .get<ReinsurerResponse[]>(url)
      .pipe(
        map(convertReinsurersFromResponse),
        mapToMaybeData(),
        catchAndHandleError('GET Risk Reinsurers By LayerRef')
      )
  }

  getRiskReinsurersByLayerAndProgramGroupID(
    layerRef: string,
    programGroupID: number
  ): ApiResponse<QuoteReinsurer[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskReinsurer}/layerRefAndProgramGroup?layerRef=${layerRef}&programGroupID=${programGroupID}`
    return this.httpClient
      .get<ReinsurerResponse[]>(url)
      .pipe(
        map(convertReinsurersFromResponse),
        mapToMaybeData(),
        catchAndHandleError(
          'GET Risk Reinsurers By LayerRef and ProgramGroup ID'
        )
      )
  }

  getRiskReinsurersByProgramGroupID(
    programGroupID: string
  ): ApiResponse<QuoteReinsurer[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskReinsurer}/programGroup?programGroupID=${programGroupID}`
    return this.httpClient
      .get<ReinsurerResponse[]>(url)
      .pipe(
        map(convertReinsurersFromResponse),
        mapToMaybeData(),
        catchAndHandleError('GET Risk Reinsurers By ProgramGroup ID')
      )
  }

  getRiskReinsurersByLayerAndSharedLimitID(
    layerRef: string,
    sharedLimitID: number
  ): ApiResponse<QuoteReinsurer[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskReinsurer}/layerRefAndSharedLimit?layerRef=${layerRef}&sharedLimitID=${sharedLimitID}`
    return this.httpClient
      .get<ReinsurerResponse[]>(url)
      .pipe(
        map(convertReinsurersFromResponse),
        mapToMaybeData(),
        catchAndHandleError(
          'GET Risk Reinsurers By LayerRef and SharedLimit ID'
        )
      )
  }

  getRiskReinsurersBySharedLimitID(
    sharedLimitID: string
  ): ApiResponse<QuoteReinsurer[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskReinsurer}/sharedLimit?sharedLimitID=${sharedLimitID}`
    return this.httpClient
      .get<ReinsurerResponse[]>(url)
      .pipe(
        map(convertReinsurersFromResponse),
        mapToMaybeData(),
        catchAndHandleError('GET Risk Reinsurers By SharedLimit ID')
      )
  }

  getRiskReinsurersByID(id: string): ApiResponse<Partial<QuoteReinsurer>> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskReinsurer}/${id}`
    return this.httpClient
      .get<ReinsurerResponse>(url)
      .pipe(
        map(convertReinsurerFromResponse),
        mapToMaybeData(),
        catchAndHandleError('GET Risk Reinsurer By ID')
      )
  }

  postRiskReinsurers(
    reinsurer: Partial<QuoteReinsurer>
  ): ApiResponse<QuoteReinsurer> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskReinsurer}`
    const req = convertReinsurerToRequest(reinsurer)
    return this.httpClient
      .post<ReinsurerResponse>(url, req, this.httpOptions)
      .pipe(
        map(convertReinsurerFromResponse),
        mapToMaybeData(),
        catchAndHandleError('POST Risk Reinsurer')
      )
  }

  putRiskReinsurers(reinsurer: QuoteReinsurer): ApiResponse<QuoteReinsurer> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskReinsurer}`
    const req = convertReinsurerToRequest(reinsurer)
    return this.httpClient
      .put<ReinsurerResponse>(url, req, this.httpOptions)
      .pipe(
        map(convertReinsurerFromResponse),
        mapToMaybeData(),
        catchAndHandleError('PUT Risk Reinsurer')
      )
  }

  deleteRiskReinsurers(id: string): ApiResponse<Partial<QuoteReinsurer>> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskReinsurer}/${id}`
    return this.httpClient
      .delete<ReinsurerResponse>(url)
      .pipe(
        map(convertReinsurerFromResponse),
        mapToMaybeData(),
        catchAndHandleError('DELETE Risk Reinsurer')
      )
  }

  // Risk Section APIs
  getRiskSectionByLayer(layerRef: string): ApiResponse<Section> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskSection}?layerRef=${layerRef}`
    return this.httpClient
      .get<SectionResponse[]>(url)
      .pipe(
        map(getFirstSectionFromResponseArray),
        mapToMaybeData(),
        catchAndHandleError('GET Risk Section By LayerRef')
      )
  }

  getRiskSectionByLayerAndProgramGroupID(
    layerRef: string,
    programGroupID: string
  ): ApiResponse<Section> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskSection}/layerRefAndProgramGroup?layerRef=${layerRef}&programGroupID=${programGroupID}`
    return this.httpClient
      .get<SectionResponse[]>(url)
      .pipe(
        map(getFirstSectionFromResponseArray),
        mapToMaybeData(),
        catchAndHandleError('GET Risk Section By LayerRef and Program Group ID')
      )
  }

  getRiskSectionByProgramGroupID(
    programGroupID: string
  ): ApiResponse<Section[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskSection}/programGroup?programGroupID=${programGroupID}`
    return this.httpClient
      .get<SectionResponse[]>(url)
      .pipe(
        map(convertSectionsFromResponseToArray),
        mapToMaybeData(),
        catchAndHandleError('GET Risk Section By Program Group ID')
      )
  }

  getRiskSectionByLayerAndSharedLimitID(
    layerRef: string,
    sharedLimitID: string
  ): ApiResponse<Section> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskSection}/layerRefAndSharedLimit?layerRef=${layerRef}&sharedLimitID=${sharedLimitID}`
    return this.httpClient
      .get<SectionResponse[]>(url)
      .pipe(
        map(getFirstSectionFromResponseArray),
        mapToMaybeData(),
        catchAndHandleError('GET Risk Section By LayerRef and SharedLimit ID')
      )
  }

  getRiskSectionBySharedLimitID(sharedLimitID: string): ApiResponse<Section[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskSection}/sharedLimit?sharedLimitID=${sharedLimitID}`
    return this.httpClient
      .get<SectionResponse[]>(url)
      .pipe(
        map(convertSectionsFromResponseToArray),
        mapToMaybeData(),
        catchAndHandleError('GET Risk Section By SharedLimit ID')
      )
  }

  getRiskSectionByStructureID(structureId: string): ApiResponse<Section[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskSection}/structure?structureID=${structureId}`
    return this.httpClient
      .get<SectionResponse[]>(url)
      .pipe(
        map(convertSectionsFromResponseToArray),
        mapToMaybeData(),
        catchAndHandleError('GET Risk Section By StructureID')
      )
  }

  getRiskSectionByProgramID(programID: string): ApiResponse<Section[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskSection}/program?programID=${programID}`
    return this.httpClient
      .get<SectionResponse[]>(url)
      .pipe(
        map(convertSectionsFromResponseToArray),
        mapToMaybeData(),
        catchAndHandleError('GET Risk Section By Program ID')
      )
  }

  getRiskSectionByID(id: string): ApiResponse<SectionResponse> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskSection}/${id}`
    return this.httpClient
      .get<SectionResponse>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('GET Risk Section By ID'))
  }

  postRiskSection(section: Partial<Section>): ApiResponse<Section> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskSection}`
    const req = convertSectionToRequestPost(section)
    return this.httpClient
      .post<SectionResponse>(url, req, this.httpOptions)
      .pipe(
        map(toSection),
        mapToMaybeData(),
        catchAndHandleError('POST Risk Section')
      )
  }

  putRiskSection(section: Section): ApiResponse<Section> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskSection}`
    return this.httpClient
      .put<SectionResponse>(url, section, this.httpOptions)
      .pipe(
        map(toSection),
        mapToMaybeData(),
        catchAndHandleError('PUT Risk Section')
      )
  }

  deleteRiskSection(id: string): ApiResponse<SectionResponse> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskSection}/${id}`
    return this.httpClient
      .delete<SectionResponse>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('DELETE Risk Section'))
  }

  // Risk Reinstatement API
  deleteRiskReinstatement(id: number): ApiResponse<ReinstatementResponse> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskReinstatement}/${id}`
    return this.httpClient
      .delete<ReinstatementResponse>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('DELETE Risk Reinstatement'))
  }

  // Risk Subjectivity API
  deleteSubjectivity(id: number): ApiResponse<SubjectivityResponse> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskSubjectivity}/${id}`
    return this.httpClient
      .delete<SubjectivityResponse>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('DELETE Risk Subjectivity'))
  }

  postSubjectivity(
    subjectivity: Partial<Subjectivity>
  ): ApiResponse<Subjectivity> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskSubjectivity}`
    const req = convertSubjectivityToRequest(subjectivity)
    return this.httpClient
      .post<SubjectivityResponse>(url, req, this.httpOptions)
      .pipe(
        map(convertSubjectivitiesFromResponse),
        mapToMaybeData(),
        catchAndHandleError('POST Risk Subjectivity')
      )
  }

  putSubjectivity(subjectivity: Subjectivity): ApiResponse<Subjectivity> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskSubjectivity}`
    const req = convertSubjectivityToRequest(subjectivity)
    return this.httpClient
      .put<SubjectivityResponse>(url, req, this.httpOptions)
      .pipe(
        map(convertSubjectivitiesFromResponse),
        mapToMaybeData(),
        catchAndHandleError('PUT Risk Subjectivity')
      )
  }

  // Risk APIs
  getRiskByStructureID(structureID: number): ApiResponse<Risk> {
    const url = `${environment.internalApi.base}${environment.internalApi.risk}?structureID=${structureID}`
    return this.httpClient
      .get<RiskResponse[]>(url)
      .pipe(
        map(convertRisksFromResponse),
        mapToMaybeData(),
        catchAndHandleError('GET Risk By Structure ID')
      )
  }

  getRiskByProgramID(programID: number): ApiResponse<Risk> {
    const url = `${environment.internalApi.base}${environment.internalApi.risk}/program?programID=${programID}`
    return this.httpClient
      .get<RiskResponse[]>(url)
      .pipe(
        map(convertRisksFromResponse),
        mapToMaybeData(),
        catchAndHandleError('GET Risk By Program ID')
      )
  }

  postRisk(risk: Partial<Risk>): ApiResponse<Risk> {
    const url = `${environment.internalApi.base}${environment.internalApi.risk}`
    const req = convertRiskToRequestPost(risk)
    return this.httpClient
      .post<RiskResponse>(url, req, this.httpOptions)
      .pipe(
        map(convertRiskFromResponse),
        mapToMaybeData(),
        catchAndHandleError('POST Risk')
      )
  }

  postLinkReinsurerSubjectivity(
    data: Partial<ReinsurerSubjectivity>
  ): ApiResponse<ReinsurerSubjectivityResponse> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskReinsurerSubjectivities}`
    const req = convertSubjectivityLinkToRequestPost(data)
    return this.httpClient
      .post<ReinsurerSubjectivityResponse>(url, req, this.httpOptions)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('POST Risk Reinsurer Subjectivities Link')
      )
  }

  deleteLinkReinsurerSubjectivity(
    riskReinsurerId: number,
    subjectivityId: number
  ): ApiResponse<ReinsurerSubjectivityResponse> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskReinsurerSubjectivities}?riskReinsurerId=${riskReinsurerId}&subjectivityId=${subjectivityId}`
    return this.httpClient
      .delete<ReinsurerSubjectivityResponse>(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('DELETE Risk Reinsurer Subjectivity')
      )
  }

  // Risk Assigned Lines API
  deleteAssignedLines(id: number): ApiResponse<AssignedLinesResponse> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskAssignedLines}/${id}`
    return this.httpClient
      .delete<AssignedLinesResponse>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('DELETE Risk Assigned Lines'))
  }

  // Get Bureau Details
  getBureau(): ApiResponse<Bureaus[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.bureaus}`
    return this.httpClient
      .get<Bureaus[]>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('GET BUREAUS DATA'))
  }

  // Renewed Opportunity APIs
  getRenewedOpportunityRiskSections(
    opportunity: RenewedFromOpportunity
  ): ApiResponse<RenewedFromRiskAndSection[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.ot_risk_sections}`
    const params = getQueryParamKvps(opportunity)
    return this.httpClient
      .get<RenewedFromRiskAndSection[]>(url, { params: encodeParams(params) })
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('GET Renewed Opportunity Risk Sections')
      )
  }

  getQuoteReinsurerForRenewedOpportunity(
    riskDetails: RenewedFromRiskAndSection
  ): ApiResponse<QuoteReinsurer> {
    const url = `${environment.internalApi.base}${environment.internalApi.ot_risk_details}`
    const params = getQueryParamKvps(riskDetails)
    return this.httpClient
      .get<ReinsurerResponse>(url, { params: encodeParams(params) })
      .pipe(
        map(convertReinsurerFromResponse),
        mapToMaybeData(),
        catchAndHandleError('GET Quote Reinsurer for Renewed Opportunity')
      )
  }
  getSignedLinesFromWhitespace(
    structureID: string | null | undefined,
    cededLayers: string[]
  ): ApiResponse<WSAssignedLinesResponse[]> {
    const cededLayersJoin = cededLayers.join(',')
    const url = `${environment.internalApi.base}${environment.internalApi.whitespace_risks}/${structureID}/syncFromWS?secIds=${cededLayersJoin} `
    return this.httpClient
      .get<WSAssignedLinesResponse[]>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('GET Sync from WhiteSpace'))
  }

  getTemplateDataByStructureId(
    structureId: string | null
  ): ApiResponse<ExternalVendorMapping> {
    const url = `${environment.internalApi.base}${environment.internalApi.evm}${structureId}`
    return this.httpClient
      .get<ExternalVendorMapping>(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('GET Renewal Details by riskRef')
      )
  }

  getLayerQuoteStatus(layerRef: string): ApiResponse<LayerQuoteStatus> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskSection}/layerQuoteStatus?layerRef=${layerRef}`

    return this.httpClient
      .get<LayerQuoteStatus>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('GET Layer Quote Status'))
  }

  deleteRiskSectionAndDependencies(layerRef: string): ApiResponse<PanelCounts> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskSection}/deleteRiskSectionAndDependencies?layerRef=${layerRef}`
    return this.httpClient
      .delete<PanelCounts>(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('DELETE Risk Section and Dependencies')
      )
  }

  fetchSubjectivitiesByReinsurer(
    tpRef: string
  ): ApiResponse<ReinsurerTrackingSubjectivity[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.subjectivitiesByReinsurer}/${tpRef}`
    return this.httpClient
      .get<ReinsurerTrackingSubjectivity[]>(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('Fetch Subjectivites by Reinsurer')
      )
  }
}
