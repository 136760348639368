import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { Observable } from 'rxjs'
import { FormControl, FormGroup } from '@angular/forms'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { MatAutocompleteTrigger } from '@angular/material/autocomplete'
import { startWith, map } from 'rxjs/operators'
import {
  IControl,
  Selectors,
  SelectorValueOverride,
} from 'src/app/pricingcurve/model/pricing-curve.model'
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-multiselect-filter',
  styleUrls: ['./multiselect-filter.component.scss'],
  templateUrl: 'multiselect-filter.component.html',
})
export class MultiselectFilterComponent implements OnChanges {
  _filter: IControl
  selecteds: string[] = new Array<string>()
  filtered: Observable<string[]>
  lastFilter: string
  separatorKeysCodes: number[] = [ENTER, COMMA]
  filterCtrl = new FormControl()
  @Input() form: FormGroup
  @Input() useLessWidth: boolean
  @Input() loading: boolean
  @Input() name: string
  @Input() hideClearFilter: boolean
  @Input() childNumber: number
  @Input() valueOverrides: Record<string, string> | undefined

  @Input() set filter(value: IControl) {
    this._filter = value
    this.setDataOnReload(value.columnName, value.selectedValues)
    this.filtered = this.filterCtrl.valueChanges.pipe(
      startWith<string>(''),
      map((filterBy: string) =>
        typeof filterBy === 'string' ? filterBy : this.lastFilter
      ),
      map((filterBy: string) => this.filterData(filterBy))
    )
  }
  get filter(): IControl {
    return this._filter
  }
  @ViewChild('filterInput') filterInput: ElementRef<HTMLInputElement>
  @ViewChild('filterInput', { read: MatAutocompleteTrigger, static: true })
  triggerPanel: MatAutocompleteTrigger

  @Output() clearFilter = new EventEmitter()
  @Output() setFilter = new EventEmitter()

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter || changes.loading) {
      this.updateFilterControlDisabled()
    }
  }

  applyOverride(value: string): string {
    if (this.valueOverrides?.[value]) {
      return this.valueOverrides[value]
    }
    return value
  }

  set autoCompleteDisabled(status: boolean) {
    this.triggerPanel.autocompleteDisabled = status
  }

  updateFilterControlDisabled(): void {
    if (this.filter && this.filter.values.length && !this.loading) {
      this.filterCtrl.enable()
    } else {
      this.filterCtrl.disable()
    }
  }

  openAutosuggest(): void {
    if (
      this.filterInput.nativeElement.value.length > 0 ||
      this.selecteds.length === 0
    ) {
      this.triggerPanel.autocompleteDisabled = false
      this.triggerPanel.openPanel()
    } else {
      this.triggerPanel.autocompleteDisabled = true
    }
  }

  setDataOnReload(filterId: string, values: string[]): void {
    this.form.controls[filterId].setValue(values, { emitEvent: false })
    this.selecteds = [...values]
    if (this.selecteds.length > 0) {
      this.autoCompleteDisabled = true
    }
  }

  filterData(filter: string): string[] {
    this.lastFilter = filter

    return (
      this.getFiltered(this.filter.values, filter).concat(
        '', // concatenate empty string to divide applicable and other options
        this.getFilteredWithoutDupes(
          this.getFiltered(this.filter.allValues, filter)
        )
      ) ?? []
    )
  }

  getFiltered(vals: string[], filter: string): string[] {
    if (!filter) {
      return vals
    }

    return vals.filter(option => {
      return option.toLowerCase().indexOf(filter.toLowerCase()) >= 0
    })
  }

  getFilteredWithoutDupes(vals: string[]): string[] {
    return vals.filter(option => {
      return this.filter.values.indexOf(option) < 0
    })
  }

  getIndentationClass(childNumber?: number): string {
    if (childNumber) {
      return `child-filter-${childNumber}`
    }
    return ''
  }

  isSelected(option: string): boolean {
    return this.selecteds.indexOf(option) > -1
  }

  remove(option: string, filter: IControl): void {
    const index = this.selecteds.indexOf(option)

    if (index >= 0) {
      this.selecteds.splice(index, 1)
      this.form.controls[filter.columnName].setValue(this.selecteds)
      if (this.selecteds.length > 0) {
        this.setFilter.emit()
      } else {
        this.clearFilter.emit()
      }
    }
  }

  panelClosed(filter: IControl): void {
    this.filterInput.nativeElement.value = ''
    this.filterCtrl.setValue('')
    const control = this.form.controls[filter.columnName]

    if (this.selecteds.length > 0) {
      this.autoCompleteDisabled = true
    }

    if (control.value?.join() !== this.selecteds.join()) {
      control.setValue(this.selecteds)
      this.setFilter.emit()
    }
  }

  toggleOption(opt: string): void {
    let option = opt
    const override = Object.entries(this.valueOverrides ?? {}).find(
      ([_key, val]) => val === opt
    )
    if (override) {
      option = override[0]
    }
    const indexOf = this.selecteds.indexOf(option)
    if (indexOf < 0) {
      this.selecteds.push(option)
    } else {
      this.selecteds.splice(indexOf, 1)
    }
  }

  openOptions(): void {
    this.triggerPanel.openPanel()
  }

  includedInFilterValues = (selectedOption: string): boolean =>
    this.filter.selectedValues.includes(selectedOption)
}
