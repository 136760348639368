<div *ngIf="scenarioEventsResult.length === 0" class="no-results">
  <app-tower-3d
    [name]="name"
    [layers]="originalLayers"
    [layersData]="originalStructure.layerData"
    [sharedIDGroup]="sharedIDPortfolio"
  ></app-tower-3d>
</div>
<div *ngIf="scenarioEventsResult.length > 0" class="result-container">
  <div #swiper class="swiper-container" [swiper]="configGroup">
    <div class="swiper-wrapper">
      <div
        *ngFor="let result of selectEventResultsForDisplay; let i = index"
        class="swiper-slide"
      >
        <h2 class="tower-title">Loss Event {{ i + 1 }}</h2>
        <div class="table-result">
          <app-scenario-event-result-table
            [eventResult]="result"
            [animating]="animating"
            (cededLossClick)="onCededLossClick($event)"
          >
          </app-scenario-event-result-table>
        </div>
      </div>
    </div>
    <div *ngIf="selectEventResultsForDisplay.length > 1" class="swiper-button-prev" (click)="onPrev()"></div>
    <div *ngIf="selectEventResultsForDisplay.length > 1" class="swiper-button-next" (click)="onNext()"></div>
  </div>
  <div class="footer-text">* Includes impact of any inuring structures</div>
  <app-tower-3d
    class="result-tower"
    [name]="name"
    [layers]="originalLayers"
    [layersData]="originalStructure.layerData"
    [sharedIDGroup]="sharedIDPortfolio"
    [eventResults]="scenarioEventsResult"
    [eventResultStructure]="structure"
    [animating]="animating"
    (animationEnd)="animationEnd.emit()"
    (eventAnimationIndex)="onEventAnimationIndex($event)"
  ></app-tower-3d>
</div>
