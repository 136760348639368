import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import {
  BasicControl,
  Selectors,
  IControl,
  IIntervalFilter,
  PricingCurveStatus,
  PricingCurveContextTypes,
  CreditSelectors,
  SelectorValueOverride,
} from '../../../model/pricing-curve.model'
import * as fromPricingCurve from '../../../store/pricing-curve.actions'
import { MatDatepickerInputEvent } from '@angular/material/datepicker'
import { DatePipe } from '@angular/common'
import { AppState } from 'src/app/core/store'
import { Store } from '@ngrx/store'
import { MatInput } from '@angular/material/input'
import {
  getSelectorFromCreditSelectors,
  setCreditSelector,
} from 'src/app/pricingcurve/pricing-curve.utils'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-pricing-curve-filter-bar',
  styleUrls: ['./pricing-curve-layer-filter-bar.component.scss'],
  templateUrl: './pricing-curve-layer-filter-bar.component.html',
})
export class PricingCurveFilterBarComponent implements OnInit, OnChanges {
  _dateIntervals: BasicControl[]
  inceptionDate: BasicControl
  form: FormGroup

  valueOverrides: SelectorValueOverride = {
    ignoreForPricingCurve: {
      "N": "N (Includes Null)"
    }
  }

  @Input() creditSelectors: CreditSelectors
  @Input() selectors: Selectors
  @Input() status: PricingCurveStatus
  @Input() context: PricingCurveContextTypes

  @Input() set dateIntervals(value: BasicControl[]) {
    this._dateIntervals = value
    this.inceptionDate = value?.filter(
      item => item.columnName === 'inceptionDate'
    )[0]

    if (this.form && this.inceptionDate) {
      this.form
        .get('inceptionDateInputStart')
        ?.setValue(new Date(this.inceptionDate.minValue ?? ''))
    }
  }

  @ViewChild('inceptionDateInputStart', {
    read: MatInput,
  })
  inceptionDateInputStart: MatInput

  @ViewChild('inceptionDateInputEnd', {
    read: MatInput,
  })
  inceptionDateInputEnd: MatInput

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly datePipe: DatePipe,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    if (this.context === 'credit') {
      this.form = this.formBuilder.group({
        organization: new FormControl([]),
        dealType: new FormControl([]),
        highLowLTV: new FormControl([]),
        dealName: new FormControl([]),
        tranche: new FormControl([]),
        year: new FormControl([]),
      })
    } else {
      this.form = this.formBuilder.group({
        vendor: new FormControl([]),
        class: new FormControl([]),
        layerCategory: new FormControl([]),
        clientName: new FormControl([]),
        reinstatement: new FormControl([]),
        reinsurerName: new FormControl([]),
        ignoreForPricingCurve: new FormControl([]),
        territory: new FormControl([]),
        section: new FormControl([]),
        status: new FormControl([]),
        placedThrough: new FormControl([]),
        correspondentBroker: new FormControl([]),
        lossImpactedFromPrevYear: new FormControl([]),
        perils: new FormControl([]),
        pcClass: new FormControl([]),
        pcSubClass: new FormControl([]),
        inceptionDateInputStart: new FormControl(),
        inceptionDateInputEnd: new FormControl(),
      })

      if (this.inceptionDate) {
        this.form
          .get('inceptionDateInputStart')
          ?.setValue(new Date(this.inceptionDate.minValue ?? ''))
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.status?.currentValue) {
      const eitherLoading = this.status.isLoading || this.status.dialogLoading
      if (this.inceptionDateInputStart) {
        this.inceptionDateInputStart.disabled = eitherLoading
      }
      if (this.inceptionDateInputEnd) {
        this.inceptionDateInputEnd.disabled = eitherLoading
      }
    }
  }

  dispatchFilters() {
    const filters = this.getFiltersFromControls()
    if (this.context === 'credit' && this.creditSelectors) {
      let updatedSelectors = { ...this.creditSelectors }
      Object.entries(filters).map(([key, filter]) => {
        const currentVal: IControl = getSelectorFromCreditSelectors(
          updatedSelectors,
          key
        )
        const newVal: IControl = {
          ...currentVal,
          selectedValues: filter,
        }
        updatedSelectors = setCreditSelector(updatedSelectors, newVal, key)
      })
      this.store.dispatch(
        fromPricingCurve.updateWorkingCurveData({
          curveData: { creditSelectors: updatedSelectors },
        })
      )
    } else {
      this.store.dispatch(fromPricingCurve.filtersChanged({ filters }))
    }
    this.form.markAsPristine()
  }

  clearFilter(event: MouseEvent, filter: IControl) {
    event?.stopPropagation()
    const columnName = filter.columnName
    if (this.context === 'credit' && this.creditSelectors) {
      const oldCreditSelectors = { ...this.creditSelectors }
      const newVal: IControl = {
        ...filter,
        selectedValues: [],
      }
      this.store.dispatch(
        fromPricingCurve.updateWorkingCurveData({
          curveData: {
            creditSelectors: setCreditSelector(
              oldCreditSelectors,
              newVal,
              columnName
            ),
          },
        })
      )
    } else {
      this.form.controls[columnName].setValue([])
      this.store.dispatch(fromPricingCurve.clearFilter({ columnName }))
    }
  }

  clearDate(columnName: string, isMin: boolean) {
    if (columnName === 'inceptionDate') {
      if (isMin) {
        this.inceptionDateInputStart.value = ''
      } else {
        this.inceptionDateInputEnd.value = ''
      }
    }

    const payload = { columnName, isMin }

    this.store.dispatch(fromPricingCurve.clearDateInterval(payload))
  }

  onStartDateChange(columnName: string, $event: MatDatepickerInputEvent<Date>) {
    const dateValue = $event.target.value
    const date = this.datePipe.transform(dateValue, 'MM/dd/yyyy')
    const intervalToUpdate: IIntervalFilter = {
      filterId: columnName,
      newMinValue: date ?? undefined,
    }

    const payload = {
      data: intervalToUpdate,
      isMin: true,
    }
    this.store.dispatch(fromPricingCurve.updateDateIntervalValues(payload))
  }

  onEndDateChange(columnName: string, $event: MatDatepickerInputEvent<Date>) {
    const dateValue = $event.target.value
    const date = this.datePipe.transform(dateValue, 'MM/dd/yyyy')
    const intervalToUpdate: IIntervalFilter = {
      filterId: columnName,
      newMaxValue: date ?? undefined,
    }

    const payload = {
      data: intervalToUpdate,
      isMin: false,
    }

    this.store.dispatch(fromPricingCurve.updateDateIntervalValues(payload))
  }

  private getFiltersFromControls(): Record<string, string[]> {
    return Object.entries(this.form.controls).reduce(
      (acc, [key, formControl]) => {
        acc = {
          ...acc,
          [key]: formControl.value,
        }
        return acc
      },
      {}
    )
  }
}
