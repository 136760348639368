<h1 mat-dialog-title>Create Submission</h1>
<div mat-dialog-content>
  <app-layer-property
    [type]="'dropdown'"
    [name]="'Transaction'"
    [value]="transactionId"
    [references]="references"
    (inputChange)="transactionId = $event"
  ></app-layer-property>
  <app-layer-property
    [type]="'text'"
    [name]="'Label'"
    [value]="label"
    (inputChange)="label = $event"
  ></app-layer-property>
  <app-layer-property
    [type]="'text'"
    [name]="'Description'"
    [value]="description"
    (inputChange)="description = $event"
  ></app-layer-property>
  <mat-progress-bar
    *ngIf="creatingSubmissions"
    color="accent"
    mode="indeterminate"
  ></mat-progress-bar>
</div>
<div mat-dialog-actions align="start">
  <button appButton border accent (click)="onCreateClick()">Create</button>
  <button appButton link mat-dialog-close>Cancel</button>
</div>
