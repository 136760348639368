import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import * as fromAnimatedScenariosActions from './animated-scenarios.actions'
import * as fromAnimatedSceneariosSelectors from './animated-scenarios.selectors'
import * as fromScenarioEventResultActions from './scenario-event-result.actions'
import { withLatestFrom, map, concatMap, mergeMap, defaultIfEmpty } from 'rxjs/operators'
import { Store, select } from '@ngrx/store'
import { AppState } from '../../../core/store'
import { AnimatedScenariosService } from '../../../api/animated-scenarios/animated-scenarios.service'
import { rejectErrorWithInput, mergeMapWithInput } from '../../../api/util'
import { selectCurrentAnalysisProfile } from '../../../core/store/broker/broker.selectors'
import { LogicalPortfolioLayer } from '../../../api/analyzere/analyzere.model'
import { ApiResponse } from '../../../api/model/api.model'
import { Program } from '../../../core/model/program.model'
import { PortfoliosIDAndName } from '../../model/portfolio-set.model'
import { of } from 'rxjs'
import { isLayerActualRisk } from '../../model/layers.util'
import { AnalyzreService } from '../../../api/analyzere/analyzre.service'
import { selectCededLayers, selectLossSetLayers } from '../../store/analysis.selectors'
import { ScenarioEventResult } from '../animated-scenarios.model'

// tslint:disable: no-non-null-assertion
@Injectable()
export class AnimatedScenariosEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private animatedScenarioService: AnimatedScenariosService,
    private analyzereService: AnalyzreService
  ) {}

  execute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAnimatedScenariosActions.execute),
      withLatestFrom(
        this.store.pipe(
          select(fromAnimatedSceneariosSelectors.selectAllScenarioEvents)
        ),
        this.store.pipe(
          select(fromAnimatedSceneariosSelectors.selectSelectedStructure)
        ),
        this.store.pipe(select(selectCurrentAnalysisProfile)),
        this.store.pipe(select(selectLossSetLayers)),
        this.store.pipe(select(selectCededLayers)),
      ),
      mergeMapWithInput(([_, events, structure, __, lossSetLayers, cededLayers]) => {
        const eventCatalogDataID =
          this.animatedScenarioService.getLossSetDataIdWithMinSize(
            lossSetLayers
          )
        return this.animatedScenarioService
          .getEventIds(events.length, eventCatalogDataID)
          .pipe(
            mergeMap(response => {
              if (response.error) {
                return of({ error: response.error })
              } else {
                // find the inurance targets and push the ids in inuranceLayerIds
                const defaultInuranceLayers : LogicalPortfolioLayer[] = []
                const inuranceLayerIds: string[] = []
                cededLayers.forEach(x => {
                  if (x.layer.meta_data.inuranceTarget) {
                    const data = JSON.parse(x.layer.meta_data.inuranceTargetFor)
                    const ids = data.map((item: { id: any }) => item.id)
                    inuranceLayerIds.push(...ids)
                  } 
                })
                const inuranceLayersObservable = (inuranceLayerIds: string[]) => {
                  if (inuranceLayerIds && inuranceLayerIds.length > 0) {
                    return this.analyzereService
                      .fetchLayers<LogicalPortfolioLayer>(inuranceLayerIds)
                      .pipe(
                        map(response => {
                          return response?.data!
                        })
                      )
                  } else {
                    return of(defaultInuranceLayers)
                  }
                }
                const eventIDs = response.data!
                return inuranceLayersObservable(inuranceLayerIds)
                .pipe(
                  mergeMap(inuranceLayersReponse => {
                    return this.animatedScenarioService
                      .cloneStructureWithSharedLimit(
                        structure?.id,
                        `${
                          structure?.label
                        } - Loss Scenario - ${new Date().getTime()}`,
                        structure?.description ? structure?.description : '',
                        {
                          scenarioEvents: events,
                          eventIDs,
                        },
                        inuranceLayersReponse
                      )
                      .pipe(
                        map(r => {
                          if (!r.error && r.data) {
                            return {
                              ...r,
                              data: {
                                ...r.data,
                                eventIDs,
                              },
                            }
                          } else {
                            return { ...r }
                          }
                        })
                      ) as ApiResponse<{
                      program: Program
                      cededLayers: LogicalPortfolioLayer[]
                      portfolioIDs: PortfoliosIDAndName
                      eventIDs: number[]
                    }>
                  })
                )
              }
            })
          )
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromAnimatedScenariosActions.executeFailure({ error })
        )
      ),
      mergeMapWithInput(
        ([{ cededLayers, portfolioIDs, eventIDs }, [_, events, structure]]) => {
          const riskXOL = cededLayers.filter(isLayerActualRisk)
          if (riskXOL.length > 0) {
            const visibleRiskXOL = riskXOL.map(
              l => l.meta_data.riskVisibleLayerID as string
            )
            return this.analyzereService
              .fetchLayers<LogicalPortfolioLayer>(visibleRiskXOL)
              .pipe(
                mergeMap(r => {
                  if (r.error) {
                    return of({ error: r.error })
                  } else {
                    return this.animatedScenarioService.processScenarioEvents(
                      [...cededLayers, ...r.data!],
                      portfolioIDs,
                      structure!.analysisID,
                      eventIDs,
                      events
                    )
                  }
                })
              )
          } else {
            return this.animatedScenarioService.processScenarioEvents(
              cededLayers,
              portfolioIDs,
              structure!.analysisID,
              eventIDs,
              events
            )
          }
        }
      ),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromAnimatedScenariosActions.executeFailure({ error })
        )
      ),
      concatMap(([eventResults, [{ program }]]) => {
        let newScenarioEventsResult: ScenarioEventResult[] = []
        if (eventResults && eventResults.length > 0) {
          const inuranceLayerIds = new Set<string>()
          eventResults.forEach(x => {
            x.layers.forEach(y => {
              if (y.layer.meta_data.inuranceTarget) {
                const data = JSON.parse(y.layer.meta_data.inuranceTargetFor)
                const ids = data.map((item: { id: any }) => item.id)
                ids.forEach((id: string) => inuranceLayerIds.add(id))
              }
            })
          })
          newScenarioEventsResult = eventResults.map(item => {
            const newItem = { ...item }
            newItem.layers = item.layers.filter(
              layer => !inuranceLayerIds.has(layer.layer.meta_data.fromLayerID)
            )
            return newItem
          })
        }
        return [
          fromAnimatedScenariosActions.executeSuccess(),
          fromScenarioEventResultActions.addAllScenarioEventResults({
            eventResults : newScenarioEventsResult,
            scenarioStructure: program,
            eventResultsForDisplay: eventResults
          }),
        ]
      })
    )
  )
}
