import LayerMetricDefs, {
  LayerMetricDefAndID,
} from '../../model/layer-metric-defs'
import { LayerViewValues } from '../../model/layer-view'

const metricProperties: Array<keyof LayerViewValues> = [
  'depositPremium',
  'expectedCededPremium',
  'pmpmExpectedCededPremium',
  'purePremium',
  'lossOnLine',
  'standardDeviationExpectedLoss',
  'expectedCededExpenses',
  'expectedCededMargin',
  'expectedCededLossRatio',
  'cededMarginToStandardDeviation',
  'puremiumELMultiple',
  'entryProbability',
  'exitProbability',
  'exitAggProbability',
  'el',
]

const metricPropertyDefs: LayerMetricDefAndID[] = metricProperties.map(id => {
  const def = LayerMetricDefs[id]
  if (!def) {
    throw new Error(`Cannot find layer metric definition for '${id}`)
  }
  return { ...def, id }
})

export default metricPropertyDefs
