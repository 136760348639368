import { Action, createReducer, on } from '@ngrx/store'
import * as fromActions from './layer-details.actions'
import { createEntityAdapter } from '@ngrx/entity'
import { LayerMetricsState } from '../metrics/layers-metrics.reducer'

export const adapter = createEntityAdapter<LayerMetricsState>({
  selectId: entity => entity.metrics.id,
})

export interface LayerDetailsState {
  labelUpdateYear: number
  labelUpdateMethod: string
}

export const initialState: LayerDetailsState = {
  labelUpdateYear: 100,
  labelUpdateMethod: 'AEP',
}

const layerDetailsReducer = createReducer(
  initialState,

  on(fromActions.updateLayerTitle, (state, { year, method }) => ({
    ...state,
    labelUpdateYear: year,
    labelUpdateMethod: method,
  })),

  on(fromActions.resetLayerTitle, state => ({
    ...state,
    labelUpdateYear: 100,
    labelUpdateMethod: 'AEP',
  })),

)


export function reducer(state: LayerDetailsState | undefined, action: Action) {
  return layerDetailsReducer(state, action)
}
