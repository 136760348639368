import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { of } from 'rxjs'
import { concatMap, map, withLatestFrom } from 'rxjs/operators'
import { AppState } from '../../../../core/store'
import { selectCompareSlidesPerView } from '../../analysis.selectors'
import { calculateTowerSize } from '../../grouper/grouper-view/calculate-tower-size'
import * as fromCompareViewActions from './compare-view.actions'
import { CompareViewService } from '../../../../api/compare-view/compare-view.service'
import {
  concatMapWithInput,
  rejectError,
  rejectErrorWithInput,
} from '../../../../api/util'
import { selectCurrentClientID } from '../../../../core/store/broker/broker.selectors'
import { switchMap } from 'rxjs'
import { selectProgramState } from 'src/app/core/store/program/program.selectors'
import { selectProgramGroupState } from 'src/app/core/store/program-group/program-group.selectors'

@Injectable()
export class CompareViewEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(private service: CompareViewService) {}

  calculateTowerSize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromCompareViewActions.setCompareSlidesPerView,
        fromCompareViewActions.refreshCompareTowerSizePercentage
      ),
      concatMap(id =>
        of(id).pipe(
          withLatestFrom(this.store.pipe(select(selectCompareSlidesPerView)))
        )
      ),
      map(([_, slidesPerView]) => calculateTowerSize(slidesPerView)),
      map(percentage =>
        fromCompareViewActions.setCompareTowerSizePercentage({ percentage })
      )
    )
  )

  fetchCompareViews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCompareViewActions.fetchCompareViews),
      switchMap(() => this.service.getCompareViews()),
      rejectError(error =>
        this.store.dispatch(
          fromCompareViewActions.fetchCompareViewsFailure({ error })
        )
      ),
      withLatestFrom(
        this.store.pipe(select(selectProgramState)),
        this.store.pipe(select(selectProgramGroupState))
      ),
      switchMap(([data, structureGroupState, structureGroupMemberState]) => [
        fromCompareViewActions.fetchCompareViewsSuccess({
          compareViews: data,
          programs: Object.values(structureGroupState.entities),
          programGroups: Object.values(structureGroupMemberState.entities),
        }),
      ])
    )
  )

  saveCompareView$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromCompareViewActions.saveCompareView),
      map(({ type, ...props }) => props),
      concatMapWithInput(props => {
        // tslint:disable-next-line: no-non-null-assertion
        return this.service.saveCompareView(props.compareView)
      }),
      rejectErrorWithInput((error, _) =>
        this.store.dispatch(
          fromCompareViewActions.saveCompareViewFailure({
            error,
          })
        )
      ),
      withLatestFrom(this.store.select(selectCurrentClientID)),
      map(([[compareView], clientID]) => {
        compareView.client_id = clientID || ''
        return fromCompareViewActions.saveCompareViewSuccess({
          compareView,
        })
      })
    )
  })

  deleteCompareView$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromCompareViewActions.deleteCompareView),
      map(({ type, ...props }) => props),
      concatMapWithInput(props => {
        // tslint:disable-next-line: no-non-null-assertion
        return this.service.deleteCompareView(props.compareView)
      }),
      rejectErrorWithInput((error, _) =>
        this.store.dispatch(
          fromCompareViewActions.deleteCompareViewFailure({
            error,
          })
        )
      ),
      map(([compareViewId]) => {
        return fromCompareViewActions.deleteCompareViewSuccess({
          compareViewId,
        })
      })
    )
  })
}
