import { Injectable } from '@angular/core'
import { DatePipe } from '@angular/common'
import { ValueType, Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { MetricValueType } from '../../../core/model/metric-value-type.model'
import { RValue, Value } from '../../store/compare/compare.effects'
import { currencySymbol } from '../../model/layers.util'
import { locktonLogoBase64, sageLogoBase64 } from '@shared/util/export'
import { CompareStructureOptionsView } from '../../model/compare-metrics.model'

@Injectable({
  providedIn: 'root',
})
export class CompareExportService {
  constructor(private datePipe: DatePipe) {}

  private NUMERIC_TYPES: MetricValueType[] = [
    'numeric',
    'percentage',
    'ratio',
    'currency',
  ]

  exportXLSX<T extends RValue>(
    filename: string,
    companyName: string,
    opportunityName: string,
    effectiveDate: string,
    values: Record<string, T>[],
    headers: Value[],
    currencies: string[],
    graphBase64: string,
    structureOptions: CompareStructureOptionsView
  ): void {
    if (!values || values.length === 0) {
      return null
    }

    this.createXlsx(
      filename,
      companyName,
      opportunityName,
      effectiveDate,
      values,
      headers,
      currencies,
      graphBase64,
      structureOptions
    )
  }

  private createXlsx(
    filename: string,
    companyName: string,
    opportunityName: string,
    effectiveDate: string,
    values: Record<string, RValue>[],
    headers: Value[],
    currencies: string[],
    graphBase64: string,
    structureOptions: CompareStructureOptionsView
  ): void {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Compare', {
      pageSetup: { fitToPage: true },
      views: [{ showGridLines: false }],
    })
    let currentRow = 1
    const companyNameRow = worksheet.addRow([companyName])
    companyNameRow.getCell(1).style = {
      font: {
        name: 'Segoe Pro',
        size: 14,
        color: { argb: '00AEEF' },
      },
    }
    currentRow++

    worksheet.addRow([opportunityName])
    currentRow++

    const effectiveInfo = `Effective: ${effectiveDate}`
    const effectiveDateRow = worksheet.addRow([effectiveInfo])
    effectiveDateRow.getCell(1).style = {
      font: {
        name: 'Segoe Pro',
        size: 8,
        color: { argb: '5b6670' },
      },
    }
    currentRow++

    // Empty row
    worksheet.addRow([])
    currentRow++

    const optionComparisonRow = worksheet.addRow(['Option Comparison'])
    optionComparisonRow.getCell(1).style = {
      font: {
        name: 'Segoe Pro',
        size: 14,
        color: { argb: '00AEEF' },
      },
    }
    currentRow++

    const dateInfo = 'As of ' + this.datePipe.transform(new Date(), 'longDate')
    const asOfDateRow = worksheet.addRow([dateInfo])
    asOfDateRow.getCell(1).style = {
      font: {
        name: 'Segoe Pro',
        size: 8,
        color: { argb: '5b6670' },
      },
    }
    currentRow++

    // Empty row
    worksheet.addRow([])
    currentRow++

    const headersColumn = worksheet.getColumn(1)
    headersColumn.width = 40

    const categoryRows: number[] = []
    const firstValueRowNumber = currentRow

    for (const { value, isCategory } of headers) {
      const separatorIndex = value.toString().indexOf('--')
      let header = value

      if (separatorIndex !== -1) {
        header = value.toString().slice(0, separatorIndex)
      }
      if (header === 'MISC') {
        header = 'KEY PROBABILITIES'
      }

      const row = worksheet.addRow([header])
      row.getCell(1).style = {
        font: {
          name: 'Segoe Pro',
          size: 8,
        },
        border: {
          bottom: {
            style: 'thin',
            color: { argb: '000000' },
          },
        },
      }

      if (isCategory) {
        categoryRows.push(currentRow)
      }

      currentRow++
    }

    let currentColumn = 2

    values.forEach((val, i) => {
      const currency = currencies[i]
      currentRow = firstValueRowNumber
      const column = worksheet.getColumn(currentColumn)
      column.width = 40
      column.style = {
        alignment: { horizontal: 'right' },
      }
      for (const header of headers) {
        if (!val[header.value]) {
          continue
        }

        const { value: rValue } = val[header.value]
        const { value, valueType } = rValue
        const transformedValue = this.transformValue(value, valueType)
        const row = worksheet.getRow(currentRow)
        const cell = row.getCell(currentColumn)

        cell.model = {
          ...cell.model,
          type: this.isNumber(valueType) ? ValueType.Number : ValueType.String,
        }

        cell.style = {
          font: {
            name: 'Segoe Pro',
            size: 8,
          },
          border: {
            bottom: {
              style: 'thin',
              color: { argb: '000000' },
            },
          },
        }

        if (value !== undefined) {
          if (valueType === 'percentage' || valueType === 'ratio') {
            cell.numFmt = '#0.000"%"'
          }

          if (valueType === 'currency') {
            const absValue = Math.abs(value as number)
            cell.numFmt = this.numberFormat(absValue, currency)
          }

          cell.value = transformedValue
          if (valueType === 'ratio') {
            const formatVal = 100 * (1 / ((transformedValue as number) * 100))
            cell.value =
              (cell.value as number).toFixed(3) +
              '% / ' +
              ((formatVal * 100) as number).toFixed(3)
            cell.style = {
              ...cell.style,
              alignment: { horizontal: 'right' },
              font: {
                name: 'Segoe Pro',
                size: 8,
              },
            }
          }
        }
        currentRow++
      }
      currentColumn++
    })

    categoryRows.forEach(row => {
      worksheet.getRow(row).eachCell(cell => {
        cell.style = {
          font: {
            name: 'Segoe Pro',
            size: 8,
            bold: true,
            color: { argb: 'FFFFFF' },
          },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: '00AEEF',
            },
          },
        }
      })
    })

    worksheet.getRow(firstValueRowNumber).eachCell(cell => {
      cell.style = {
        font: {
          bold: true,
          name: 'Segoe Pro',
          size: 8,
          color: {
            argb: 'FFFFFF',
          },
        },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: '737F8B',
          },
        },
      }
    })
    for (let i = 0; i < 11; i++) {
      worksheet.addRow([' '])
    }
    const logoRow = currentRow + 5
    const logo = workbook.addImage({
      base64: locktonLogoBase64,
      extension: 'png',
    })
    worksheet.addImage(logo, {
      tl: { col: Math.floor(values.length / 2), row: logoRow },
      ext: { width: 120, height: 60 },
      editAs: 'absolute',
    })

    const sageLogo = workbook.addImage({
      base64: sageLogoBase64,
      extension: 'png',
    })
    worksheet.addImage(sageLogo, {
      tl: { col: Math.floor(values.length / 2) + 2, row: logoRow },
      ext: { width: 120, height: 60 },
      editAs: 'absolute',
    })

    const patentLinkRow = logoRow + 4
    const patentLinkCol = Math.floor(values.length / 2) + 3

    if (patentLinkRow > 0 && patentLinkCol > 0) {
      const patentLinkCell = worksheet.getCell(patentLinkRow, patentLinkCol)

      patentLinkCell.value = {
        text: 'Patent: https://global.lockton.com/re/en/sage-patents',
        hyperlink: 'https://global.lockton.com/re/en/sage-patents',
      }

      patentLinkCell.style = {
        font: {
          name: 'Segoe Pro',
          size: 8,
          underline: true,
          color: { argb: '00AEEF' },
        },
        alignment: { horizontal: 'left' },
      }
    }

    // worksheet2
    if (graphBase64) {
      const worksheet2 = workbook.addWorksheet('Graph', {
        pageSetup: { fitToPage: true },
        views: [{ showGridLines: false }],
      })
      let currentRow2 = 2
      const companyNameRow2 = worksheet2.addRow([companyName])
      companyNameRow2.getCell(1).style = {
        font: {
          name: 'Segoe Pro',
          size: 14,
          color: { argb: '00AEEF' },
        },
      }
      currentRow2++

      worksheet2.addRow([opportunityName])
      currentRow2++

      const effectiveInfo2 = `Effective: ${effectiveDate}`
      const effectiveDateRow2 = worksheet2.addRow([effectiveInfo2])
      effectiveDateRow2.getCell(1).style = {
        font: {
          name: 'Segoe Pro',
          size: 8,
          color: { argb: '5b6670' },
        },
      }
      currentRow2++

      worksheet2.addRow(['']) // Blank Row
      worksheet2.addRow(['']) // Blank Row

      currentRow2 = 39

      const graphImageId = workbook.addImage({
        base64: graphBase64,
        extension: 'png',
      })
      worksheet2.addRow(['']) // Blank Row
      worksheet2.addRow(['']) // Blank Row

      worksheet2.addImage(graphImageId, 'B5:U35') // Add graph image over range

      worksheet2.addRow(['']) // Blank Row
      worksheet2.addRow(['']) // Blank Row

      const headersColumn2 = worksheet2.getColumn(1)
      headersColumn2.width = 40
      const firstValueRowNumber2 = currentRow2
      headers = headers.filter(f => {
        return (
          f.value == 'Metric' ||
          f.metricLabel === structureOptions.data[0].structureOptions.x ||
          f.metricLabel === structureOptions.data[0].structureOptions.y
        )
      })
      for (const { value, isCategory } of headers) {
        const separatorIndex = value.toString().indexOf('--')
        let header = value

        if (separatorIndex !== -1) {
          header = value.toString().slice(0, separatorIndex)
        }
        if (header === 'MISC') {
          header = 'KEY PROBABILITIES'
        }

        const row = worksheet2.addRow([header])
        row.getCell(1).style = {
          font: {
            name: 'Segoe Pro',
            size: 8,
          },
          border: {
            bottom: {
              style: 'thin',
              color: { argb: '000000' },
            },
          },
        }

        if (isCategory) {
          categoryRows.push(currentRow2)
        }

        currentRow2++
      }

      currentColumn = 2
      values.forEach((val, i) => {
        const currency = currencies[i]
        currentRow2 = firstValueRowNumber2
        const column = worksheet2.getColumn(currentColumn)
        column.width = 40
        column.style = {
          alignment: { horizontal: 'right' },
        }
        for (const header of headers) {
          if (!val[header.value]) {
            continue
          }

          const { value: rValue } = val[header.value]
          const { value, valueType } = rValue
          const transformedValue = this.transformValue(value, valueType)
          const row = worksheet2.getRow(currentRow2)
          const cell = row.getCell(currentColumn)

          cell.model = {
            ...cell.model,
            type: this.isNumber(valueType)
              ? ValueType.Number
              : ValueType.String,
          }

          cell.style = {
            font: {
              name: 'Segoe Pro',
              size: 8,
            },
            border: {
              bottom: {
                style: 'thin',
                color: { argb: '000000' },
              },
            },
          }

          if (value !== undefined) {
            if (valueType === 'percentage' || valueType === 'ratio') {
              cell.numFmt = '#0.000"%"'
            }

            if (valueType === 'currency') {
              const absValue = Math.abs(value as number)
              cell.numFmt = this.numberFormat(absValue, currency)
            }

            cell.value = transformedValue
            if (valueType === 'ratio') {
              const formatVal = 100 * (1 / ((transformedValue as number) * 100))
              cell.value =
                (cell.value as number).toFixed(3) +
                '% / ' +
                ((formatVal * 100) as number).toFixed(3)
              cell.style = {
                ...cell.style,
                alignment: { horizontal: 'right' },
                font: {
                  name: 'Segoe Pro',
                  size: 8,
                },
              }
            }
          }
          currentRow2++
        }
        currentColumn++
      })

      worksheet2.getRow(firstValueRowNumber2).eachCell(cell => {
        cell.style = {
          font: {
            bold: true,
            name: 'Segoe Pro',
            size: 8,
            color: {
              argb: 'FFFFFF',
            },
          },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: '737F8B',
            },
          },
        }
      })
      for (let i = 0; i < 11; i++) {
        worksheet2.addRow([' '])
      }
    }
    workbook.xlsx.writeBuffer().then((data: BlobPart) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      saveAs(blob, filename)
    })
  }

  private isNumber(valueType: MetricValueType): boolean {
    return this.NUMERIC_TYPES.includes(valueType)
  }

  private numberFormat(value: number, currency: string): string {
    const lookup = [
      { value: 1e12, symbol: 'T', commas: ',,,,' },
      { value: 1e9, symbol: 'B', commas: ',,,' },
      { value: 1e6, symbol: 'M', commas: ',,' },
      { value: 1e3, symbol: 'K', commas: ',' },
      { value: 0, symbol: '', commas: '' },
    ]

    const item = lookup.find(i => value >= i.value)
    const positive = `"${currencySymbol(currency)}"#,##0.0##${item?.commas}"${
      item?.symbol
    }"`
    const negative = `-${positive}`
    return `${positive};${negative}`
  }

  private transformValue(
    value: string | number,
    valueType: MetricValueType | string
  ) {
    if (value === undefined) {
      return ''
    }

    switch (valueType) {
      case 'ratio':
      case 'percentage':
        return (value as number) * 100
      case 'currency':
        return value as number
      default:
        return value
    }
  }
}
