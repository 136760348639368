<div class="dialog-container">
  <div class="actions">
    <mat-icon appButtonIcon class="close" (click)="destroyDialog()"
      >close</mat-icon
    >
  </div>
  <app-pricing-curve-saved-curves
    [savedCurves]="savedCurves$ | async"
    [savedCurveSelectors]="savedCurveSelectors$ | async"
    [status]="status$ | async"
    [isAdmin]="isAdmin$ | async"
    [usageContext]="usageContext"
    [addedCurveIds]="addedCurveIds"
  ></app-pricing-curve-saved-curves>
</div>
