import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'
import { SortTableValueChangeEvent } from '@shared/sort-table/sort-table.model'
import { AssignedLines, ReinsurerPhases } from '../models/quote.model'
import { ReinsurerState } from '../store/reinsurer/reinsurer.reducer'
import { SectionState } from '../store/section/section.reducer'
import { FUND_MANAGER_MARKET_USE, LLOYDS_SYNDICATE_PREFIX, Reinsurer } from 'src/app/core/model/reinsurer.model'
import { getAssignedLineBureausStamp, hasOffMarketLines } from '../quote.util'
import { Bureaus } from 'src/app/api/model/quote.model'
import { Reference } from '@shared/layer-property/layer-property.component'

@Component({
  selector: 'app-set-off-market-reinsurer-dialog',
  styleUrls: ['./set-off-market-reinsurer-dialog.component.scss'],
  templateUrl: './set-off-market-reinsurer-dialog.component.html',
})
export class SetOffMarketReinsurerDialogComponent implements OnInit {
  columnDefs: SortTableColumnDef[] = [
    {
      id: 'reinsurer',
      label: 'Reinsurer',
      valueType: 'dropdown',
      editable: true,
      bodyStyle: { width: '35%' },
      references: this.getReinsurerRefs(),
    },
    {
      id: 'whiteSpaceBusinessUnit',
      label: 'Whitespace Business Unit',
      bodyStyle: { width: '35%' },
      valueType: 'text',
      editable: false,
    },
    {
      id: 'underwriterRef',
      label: 'Underwriter Ref #',
      bodyStyle: { width: '15%' },
      valueType: 'text',
      editable: false,
    },
    {
      id: 'written',
      label: 'Written %',
      bodyStyle: { width: '15%' },
      valueType: 'percentage',
      decimals: 4,
      whenLoading: 'show',
      editable: false,
    }
  ]


  modifiedReinsurers: ReinsurerState[] = []
  layerNames: {[key: string]: string} = {}

  constructor(
    private dialogRef: MatDialogRef<SetOffMarketReinsurerDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      reinsurerList: Reinsurer[]
      reinsurers: ReinsurerState[]
      sectionList: SectionState[]
      bureaus: Bureaus[]
    }
  ) {}

  getReinsurerRefs(): Reference[]{
    let refs: Reference[] = []
    this.data.reinsurerList.forEach(reinsurer =>{
      if (reinsurer.selectedCompanyPapers){
        reinsurer.selectedCompanyPapers.forEach(cp => {
          refs.push({
            value: cp.agencyName,
            viewValue: cp.agencyName
          })
        })
      }
      else{
        refs.push({
          value: reinsurer.name,
          viewValue: reinsurer.name
        })
      }
    }

    )
    return refs
  }

  ngOnInit(): void {
    this.data.reinsurers.forEach((reinsurer, idx) => {
      if (! reinsurer.reinsurer.cededlayerID){
        return
      }
      const foundLayerName =
        this.data.sectionList.find(
          x => x.section.layerRef === reinsurer.reinsurer.cededlayerID
      )?.section.layerName || `Layer ${idx + 1}` // Gives layer name if no name is found
      this.layerNames[reinsurer.reinsurer.cededlayerID] = foundLayerName
    })
    this.modifiedReinsurers = JSON.parse(JSON.stringify(this.data.reinsurers))
    this.modifiedReinsurers = this.modifiedReinsurers.filter(reinsurer => reinsurer.reinsurer.reinsurerPhase === ReinsurerPhases.FOT)
    this.modifiedReinsurers = this.modifiedReinsurers.filter(hasOffMarketLines)
    this.modifiedReinsurers.forEach(reinsurer => {
      reinsurer.reinsurer.riskAssignedLinesLink = reinsurer.reinsurer.riskAssignedLinesLink?.filter(row => !row.marketTpRef)
    })
  }

  updateLineName(update: SortTableValueChangeEvent<AssignedLines>): void {

    const fundManagers = this.data.reinsurerList.filter(
      r => r.market_use === FUND_MANAGER_MARKET_USE
    )
    const fundManagerFound = fundManagers?.find(fundManager =>
      fundManager.selectedCompanyPapers.some(
        paper => paper.agencyName === update.value
      )
    )
    const agencyDetails = fundManagerFound?.selectedCompanyPapers.find(
      paper => paper.agencyName === update.value
    )

    var selectedReinsurer = this.data.reinsurerList.find(
      x => x.name === update.value)
    
    let tpRef = ''
    let reinsurerName = ''
    let seqNum = 0
    if (agencyDetails){
      tpRef = agencyDetails.agencyTPRef
      reinsurerName = agencyDetails.agencyName
      seqNum = agencyDetails.agencySeqNumber
    }
    else if (selectedReinsurer){
      tpRef = selectedReinsurer.tpRef
      reinsurerName = update.value
      seqNum = selectedReinsurer.reinsurerProgramFactor && selectedReinsurer.reinsurerProgramFactor.length > 0 ? selectedReinsurer.reinsurerProgramFactor[0].relation_seq_number : null
    }

    let updateLine: AssignedLines | undefined
    this.modifiedReinsurers.forEach(reinsurerLine => {
      const foundLine = reinsurerLine.reinsurer.riskAssignedLinesLink?.find(row => row.id === update.id)
      if (foundLine) {
        updateLine = foundLine
      }
    })
    if (updateLine) {
      updateLine.marketTpRef = tpRef
      updateLine.relationSeqNumber = seqNum
      const refLine = updateLine
      this.modifiedReinsurers.forEach(reinsurerLine => {
        reinsurerLine.reinsurer.riskAssignedLinesLink?.forEach(row => {
          if (row.whiteSpaceBusinessUnit === refLine.whiteSpaceBusinessUnit) {
            row.reinsurer = reinsurerName
            row.marketTpRef = tpRef
            row.relationSeqNumber = seqNum
          }
        })
      })
    }
    this.modifiedReinsurers = JSON.parse(JSON.stringify(this.modifiedReinsurers))
  }

  mergeChangedLines(input: ReinsurerState[]): ReinsurerState[] {
    const tempReinsurers = JSON.parse(JSON.stringify(this.data.reinsurers)) as ReinsurerState[]
    tempReinsurers.forEach(reinsurer => {
      reinsurer.reinsurer.riskAssignedLinesLink = reinsurer.reinsurer.riskAssignedLinesLink?.filter(row => row.marketTpRef)
    })
    input.forEach(reinsurer => {
      const foundReinsurer = tempReinsurers.find(x => x.reinsurer.id === reinsurer.reinsurer.id)
      if (!foundReinsurer || !reinsurer.reinsurer.riskAssignedLinesLink) {
        return
      }
      foundReinsurer.reinsurer.riskAssignedLinesLink =
        foundReinsurer.reinsurer.riskAssignedLinesLink?.concat(
          reinsurer.reinsurer.riskAssignedLinesLink
      )
    })
    return tempReinsurers
  }

  setLloydsSyndicateBureausStamps(): void{
    this.modifiedReinsurers.forEach(reinsurer => {
      reinsurer.reinsurer.riskAssignedLinesLink?.forEach(row => {
        if (row.reinsurer?.startsWith(LLOYDS_SYNDICATE_PREFIX)) {
          row.bureaus = getAssignedLineBureausStamp(row, this.data.bureaus)
        }
      })
    })
  }

  save(): void {
    this.setLloydsSyndicateBureausStamps()
    this.dialogRef.close(this.modifiedReinsurers)
  }
}
