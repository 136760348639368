import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { select } from 'd3-selection'
import { head, uniqBy } from 'ramda'
import { filter, map } from 'rxjs/operators'
import {
  CurrencyCode,
  LossFilter,
} from '../../../api/analyzere/analyzere.model'
import {
  BlobResponse,
  StructureLayerDataResponse,
  StudyResponse,
} from '../../../api/model/backend.model'
import { Program } from '../../../core/model/program.model'
import { State as AuthState } from '../../../core/store/auth/auth.state.facade'
import {
  StructureNameEditEvent,
  ImportLossSetsClickEvent,
} from '../../../core/store/program/program.actions'
import {
  SetNameDialogComponent,
  SetNameDialogData,
  SetNameDialogResult,
} from '@shared/set-name-dialog.component'
import {
  ImportLossSetsDialogComponent,
  ImportLossSetsResult,
} from '@shared/import-loss-sets-dialog.component'
import { TierNames } from '../../../tier/tier.model'
import { RiskLossSetLayerModel } from '../../layers/loss-set-layer-selector/loss-set-layer-selector.component'
import { Layer, PhysicalLayer } from '../../model/layers.model'
import {
  isLayerAgg,
  isLayerAggFeeder,
  isLayerShared,
} from '../../model/layers.util'
import { LossSetGroup, LossSetLayer } from '../../model/loss-set-layers.model'
import { AggregationMethodType, Perspective } from '../../model/metrics.model'
import {
  PortfolioMetrics,
  PortfolioType,
} from '../../model/portfolio-metrics.model'
import {
  PortfolioSetID,
  SharedIDPortfolio,
  StructureSaveAsEvent,
  StructureSaveEvent,
} from '../../model/portfolio-set.model'
import { getOptimizationType } from '../../optimization/optimization.model'
import {
  AnalysisPanelsCollapseEvent,
  AnalysisPanelsSection,
} from '../../store/analysis-panels.actions'
import { LayerState } from '../../store/ceded-layers/layers.reducer'
import { LossSetGroupEntity } from '../../store/loss-set-layers/loss-set-group/loss-set-group.reducer'
import { State as ReinsurerState } from '../../../reinsurers/store/study-reinsurers.reducer'
import { layerIds } from '../../model/layer-palette.model'
import { timer } from 'rxjs'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
declare var require: any

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-portfolio',
  styleUrls: ['./portfolio.component.scss'],
  templateUrl: './portfolio.component.html',
})
export class AnalysisComponent {
  @Input() id: PortfolioSetID | null
  @Input() tierNames: TierNames
  @Input() metrics: PortfolioMetrics
  @Input() reinsurers: ReinsurerState
  @Input() currentProgram?: Program
  @Input() currentProgramStructureNames: string[]
  @Input() dirty: boolean
  @Input() portfolioMetricsLoading: boolean
  @Input() activeAction: string
  @Input() selectedClientID: string | null
  @Input() selectedYearID: string | null
  @Input() selectedViewID: string | null
  @Input() rss: number | null
  @Input() layers: LayerState[]
  @Input() portfolioMetricsOpen: boolean
  @Input() layersPanelOpen: boolean
  @Input() propertiesPanelOpen: boolean
  @Input() showAgg: boolean
  @Input() showOcc: boolean
  @Input() showZoom: number
  @Input() showingTowerTab: boolean
  @Input() submitMarketPermission: boolean
  @Input() authState: AuthState
  @Input() layerData: StructureLayerDataResponse[]
  @Input() marketBlob: BlobResponse
  @Input() sharedIDPortfolio: SharedIDPortfolio[]
  @Input() showingAnimatedScenariosTab: boolean
  @Input() showingExploreGrossTab: boolean
  @Input() lossFilters: LossFilter[]
  @Input() scenarios: Program[]
  @Input() optimizations: Program[]
  @Input() showLossSetGroupEditor = false
  @Input() groupEditSelectedLossSets: LossSetLayer[]
  @Input() lossSetGroupEditMode: string
  @Input() lossSetGroups: LossSetGroupEntity[]
  @Input() lossSetGroupsMetrics: Record<string, PortfolioMetrics>[]
  @Input() lossSetSelectedGroup: string
  @Input() lossSetSelectionMode: string
  @Input() lossSetGroupsActiveAction: string
  @Input() currentCededLayer: LayerState | null
  @Input() collapseBySection: Record<AnalysisPanelsSection, boolean>
  @Input() parentLossSets: LossSetLayer[]
  @Input() grossLossSets: LossSetLayer[]
  @Input() layerViewIDs: Record<string, string>
  @Input() grossPortfolioViewID: string | null
  @Input() currencyList: CurrencyCode[]
  @Input() filteredCurrencyList: CurrencyCode[]
  @Input() currentCurrency: string
  @Input() hideTechPremiumTab: boolean
  @Input() showingTechPremiumTab: boolean
  @Input() canSaveTechnicalPremium: boolean
  @Input() studies: StudyResponse[]
  @Input() selectedProgramID: string

  @Output() structureCurrency = new EventEmitter<string>()
  @Output() togglePortfolioMetricsClick = new EventEmitter<void>()
  @Output() showAggChange = new EventEmitter<boolean>()
  @Output() showOccChange = new EventEmitter<boolean>()
  @Output() showZoomChange = new EventEmitter<number>()
  @Output() portfolioTypeChange = new EventEmitter<PortfolioType>()
  @Output() returnPeriod1Change = new EventEmitter<number>()
  @Output() returnPeriod2Change = new EventEmitter<number>()
  @Output() returnPeriod3Change = new EventEmitter<number>()
  @Output() returnPeriod4Change = new EventEmitter<number>()
  @Output() returnPeriod5Change = new EventEmitter<number>()
  @Output() aggregationMethodChange = new EventEmitter<AggregationMethodType>()
  @Output() perspectiveChange = new EventEmitter<Perspective>()
  @Output() lossFilterClick = new EventEmitter<string>()
  @Output() resetClick = new EventEmitter<void>()
  @Output() saveClick = new EventEmitter<StructureSaveEvent>()
  @Output() showDetails = new EventEmitter<string | undefined>()
  @Output() showExchange = new EventEmitter<string | undefined>()
  @Output() submitMarketClick = new EventEmitter<any>()
  @Output() showProperties = new EventEmitter<void>()
  @Output() toggleLayersPanelClick = new EventEmitter<void>()
  @Output() togglePropertiesPanelClick = new EventEmitter<void>()
  @Output() saveAsClick = new EventEmitter<StructureSaveAsEvent>()
  @Output() downloadTower = new EventEmitter<void>()
  @Output() towerTabSelected = new EventEmitter<void>()
  @Output() scenarioOrOptimizationClick = new EventEmitter<Program>()
  @Output() backClick = new EventEmitter()
  @Output() grossSelectionModeClick = new EventEmitter<void>()
  @Output() cededSelectionModeClick = new EventEmitter<void>()
  @Output() lossSetSelection = new EventEmitter<Partial<Layer>>()
  @Output() grossLossSetSelection = new EventEmitter<
    LossSetLayer[] | undefined
  >()
  @Output() newLossSetGroupClick = new EventEmitter<void>()
  @Output() saveLossSetGroupClick = new EventEmitter<any>()
  @Output() updateLossSetGroupClick = new EventEmitter<LossSetGroup>()
  @Output() cancelLossSetGroupClick = new EventEmitter<void>()
  @Output() groupEditLossSetSelection = new EventEmitter<
    LossSetLayer[] | undefined
  >()
  @Output() lossSetGroupClick = new EventEmitter<any>()
  @Output() deleteLossSetGroupClick = new EventEmitter<LossSetGroup>()
  @Output() lossSetGroupEditorClick = new EventEmitter<boolean>()
  @Output() lossSetGroupSetDirty = new EventEmitter<string>()
  @Output() removeRiskLossSets = new EventEmitter<RiskLossSetLayerModel>()
  @Output() addRiskLossSets = new EventEmitter<RiskLossSetLayerModel>()
  @Output() structureNameEdit = new EventEmitter<StructureNameEditEvent>()
  @Output() importLossSetsClick = new EventEmitter<ImportLossSetsClickEvent>()
  @Output() collapseChange = new EventEmitter<AnalysisPanelsCollapseEvent>()
  @Output() optimizeClick = new EventEmitter()
  @Output() openAddInuranceDialog = new EventEmitter<{
    structure: Program
    layer: LayerState
  }>()
  @Output() updateLayerCurrencyAndSaveClick = new EventEmitter<string>()
  @Output() updatePhysicalLayer = new EventEmitter<{
    id: string
    change: Partial<PhysicalLayer>
    debounce?: boolean
    property?: string
  }>()
  @Output() updateLayer = new EventEmitter<{
    id: string
    change: Partial<Layer>
    debounce?: boolean
  }>()

  layersPanelToggleHover = false
  propertiesPanelToggleHover = false
  structureDirtyState = false
  layerPasteTrigger = false

  get isEmpty() {
    return this.metrics === null || this.metrics === undefined
  }

  get isLayersPanelOpen(): boolean {
    return this.showingTowerTab && this.layersPanelOpen
  }

  get isPropertiesPanelOpen(): boolean {
    return this.showingTowerTab && this.propertiesPanelOpen
  }

  get isLibRETemplate(): boolean {
    return this.currentProgram?.libRE === 'T'
  }

  get isLibRE(): boolean {
    return (
      this.currentProgram?.libRE === 'Y' || this.currentProgram?.libRE === 'T'
    )
  }

  constructor(private dialog: MatDialog) {}

  onParentClick(): void {
    const parent =
      this.scenarios.length > 0
        ? head(this.scenarios)
        : head(this.optimizations)
    if (parent) {
      this.scenarioOrOptimizationClick.emit(parent)
    }
  }

  onLayersPanelClick($event: MouseEvent | TouchEvent) {
    $event.preventDefault()
    if (!this.layersPanelOpen) {
      this.toggleLayersPanelClick.emit()
    }
  }

  onPropertiesPanelClick($event: MouseEvent | TouchEvent) {
    $event.preventDefault()
    if (!this.propertiesPanelOpen) {
      this.togglePropertiesPanelClick.emit()
    }
  }

  onTowerDownloadClick() {
    const currentStructure = this.currentProgram
    let fileName: string = ''
    if (currentStructure) {
      if (this.showOcc) {
        fileName = 'SAGETowerOccurrence'
      }
      if (this.showAgg) {
        fileName = 'SAGETowerAggregate'
      }
    }
    this.saveAsPDF(fileName)
  }

  async saveAsPDF(fileName: string): Promise<void> {
    const towerWindow = document.querySelector('.towers') as HTMLElement
    const canvas = await html2canvas(towerWindow, {
      backgroundColor: 'black',
      ignoreElements: element => element.tagName.toLowerCase() === 'button',
    })
    let doc = new jsPDF({
      orientation: 'l',
      unit: 'px',
    })

    await this.addCanvas(doc, canvas)
    doc.save(fileName + '.pdf')
  }

  private async addCanvas(
    doc: jsPDF,
    canvas: HTMLCanvasElement
  ): Promise<void> {
    this.setDocBackgroundBlack(doc)
    const sageLogo = document.createElement('img')
    const loaded = new Promise<void>((resolve, _reject) => {
      sageLogo.onload = () => {
        resolve()
      }
    })
    sageLogo.src = 'assets/logos/app-logo-white-export.png'
    await loaded
    doc.addImage(sageLogo, 'PNG', 20, 20, 100, 50)
    doc.addImage(
      canvas.toDataURL('image/png'),
      'PNG',
      0,
      130,
      doc.internal.pageSize.width,
      doc.internal.pageSize.height - 150
    )
    doc.setFontSize(10)
    doc.setTextColor(255, 255, 255)
    doc.text(
      'Patent: https://global.lockton.com/re/en/sage-patents',
      20,
      doc.internal.pageSize.height - 10
    )
  }

  private setDocBackgroundBlack(doc: jsPDF): void {
    doc.setFillColor(0, 0, 0)
    doc.rect(
      0,
      0,
      doc.internal.pageSize.width,
      doc.internal.pageSize.height,
      'F'
    )
  }

  onEditNameClick(): void {
    const structure = this.currentProgram
    if (structure) {
      const data: SetNameDialogData = {
        actionName: 'Edit',
        entityName: 'Name & Description',
        otherNames: this.currentProgramStructureNames.filter(
          name => name !== structure.label
        ),
        currentName: structure?.label,
        currentDescription: structure?.description,
        showDescription: true,
      }
      this.dialog
        .open(SetNameDialogComponent, { width: '30vw', data })
        .afterClosed()
        .pipe(
          filter<SetNameDialogResult>(res => !!res),
          map(({ name, description }) => ({ structure, name, description }))
        )
        .subscribe(props => this.structureNameEdit.emit(props))
    }
  }

  onImportLossSetsClick(): void {
    const structure = this.currentProgram
    if (structure) {
      this.dialog
        .open(ImportLossSetsDialogComponent, { width: '30vw' })
        .afterClosed()
        .pipe(
          filter<ImportLossSetsResult>(res => !!res),
          map(({ parentGrossPortfolioID }) => ({
            structure,
            parentGrossPortfolioID,
          }))
        )
        .subscribe(props => this.importLossSetsClick.emit(props))
    }
  }

  resetSelectedGroup() {
    this.lossSetGroupClick.emit({ lossSetGroup: null, mode: '' })
  }

  get enableOptimization() {
    // Do not allow Shared Limits
    if (this.layers.some(l => isLayerShared(l.layer))) {
      return false
    }
    if (this.currentProgram?.isOptimization) {
      return false
    }

    if (this.dirty) {
      return false
    }
    if (this.optimizationValidQS) {
      return true
    }
    if (this.optimizationValidXOL) {
      return true
    }
    if (this.optimizationValidAggLayerS) {
      return true
    }
    return this.optimizationValidXOLQS
  }

  get optimizationValidAggLayerS() {
    return this.layers.every(
      l => isLayerAgg(l.layer) || isLayerAggFeeder(l.layer)
    )
  }

  get optimizationValidQS() {
    return (
      this.layers.length === 1 &&
      (this.layers[0].layer.meta_data.sage_layer_type === layerIds.catQs ||
        this.layers[0].layer.meta_data.sage_layer_type === layerIds.noncatQs ||
        this.layers[0].layer.meta_data.sage_layer_type === layerIds.ahlQs)
    )
  }

  get optimizationValidXOL() {
    return this.layers.every(
      l =>
        l.layer.meta_data.sage_layer_type === layerIds.catXl ||
        l.layer.meta_data.sage_layer_type === layerIds.noncatXl ||
        l.layer.meta_data.sage_layer_type === layerIds.ahlXl ||
        l.layer.meta_data.sage_layer_type === layerIds.noncatIndxl
    )
  }

  get optimizationValidXOLQS() {
    const uniqueLayers = uniqBy(
      l => getOptimizationType(l.layer.meta_data.sage_layer_type || ''),
      this.layers
    )
    return (
      uniqueLayers.length > 1 &&
      uniqueLayers.every(
        l =>
          l.layer.meta_data.sage_layer_type === layerIds.catXl ||
          l.layer.meta_data.sage_layer_type === layerIds.noncatXl ||
          l.layer.meta_data.sage_layer_type === layerIds.ahlXl ||
          l.layer.meta_data.sage_layer_type === layerIds.noncatIndxl ||
          l.layer.meta_data.sage_layer_type === layerIds.catQs ||
          l.layer.meta_data.sage_layer_type === layerIds.noncatQs ||
          l.layer.meta_data.sage_layer_type === layerIds.ahlQs
      )
    )
  }
  onHandlePastedLayer(e: boolean): void {
    this.layerPasteTrigger = e
    const pasteCheck$ = timer(500).subscribe(() => {
      pasteCheck$.unsubscribe()
      this.layerPasteTrigger = false
    })
  }
  onStructureCurrencyEvent(event: string | undefined): void {
    this.structureCurrency.emit(event)
  }

  onUpdateLayerCurrencyAndSaveClick(currency: string): void {
    this.layers.forEach(l => {
      this.updateLayersState(JSON.parse(JSON.stringify(l)), currency)
    })
    this.onSaveClick()
  }

  onSaveClick(
    event: StructureSaveEvent = {
      saveAnalysis: true,
      saveTechPremiumLayerTypes: false,
    }
  ): void {
    this.structureDirtyState = false
    this.saveClick.emit(event)
  }

  updateLayersState(layer: LayerState, currency: string): void {
    layer.layer.physicalLayer.aggregateAttachment.currency = currency
    layer.layer.physicalLayer.aggregateLimit.currency = currency
    layer.layer.physicalLayer.attachment.currency = currency
    layer.layer.physicalLayer.franchise.currency = currency
    layer.layer.physicalLayer.limit.currency = currency
    layer.layer.physicalLayer.premium.currency = currency
    if (layer.layer.physicalLayer.riskLimit) {
      layer.layer.physicalLayer.riskLimit.currency = currency
    }
    if (layer.layer.physicalLayer.riskAttachment) {
      layer.layer.physicalLayer.riskAttachment.currency = currency
    }
    if (layer.layer.physicalLayer.event_limit) {
      layer.layer.physicalLayer.event_limit.currency = currency
    }
    if (layer.layer.physicalLayer.payout) {
      layer.layer.physicalLayer.payout.currency = currency
    }
    if (layer.layer.physicalLayer.trigger) {
      layer.layer.physicalLayer.trigger.currency = currency
    }
    this.updatePhysicalLayer.emit({
      id: layer.layer.id,
      change: layer.layer.physicalLayer,
    })
    this.updateLayer.emit({
      id: layer.layer.id,
      change: {
        currency,
        meta_data: {
          ...layer.layer.meta_data,
          isChangedInDesign: true,
        },
      },
    })
  }
}
