import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  TrackByFunction,
  ViewChild,
} from '@angular/core'
import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { MatMenuTrigger } from '@angular/material/menu'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort, MatSortable } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { keys, values } from 'ramda'
import { Subject, Subscription } from 'rxjs'
import { debounceTime, takeUntil } from 'rxjs/operators'
import { LossFilter } from '../../../api/analyzere/analyzere.model'
import { Bureaus } from '../../../api/model/quote.model'
import { MetricValueType } from '../../../core/model/metric-value-type.model'
import { DataFilterConfig } from '../../data-filter/data-filterer'
import { NamedNgTemplateDirective } from '../../services/named-ng-template.directive'
import { toArray } from '../../util/operators'
import { Selections, SelectionsChangeEvent } from '../../util/selections'
import {
  SortTableColumnsConfig,
  toSortTableColumnsConfig,
} from '../sort-table-column-view'
import {
  SortTableColumnFilter,
  SortTableFilter,
} from '../sort-table-filter/sort-table-filter'
import {
  CheckboxSelectionsChange,
  RowStyle,
  SortTableColumnDef,
  SortTableColumnView,
  SortTableRow,
  SortTableRowClickEvent,
  SortTableRowView,
  SortTableValueChangeEvent,
} from '../sort-table.model'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { EditRowDialogComponent } from '../../edit-row-dialog/edit-row-dialog.component'
import { Subjectivity } from '../../../quote/models/quote.model'
import { DynamicMenuItem } from '../../dynamic-menu/dynamic-menu.model'
import { SortTableExcel } from './sort-table-excel'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ErrorComponent } from 'src/app/error/error.component'
import { errorPayload } from 'src/app/error/model/error'
import { findScrollableParent } from './sort-table-excel.util'
import { CREDIT_ANIMATED_SCENARIOS } from 'src/app/credit/model/credit-animated-scenarios.model'

/**
 * If you use app-sort-table and notice some lag when scrolling or app is freezing,
 * check if you created a wrapper component on top of app-sort-table, instead use the table component directly.
 * This might cause a memory leak, a bunch of IterableChangeRecord objects are not GC'd.
 * Reference: angular/angular#36878
 * */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-sort-table',
  styleUrls: ['./sort-table.component.scss'],
  templateUrl: './sort-table.component.html',
})
export class SortTableComponent<T extends { id: string; parentLayerId: string }>
  extends SortTableExcel<T>
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  protected _destroy$ = new Subject<void>()
  private _modelChange$ = new Subject<void>()
  private canShowEmptyMessage = false
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger
  @ViewChild('excelContextMenu') excelContextMenu: ElementRef<HTMLDivElement>
  @ViewChild('excelSelectionBox') excelSelectionBox: ElementRef<HTMLDivElement>
  @ContentChildren(NamedNgTemplateDirective)
  namedTemplates: QueryList<NamedNgTemplateDirective>

  dataSource = new MatTableDataSource<SortTableRow<T>>()
  columns: SortTableColumnsConfig<T>
  selections = new Selections()
  filter?: SortTableFilter<T>
  checkboxes: Record<string, Selections> = {}
  checkboxSubscriptions: Subscription[] = []
  lossFilterValue: string
  @Input() customSort: (
    data: SortTableRow<T>[],
    sort: MatSort
  ) => SortTableRow<T>[]
  @Input() subjectivityOptions: string[]
  @Input() columnDefs: SortTableColumnDef<T>[]
  @Input() defaultSort: MatSortable
  @Input() rows: SortTableRow<T>[]
  @Input() footer?: SortTableRow<T>
  @Input() footerReadOnly: boolean
  @Input() footer2?: SortTableRow<T>
  @Input() footer2ReadOnly: boolean
  @Input() readOnlyRows: Map<T, Set<keyof T>>

  private _filterText = ''
  cas = CREDIT_ANIMATED_SCENARIOS
  @Input() set filterText(value: string) {
    this._filterText = value
    this.updateFilter()
  }
  get filterText(): string {
    return this._filterText
  }
  @Input() noLoadingSpinnerOnSubmit: boolean
  @Input() filterConfig?: DataFilterConfig<SortTableRow<T>>
  @Input() paginator?: MatPaginator
  @Input() selectAllColumnID?: keyof T
  @Input() clamp?: number
  @Input() emptyMessage?: string
  @Input() lossFilters?: LossFilter[]
  @Input() currentCurrency?: string
  @Input() isSubjectivity?: boolean
  @Input() isTracking?: boolean
  @Input() singleRowFilter: string[]
  @Input() isAssignedLines?: boolean
  @Input() showTitle = true
  @Input() bureaus?: Bureaus[] = []
  @Input() removeSuffixCurrencyTemplateOption = false
  @Input() isLayerDetailsTab = false
  @Input() accentHeaderRow = false
  @Input() isQQ?: boolean
  @Input() useZeroText: boolean
  @Input() enableHover: boolean

  @HostBinding('class.click-select') _enableRowClickSelect = false
  @Input() set enableRowClickSelect(value: any) {
    this._enableRowClickSelect = coerceBooleanProperty(value)
  }
  get enableRowClickSelect() {
    return this._enableRowClickSelect
  }

  private _singleRowSelect = false
  @Input() set singleRowSelect(value: any) {
    this._singleRowSelect = coerceBooleanProperty(value)
  }
  get singleRowSelect() {
    return this._singleRowSelect
  }

  @HostBinding('class.tight') private _spacing: 'tight' | 'loose' | 'wide' =
    'loose'
  @Input() set spacing(value: 'tight' | 'loose' | 'wide') {
    this._spacing = value
  }
  get spacing() {
    return this._spacing
  }

  @HostBinding('class.tiny') private _tiny = false
  @Input() set tiny(value: any) {
    this._tiny = coerceBooleanProperty(value)
  }
  get tiny() {
    return this._tiny
  }

  private _disableSort = false
  @Input() set disableSort(value: any) {
    this._disableSort = coerceBooleanProperty(value)
  }
  get disableSort() {
    return this._disableSort
  }
  @Output() headerValueChange = new EventEmitter<{
    id: string
    value: string
  }>()
  @Output() valueChange = new EventEmitter<SortTableValueChangeEvent<T>>()
  @Output() selectedChange = new EventEmitter<SelectionsChangeEvent>()
  @Output() checkboxChanges = new EventEmitter<CheckboxSelectionsChange>()
  @Output() addLayerClick = new EventEmitter<{
    id: string
    lossFilterValue: string
  }>()
  @Output() deleteLayerClick = new EventEmitter<{
    id: string
    lossFilterName: string
  }>()
  @Output() deleteSubjectivity = new EventEmitter<{
    id: string
    reinsurerId?: string
  }>()
  @Output() subjectivityClick = new EventEmitter<{ id: string }>()
  @Output() handleCustomClickFunction = new EventEmitter<SortTableRow<T>>()

  @Output() deleteAssignedLines = new EventEmitter<{
    id: string
  }>()

  @Output() rowClick = new EventEmitter<SortTableRowClickEvent<T>>()

  @Output() rowMouseover = new EventEmitter<{
    row: SortTableRow<T>
    event: MouseEvent
  }>()
  @Output() rowMouseout = new EventEmitter()

  @ViewChild(MatSort, { static: true }) sort: MatSort

  @Output() editedSubjectivity = new EventEmitter<{
    rows: Subjectivity[]
    id: string
  }>()

  @Output() pinColumnChange = new EventEmitter<SortTableColumnView<T>>()
  @Output() versionClick = new EventEmitter<DynamicMenuItem>()
  @Output() updatePlaceholder = new EventEmitter<string | undefined>()

  get hasCategories(): boolean {
    return this.columns.header2Names.length !== 0
  }

  get hasCategoryTemplates(): boolean {
    return this.namedTemplates ? this.namedTemplates.length > 0 : false
  }

  get selectAllTooltip(): string {
    const s =
      this.selections.count === this.dataSource.filteredData.length
        ? 'Deselect'
        : 'Select'
    return `${s} all`
  }

  get shownEmptyMessage(): string {
    return this.canShowEmptyMessage && this.dataSource.data.length === 0
      ? (this.emptyMessage ?? 'No data found.')
      : ''
  }

  get lossFilterButtonLabel() {
    return 'Select Loss Filter'
  }

  private _readonly = false
  @Input() set readonly(value: any) {
    this._readonly = coerceBooleanProperty(value)
  }
  get readonly() {
    return this._readonly
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private elementRef: ElementRef<HTMLElement>,
    private snackbar: MatSnackBar
  ) {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.columnDefs) {
      this.onColumnDefChanges(changes.columnDefs.currentValue)
    }
    if (changes.rows) {
      this.onRowChanges(changes.rows.currentValue)
    }
    this.tableDomCells = this.getTableDomCells()
  }

  ngAfterViewInit() {
    this.tableDomCells = this.getTableDomCells()
    this.tableParentElement = findScrollableParent(
      this.elementRef.nativeElement
    )
  }

  findFilter(name: string): boolean {
    return name.includes('filter=')
  }

  deleteRowTable(id: string, lossFilterName: string): void {
    this.deleteLayerClick.emit({
      id,
      lossFilterName
    })
  }

  ngOnInit(): void {
    if (this.customSort) {
      this.dataSource.sortData = this.customSort
    } else {
      if (this.defaultSort) {
        this.sort.sort(this.defaultSort)
      }
    }
    this.dataSource.sort = this.sort
    if (this.paginator) {
      this.dataSource.paginator = this.paginator
    }

    this.selections.changes
      .pipe(takeUntil(this._destroy$))
      // tslint:disable: deprecation
      .subscribe(value => this.selectedChange.emit(value))

    if (this.filterConfig) {
      this.filter = new SortTableFilter(this.filterConfig)
      this.filter.setTableModel(this.columns.views, this.dataSource.data)
    }

    this._modelChange$
      .pipe(takeUntil(this._destroy$), debounceTime(100))
      .subscribe(() => {
        if (this.filter) {
          this.filter.setTableModel(this.columns.views, this.dataSource.data)
        }
      })
  }

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
    this.cleanup()
  }

  showRowLoading(
    col: SortTableColumnView<T>,
    row: SortTableRow<T>
  ): boolean | undefined {
    return col.whenLoading === 'message' && row.loading
  }

  getHeaderCellClass(
    col: SortTableColumnView<T>,
    isCategory = false
  ): string[] {
    const cs = [...toArray(col.classes)]
    if (isCategory) {
      if (col.categoryClasses) {
        return [...cs, ...col.categoryClasses]
      }
      return cs
    }
    if (col.valueType) {
      cs.push(col.valueType)
    }
    if (this.selectAllColumnID) {
      cs.push('select-all')
    }
    if (this.spacing === 'wide') {
      cs.push('th-wide')
    }
    if (this.accentHeaderRow) {
      cs.push('primary-header')
    }
    if (col.alignRight) {
      cs.push('text-align-right')
    }
    return cs
  }

  getCellClass(
    col: SortTableColumnView<T>,
    row: SortTableRow<T>,
    extra?: string
  ): string[] {
    const cs = [...toArray(col.classes)]
    if (!this.showRowLoading(col, row) && col.valueType) {
      cs.push(col.valueType)
    }
    if (row.colorClassByColumn && row.colorClassByColumn[col.id]) {
      cs.push(row.colorClassByColumn[col.id] ?? '')
    }
    if (this.selectAllColumnID) {
      cs.push('select-all')
    }
    if (extra) {
      cs.push(extra)
    }
    if (col.alignRight) {
      cs.push('text-align-right')
    }
    return cs
  }

  getCellStyle(
    col: SortTableColumnView<T>,
    row?: SortTableRow<T>,
    ignoreOverride = false
  ): Record<string, string> {
    let style: Record<string, string> = {}
    const styleOverride = row ? col.bodyStyle : col.headerStyle
    if (!ignoreOverride && styleOverride) {
      style = { ...styleOverride }
    }
    if (style.minWidth == null) {
      style.minWidth = col.minWidth || col.categoryMinWidth || '3.5rem'
    }
    if (row && row.colorClassByColumn && row.colorClassByColumn[col.id]) {
      style.color = 'rgba(var(--rgb), var(--alpha))'
    }
    if (row && row.style && row.type !== CREDIT_ANIMATED_SCENARIOS) {
      style = row.style
    }
    if (col?.maxWidth != null) {
      style.maxWidth = col.maxWidth
    }
    if (col?.width != null) {
      style.width = col.width
    }
    return style
  }

  getCellSpanStyle(col: SortTableColumnView<T>): Record<string, string> {
    return this.getCellStyle(col, undefined, true)
  }

  getRowClass(
    row?: SortTableRow<T>
  ): Record<string, boolean | undefined> | string[] {
    const classTruthMap: Record<string, boolean | undefined> = {
      selected: this.getIsRowSelected(row),
      highlight: row?.highlight === true,
      distinguish: row?.distinguish === true,
    }
    if (row?.colorClass != null) {
      classTruthMap[row.colorClass] = true
    }
    return classTruthMap
  }

  getIsRowSelected(row?: SortTableRow<T>): boolean | undefined {
    return row && this.selections.dictionary[row.id]
  }

  getRowStyle(row?: SortTableRow<T>): RowStyle | undefined {
    const ignoreRowStyle = ['parent-row', 'sub-row']
    if (!row) {
      return
    }
    if (
      this.singleRowSelect &&
      this.getIsRowSelected(row) &&
      (this.singleRowFilter == null || this.singleRowFilter.includes(row.id))
    ) {
      return { cursor: 'default', background: 'var(--accent-strong-hint)' }
    }
    if (row.colorClass && !ignoreRowStyle.includes(row.colorClass)) {
      return { background: 'rgba(var(--rgb), var(--bg-alpha))' }
    }
  }

  updateFilter(): void {
    // The default `MatTableDataSource` class does not call the
    // `filterPredicate` if `filter` is falsy, so we ensure it is never
    // falsy (by using an obscure unicode char that is rarely used in data)
    // and handle it in our custom filter predicate (created above).
    const value = this.filterText || SortTableFilter.EMPTY_SYMBOL

    if (this.filter) {
      this.dataSource.filterPredicate = this.filter.createFilterPredicate(value)
    }
    this.dataSource.filter = value
    this.selections.update(this.dataSource.filteredData)
    values(this.checkboxes).forEach(c => c.update(this.dataSource.filteredData))
  }

  getColumnFilter(colID: string): SortTableColumnFilter {
    return this.filter?.getColumn(colID) as SortTableColumnFilter
  }

  pinColumn(col: SortTableColumnView<T>): void {
    col.pinned = !col.pinned
    this.pinColumnChange.emit(col)
  }

  onRowClick(row?: SortTableRow<T>, $event?: MouseEvent | TouchEvent): void {
    if (row && this.enableRowClickSelect) {
      // Check if the row click target was actually the select checkbox;
      // if so, do not trigger toggle since the checkbox handles it
      let isCheckbox = false
      let el = $event?.target as HTMLElement | null
      let isNotTriggerElement = false
      while (el) {
        if (el.classList.contains('select-checkbox')) {
          isCheckbox = true
        }
        // validated elements can possibly not trigger clicks
        if (el.classList.contains('ng-valid')) {
          isNotTriggerElement = true
        }
        el = el.parentElement
      }
      if (!isCheckbox) {
        if (
          this.singleRowSelect &&
          (this.singleRowFilter == null || !this.getIsRowSelected(row))
        ) {
          const prev = this.selections.dictionary[row.id]
          this.selections.clear()
          if (!prev) {
            this.selections.toggle(row.id, this.dataSource.filteredData)
          }
        }

        this.rowClick.emit({ row, isNotTriggerElement, $event })
      }
    }
  }

  onRowMouseover(row: SortTableRow<T>, event: MouseEvent): void {
    if (this.enableHover) {
      this.rowMouseover.emit({ row, event })
    }
  }
  onRowMouseout(): void {
    if (this.enableHover) {
      this.rowMouseout.emit()
    }
  }

  onValueChange({
    id,
    column,
    row,
    valueType,
    value,
  }: {
    id: string
    column: SortTableColumnView
    row: SortTableRow<T>
    valueType: MetricValueType
    value: any
  }): void {
    if (valueType !== 'checkbox') {
      this.valueChange.emit({ id, row, column, value })
    } else {
      this.checkboxes[column.id as string].dictionary[id] = value
      this.checkboxes[column.id as string].update(this.dataSource.filteredData)
      this.valueChange.emit({ id, row, column, value })
    }
  }

  trackByView: TrackByFunction<SortTableColumnView<T>> = (
    index,
    view
  ): number | keyof T => {
    return view ? view.id : index
  }

  trackByRow: TrackByFunction<SortTableRow<T>> = (
    index,
    row
  ): string | number => {
    return row ? row.id : index
  }

  onColumnDefChanges(value: SortTableColumnDef<T>[]): void {
    this.checkboxes = {}
    value.forEach(v => {
      if (v.valueType === 'checkbox') {
        this.checkboxes[v.id as string] = new Selections()
      }
    })
    const ids = keys(this.checkboxes)
    this.checkboxSubscriptions.forEach(s => s.unsubscribe())
    this.checkboxSubscriptions = []
    values(this.checkboxes).forEach((c, i) => {
      this.checkboxSubscriptions.push(
        c.changes
          .pipe(takeUntil(this._destroy$))
          .subscribe(s =>
            this.checkboxChanges.emit({ id: ids[i], selections: s.selections })
          )
      )
    })
    this.columns = toSortTableColumnsConfig(value)
    this._modelChange$.next()
  }

  onRowChanges(value: SortTableRow<T>[]): void {
    this.dataSource.data = value
    this._modelChange$.next()
    let changed = false
    const checkboxIDs = keys(this.checkboxes)

    this.dataSource.filteredData.forEach((row: any) => {
      for (const checkboxID of checkboxIDs) {
        if (
          row[checkboxID] === true &&
          !this.checkboxes[checkboxID].dictionary[row.id]
        ) {
          this.checkboxes[checkboxID].dictionary[row.id] = true
          changed = true
        } else if (
          row[checkboxID] === false &&
          this.checkboxes[checkboxID].dictionary[row.id]
        ) {
          this.checkboxes[checkboxID].dictionary[row.id] = false
          changed = true
        }
      }
      this.selections.dictionary[row.id] = row.include
    })
    this.selections.update(this.dataSource.filteredData, true)
    if (changed) {
      values(this.checkboxes).forEach(c =>
        c.update(this.dataSource.filteredData)
      )
    }

    // Only show an empty message after 2s to prevent the initial flash of
    // the empty message before loading starts
    this.canShowEmptyMessage = false
    setTimeout(() => {
      this.canShowEmptyMessage = true
      this.cdRef.markForCheck()
    }, 2000)
  }

  onLossFilterClick(description: string, id: string): void {
    if (this.lossFilters) {
      const name = this.lossFilters.find(
        f => f.description === description
      )?.name
      this.lossFilterValue = name ? name : 'all'
      this.trigger.closeMenu()
      this.addLayerClick.emit({
        id,
        lossFilterValue: this.lossFilterValue,
      })
    }
  }

  getCategoryTemplate(templateID: string): TemplateRef<any> | undefined {
    if (this.hasCategoryTemplates && templateID) {
      return this.namedTemplates.find(n => n.name === templateID)?.template
    }
  }

  getCol(col: SortTableColumnView, row: SortTableRowView) {
    if (this.isAssignedLines && col && row) {
      if (col.id === 'bureaus' && row.reinsurer) {
        const refs: any[] = []

        this.bureaus?.forEach(b => {
          if (b.tpRef === row.marketTpRef) {
            const obj = {
              value: b.bureau_stamp,
              viewValue: b.bureau_stamp,
            }
            refs.push(obj)
          }
        })
        if (!row.reinsurer.startsWith("Lloyd's")) {
          if (refs.length > 0) {
            const temp = refs.find(r => r.value === 'NA')
            if (!temp) {
              refs.push({ value: 'NA', viewValue: 'NA' })
            }
          } else {
            refs.push({ value: 'NA', viewValue: 'NA' })
          }
        }
        if (refs.length > 0) {
          col.references = refs
        }
      }
    }
    return col
  }

  onCheckboxSelectAll(column: SortTableColumnView, value: boolean): void {
    if (column.valueType === 'checkbox') {
      this.rows.forEach(row => {
        const id = row.id
        const columnId = column.id as string
        this.checkboxes[columnId].dictionary[id] = value
        this.checkboxes[columnId].update(this.dataSource.filteredData)
        this.valueChange.emit({ id, row, column, value })
      })
    }
  }

  onDelete({ id }: { id: string }): void {
    if (this.isAssignedLines) {
      this.deleteAssignedLines.emit({ id })
    } else {
      this.deleteSubjectivity.emit({ id })
    }
  }

  deleteSubjectivityFromTracking(row: any): void {
    this.deleteSubjectivity.emit({ id: row.id, reinsurerId: row.reinsurerId })
  }

  onSubjectivityClick(id: string): void {
    this.subjectivityClick.emit({ id })
  }

  openDialog(row: any, subjectivityOptions: string[]): void {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = { row, subjectivityOptions }
    const dialogRef = this.dialog.open(EditRowDialogComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      this.editedSubjectivity.emit(result)
    })
  }

  refresh(): void {
    this.cdRef.detectChanges()
  }

  onCustomClick(col: SortTableColumnDef<T>, row: SortTableRow<T>): void {
    if (col.customClick) {
      this.handleCustomClickFunction.emit(row)
    }
  }

  onHeaderValueChange($event: { id: string; value: string }): void {
    const { id, value } = $event
    this.headerValueChange.emit({ id, value })
  }

  onSpaceBarPress(event: KeyboardEvent): void {
    event.stopPropagation() // Prevent the space bar from sorting the column on header input change
  }

  showUserMessage(message: string, error: boolean): void {
    this.snackbar.openFromComponent(ErrorComponent, {
      data: errorPayload(message, { info: !error }),
      panelClass: error ? 'app-error' : 'app-success',
      duration: 8000,
    })
  }

  getTableDomCells(): HTMLTableDataCellElement[] {
    const array = Array.from(
      (this.elementRef.nativeElement as HTMLElement).querySelectorAll('td')
    )
    return array.filter(e => !e.classList.contains('mat-footer-cell'))
  }
}
