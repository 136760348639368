import { createAction, props } from '@ngrx/store'
import { ApplicationError } from '../../error/model/error'
import { CreditPortfolio } from '../model/credit-portfolio.model'
import { CreditStructure } from '../model/credit-structure.model'
import { StudyResponse } from '../../api/model/backend.model'
import { CreditStructureGroup } from '../model/credit-structure-group.model'
import { CreditSubmissionStructure } from '../model/credit-submission.model'
import { CreditModuleContext } from '../model/credit-routes.model'

export enum CreditActions {
  SetModuleContext = '[Credit] Set Module Context',
  FetchSelectedProgram = '[Credit] Fetch Selected Program',
  FetchSelectedProgramSuccess = '[Credit] Fetch Selected Program - Success',
  FetchSelectedProgramFailure = '[Credit] Fetch Selected Program - Failure',
  FetchPortfolioForSelectedProgram = '[Credit] Fetch Portfolio For Selected Program',
  FetchPortfolioForSelectedProgramSuccess = '[Credit] Fetch Portfolio For Selected Program - Success',
  FetchPortfolioForSelectedProgramFailure = '[Credit] Fetch Portfolio For Selected Program - Failure',
  FetchCreditStructuresForProgram = '[Credit] Fetch Credit Structures for Selected Program',
  FetchCreditStructuresForProgramSuccess = '[Credit] Fetch Credit Structures for Selected Program - Success',
  FetchCreditStructuresForProgramFailure = '[Credit] Fetch Credit Structures for Selected Program - Failure',
  PrepImageUpload = '[Credit] Prep Image Upload',
  ImageUpload = '[Credit] Image Upload',
  ImageUploadSuccess = '[Credit] Image Upload - Success',
  ImageUploadFailure = '[Credit] Image Upload - Failure',
  ToggleCreditStructureChecked = '[Credit] Toggle Credit Structure Checked',
  ToggleCreditStructureGroupChecked = '[Credit] Toggle Credit Structure Group Checked',
  ResetAllCheckedCreditStructuresAndGroups = '[Credit] Reset All Checked Credit Structures & Groups',
}

export const SetModuleContext = createAction(
  CreditActions.SetModuleContext,
  props<{ context: CreditModuleContext }>()
)

export const FetchSelectedProgram = createAction(
  CreditActions.FetchSelectedProgram,
  props<{ programId: string }>()
)
export const FetchSelectedProgramFailure = createAction(
  CreditActions.FetchSelectedProgramFailure,
  props<{ error: ApplicationError }>()
)
export const FetchSelectedProgramSuccess = createAction(
  CreditActions.FetchSelectedProgramSuccess,
  props<{ program: Partial<StudyResponse> }>()
)

export const FetchCreditPortfolioForSelectedProgram = createAction(
  CreditActions.FetchPortfolioForSelectedProgram,
  props<{ portfolioId: string }>()
)
export const FetchCreditPortfolioForSelectedProgramFailure = createAction(
  CreditActions.FetchPortfolioForSelectedProgramFailure,
  props<{ error: ApplicationError }>()
)
export const FetchCreditPortfolioForSelectedProgramSuccess = createAction(
  CreditActions.FetchPortfolioForSelectedProgramSuccess,
  props<{ portfolio: CreditPortfolio }>()
)

export const FetchCreditStructuresForProgram = createAction(
  CreditActions.FetchCreditStructuresForProgram,
  props<{ programId: string }>()
)
export const FetchCreditStructuresForProgramFailure = createAction(
  CreditActions.FetchCreditStructuresForProgramFailure,
  props<{ error: ApplicationError }>()
)
export const FetchCreditStructuresForProgramSuccess = createAction(
  CreditActions.FetchCreditStructuresForProgramSuccess,
  props<{ creditStructures: CreditStructure[] }>()
)

export const CreditPrepImageUpload = createAction(
  CreditActions.ImageUpload,
  props<{ imageData: Blob }>()
)
export const CreditImageUpload = createAction(
  CreditActions.ImageUpload,
  props<{ imageData: Blob }>()
)
export const CreditImageUploadFailure = createAction(
  CreditActions.ImageUploadFailure,
  props<{ error: ApplicationError }>()
)
export const CreditImageUploadSuccess = createAction(
  CreditActions.ImageUploadSuccess
)

export const ToggleCreditStructureChecked = createAction(
  CreditActions.ToggleCreditStructureChecked,
  props<{ creditStructure: CreditStructure }>()
)
export const ToggleCreditStructureGroupChecked = createAction(
  CreditActions.ToggleCreditStructureGroupChecked,
  props<{ creditStructureGroup: CreditStructureGroup }>()
)

export const ResetAllCheckedCreditStructuresAndGroups = createAction(
  CreditActions.ResetAllCheckedCreditStructuresAndGroups
)
