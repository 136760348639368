import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import {
  PricingCurveContextTypes,
  PricingCurveStatus,
} from '../../../model/pricing-curve.model'
import { CdkDragDrop } from '@angular/cdk/drag-drop'
import { AppState } from 'src/app/core/store'
import { Store } from '@ngrx/store'
import * as fromActions from '../../../store/pricing-curve.actions'
import { PricingCurve } from '../../../model/pricing-curve'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-pricing-curve-details-container',
  styleUrls: ['./pricing-curve-details-container.component.scss'],
  templateUrl: './pricing-curve-details-container.component.html',
})
export class PricingCurveDetailsContainerComponent {
  @Input() pricingCurves: PricingCurve[]
  @Input() status: PricingCurveStatus
  @Input() context: PricingCurveContextTypes
  @Input() readonly: boolean
  @Input() isAdmin: boolean

  constructor(private store: Store<AppState>) {}

  drop(event: CdkDragDrop<string[]>) {
    const previousIndex = event.previousIndex
    const newIndex = event.currentIndex

    if (previousIndex !== newIndex) {
      this.store.dispatch(
        fromActions.reorderCards({
          previousIndex,
          newIndex,
        })
      )
    }
  }

  trackByID(index: number, entity: PricingCurve) {
    return entity && entity.id ? entity.id : index
  }
}
