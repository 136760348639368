import { Reference } from '@shared/layer-property/layer-property.component'
import {
  SortTableColumnDef,
  SortTableRow,
} from '@shared/sort-table/sort-table.model'
import { isLayerMetricApplicable } from '../../analysis/model/layer-metric-applicability'
import LayerMetricDefs, {
  LayerMetricDef,
  LayerMetricDefMap,
  LayerMetricDefResolved,
  resolveLayerMetricDef,
} from '../../analysis/model/layer-metric-defs'
import { LayerViewValues } from '../../analysis/model/layer-view'
import {
  AssignedLines,
  SlidingScale,
  Subjectivity,
  SubjectivityTracking,
  SubjectivityOptions,
  Section,
} from '../models/quote.model'

interface IQuotePanelDef {
  id: keyof LayerMetricDefMap
  showUndefinedUnlessTypes?: string[]
  quoteDecline?: boolean
  ecoApply?: boolean
  xplApply?: boolean
  bothApply?: boolean
  references?: Reference[]
  exportID?: string
}

export type QuotePanelDef = Partial<LayerMetricDef> & IQuotePanelDef

export type QuotePanelDefResolved = LayerMetricDefResolved & IQuotePanelDef

export const QUOTE_PANEL_DEFS: QuotePanelDef[] = [
  {
    id: 'subjectPremium',
    quoteDecline: false,
    exportID: 'subjectPremiumToggle',
  },
  {
    id: 'quoteRiskAttachment',
    quoteDecline: false,
    exportID: 'riskAttachmentToggle',
  },
  { id: 'quoteRiskLimit', quoteDecline: false, exportID: 'riskLimitToggle' },
  { id: 'quoteTrigger', exportID: 'triggerToggle' },
  {
    id: 'quoteOccurrenceLimit',
    quoteDecline: false,
    exportID: 'occurrenceLimitToggle',
  },
  { id: 'quotePayout', exportID: 'payoutToggle' },
  { id: 'quoteNth', exportID: 'nthToggle' },
  {
    id: 'limitApplication',
    quoteDecline: false,
    exportID: 'limitApplicationToggle',
  },
  {
    id: 'quoteOccurrenceAttachment',
    quoteDecline: false,
    exportID: 'occurrenceAttachmentToggle',
  },
  {
    id: 'quoteCedingCommission',
    quoteDecline: false,
    exportID: 'cedingCommissionToggle',
  },
  {
    id: 'quotePmpm',
    quoteDecline: false,
    exportID: 'pmpmToggle',
  },
  {
    id: 'quoteFranchiseDeductible',
    quoteDecline: false,
    exportID: 'franchiseDeductibleToggle',
  },
  {
    id: 'quoteAggregateLimit',
    quoteDecline: false,
    exportID: 'aggregateLimitToggle',
  },
  {
    id: 'quoteTopOccurrenceLimit',
    quoteDecline: false,
    exportID: 'quoteTopOccurrenceLimitToggle',
  },
  {
    id: 'quoteTopOccurrenceAttachment',
    quoteDecline: false,
    exportID: 'quoteTopOccurrenceAttachmentToggle',
  },
  {
    id: 'quoteDropOccurrenceLimit',
    quoteDecline: false,
    exportID: 'quoteDropOccurrenceLimitToggle',
  },
  {
    id: 'quoteDropOccurrenceAttachment',
    quoteDecline: false,
    exportID: 'quoteDropOccurrenceAttachmentToggle',
  },
  {
    id: 'quoteAggregateAttachment',
    quoteDecline: false,
    exportID: 'aggregateAttachmentToggle',
  },
  {
    id: 'quoteAggregateLimitTop',
    quoteDecline: false,
    exportID: 'quoteAggregateLimitTopToggle',
  },
  {
    id: 'quoteAggregateLimitDrop',
    quoteDecline: false,
    exportID: 'quoteAggregateLimitDropToggle',
  },
  {
    id: 'quoteOfferedPercentage',
    quoteDecline: false,
    exportID: 'quoteOfferedPercentageToggle',
  },
  {
    id: 'quoteRolPercentage',
    showUndefinedUnlessTypes: ['noncat_xl', 'noncat_indxl', 'noncat_ag'],
    quoteDecline: false,
    exportID: 'rolPercentageToggle',
  },
  {
    id: 'quoteRateOnLineSubject',
    quoteDecline: false,
    exportID: 'rateOnLineSubjectToggle',
  },
  { id: 'quotePremium', quoteDecline: false, exportID: 'premiumToggle' },
  { id: 'quoteEffectiveDate', exportID: 'effectiveDateToggle' },
  { id: 'quoteExpirationDate', exportID: 'expirationDateToggle' },
  {
    id: 'quoteCessionsBasedPremium',
    quoteDecline: false,
    exportID: 'quoteCessionsBasedPremiumToggle',
  },
  {
    id: 'minimumPremiumPercentage',
    quoteDecline: false,
    exportID: 'minimumPremiumPercentageToggle',
  },
  {
    id: 'minimumPremium',
    quoteDecline: false,
    exportID: 'minimumPremiumToggle',
  },
  {
    id: 'quoteReinstatements',
    quoteDecline: false,
    exportID: 'reinstatementsToggle',
  },
  {
    id: 'quoteCessionPercentage',
    quoteDecline: false,
    exportID: 'quoteCessionPercentageToggle',
  },
  {
    id: 'depositPremiumPercentage',
    quoteDecline: false,
    exportID: 'depositPremiumPercentageToggle',
  },
  {
    id: 'quoteDepositPremium',
    quoteDecline: false,
    exportID: 'quoteDepositPremiumToggle',
  },
  { id: 'lossRatioCap', quoteDecline: false, exportID: 'lossRatioCapToggle' },
  {
    id: 'lossRatioCapPercentage',
    quoteDecline: false,
    exportID: 'lossRatioCapPercentageToggle',
  },
  {
    id: 'lossCapApplication',
    quoteDecline: false,
    exportID: 'lossCapApplicationToggle',
  },
  {
    id: 'quoteMinPercentage',
    quoteDecline: false,
    exportID: 'quoteMinPercentageToggle',
  },
  {
    id: 'quoteOfferedLimit',
    quoteDecline: false,
    exportID: 'quoteOfferedLimitToggle',
  },

  {
    id: 'quoteReinsurerExpenseProvision',
    exportID: 'reinsurerExpenseProvisionToggle',
    quoteDecline: false,
  },
  {
    id: 'brokerageCommission',
    quoteDecline: false,
    exportID: 'brokerageCommissionToggle',
  },
  {
    id: 'brokerageType',
    quoteDecline: false,
    references: [
      { value: 'Gross Ceded Premium', viewValue: 'Gross Ceded Premium' },
      { value: 'Net Ceded Premium', viewValue: 'Net Ceded Premium' },
    ],
    exportID: 'brokerageTypeToggle',
  },
  {
    id: 'brokerageRIPCommission',
    quoteDecline: false,
    exportID: 'brokerageRIPCommissionToggle',
  },
  {
    id: 'orderPercent',
    quoteDecline: false,
    exportID: 'orderPercentToggle',
  },
  {
    id: 'quoteMinRateSubject',
    quoteDecline: false,
    exportID: 'quoteMinRateSubjectToggle',
  },
  {
    id: 'quoteMaxRateSubject',
    quoteDecline: false,
    exportID: 'quoteMaxRateSubjectToggle',
  },
  {
    id: 'quoteMinRatePmpm',
    quoteDecline: false,
    exportID: 'quoteMinRatePmpmToggle',
  },
  {
    id: 'quoteMaxRatePmpm',
    quoteDecline: false,
    exportID: 'quoteMaxRatePmpmToggle',
  },
  {
    id: 'quoteSwingRate',
    quoteDecline: false,
    exportID: 'quoteSwingRateToggle',
  },
  {
    id: 'quoteSwingBasis',
    quoteDecline: false,
    references: [
      { value: 'In addition to Min', viewValue: 'In addition to Min' },
      { value: 'Subject to Min', viewValue: 'Subject to Min' },
    ],
    exportID: 'quoteSwingBasisToggle',
  },
  {
    id: 'quoteIndexation',
    quoteDecline: false,
    references: [
      { value: 'Full', viewValue: 'Full' },
      { value: 'Severe', viewValue: 'Severe' },
      { value: 'Franchise', viewValue: 'Franchise' },
    ],
    exportID: 'quoteIndexationToggle',
  },
  {
    id: 'quoteFixedIndexValue',
    quoteDecline: false,
    exportID: 'quoteFixedIndexValueToggle',
  },
  {
    id: 'quoteSicOrFranchise',
    quoteDecline: false,
    exportID: 'quoteSicOrFranchiseToggle',
  },

  {
    id: 'quoteProfitCommission',
    quoteDecline: false,
    exportID: 'quoteProfitCommissionToggle',
  },

  { id: 'quoteExpiryDate', exportID: 'expiryDateToggle' },
  { id: 'underwriter', quoteDecline: false, exportID: 'underwriterToggle' },
  { id: 'maolLimit', quoteDecline: false, exportID: 'maolLimitToggle' },
  {
    id: 'terrorismAggSubLimit',
    quoteDecline: false,
    exportID: 'terrorismAggSubLimitToggle',
  },
  { id: 'laeCap', quoteDecline: false, exportID: 'laeCapToggle' },
  {
    id: 'laeTreatment',
    quoteDecline: false,
    references: [
      { value: 'W/IN Limit', viewValue: 'W/IN Limit' },
      { value: 'In addition to Limit', viewValue: 'In addition to Limit' },
    ],
    exportID: 'laeTreatmentToggle',
  },
  {
    id: 'adjustmentBasis',
    quoteDecline: false,
    references: [
      { value: 'Subject Premium Income', viewValue: 'Subject Premium Income' },
      { value: 'Total Insured Value', viewValue: 'Total Insured Value' },
      { value: 'Total Insured Limit', viewValue: 'Total Insured Limit' },
      { value: 'Modelled Loss', viewValue: 'Modelled Loss' },
      { value: 'Modelled Exposure', viewValue: 'Modelled Exposure' },
      {
        value: 'Flat Rate (No Adjustment)',
        viewValue: 'Flat Rate (No Adjustment)',
      },
    ],
    exportID: 'adjustmentBasisToggle',
  },
  {
    id: 'xplEcoDropdown',
    quoteDecline: false,
    references: [
      { value: 'Both ECO & XPL apply', viewValue: 'Both ECO & XPL apply' },
      { value: 'ECO applies', viewValue: 'ECO applies' },
      { value: 'XPL applies', viewValue: 'XPL applies' },
      { value: 'Neither apply', viewValue: 'Neither apply' },
    ],
    exportID: 'xplEcoDropdownToggle',
  },
  {
    id: 'xplEcoConditions',
    quoteDecline: false,
    bothApply: true,
    exportID: 'xplEcoConditionsToggle',
  },
  {
    id: 'ecoCoveragePct',
    quoteDecline: false,
    ecoApply: true,
    exportID: 'ecoCoveragePctToggle',
  },
  {
    id: 'xplCoveragePct',
    quoteDecline: false,
    xplApply: true,
    exportID: 'xplCoveragePctToggle',
  },
  { id: 'clashPremium', quoteDecline: false, exportID: 'clashPremiumToggle' },
  {
    id: 'profitShareCommission',
    quoteDecline: false,
    exportID: 'profitShareCommissionToggle',
  },
  {
    id: 'profitShareMinRate',
    quoteDecline: false,
    exportID: 'profitShareMinRateToggle',
  },
  {
    id: 'profitShareMaxRate',
    quoteDecline: false,
    exportID: 'profitShareMaxRateToggle',
  },
  { id: 'otherFeatures', quoteDecline: false, exportID: 'otherFeaturesToggle' },
  { id: 'coverageBasis', quoteDecline: false, exportID: 'coverageBasisToggle' },
  {
    id: 'indexationtext',
    quoteDecline: false,
    exportID: 'indexationtextToggle',
  },

  // {
  //   id: 'quoteDepositPremiumCalc',
  //   quoteDecline: false,
  //   editable: false,
  // },

  {
    id: 'slidingComm',
    quoteDecline: false,
    exportID: 'slidingCommToggle',
  },
  {
    id: 'territorialScope',
    quoteDecline: false,
    references: [
      { value: 'Non-Peak', viewValue: 'Non-Peak' },
      { value: 'Peak (inc US)', viewValue: 'Peak (inc US)' },
      { value: 'Peak (exl US)', viewValue: 'Peak (exl US)' },
      { value: 'US Only', viewValue: 'US Only' },
      { value: 'Worldwide', viewValue: 'Worldwide' },
      { value: 'Worldwide ex US', viewValue: 'Worldwide ex US' },
    ],
    exportID: 'territorialScopeToggle',
  },
  {
    id: 'vendor',
    quoteDecline: false,
    references: [
      { value: 'AIR', viewValue: 'AIR' },
      { value: 'Client model', viewValue: 'Client model' },
      { value: 'Custom', viewValue: 'Custom' },
      { value: 'KCC', viewValue: 'KCC' },
      { value: 'RMS', viewValue: 'RMS' },
      { value: 'Weighted', viewValue: 'Weighted' },
    ],
    exportID: 'vendorToggle',
  },
  {
    id: 'feeOrBrokerage',
    quoteDecline: false,
    references: [
      { value: 'Fee', viewValue: 'Fee' },
      { value: 'Brokerage', viewValue: 'Brokerage' },
    ],
    exportID: 'feeOrBrokerageToggle',
  },
  {
    id: 'structureFX',
    quoteDecline: false,
    references: [],
    exportID: 'structureFXToggle',
  },
  {
    id: 'premiumFX',
    quoteDecline: false,
    references: [],
    exportID: 'premiumFXToggle',
  },
  {
    id: 'premiumFXToUSD',
    quoteDecline: false,
    references: [],
    exportID: 'premiumFXToUSDToggle',
  },
  {
    id: 'layerCategory',
    references: [
      { value: 'Excess of Loss', viewValue: 'Excess of Loss' },
      {
        value: 'Excess of Loss Subsequent Event',
        viewValue: 'Excess of Loss Subsequent Event',
      },
      { value: 'Risk XOL', viewValue: 'Risk XOL' },
      {
        value: 'Risk XOL Subsequent Event',
        viewValue: 'Risk XOL Subsequent Event',
      },
      { value: 'Multi-section', viewValue: 'Multi-section' },
      {
        value: 'Multi-section Subsequent Event',
        viewValue: 'Multi-section Subsequent Event',
      },
      { value: 'Index XOL', viewValue: 'Index XOL' },
      {
        value: 'Index XOL Subsequent Event',
        viewValue: 'Index XOL Subsequent Event',
      },
      { value: 'Swing Rated', viewValue: 'Swing Rated' },
      {
        value: 'Swing Rated Subsequent Event',
        viewValue: 'Swing Rated Subsequent Event',
      },
      { value: 'Cascading', viewValue: 'Cascading' },
      {
        value: 'Cascading Subsequent Event',
        viewValue: 'Cascading Subsequent Event',
      },
      { value: 'Aggregate', viewValue: 'Aggregate' },
      { value: 'Aggregate ILW', viewValue: 'Aggregate ILW' },
      { value: 'Aggregate Risk', viewValue: 'Aggregate Risk' },
      { value: 'Quota Share', viewValue: 'Quota Share' },
      { value: 'Top & Drop', viewValue: 'Top & Drop' },
      { value: 'Binary ILW', viewValue: 'Binary ILW' },
      {
        value: 'Binary ILW Subsequent Event',
        viewValue: 'Binary ILW Subsequent Event',
      },
      { value: 'Pro Rata ILW', viewValue: 'Pro Rata ILW' },
      {
        value: 'Pro Rata ILW Subsequent Event',
        viewValue: 'Pro Rata ILW Subsequent Event',
      },
      { value: 'AHL', viewValue: 'AHL' },
      { value: 'Shared Limit', viewValue: 'Shared Limit' },
      { value: 'Ignore', viewValue: 'Ignore' },
    ],
    exportID: 'layerCategoryToggle',
  },
  {
    id: 'layerClass',
    exportID: 'layerClassToggle',
  },
  {
    id: 'layerSubClass',
    exportID: 'layerClassToggle',
  },
  {
    id: 'excludeFromPricingCurve',
    quoteDecline: false,
    exportID: 'excludeFromPricingCurveToggle',
  },
  {
    id: 'perils',
    quoteDecline: false,
    references: [
      { value: 'All Wind', viewValue: 'All Wind' },
      {
        value: 'ANP (All Natural Perils)',
        viewValue: 'ANP (All Natural Perils)',
      },
      { value: 'Earthquake', viewValue: 'Earthquake' },
      { value: 'Flood', viewValue: 'Flood' },
      { value: 'Hurricane', viewValue: 'Hurricane' },
      { value: 'Named Perils', viewValue: 'Named Perils' },
      { value: 'Non-natural', viewValue: 'Non-natural' },
      { value: 'Risk', viewValue: 'Risk' },
      { value: 'SCS', viewValue: 'SCS' },
      { value: 'Terror', viewValue: 'Terror' },
      { value: 'Wildfire', viewValue: 'Wildfire' },
      { value: 'Windstorm', viewValue: 'Windstorm' },
      { value: 'Winterstorm', viewValue: 'Winterstorm' },
    ],
    exportID: 'perilsToggle',
  },
  {
    id: 'lossImpactedFromPreviousYear',
    quoteDecline: false,
    references: [
      { value: 'Y', viewValue: 'Y' },
      { value: 'N', viewValue: 'N' },
    ],
    exportID: 'lossImpactedFromPreviousYearToggle',
  },
  {
    id: 'cedingCommissionBasis',
    quoteDecline: false,
    references: [
      {
        value: 'Gross of Acquisition Costs',
        viewValue: 'Gross of Acquisition Costs',
      },
      {
        value: 'Net of Acquisition Costs',
        viewValue: 'Net of Acquisition Costs',
      },
    ],
    exportID: 'cedingCommissionBasisToggle',
  },
  {
    id: 'modelVersion',
    quoteDecline: false,
    exportID: 'modelVersionToggle',
  },
  {
    id: 'fee',
    quoteDecline: false,
    exportID: 'feeToggle',
  },
  {
    id: 'rebate',
    quoteDecline: false,
    exportID: 'rebateToggle',
  },
  {
    id: 'quoteProbabilityOfAttach',
    quoteDecline: false,
    exportID: 'quoteProbabilityOfAttachToggle',
  },
  {
    id: 'quoteProbabilityOfExhaust',
    quoteDecline: false,
    exportID: 'quoteProbabilityOfExhaustToggle',
  },
  {
    id: 'quoteIndex',
    quoteDecline: false,
    references: [
      { value: 'PCS', viewValue: 'PCS' },
      { value: 'Perils AG', viewValue: 'Perils AG' },
      { value: 'CLIX', viewValue: 'CLIX' },
    ],
    exportID: 'quoteIndexToggle',
  },
  {
    id: 'quoteLossOnLine',
    quoteDecline: false,
    exportID: 'quoteLossOnLineToggle',
    editable: true,
  },
  {
    id: 'quoteExpectedCededLoss',
    quoteDecline: false,
    exportID: 'quoteExpectedCededLossToggle',
  },
  {
    id: 'quoteExpectedCededPremium',
    quoteDecline: false,
    exportID: 'quoteExpectedCededPremiumToggle',
  },

  {
    id: 'totalQuoteExpectedCededLoss',
    quoteDecline: false,
    exportID: 'totalQuoteExpectedCededLossToggle',
    editable: false,
  },

  {
    id: 'totalQuoteExpectedCededPremium',
    quoteDecline: false,
    exportID: 'totalQuoteExpectedCededPremiumToggle',
    editable: false,
  }
]

export const QUOTE_PANEL_DEFS_TOGGLE_MAP: {
  [key: string]: keyof Section
} = {
  quoteOccurrenceLimit: 'occurrenceLimitToggle',
  quoteTopOccurrenceLimit: 'quoteTopOccurrenceLimitToggle',
  quoteOccurrenceAttachment: 'occurrenceAttachmentToggle',
  quoteTopOccurrenceAttachment: 'quoteTopOccurrenceAttachmentToggle',
  quoteAggregateLimitTop: 'quoteAggregateLimitTopToggle',
  quoteDropOccurrenceAttachment: 'quoteDropOccurrenceAttachmentToggle',
  quoteDropOccurrenceLimit: 'quoteDropOccurrenceLimitToggle',
  quoteAggregateLimitDrop: 'quoteAggregateLimitDropToggle',
  quoteRiskLimit: 'riskLimitToggle',
  quoteRiskAttachment: 'riskAttachmentToggle',
  quoteFranchiseDeductible: 'franchiseDeductibleToggle',
  quoteReinstatements: 'reinstatementsToggle',
  quoteAggregateLimit: 'aggregateLimitToggle',
  quoteAggregateAttachment: 'aggregateAttachmentToggle',
  quoteMinPercentage: 'quoteMinPercentageToggle',
  quoteOfferedPercentage: 'quoteOfferedPercentageToggle',
  quoteOfferedLimit: 'quoteOfferedLimitToggle',
  quoteCedingCommission: 'cedingCommissionToggle',
  quoteReinsurerExpenseProvision: 'reinsurerExpenseProvisionToggle',
  quoteProfitCommission: 'profitCommissionToggle',
  quoteRolPercentage: 'rolPercentageToggle',
  quoteRateOnLineSubject: 'rateOnLineSubjectToggle',
  quotePmpm: 'pmpmToggle',
  quotePremium: 'premiumToggle',
  underwriter: 'underwriterToggle',
  maolLimit: 'maolLimitToggle',
  terrorismAggSubLimit: 'terrorismAggSubLimitToggle',
  lossRatioCap: 'lossRatioCapToggle',
  lossRatioCapPercentage: 'lossRatioCapPercentageToggle',
  lossCapApplication: 'lossCapApplicationToggle',
  laeCap: 'laeCapToggle',
  laeTreatment: 'laeTreatmentToggle',
  adjustmentBasis: 'adjustmentBasisToggle',
  xplEcoDropdown: 'xplEcoDropdownToggle',
  xplEcoConditions: 'xplEcoConditionsToggle',
  ecoCoveragePct: 'ecoCoveragePctToggle',
  xplCoveragePct: 'xplCoveragePctToggle',
  subjectPremium: 'subjectPremiumToggle',
  minimumPremiumPercentage: 'minimumPremiumPercentageToggle',
  depositPremiumPercentage: 'depositPremiumPercentageToggle',
  minimumPremium: 'minimumPremiumToggle',
  clashPremium: 'clashPremiumToggle',
  profitShareCommission: 'profitShareCommissionToggle',
  profitShareMinRate: 'profitShareMinRateToggle',
  profitShareMaxRate: 'profitShareMaxRateToggle',
  brokerageCommission: 'brokerageCommissionToggle',
  brokerageType: 'brokerageTypeToggle',
  brokerageRIPCommission: 'brokerageRIPCommissionToggle',
  orderPercent: 'orderPercentToggle',
  otherFeatures: 'otherFeaturesToggle',
  coverageBasis: 'coverageBasisToggle',
  indexationtext: 'indexationtextToggle',
  quoteDepositPremium: 'quoteDepositPremiumToggle',
  limitApplication: 'limitApplicationToggle',
  quoteCessionsBasedPremium: 'quoteCessionsBasedPremiumToggle',
  quoteMinRateSubject: 'quoteMinRateSubjectToggle',
  quoteMaxRateSubject: 'quoteMaxRateSubjectToggle',
  quoteMinRatePmpm: 'quoteMinRatePmpmToggle',
  quoteMaxRatePmpm: 'quoteMaxRatePmpmToggle',
  quoteSwingRate: 'quoteSwingRateToggle',
  quoteSwingBasis: 'quoteSwingBasisToggle',
  quoteIndexation: 'quoteIndexationToggle',
  quoteFixedIndexValue: 'quoteFixedIndexValueToggle',
  quoteSicOrFranchise: 'quoteSicOrFranchiseToggle',
  quoteExpectedCededLoss: 'quoteExpectedCededLossToggle',
  quoteExpectedCededPremium: 'quoteExpectedCededPremiumToggle',
  slidingComm: 'slidingCommToggle',
  structureFX: 'structureFXToggle',
  premiumFX: 'premiumFXToggle',
  premiumFXToUSD: 'premiumFXToUSDToggle',
  territorialScope: 'territorialScopeToggle',
  vendor: 'vendorToggle',
  feeOrBrokerage: 'feeOrBrokerageToggle',
  layerCategory: 'layerCategoryToggle',
  layerClass: 'layerClassToggle',
  excludeFromPricingCurve: 'excludeFromPricingCurveToggle',
  perils: 'perilsToggle',
  modelVersion: 'modelVersionToggle',
  lossImpactedFromPreviousYear: 'lossImpactedFromPreviousYearToggle',
  cedingCommissionBasis: 'cedingCommissionBasisToggle',
  fee: 'feeToggle',
  rebate: 'rebateToggle',
  quoteProbabilityOfAttach: 'quoteProbabilityOfAttachToggle',
  quoteProbabilityOfExhaust: 'quoteProbabilityOfExhaustToggle',
  quoteEffectiveDate: 'effectiveDateToggle',
  quoteExpirationDate: 'expirationDateToggle',
  quoteExpiryDate: 'expiryDateToggle',
  quotePayout: 'payoutToggle',
  quoteTrigger: 'triggerToggle',
  quoteNth: 'nthToggle',
  quoteIndex: 'quoteIndexToggle',
  quoteLossOnLine: 'quoteLossOnLineToggle'
}

const mergeMetricDef =
  (defs: LayerMetricDefMap, values: LayerViewValues) =>
  (propDef: QuotePanelDef): QuotePanelDefResolved => {
    let mergedDef: LayerMetricDef & IQuotePanelDef
    const def = defs[propDef.id as keyof LayerMetricDefMap]
    if (!def) {
      throw new Error(`Cannot find quote panel definition for '${propDef.id}`)
    }
    // if (def.label === 'Premium') {
    //   if (
    //     (values.type === 'cat_ag' && values.subtype === 'feeder') ||
    //     values.sharedLayerID !== ''
    //   ) {
    //     def.editable = false
    //   } else {
    //     def.editable = true
    //   }
    // }
    mergedDef = { ...def, ...propDef }

    const resolved = resolveLayerMetricDef(mergedDef, values)
    return resolved
  }

export default function getQuotePanelDefs(
  values: LayerViewValues,
  decline: boolean,
  xplEcoType: string,
  fotMaster: boolean,
  isSharedLimit?: boolean
): QuotePanelDefResolved[] {
  const defs = QUOTE_PANEL_DEFS.map(mergeMetricDef(LayerMetricDefs, values))
    .filter(def => isSharedLimit && (def.id === 'quoteRateOnLineSubject' || def.id === 'quoteRolPercentage') ? true : isLayerMetricApplicable(values, def))
    .filter(def => {
      if (xplEcoType === 'Neither apply') {
        return !def.ecoApply && !def.xplApply && !def.bothApply
      } else if (xplEcoType === 'XPL applies') {
        return !def.ecoApply
      } else if (xplEcoType === 'ECO applies') {
        return !def.xplApply
      } else {
        return def
      }
    })
    .filter(def => {
      if (fotMaster) {
        if (
          // def.id === 'quoteSignedPercentage' ||
          def.id === 'quoteMinPercentage' ||
          def.id === 'quoteOfferedPercentage'
        ) {
          def.editable = false
          return def
        } else {
          return def
        }
      } else {
        return def
      }
    })
    .filter(def => !def.hide)
    .filter(def => {
      if (decline) {
        return !def.quoteDecline
      } else {
        return def
      }
    })
  return defs
}

export interface SubjectivityColumnDef
  extends Partial<SortTableColumnDef<Subjectivity>> {
  id: keyof Subjectivity
}
const subjectivityDropDownReferences: Reference[] = []

SubjectivityOptions.forEach((option: string) => {
  const subType: Reference = { value: option, viewValue: option }
  subjectivityDropDownReferences.push(subType)
})

export const subjectivityDef: SubjectivityColumnDef[] = [
  {
    id: 'type',
    minWidth: '8rem',
    label: 'Select Type',
    valueType: 'dropdown',
    whenLoading: 'show',
    editable: true,
    references: subjectivityDropDownReferences,
  },
  {
    id: 'reinsCommentary',
    minWidth: '9rem',
    label: 'Reinsurer Commentary',
    valueType: 'textarea',
    whenLoading: 'show',
    editable: true,
  },
  {
    id: 'deadline',
    minWidth: '1rem',
    label: 'Deadline',
    valueType: 'date',
    whenLoading: 'show',
    editable: true,
  },
  {
    id: 'brokerComments',
    minWidth: '9rem',
    label: 'Broker Comments',
    valueType: 'textarea',
    whenLoading: 'show',
    editable: true,
  },
  // {
  //   id: 'reinsurerResponse',
  //   minWidth: '9rem',
  //   label: 'Reinsurer Response',
  //   valueType: 'textarea',
  //   whenLoading: 'show',
  //   editable: true,
  // },
  // {
  //   id: 'reinsResponseStatus',
  //   minWidth: '9rem',
  //   label: 'Reinsurer Response Status',
  //   valueType: 'textarea',
  //   whenLoading: 'show',
  //   editable: true,
  // },
  // {
  //   id: 'acceptedLineCond',
  //   minWidth: '9rem',
  //   label: 'Accepted Line Condition',
  //   valueType: 'textarea',
  //   whenLoading: 'message',
  //   editable: true,
  // },
  // {
  //   id: 'acceptedContractWord',
  //   label: 'Accepted Contract Wording',
  //   valueType: 'textarea',
  //   minWidth: '9rem',
  //   editable: true,
  // },
  {
    id: 'clientAccepted',
    label: 'Client Accepted?',
    valueType: 'dropdown',
    minWidth: '4.5rem',
    editable: true,
    references: [
      { value: 'Yes', viewValue: 'Yes' },
      { value: 'No', viewValue: 'No' },
    ],
  },
  {
    id: 'applyToStructureForSameMarket',
    label: 'Applies to Market for All Layers',
    valueType: 'checkbox',
    editable: true,
    deleteButton: true,
  },
]

export interface SlidingScaleColumnDef
  extends Partial<SortTableColumnDef<SlidingScale>> {
  id: keyof SlidingScale
}

export const slidingScaleDef: SlidingScaleColumnDef[] = [
  {
    id: 'type',
    maxWidth: '1rem',
    label: '',
    valueType: 'text',
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'commission',
    maxWidth: '1rem',
    label: 'Commi- ssion',
    valueType: 'percentage',
    decimals: 1,
    whenLoading: 'show',
    editable: true,
  },
  {
    id: 'lossRatio',
    maxWidth: '1rem',
    label: 'Loss Ratio',
    valueType: 'percentage',
    decimals: 1,
    whenLoading: 'show',
    editable: true,
  },
  {
    id: 'slideRate',
    maxWidth: '1rem',
    label: 'Slide Rate',
    valueType: 'percentage',
    decimals: 1,
    whenLoading: 'show',
    editable: false,
  },
]

export interface SubjectivityTrackingColumnDef
  extends Partial<SortTableColumnDef<SubjectivityTracking>> {
  id: keyof SubjectivityTracking
}

export const subjectivityTrackingDef: SubjectivityTrackingColumnDef[] = [
  {
    id: 'id',
    label: 'Id',
    valueType: 'numeric',
    whenLoading: 'show',
    editable: false,
    hide: true,
  },
  {
    id: 'tpRef',
    label: 'TPRef',
    valueType: 'text',
    editable: false,
    hide: true,
  },
  {
    id: 'reinsurerName',
    minWidth: '8rem',
    label: 'Reinsurer Name',
    valueType: 'textarea',
    whenLoading: 'show',
    editable: false,
    customClick: true
  },
  {
    id: 'layerID',
    minWidth: '8rem',
    label: 'Section',
    valueType: 'text',
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'type',
    minWidth: '8rem',
    label: 'Select Type',
    valueType: 'textarea',
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'reinsCommentary',
    minWidth: '9rem',
    label: 'Reinsurer Commentary',
    valueType: 'textarea',
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'brokerComments',
    minWidth: '9rem',
    label: 'Broker Comments',
    valueType: 'textarea',
    whenLoading: 'show',
    editable: false,
  },
  // {
  //   id: 'reinsurerResponse',
  //   minWidth: '9rem',
  //   label: 'Reinsurer Response',
  //   valueType: 'textarea',
  //   whenLoading: 'show',
  //   editable: false,
  //   hide: true,
  // },
  // {
  //   id: 'reinsResponseStatus',
  //   minWidth: '9rem',
  //   label: 'Reinsurer Response Status',
  //   valueType: 'textarea',
  //   whenLoading: 'show',
  //   editable: false,
  //   hide: true,
  // },
  // {
  //   id: 'acceptedLineCond',
  //   minWidth: '9rem',
  //   label: 'Accepted Line Condition',
  //   valueType: 'textarea',
  //   whenLoading: 'message',
  //   editable: false,
  //   hide: true,
  // },
  // {
  //   id: 'acceptedContractWord',
  //   label: 'Accepted Contract Wording',
  //   valueType: 'textarea',
  //   minWidth: '9rem',
  //   editable: false,
  //   hide: true,
  // },
  {
    id: 'clientAccepted',
    label: 'Client Accepted?',
    valueType: 'dropdown',
    minWidth: '4.5rem',
    editable: false,
    references: [
      { value: 'Yes', viewValue: 'Yes' },
      { value: 'No', viewValue: 'No' },
    ],
    editButton: true,
  },
]

export interface AssignedLinesColumnDef
  extends Partial<SortTableColumnDef<AssignedLines>> {
  id: keyof AssignedLines
}

export const assignedLinesDef: AssignedLinesColumnDef[] = [
  {
    id: 'reinsurer',
    minWidth: '15rem',
    label: 'Select Reinsurer',
    valueType: 'textarea',
    whenLoading: 'show',
    editable: false,
    editButton: true,
    pinnable: true,
    pinned: true,
    customClick: true,
  },
  {
    id: 'marketTpRef',
    label: 'TPRef',
    valueType: 'text',
    editable: false,
    hide: true,
  },
  {
    id: 'externalVendor',
    minWidth: '8rem',
    label: 'External Vendor',
    valueType: 'textarea',
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'bureaus',
    minWidth: '8rem',
    label: 'Bureau Stamp',
    valueType: 'dropdown',
    whenLoading: 'show',
    editable: true,
    references: [{ value: 'NA', viewValue: 'NA' }],
  },
  {
    id: 'underwriterRef',
    minWidth: '10rem',
    label: 'Underwriter Ref #',
    valueType: 'text',
    whenLoading: 'show',
    editable: true,
  },
  {
    id: 'written',
    minWidth: '5rem',
    label: 'Written %',
    valueType: 'percentage',
    decimals: 4,
    whenLoading: 'show',
    editable: true,
  },
  {
    id: 'recommended',
    minWidth: '8rem',
    label: 'Recommended %',
    valueType: 'percentage',
    decimals: 4,
    whenLoading: 'show',
    editable: true,
  },
  {
    id: 'signed',
    minWidth: '5rem',
    label: 'Signed %',
    valueType: 'percentage',
    decimals: 4,
    whenLoading: 'show',
    editable: true,
  },
  {
    id: 'signedOfAuthorized',
    minWidth: '10rem',
    label: '% Signed of Authorized',
    valueType: 'percentage',
    decimals: 4,
    whenLoading: 'show',
    editable: true,
  },
  {
    id: 'placedThrough',
    minWidth: '8rem',
    label: 'Placed Through',
    valueType: 'dropdown',
    whenLoading: 'show',
    editable: true,
    references: [
      { value: 'Lockton Re', viewValue: 'Lockton Re' },
      { value: 'Acrisure', viewValue: 'Acrisure' },
      {
        value: 'Aon Reinsurance Solutions',
        viewValue: 'Aon Reinsurance Solutions',
      },
      {
        value: 'BMS Intermediaries, Inc',
        viewValue: 'BMS Intermediaries, Inc',
      },
      {
        value: 'CJ Coleman & Company Limited',
        viewValue: 'CJ Coleman & Company Limited',
      },
      { value: 'Direct', viewValue: 'Direct' },
      { value: 'eReinsure', viewValue: 'eReinsure' },
      { value: 'Gallagher Re', viewValue: 'Gallagher Re' },
      {
        value: 'Guy Carpenter & Company',
        viewValue: 'Guy Carpenter & Company',
      },
      { value: 'Holborn Corporation', viewValue: 'Holborn Corporation' },
      { value: 'Howden Re/TigerRisk', viewValue: 'Howden Re/TigerRisk' },
      { value: 'UIB', viewValue: 'UIB' },
      { value: 'Unknown', viewValue: 'Unknown' },
      { value: 'Willis Re', viewValue: 'Willis Re' },
    ],
  },
  {
    id: 'coBroker',
    minWidth: '10rem',
    label: 'Correspondent Broker',
    valueType: 'dropdown',
    whenLoading: 'show',
    editable: true,
    references: [
      { value: ' ', viewValue: ' ' },
      { value: 'Acrisure RE US Limited', viewValue: 'Acrisure RE US Limited' },
      {
        value: 'BMS Intermediaries, Inc',
        viewValue: 'BMS Intermediaries, Inc',
      },
      { value: 'Lockton Companies LLP', viewValue: 'Lockton Companies LLP' },
      { value: 'Lockton Re LLC', viewValue: 'Lockton Re LLC' },
      { value: 'Lockton Re LLP', viewValue: 'Lockton Re LLP' },
    ],
  },
  {
    id: 'leadMarket',
    minWidth: '5rem',
    label: 'Lead Market',
    valueType: 'dropdown',
    whenLoading: 'show',
    editable: true,
    references: [
      { value: ' ', viewValue: ' ' },
      { value: 'Contract Lead', viewValue: 'Contract Lead' },
      {
        value: "Lloyd's Lead",
        viewValue: "Lloyd's Lead",
      },
      { value: "Second Lloyd's Lead", viewValue: "Second Lloyd's Lead" },
      { value: 'ILU Bureau Lead', viewValue: 'ILU Bureau Lead' },
      { value: 'LIRMA Bureau Lead', viewValue: 'LIRMA Bureau Lead' },
      { value: 'N/A', viewValue: 'N/A' },
    ],
  },
  {
    id: 'brokerage',
    minWidth: '6rem',
    label: 'Brokerage %',
    valueType: 'percentage',
    decimals: 4,
    whenLoading: 'show',
    editable: true,
  },
  {
    id: 'brokerageRe',
    minWidth: '8rem',
    label: 'Brokerage RIP %',
    valueType: 'percentage',
    decimals: 4,
    whenLoading: 'show',
    editable: true,
  },
  {
    id: 'brokerageCoBrokerShare',
    minWidth: '8rem',
    label: 'Brokerage % (Co-broker share)',
    valueType: 'percentage',
    decimals: 4,
    whenLoading: 'show',
    editable: true,
    isEditableFunction: (row: SortTableRow<AssignedLines>): boolean =>
      row.coBroker && row.coBroker.trim() !== '' ? true : false,
  },
  {
    id: 'brokerageReCoBrokerShare',
    minWidth: '12rem',
    label: 'Brokerage RIP % (Co-broker share)',
    valueType: 'percentage',
    decimals: 4,
    whenLoading: 'show',
    editable: true,
    isEditableFunction: (row: SortTableRow<AssignedLines>): boolean =>
      row.coBroker && row.coBroker.trim() !== '' ? true : false,
  },
  {
    id: 'contract',
    minWidth: '10rem',
    label: 'Final Contract Changes',
    valueType: 'textarea',
    whenLoading: 'show',
    editable: true,
  },
  {
    id: 'subjectivity',
    minWidth: '10rem',
    label: 'Final Signature Page Changes',
    valueType: 'textarea',
    whenLoading: 'show',
    editable: true,
  },
  {
    id: 'id',
    minWidth: '15rem',
    label: '',
    valueType: 'button',
    whenLoading: 'show',
    deleteButton: true,
    editable: true,
  },
]
