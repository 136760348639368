import { Component, Input } from '@angular/core'
import { DATA_COUNT, DataCounts, DataCountValues } from '../../tracking.model'
@Component({
  selector: 'app-tracking-tabs',
  templateUrl: './tracking-tabs.component.html',
  styleUrls: ['./tracking-tabs.component.scss'],
})
export class TrackingTabsComponent {
  @Input() dataCounts: DataCounts
  dataCountValues: DataCountValues[] = DATA_COUNT

  getCountValue(count: DataCountValues): number {
    return this.dataCounts ? this.dataCounts[count.id as keyof DataCounts] : 0
  }
}
