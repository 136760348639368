import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'
import { MatSelectChange } from '@angular/material/select'
import {
  LayerDialogData,
  LayerPaletteItem,
  LAYER_PALETTE,
  layerIds,
} from '../../analysis/model/layer-palette.model'
import { BackendService } from '../../api/backend/backend.service'
import { LayerRow, RiskSectionWithMarkets } from '../../api/model/backend.model'
import { DesignDialogTower } from '../tier.model'
import { animate, state, style, transition, trigger } from '@angular/animations'

@Component({
  selector: 'app-design-from-ot-dialog',
  templateUrl: './design-from-ot-dialog.html',
  styleUrls: ['./design-from-ot-dialog.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class DesignFromOTDialogComponent implements OnInit {
  errorMessage: string
  riskSections: RiskSectionWithMarkets[] | undefined
  riskSectionsDataSource: MatTableDataSource<LayerRow>
  asteriskText = ''
  layerFlagInfo: string[] = []
  displayedColumns = [
    'riskRef',
    'interestNarrative',
    'occurrenceLimit',
    'occurrenceAttachment',
    'topMarket',
    'layerTypeSelection',
    'towerSelection',
  ]
  columnsToDisplayWithExpand = [...this.displayedColumns, 'expand']
  expandedElement: RiskSectionWithMarkets | null
  layers: LayerPaletteItem[]
  towers: DesignDialogTower[] = [{ name: 'Structure 1', id: 1 }]
  layerIds = layerIds
  uniqueLayers: LayerRow[] = []
  shouldDefaultCessionPct = false
  responseHasData = false

  constructor(
    public dialogRef: MatDialogRef<DesignFromOTDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: LayerDialogData,
    private backendService: BackendService
  ) {}

  ngOnInit(): void {
    this.layers = LAYER_PALETTE
    this.layers = this.layers.filter(x => x.name !== 'Aggregate Feeder' && x.name !== 'ILW Aggregate Feeder') // Remove Agg feeder from auto build
    if (this.data.renewedFromOpportunityId) {
      this.backendService
        .getRiskSectionsWithMarketsByOpportunityId(
          this.data.renewedFromOpportunityId
        )
        .subscribe(res => {
          if (res.error !== undefined) {
            this.errorMessage = res.error.message
          }
          this.riskSections = res.data
          this.riskSections?.map(rs => this.setDefaultLayer(rs))

          // get an array of objects of all the unique layers by riskRef
          // occurrence Limit and Attachment for MAT table in dialog
          // @ts-ignore
          this.uniqueLayers = this.riskSections?.filter(
            (el, i, ary) =>
              !ary
                .slice(i + 1)
                .some(
                  x =>
                    x.riskRef === el.riskRef &&
                    x.occurrenceLimit === el.occurrenceLimit &&
                    x.occurrenceAttachment === el.occurrenceAttachment
                )
          )
          const layersWithSections = this.layersWithSections(this.uniqueLayers)

          const layersWithSectionsWithTopMarkets =
            this.getEachSectionsLargestReinsurerTopMarket(layersWithSections)

          const layersWithTopMarketWithSectionsWithTopMarketAsChildren =
            this.getEachLayersLargestReinsurerTopMarket(
              layersWithSectionsWithTopMarkets
            )

          this.riskSectionsDataSource = new MatTableDataSource<LayerRow>(
            layersWithTopMarketWithSectionsWithTopMarketAsChildren
          )
          this.responseHasData =
            this.riskSectionsDataSource.filteredData.length > 0
        })
    }
  }

  setDefaultLayer(riskSection: RiskSectionWithMarkets): void {
    let layerPrefix = 'noncat_' // default to non cat

    if (riskSection.defaultSageView === 'Property') {
      layerPrefix = 'cat_'
    }

    if (riskSection.defaultSageView === 'AHL') {
      layerPrefix = 'ahl_'
    }

    if (riskSection.extendedRiskTypeValue === 'PROP') {
      riskSection.selectedLayer = `${layerPrefix}qs`
    } else {
      riskSection.selectedLayer = `${layerPrefix}xl`
    }
  }

  getNumberOfMarkets(riskSection: RiskSectionWithMarkets): number {
    return riskSection ? riskSection.markets?.length : 0
  }

  onLayerChange(event: MatSelectChange): void {
    this.asteriskText = ''
    const warning =
      'The main fields for this Layer type are pulled in from OT. ' +
      'Please update technical fields to mirror the placement.'
    if (event.value === layerIds.noncatIndxl) {
      this.asteriskText = `${warning}`
      this.layerFlagInfo = [
        'Indexed XL Layers',
        'i. Index %',
        'ii. Custom entered Index %s',
        'iii. Franchise Deductible',
        'iv. Indexation',
        'v. SIC/Franchise',
      ]
    }
    if (event.value === layerIds.noncatSwing) {
      this.asteriskText = `${warning}`
      this.layerFlagInfo = [
        'Swing Rated Layers',
        'i. Min Rate, % of Subject',
        'ii. Max Rate, % of Subject',
        'iii. Swing Rate, % of Layer Loss',
        'iv. Swing Basis',
      ]
    }
    if (
      event.value === layerIds.noncatMultisection ||
      event.value === layerIds.catMultisection
    ) {
      this.asteriskText = `${warning}`
      this.layerFlagInfo = ['Multi-Section Layers', 'i.Section information']
    }
  }

  onTowerSelectionChange(event: MatSelectChange, index: number): void {
    if (event.value === 'newTower') {
      const nextId = this.towers.length + 1
      this.towers.push({ name: `Structure ${nextId}`, id: nextId })

      // set current row to the newly created tower option
      if (this.uniqueLayers) {
        this.uniqueLayers[index].selectedStructure = nextId
      }
    }
  }

  onImport(): void {
    const selectedLayers = this.riskSectionsDataSource.data.filter(
      rs => rs.selectedStructure !== undefined
    )
    this.dialogRef.close({
      event: 'import',
      selectedLayers,
      defaultCessionPct: this.shouldDefaultCessionPct ? 100 : null,
    })
  }

  onClose(): void {
    this.dialogRef.close({ event: 'close' })
  }

  // loop through layers and attach OT sections with similar riskRef,
  // occurrence limit and attach as children for drawer/accordion of table
  layersWithSections(uniqueLayers: LayerRow[]) {
    if (uniqueLayers) {
      for (const layer of uniqueLayers) {
        layer.sections = []
        if (this.riskSections) {
          for (const section of this.riskSections) {
            if (
              layer.riskRef === section.riskRef &&
              layer.occurrenceLimit === section.occurrenceLimit &&
              layer.occurrenceAttachment === section.occurrenceAttachment
            ) {
              layer.sections.push(section)
            }
          }
        }
      }
    }
    return uniqueLayers
  }

  // for each section in each layer, loop through its markets, find the signed %
  // that is the largest and its reinsurer, and assign those to that section
  getEachSectionsLargestReinsurerTopMarket(
    layersWithSectionsAsChildren: LayerRow[]
  ) {
    if (layersWithSectionsAsChildren) {
      for (const layer of layersWithSectionsAsChildren) {
        if (layer.sections) {
          for (const section of layer.sections) {
            if (section.markets) {
              let largestSignedPct = 0
              let largestSignedPctReinsurer
              for (const market of section.markets) {
                if (Number(market.signedPct) > largestSignedPct) {
                  // @ts-ignore
                  largestSignedPct = market.signedPct
                  largestSignedPctReinsurer = market.reinsurerName
                }
              }
              section.largestSectionSignedPct = largestSignedPct
              section.largestSectionSignedPctReinsurer =
                largestSignedPctReinsurer
            }
          }
        }
      }
    }
    return layersWithSectionsAsChildren
  }

  // for each layer, loop through its sections, find the largest signed %
  // and that reinsurer, and assign that largest reinsurer that layer
  getEachLayersLargestReinsurerTopMarket(
    layersWithSectionsWithTopMarkets: LayerRow[]
  ): LayerRow[] {
    if (layersWithSectionsWithTopMarkets) {
      for (const layer of layersWithSectionsWithTopMarkets) {
        if (layer.sections) {
          let largestSignedPct = 0
          let largestSignedPctReinsurer = ''
          for (const section of layer.sections) {
            if (Number(section.largestSectionSignedPct) > largestSignedPct) {
              // @ts-ignore
              largestSignedPct = section.largestSectionSignedPct
              largestSignedPctReinsurer = String(
                section.largestSectionSignedPctReinsurer
              )
            }
          }
          layer.largestLayerSignedPct = largestSignedPct
          layer.largestLayerSignedPctReinsurer = largestSignedPctReinsurer
        }
      }
    }
    return layersWithSectionsWithTopMarkets
  }
}
