<div class="wrapper">
  <div class="title-wrapper">
    <div class="title-right">
      <button
        appButtonIcon
        big
        class="close"
        (click)="onCancel()"
        *ngIf="!disableCloseButton"
      >
        close
      </button>
    </div>
  </div>
  <div class="content-wrapper">
    <div class="message-wrapper">
      <div class="message-container">
        {{ message }}
      </div>
      <div
        class="submessage-container"
        [ngClass]="{ warning: isSubmessageWarning }"
      >
        {{ submessage }}
      </div>
    </div>
    <div class="button-bar">
      <button appButton big accent (click)="onConfirm()">
        <span>{{ confirmMessage }}</span>
      </button>
      <button appButton big accent (click)="onCancel()">
        <span>{{ cancelMessage }}</span>
      </button>
    </div>
  </div>
</div>
