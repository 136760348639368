import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core'
import {
  NodeCheckEventArgs,
  TreeViewComponent,
} from '@syncfusion/ej2-angular-navigations'
import { TreeFields, TreeDatum, TreeNodeChange } from './checkbox-tree.model'

@Component({
  selector: 'app-checkbox-tree',
  templateUrl: './checkbox-tree.component.html',
  styleUrls: ['./checkbox-tree.component.scss'],
})
export class CheckboxTreeComponent {
  @Input() fields: TreeFields
  @Output() treeNodesChange = new EventEmitter<TreeNodeChange[]>()

  @ViewChild('treeview')
  public tree: TreeViewComponent

  onNodeChecked(nodeChecked: NodeCheckEventArgs): void {
    const changedNodes: TreeNodeChange[] = nodeChecked.data.map(
      (node: TreeDatum) => {
        // below conversion needed since props stored as string in tree
        return {
          id: Number(node.id),
          isChecked: this.getIsChecked(node.isChecked as string),
        }
      }
    )
    this.treeNodesChange.emit(changedNodes)
  }

  getIsChecked(isChecked: string): boolean | 'mixed' {
    return isChecked === 'mixed'
      ? 'mixed'
      : isChecked === 'false'
        ? false
        : true
  }
}
