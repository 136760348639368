import { FullDateToYear } from '@shared/pipes/full-date-to-year'
import {
  AssignedLines,
  AssignedLinesWithCheckFlag,
  QuoteExportAssignedLines,
  QuoteExportWrittenSignedLines,
} from '../models/quote.model'
import { ReinsurerState } from '../store/reinsurer/reinsurer.reducer'
import { SectionState } from '../store/section/section.reducer'
import { QuoteExportRowValue } from './quote-excel.model'
import { Injectable } from '@angular/core'
import { QuoteExcelUtils } from './quote-excel.utils'
import { groupBy, path, prop, uniqBy } from 'ramda'
import { layerIds } from 'src/app/analysis/model/layer-palette.model'
import { LayerState } from 'src/app/analysis/store/ceded-layers/layers.reducer'

@Injectable({
  providedIn: 'root',
})
export class QuoteExportAssignedLinesConverter {
  constructor(private utils: QuoteExcelUtils) {}

  public getAssignedLineColumns = (
    isDefaultExport: boolean,
    fotExpiringLayerSet: AssignedLines[][],
    expiringOppYear: string,
    currentOppYear: string,
    s: SectionState,
    expiringReinsurers: ReinsurerState[],
    layerState: LayerState[],
    sections: SectionState[],
    usedSections: SectionState[]
  ): string[] => {
    let actualSection = s.section
    if (actualSection.layerType === layerIds.catMultisection) {
      const usedVisibleLayerIds = usedSections
        .filter(({ section }) => section.layerType === layerIds.catMultisection)
        .map(section =>
          this.utils.getVisibleLayerSectionForMultiSectionSection(
            layerState,
            section
          ).layer.id
        )
      const visibleLayer =
        this.utils.getVisibleLayerSectionForMultiSectionSection(layerState, s)
      if (usedVisibleLayerIds.includes(visibleLayer.layer.id)) {
        return []
      }

      actualSection = sections.find(
        ({ section }) => section.layerRef === visibleLayer.layer.id
      ).section
    }
    const columns: string[] = []
    if (fotExpiringLayerSet[0].length) {
      // Expiring
      const label = `${
        (expiringOppYear && new FullDateToYear().transform(expiringOppYear)) ??
        ''
      } ${actualSection.layerName}`
      if (isDefaultExport) {
        columns.push(label)
        columns.push(...this.utils.populateArraysWithValue(3, 'fill', true))
      } else {
        const expiringVersionsForSection = expiringReinsurers.filter(
          ({ reinsurer }) =>
            reinsurer.reinsurerSectionId === Number(actualSection.id)
        )

        expiringVersionsForSection.forEach(({ reinsurer }) =>
          columns.push(
            `${label} (Expiring: ${reinsurer.reinsurerPhaseLabel})`,
            'fill'
          )
        )

        if (!fotExpiringLayerSet[1].length) {
          columns.push(' ')
        }
      }
    }
    if (fotExpiringLayerSet[1].length) {
      // FOT
      const label = `${
        (currentOppYear && new FullDateToYear().transform(currentOppYear)) ?? ''
      } ${actualSection.layerName}`
      if (isDefaultExport) {
        columns.push(label)
        columns.push(...this.utils.populateArraysWithValue(10, 'fill', true))
      } else {
        columns.push(label)
        columns.push(...this.utils.populateArraysWithValue(4, 'fill', true))
      }
    }
    return columns
  }

  public getAssignedLineRows = (
    assignedLinesRe: string[][],
    reinsurerListForExport: ReinsurerState[],
    expiringReinsurers: ReinsurerState[],
    fotReinsurers: ReinsurerState[],
    nonFotAndExpiringReinsurers: ReinsurerState[],
    isDefaultExport: boolean,
    assignedLinesTpRef: QuoteExportRowValue[][],
    assignedLinesData: QuoteExportAssignedLines[][][],
    assignedLinesColumns: string[],
    filteredSections: SectionState[],
  ): QuoteExportRowValue[][][] => {
    const assignedLinesRows: QuoteExportRowValue[][][] = []
    if (!assignedLinesRe.length || !assignedLinesData.length) {
      return assignedLinesRows
    }

    const filteredAssignedLines = isDefaultExport
      ? assignedLinesRe
      : uniqBy(prop(1), assignedLinesRe)

    filteredAssignedLines.forEach((re, i) => {
      const row: QuoteExportRowValue[][] = []
      let stamp: string | undefined

      const assignedLinesId = re[0]
      const matchingReinsurerForId = reinsurerListForExport.find(
        ({ reinsurer }) =>
          reinsurer.riskAssignedLinesLink?.some(
            link => link.id === assignedLinesId
          )
      )
      const panelReinsurerPhase =
        matchingReinsurerForId.reinsurer.reinsurerPhase

      const reinsurerList =
        panelReinsurerPhase === 'Expiring'
          ? expiringReinsurers
          : panelReinsurerPhase === 'FOT'
            ? fotReinsurers
            : nonFotAndExpiringReinsurers

      stamp =
        reinsurerList
          .find(({ reinsurer }) =>
            reinsurer.riskAssignedLinesLink.some(
              link => link.id === assignedLinesId
            )
          )
          .reinsurer.riskAssignedLinesLink.find(
            link => link.id === assignedLinesId
          ).bureaus || ' '

      row.push([re[1], 'text', 'name'])
      if (isDefaultExport) {
        const altpRef = assignedLinesTpRef[i]?.[0] ?? '0'
        row.push([stamp, 'text', 'bureauStamp'])
        row.push([altpRef, 'text', 'tpRef'])
        row.push([' '])
        assignedLinesData.forEach(line => {
          line.forEach((r, index) => {
            if (!!r.length) {
              let found = false
              r.forEach((a: AssignedLinesWithCheckFlag) => {
                if (a.reinsurer === re[1] && !found && !a.checked) {
                  found = true
                  row.push(...this.getDefaultExportRow(index, r, a))
                  a.checked = true
                }
              })
              if (!found) {
                row.push(...this.getDefaultNotFoundExportRow(index))
              }
            }
          })
        })
      } else {
        const filteredNonEmptySections = filteredSections.filter((_, index) =>
          assignedLinesData[index].some(subarray => subarray.length)
        )
        assignedLinesData
          .filter(
            sectionLineData =>
              !!sectionLineData.some(subarray => subarray.length)
          )
          .forEach((sectionLinesData, sectionIndex) => {
            const expiringForSection: ReinsurerState[] | undefined =
              !!expiringReinsurers.length
                ? Object.values(
                    groupBy(
                      path(['reinsurer', 'reinsurerSectionId']),
                      expiringReinsurers
                    )
                  )?.[sectionIndex]
                : undefined
            const sectionId = filteredNonEmptySections[sectionIndex].section.id
            const fotForSection = !!fotReinsurers.length
              ? fotReinsurers.filter(
                  ({ reinsurer }) =>
                    String(reinsurer.reinsurerSectionId) === sectionId
                )
              : undefined

            sectionLinesData.forEach((lineSet, index) => {
              const isExpiring = index === 0
              let found = false
              lineSet.forEach((line: AssignedLinesWithCheckFlag) => {
                if (line.reinsurer === re[1] && !found) {
                  found = true
                  if (isExpiring) {
                    expiringForSection?.forEach(expiring => {
                      // For each version, generate the values for that
                      const lineForSection = lineSet.find(line =>
                        expiring.reinsurer.riskAssignedLinesLink?.some(
                          link =>
                            link.marketTpRef === line.tpRef &&
                            link.reinsurer === re[1]
                        )
                      )
                      if (lineForSection) {
                        row.push(
                          ...this.getXOLExportRow(
                            index,
                            lineSet,
                            lineForSection,
                            expiring
                          )
                        )
                      } else {
                        row.push(...this.getXOLNotFoundExportRow(index, 1))
                      }
                    })
                  } else {
                    row.push(
                      ...this.getXOLExportRow(index, lineSet, line, null)
                    )
                  }
                }
              })
              if (!found) {
                row.push(
                  ...this.getXOLNotFoundExportRow(
                    index,
                    expiringForSection?.length ?? 0,
                    fotForSection
                  )
                )
              }
              if (assignedLinesColumns[row.length] === ' ') {
                row.push([' '])
              }
            })
          })
      }
      assignedLinesRows.push(row)
    })
    return assignedLinesRows
  }

  private getDefaultExportRow = (
    index: number,
    allReinsurers: QuoteExportAssignedLines[],
    assignedLine: AssignedLinesWithCheckFlag
  ): QuoteExportRowValue[][] => {
    const writtenSigned = this.getAssignedLinesWrittenSigned(
      allReinsurers,
      assignedLine,
      true,
      true
    )
    if (index === 0) {
      // Expired
      return [
        [writtenSigned.writtenPercentage, 'percentage', 'written%'],
        [writtenSigned.writtenCurrency, 'currency', 'written$'],
        [writtenSigned.signedPercentage, 'percentage', 'signed%'],
        [writtenSigned.signedCurrency, 'currency', 'signed$'],
        [' '],
      ]
    } else {
      return [
        [assignedLine.underwriterRef, 'text', 'underwriterRef'],
        [writtenSigned.writtenPercentage, 'percentage', 'written%'],
        [writtenSigned.writtenCurrency, 'currency', 'written$'],
        [assignedLine.recommended, 'percentage', 'recommended%'],
        [writtenSigned.signedPercentage, 'percentage', 'signed%'],
        [writtenSigned.signedCurrency, 'currency', 'signed$'],
        [assignedLine.placedThrough, 'text', 'placedThrough'],
        [assignedLine.coBroker, 'text', 'coBroker'],
        [assignedLine.leadMarket, 'text', 'leadMarket'],
        [assignedLine.brokerage, 'percentage', 'brokerage'],
        [assignedLine.brokerageRe, 'percentage', 'brokerageRe'],
        [' '],
      ]
    }
  }

  private getXOLExportRow = (
    index: number,
    allReinsurers: QuoteExportAssignedLines[],
    assignedLine: QuoteExportAssignedLines,
    expiring: ReinsurerState | null
  ): QuoteExportRowValue[][] => {
    const isExpiring = index === 0
    const writtenSigned = this.getAssignedLinesWrittenSigned(
      allReinsurers,
      assignedLine,
      true,
      isExpiring,
      expiring
    )
    if (isExpiring) {
      return [
        [writtenSigned.signedPercentage, 'percentage', 'signed%'],
        [writtenSigned.signedCurrency, 'currency', 'signed$'],
      ]
    } else {
      return [
        [writtenSigned.writtenPercentage, 'percentage', 'written%'],
        [writtenSigned.writtenCurrency, 'currency', 'written$'],
        [writtenSigned.signedPercentage, 'percentage', 'signed%'],
        [writtenSigned.signedCurrency, 'currency', 'signed$'],
        [assignedLine.leadMarket, 'text', 'leadMarket'],
      ]
    }
  }

  private getDefaultNotFoundExportRow = (index: number) => {
    if (index === 0) {
      return [
        [0, 'percentage', 'written%'],
        [0, 'currency', 'written$'],
        [0, 'percentage', 'signed%'],
        [0, 'currency', 'signed$'],
        [' '],
      ]
    } else {
      return [
        [' ', 'text', 'underwriterRef'],
        [0, 'percentage', 'written%'],
        [0, 'currency', 'written$'],
        [0, 'percentage', 'recommended%'],
        [0, 'percentage', 'signed%'],
        [0, 'currency', 'signed$'],
        [' ', 'text', 'placedThrough'],
        [' ', 'text', 'coBroker'],
        [' ', 'text', 'leadMarket'],
        [0, 'percentage', 'brokerage'],
        [0, 'percentage', 'brokerageRe'],
        [' '],
      ]
    }
  }

  private getXOLNotFoundExportRow = (
    index: number,
    expiringVersions?: number,
    fotForSection?: ReinsurerState[] | undefined
  ): QuoteExportRowValue[][] => {
    if (index === 0) {
      const expiringDefs: QuoteExportRowValue[][] = []
      for (let i = 0; i < expiringVersions; i++) {
        expiringDefs.push(['-', 'text', 'signed%'], ['-', 'text', 'signed$'])
      }
      return expiringDefs
    } else {
      if (!fotForSection?.length) {
        return []
      }
      return [
        ['-', 'text', 'written%'],
        ['-', 'text', 'written$'],
        ['-', 'text', 'signed%'],
        ['-', 'text', 'signed$'],
        ['-', 'text', 'leadMarket'],
      ]
    }
  }

  private getAssignedLinesWrittenSigned = (
    allAssignedLines: QuoteExportAssignedLines[],
    assignedLine: AssignedLinesWithCheckFlag | QuoteExportAssignedLines,
    isDefault: boolean,
    sumFields: boolean,
    expiring?: ReinsurerState | null
  ): QuoteExportWrittenSignedLines => {
    const allRowsOfThisReinsurer = allAssignedLines.filter(row => {
      const reinsurerMatch = row.reinsurer === assignedLine.reinsurer
      if (isDefault || sumFields) {
        return reinsurerMatch
      } else {
        return (
          expiring &&
          expiring.reinsurer.riskAssignedLinesLink.some(
            link => link.id === row.id
          )
        )
      }
    })
    return allRowsOfThisReinsurer.reduce(
      (acc: QuoteExportWrittenSignedLines, currentRow) => {
        acc.writtenPercentage += currentRow.writtenPercentage
        acc.writtenCurrency += currentRow.writtenCurrency
        acc.signedPercentage += currentRow.signedPercentage
        acc.signedCurrency += currentRow.signedCurrency
        return acc
      },
      {
        writtenPercentage: 0,
        writtenCurrency: 0,
        signedPercentage: 0,
        signedCurrency: 0,
      } as QuoteExportWrittenSignedLines
    )
  }
}
