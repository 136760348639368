import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core'
import { MatSelectChange, MatSelect } from '@angular/material/select'
import { Client, ClientYear } from '../../core/model/client.model'
import { Study } from '../../core/model/study.model'
import { CanSize, CanSizeCtor, mixinSize, Size } from '@shared/size.mixin'
import { TierPath } from '../tier.model'
import { Program } from '../../core/model/program.model'
import { LossSetLayer } from '../../api/analyzere/analyzere.model'
import {
  AccountOpportunity,
  RiskRefWithSections,
} from '../../api/model/backend.model'
import {
  filterQuoteTowerLayers,
  isLayerAggFeeder,
} from '../../analysis/model/layers.util'
import { LayerState } from '../../analysis/store/ceded-layers/layers.reducer'
import { Layer } from '../../analysis/model/layers.model'
import { QuoteStartProps } from '../../quote/store/quote.actions'
import { isNil } from 'ramda'
import { ConfirmationDialogService } from '@shared/services/confirmation-dialog.service'
import {
  ProgramGroup,
  SharedLimit,
} from '../../analysis/store/grouper/program-group.model'
import { SectionState } from '../../quote/store/section/section.reducer'
import { CreditRoutes } from '../../credit/model/credit-routes.model'
/*
 * Get a color mixin that TierBarComponent can extend to handle color inputs
 */
class TierBarBase {
  constructor(public _elementRef: ElementRef) {}
}
const _SizeMixinBase: CanSizeCtor & typeof TierBarBase = mixinSize(TierBarBase)

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tier-bar',
  templateUrl: './tier-bar.component.html',
  styleUrls: ['./tier-bar.component.scss'],
})
export class TierBarComponent
  extends _SizeMixinBase
  implements CanSize, OnChanges, OnInit
{
  @ViewChild('structureSelect') structureSelect: MatSelect
  @ViewChild('quoteLayersSelect') quoteLayersSelect: MatSelect

  @Input() isButton = false
  @Input() clientSelect = false
  @Input() lobSelect = true
  @Input() clients: Client[]
  @Input() years: ClientYear[]
  @Input() selectedClientID: string | null
  @Input() selectedYearID: string | null
  @Input() selectedProgramID: string | null
  @Input() selectedStructureID: string | null
  @Input() accountOpportunities: AccountOpportunity[] | null

  @Input() routerUrl: string
  @Input() isExplore: boolean
  @Input() programGroups: ProgramGroup[]
  @Input() structures: Program[]
  @Input() lobs: LossSetLayer[]
  @Input() exploreLoading: boolean
  @Input() selectedLob: number | null
  @Input() isQuote = false
  @Input() isSlipT = false
  @Input() isSignature = false
  @Input() isDesign = false
  @Input() quoteLayers: LayerState[] = []
  @Input() selectedCededLayerID: string | null | undefined
  @Input() quoteDirty: boolean
  @Input() sharedLimits: SharedLimit[]
  @Input() layersChangedInDesign: string[] | null
  @Input() sectionList: SectionState[] | null
  @Input() secondBar = false

  groupOrStructureFilterSelected: 'Group' | 'Structure' | 'SL' | null
  selectedProgramGroup: string | null
  selectedProgramGroupName: string | null
  selectedStructure: string | null
  selectedLayer: Layer | null
  selectedSharedLimit: string | null
  selectedSharedLimitName: string | null | undefined

  ngOnInit(): void {
    if (this.routerUrl.includes(CreditRoutes.Credit)) {
      this.programs = this.programs.filter(
        study => !!study.credit_sage_portfolio_id
      )
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.years && this.years.length !== 0) {
      const latestYear = this.years[this.years.length - 1]
      if (
        this.selectedClientID &&
        this.selectedYearID === null &&
        !this.secondBar
      ) {
        this.selectedYearID = latestYear?.id ?? null
        this.tierChange.emit({
          client: this.selectedClientID,
          year: this.selectedYearID,
          program: null,
          structure: null,
        })
        this.setNullsAndEmit()
      }
    }

    if (this.selectedStructureID && !this.selectedStructure) {
      this.selectedStructure = this.selectedStructureID
    }
    this.autoSelectStructureFilter()
    if (this.structures?.length === 1) {
      this.autoSelectLayerForSingleStructure()
    } else {
      this.autoSelectLowestAttachmentLayerForStructure()
    }
    if (changes.quoteLayers) {
      this.selectedLayer =
        this.quoteLayers.find(l => l.layer.id === this.selectedCededLayerID)
          ?.layer || this.selectedLayer
    }
  }

  @Input() set programs(value: Study[]) {
    this._programs = this.routerUrl.includes(CreditRoutes.Credit)
      ? value.filter(study => !!study.credit_sage_portfolio_id)
      : value
  }

  get programs() {
    return this._programs
  }
  private _programs: Study[] = []

  // Readonly
  @Input() set readonly(value: any) {
    this._readonly = coerceBooleanProperty(value)
  }
  get readonly() {
    return this._readonly
  }
  _readonly = false

  // Hover (parent will pass if this is hovered over)
  @Input() set hover(value: any) {
    this._hover = coerceBooleanProperty(value)
  }
  get hover() {
    return this._hover
  }
  @HostBinding('class.hover') _hover = false

  // Enable Hover (change BG on mouseover)
  @Input() set enableHover(value: any) {
    this._enableHover = coerceBooleanProperty(value)
  }
  get enableHover() {
    return this._enableHover
  }
  @HostBinding('class.enable-hover') _enableHover = false

  @HostBinding('class.bg-treatment') get bgTreatment() {
    return this.clientSelect
  }

  // Size mixin
  @Input() size: Size
  @Input() big: boolean
  @HostBinding('class.big')
  get isSizeBig() {
    return this.size === 'big'
  }

  @Output() tierChange = new EventEmitter<TierPath>()
  @Output() groupOrStructureSelectionChange = new EventEmitter<
    'Group' | 'Structure' | 'SL' | null
  >()
  @Output() structureChange = new EventEmitter<Program>()
  @Output() exploreClear = new EventEmitter()
  @Output() lobChange = new EventEmitter<number>()
  @Output() layerSelect = new EventEmitter<QuoteStartProps>()
  @Output() groupSelect = new EventEmitter<QuoteStartProps>()
  @Output() slSelect = new EventEmitter<QuoteStartProps>()

  get yearLabel(): string {
    return this.years.length > 0 ||
      !this.selectedClientID ||
      this.selectedClientID === 'none'
      ? 'Years'
      : 'No Years'
  }

  get programLabel(): string {
    return this.programs.length > 0 ||
      !this.selectedClientID ||
      this.selectedClientID === 'none' ||
      !this.selectedYearID ||
      this.selectedYearID === 'none'
      ? 'Programs'
      : 'No Programs'
  }

  get programGroupLabel(): string {
    return this.programGroups.length > 0 ||
      !this.selectedClientID ||
      this.selectedClientID === 'none' ||
      !this.selectedYearID ||
      this.selectedYearID === 'none'
      ? 'Program Group'
      : 'No Program Group'
  }

  get sharedLimitLabel(): string {
    return this.sharedLimits.length > 0 ||
      !this.selectedClientID ||
      this.selectedClientID === 'none' ||
      !this.selectedYearID ||
      this.selectedYearID === 'none'
      ? 'Shared Limit'
      : 'No Shared Limit'
  }

  get structureLabel(): string {
    return this.structures.length > 0 ||
      !this.selectedClientID ||
      this.selectedClientID === 'none' ||
      !this.selectedYearID ||
      this.selectedYearID === 'none'
      ? 'Structure'
      : 'No Structure'
  }

  get qouteLayerLabel(): string {
    return this.structures.length > 0 ||
      !this.selectedClientID ||
      this.selectedClientID === 'none' ||
      !this.selectedYearID ||
      this.selectedYearID === 'none' ||
      !this.selectedStructureID ||
      this.selectedStructureID === 'none'
      ? this.isQuoteLoading
      : 'No Layers'
  }

  get lobLabel(): string {
    return this.lobs.length > 0 ||
      !this.selectedClientID ||
      this.selectedClientID === 'none' ||
      !this.selectedYearID ||
      this.selectedYearID === 'none'
      ? this.isLoading
      : 'No LOB'
  }

  get isQuoteLoading(): string {
    return !this.getFilteredQuoteLayerState &&
      ((this.sectionList && this.sectionList.length > 0) || this.isSlipT)
      ? 'Layers'
      : (this.getFilteredQuoteLayerState.length > 0 &&
            ((this.sectionList && this.sectionList.length > 0) ||
              this.isSlipT)) ||
          !this.selectedStructureID
        ? 'Layers'
        : 'Loading...'
  }

  get isLoading(): string {
    return this.exploreLoading ? 'Loading...' : 'LOB'
  }

  get programDisabled() {
    return (
      this.programs.length === 0 ||
      this.readonly ||
      this.selectedYearID === null
    )
  }

  get selectedProgramName() {
    const program = this.programs.find(p => p.id === this.selectedProgramID)
    if (program) {
      return program.name
    }
    this.onProgramChange(undefined)
    return ''
  }

  get getFilteredQuoteLayerState() {
    return this.quoteLayers
      .filter(filterQuoteTowerLayers)
      .filter(ls => !isLayerAggFeeder(ls.layer))
  }

  constructor(
    public elementRef: ElementRef,
    private confirmationDialog: ConfirmationDialogService
  ) {
    super(elementRef)
  }

  getOpportunity(opportunityID: string): AccountOpportunity | undefined {
    return this.accountOpportunities?.find(opp => opp.oppId === opportunityID)
  }

  autoSelectStructureFilter(): void {
    if (
      this.isQuote &&
      this.selectedYearID &&
      this.selectedProgramID &&
      !this.groupOrStructureFilterSelected
    ) {
      this.groupOrStructureFilterSelected = 'Structure'
    }
  }

  autoSelectLayerForSingleStructure(): void {
    if (this.structures && this.structures.length === 1 && this.isQuote) {
      const structure = this.structures[0]
      if (!this.selectedStructure || structure.id !== this.selectedStructure) {
        this.structureChange.emit(structure)
        this.tierChange.emit({
          client: this.selectedClientID,
          year: this.selectedYearID,
          program: this.selectedProgramID,
          structure: structure.id,
        })
        this.selectedStructure = structure.id
      }
      if (!this.selectedLayer) {
        this.autoSelectLowestAttachmentLayerForStructure()
      }
    }
  }

  autoSelectLowestAttachmentLayerForStructure(): void {
    if (this.structures && !!this.structures.length && this.isQuote) {
      if (this.selectedStructure && !this.selectedLayer) {
        const layers = this.getFilteredQuoteLayerState
        if (layers && !!layers.length) {
          const lowestAttachLayer = layers.reduce((prev, curr) =>
            prev.layer.physicalLayer.attachment.value <=
            curr.layer.physicalLayer.attachment.value
              ? prev
              : curr
          )
          if (lowestAttachLayer) {
            const layer = lowestAttachLayer.layer
            this.selectedLayer = layer
            this._selectLayer(layer)
          }
        }
      }
    }
  }

  SLType(layer: Layer): string {
    if (layer.meta_data.sage_layer_type === 'shared_limits') {
      return '(SL Layer)'
    } else {
      return ''
    }
  }

  onClientChange($event: string): void {
    const client = $event
    if (client !== this.selectedClientID) {
      this.tierChange.emit({
        client,
        year: null,
        program: null,
        structure: null,
      })
    }
    this.setNullsAndEmit()
  }

  onYearChange($event: MatSelectChange): void {
    const year = $event.source.value || null
    if (year !== this.selectedYearID) {
      this.tierChange.emit({
        client: this.selectedClientID,
        year,
        program: null,
        structure: null,
      })
    }
    this.setNullsAndEmit()
  }

  setNullsAndEmit(): void {
    this.groupOrStructureFilterSelected = null
    this.selectedProgramGroup = null
    this.selectedStructure = null
    this.selectedLayer = null
    this.selectedSharedLimit = null

    if (this.isExplore) {
      this.exploreClear.emit()
    }
  }

  onProgramChange($event: MatSelectChange | undefined): void {
    const program = $event?.source.value || null
    if (program !== this.selectedProgramID) {
      this.tierChange.emit({
        client: this.selectedClientID,
        year: this.selectedYearID,
        program,
        structure: null,
      })
    }
    this.groupOrStructureFilterSelected = null
    this.selectedProgramGroup = null
    this.selectedStructure = null
    this.selectedLayer = null
    this.selectedSharedLimit = null
    if (this.isQuote || this.isSignature) {
      this.groupOrStructureSelectionChange.emit(
        this.groupOrStructureFilterSelected
      ) /* value must be set back to null on program change */
    }
    if (this.isExplore) {
      this.exploreClear.emit()
    }
  }

  onGroupOrStructureSelectionChange($event: MatSelectChange): void {
    this.groupOrStructureFilterSelected = $event.source.value
    this.selectedProgramGroup = null
    this.selectedStructure = null
    this.selectedLayer = null
    this.selectedSharedLimit = null
    this.groupOrStructureSelectionChange.emit(
      this.groupOrStructureFilterSelected
    )
  }

  onProgramGroupChange($event: MatSelectChange): void {
    this.selectedProgramGroup = $event.source.value
    if (
      this.selectedClientID &&
      this.selectedYearID &&
      this.selectedProgramID &&
      this.selectedProgramGroup
    ) {
      this.programGroups.map(programGroup => {
        if (programGroup.id === this.selectedProgramGroup) {
          this.selectedProgramGroupName = programGroup.label
        }
      })
      const data: QuoteStartProps = {
        clientID: this.selectedClientID,
        yearID: this.selectedYearID,
        studyID: this.selectedProgramID,
        structureGroupID: this.selectedProgramGroup,
        structureGroupName: this.selectedProgramGroupName,
      }
      this.groupSelect.emit(data)
    }
  }

  onSharedLimitChange($event: MatSelectChange): void {
    this.selectedSharedLimit = $event.source.value
    if (
      this.selectedClientID &&
      this.selectedYearID &&
      this.selectedProgramID &&
      this.selectedSharedLimit
    ) {
      this.sharedLimits.map(sl => {
        if (sl.id === this.selectedSharedLimit) {
          this.selectedSharedLimitName = sl.sl_name
        }
      })
      const data: QuoteStartProps = {
        clientID: this.selectedClientID,
        yearID: this.selectedYearID,
        studyID: this.selectedProgramID,
        sharedLimitID: this.selectedSharedLimit,
        sharedLimitName: this.selectedSharedLimitName,
      }
      this.slSelect.emit(data)
    }
  }

  onStructureChange($event: MatSelectChange): void {
    const structure = $event.source.value || null
    const str = this.structures.find(s => {
      return s.id === structure
    })
    if (!this.selectedStructure || structure !== this.selectedStructure) {
      this.structureChange.emit(str)
      this.tierChange.emit({
        client: this.selectedClientID,
        year: this.selectedYearID,
        program: this.selectedProgramID,
        structure,
      })
      this.selectedStructure = structure
    }
    this.selectedLayer = null
  }

  _selectLayer(layer: Layer): void {
    let sectionID = ''
    if (this.sectionList && this.sectionList?.length > 0) {
      sectionID =
        this.sectionList.find(sl => sl.section.layerRef === layer.id)?.section
          .id || ''
    }
    if (
      this.selectedClientID &&
      this.selectedYearID &&
      this.selectedProgramID &&
      this.selectedStructureID
    ) {
      const data: QuoteStartProps = {
        clientID: this.selectedClientID,
        yearID: this.selectedYearID,
        studyID: this.selectedProgramID,
        structureID: this.selectedStructureID,
        cededLayerID: layer.id,
        cededLayerName: layer.physicalLayer.description,
        cededLayerType: layer.meta_data.sage_layer_type,
        isLayerAutoBuild: layer.physicalLayer.meta_data.isAutoBuild,
        isLayerChangedInDesign:
          layer.physicalLayer.meta_data.isChangedInDesign ||
          layer.meta_data.isChangedInDesign,
        autoBuildSectionsForSelectedLayer: this.toRiskRefWithSections(
          layer.physicalLayer.meta_data.autoBuildSections
        ),
        sectionID,
      }
      this.layerSelect.emit(data)
    }
  }

  onLayerSelection(layer: Layer): void {
    if (isNil(this.selectedCededLayerID) || !this.quoteDirty) {
      this._selectLayer(layer)
      return
    }

    this.confirmationDialog
      .open({
        message:
          'There are unsaved changes. Are you sure you want to change to a different layer?',
        submitLabel: 'Confirm',
      })
      .afterClosed()
      .subscribe(confirm => {
        if (confirm) {
          this._selectLayer(layer)
        } else {
          const obj: LayerState[] = this.getFilteredQuoteLayerState.filter(
            (r: LayerState) => r.layer.id === this.selectedCededLayerID
          )
          this.selectedLayer = obj[0].layer
        }
      })
  }

  onLobChange($event: MatSelectChange): void {
    const lob = $event.source.value || null
    if (lob !== this.selectedLob) {
      this.lobChange.emit(lob)
    }
  }

  toRiskRefWithSections(value?: string): RiskRefWithSections | undefined {
    if (!value) {
      return undefined
    }
    return JSON.parse(value) as RiskRefWithSections
  }

  getLabel(value: string | Layer | null, type: string): string {
    if (!value || value === null) {
      return ''
    }
    if (type === 'Structure') {
      return (
        this.structures.find(s => {
          return s.id === value
        })?.label || ''
      )
    } else if (type === 'SL') {
      return (
        this.sharedLimits.find(sl => {
          return sl.id === value
        })?.sl_name || ''
      )
    } else if (type === 'Group') {
      return (
        this.programGroups.find(pg => {
          return pg.id === value
        })?.label || ''
      )
    } else if (type === 'Layer' && typeof value === 'object') {
      return (value as Layer).physicalLayer.description || ''
    } else if (type === 'Year') {
      return this.years.find(({ id }) => id === this.selectedYearID).year
    } else {
      return ''
    }
  }

  layerFOTOrQuote(
    layer: Layer,
    type: string,
    isCount?: boolean
  ): number | boolean | undefined {
    if (this.sectionList && this.sectionList.length > 0) {
      const sectionFound = this.sectionList.find(
        sl => sl.section.layerRef === layer.id
      )?.section
      if (sectionFound) {
        if (type === 'FOT') {
          return isCount
            ? sectionFound.fotCount
            : sectionFound.fotCount && sectionFound.fotCount > 0
        } else if (type === 'QUOTE') {
          return isCount
            ? sectionFound.quoteCount
            : sectionFound.quoteCount && sectionFound.quoteCount > 0
        }
      }
    }
  }
}
