import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { StudyData } from '../../../admin/model/admin.model'
import { BackendService } from '../../../api/backend/backend.service'

import {
  AccountOpportunity,
  CarrierAccount,
  StudyDataWithYear,
  CarrierData,
  StudyDetailsResponse,
} from '../../../api/model/backend.model'
import { Client, ClientYear } from '../../../core/model/client.model'
import { ConfirmationDialogService } from '@shared/services/confirmation-dialog.service'
import {
  SortTableColumnDef,
  SortTableRow,
} from '@shared/sort-table/sort-table.model'
import { SelectionsChangeEvent } from '@shared/util/selections'
import { ProgramInitiationDialogComponent } from '../program-initiation-dialog/program-initiation-dialog.component'
import { MatSortable } from '@angular/material/sort'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-program-initiation-content',
  styleUrls: [`./program-initiation-content.component.scss`],
  templateUrl: `./program-initiation-content.component.html`,
})
export class ProgramInitiationContentComponent implements OnChanges {
  @Input() accountOpportunities: AccountOpportunity[]
  @Input() carrierAccounts: CarrierAccount[] | null
  @Input() allCarriers: any
  @Input() isSaving: boolean | false
  @Input() carriersByID: Record<string, Client>
  @Input() unfilteredAccountOpportunities: AccountOpportunity[] | null
  @Input() currentClientID: string | null

  @Output() newProgramCreate = new EventEmitter<Partial<StudyData>>()
  @Output() newYearProgramCreate = new EventEmitter<StudyDataWithYear>()
  @Output() newCarrierYearProgramCreate = new EventEmitter<any>()
  @Output() filteredAccountOpportunities = new EventEmitter<string[]>()

  selectedAccount: AccountOpportunity | undefined
  selectedOpportunityID: string | undefined
  accountOpportunitiesRows: SortTableRow<AccountOpportunity>[] = []
  private destroy$ = new Subject()

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  // Build new Program Name using Opportunity Name
  get newProgramName(): string {
    return `${this.selectedAccount!.opportunityName}`
  }

  get columnDefs(): SortTableColumnDef[] {
    return [
      {
        id: 'accountName',
        label: 'Account',
        valueType: 'text',
        minWidth: '0',
        filter: false,
        maxWidth: '20rem',
      },
      {
        id: 'opportunityName',
        label: 'Opportunity Name',
        valueType: 'text',
        minWidth: '0',
        filter: false,
        maxWidth: '15rem',
      },
      {
        id: 'opportunityStage',
        label: 'Opportunity Stage',
        valueType: 'text',
        minWidth: '0',
        filter: false,
        maxWidth: '5rem',
      },
      {
        id: 'opportunityBU',
        label: 'Business Unit',
        valueType: 'text',
        minWidth: '0',
        filter: false,
        maxWidth: '8rem',
      },
      {
        id: 'opportunityClass',
        label: 'Class',
        valueType: 'text',
        minWidth: '0',
        filter: false,
        maxWidth: '8rem',
      },
      {
        id: 'opportunitySubClass',
        label: 'Sub-Class',
        valueType: 'text',
        minWidth: '0',
        filter: false,
        maxWidth: '8rem',
      },
      {
        id: 'opportunityRiskType',
        label: 'Risk Type',
        valueType: 'text',
        minWidth: '0',
        filter: false,
        maxWidth: '5rem',
      },
      {
        id: 'opportunityInceptionDate',
        label: 'Inception Date',
        valueType: 'text',
        minWidth: '0',
        filter: false,
        maxWidth: '8rem',
      },
    ]
  }

  rowFilterList: string[]
  defaultSort: MatSortable = {
    id: 'opportunityInceptionDate',
    start: 'desc',
    disableClear: false,
  }

  constructor(
    private confirmationDialog: ConfirmationDialogService,
    public dialog: MatDialog,
    public backendService: BackendService
  ) {}

  ngOnChanges(_changes: SimpleChanges): void {
    if (
      this.carrierAccounts != null &&
      this.carrierAccounts.length > 0 &&
      this.carriersByID !== undefined
    ) {
      this.rowFilterList = this.rowFilters()
    }

    this.accountOpportunitiesRows = this.accountOpportunities.map(account => {
      let newAcct = JSON.parse(
        JSON.stringify(account)
      ) as SortTableRow<AccountOpportunity>
      if (this.rowFilterList.includes(newAcct.oppId)) {
        newAcct = {
          ...newAcct,
          style: {
            'background-color': 'var(--accent-strong-hint)',
            cursor: 'default',
          },
        }
      }
      return newAcct
    })
  }

  rowFilters() {
    const filter = []
    for (const accountEntity of this.unfilteredAccountOpportunities || []) {
      const carrierAccount = this.carrierAccounts?.find(
        account => account.sf_acctid === accountEntity.accountId
      )
      if (carrierAccount) {
        const carrierYears =
          this.carriersByID[carrierAccount.carrier_id]?.clientYears

        if (carrierYears) {
          const carrierYear = carrierYears.find(
            year =>
              year.year ===
              accountEntity.opportunityInceptionDate?.substring(0, 4)
          )
          if (carrierYear) {
            const existingProgram = carrierYear.studies.find(
              program => program.opportunity_id === accountEntity.oppId
            )
            if (existingProgram) {
              filter.push(accountEntity.oppId)
            }
          }
        }
      }
    }
    return filter
  }

  onInitiateClick(): void {
    if (!this.selectedAccount) {
      return
    }

    // check backend to see if oppid already exists; if yes, notify user of the exact carrier-year-program that maps to the oppid
    this.backendService
      .getStudyByOpportunityId(this.selectedAccount?.oppId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (!response.error) {
          // existing program found (no 404 error), so notify user and prevent initiation
          this.showAlreadyExistsDialog(response.data)
        } else {
          // expecting a 404 Not Found error if program doesn't exist
          this.initiateProgram()
        }
      })
  }

  showAlreadyExistsDialog(studyDetails: StudyDetailsResponse): void {
    this.confirmationDialog
      .open({
        message: `⚠️ Program '${studyDetails?.study?.name}' already exists for this opportunity under ${studyDetails.carrierName} (${studyDetails.year})`,
        submitLabel: 'Ok',
      })
      .afterClosed()
      .subscribe((doClose: boolean) => {
        if (doClose) {
          // Do nothing
        }
      })
  }

  initiateProgram(): void {
    // Check if carrier-account exists in map
    const carrierAccount = this.carrierAccounts?.find(
      account => account.sf_acctid === this.selectedAccount?.accountId
    )

    // Check if inception date exists on Opportunity Object
    // TODO: Handle date - either ask user to enter year to use or use today's year
    const hasInceptionDate = this.selectedAccount?.opportunityInceptionDate
    if (!hasInceptionDate) {
      this.confirmationDialog
        .open({
          message: `Opportunity: ${this.selectedAccount?.opportunityName} does not have an associated Inception Date.`,
          submitLabel: 'Ok',
        })
        .afterClosed()
        .subscribe((doClose: boolean) => {
          if (doClose) {
            // Do nothing
          }
        })
      return
    }

    // Check if Class and Sub-Class exist on Opportunity Object
    // TODO: If both are missing, how should we handle it?
    const hasClassOrSubClass =
      this.selectedAccount?.opportunityClass ||
      this.selectedAccount?.opportunitySubClass
    if (!hasClassOrSubClass) {
      this.confirmationDialog
        .open({
          message: `Opportunity: ${this.selectedAccount?.opportunityName} does not have an associated Class or Sub-Class.`,
          submitLabel: 'Ok',
        })
        .afterClosed()
        .subscribe((doClose: boolean) => {
          if (doClose) {
            // Do nothing
          }
        })
      return
    }

    if (carrierAccount) {
      this.carrierAlreadyExists(carrierAccount)
    } else {
      this.carrierDoesNotExist()
    }
  }

  carrierDoesNotExist(): void {
    if (!this.selectedAccount) {
      return
    }

    // Carrier does not exist - create new Carrier or map to an existing one, depending on user selection
    // Add to carrierAccountMap and give permissions to current user

    const dialogRef = this.dialog.open(ProgramInitiationDialogComponent, {
      data: {
        accountName: this.selectedAccount.accountName,
        accountOpportunityName: this.selectedAccount.opportunityName,
        carriers$: this.backendService.getCarriers(),
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result?.event === 'map_new') {
        this.createNewCarrierYearProgram(
          this.selectedAccount,
          result.newCarrierName,
          null,
          result.carrierLogoFile
        )
      } else if (result?.event === 'map_existing') {
        this.createNewCarrierYearProgram(
          this.selectedAccount,
          result.newCarrierName,
          result.mapToCarrierId,
          null
        )
      }
    })
  }

  carrierAlreadyExists(carrierAccount: CarrierAccount): void {
    // Check if carrier year exists for this account
    const carrierYears =
      this.carriersByID[carrierAccount.carrier_id]?.clientYears

      const carrierYear = carrierYears ? carrierYears.find(
        year =>
          year.year ===
          this.selectedAccount?.opportunityInceptionDate?.substring(0, 4)
      ) : null
      
      // Carrier and CarrierYear exist
      if (carrierYear) {
        this.carrierYearAlreadyExists(carrierAccount, carrierYear)
      } else {
        // Carrier exists but CarrierYear does not: create new CarrierYear, then new Program
        this.confirmationDialog
          .open({
            message: `This action will create a new SAGE Year and Program for Salesforce Opportunity: ${this.selectedAccount?.opportunityName}.`,
            submitLabel: 'Confirm',
          })
          .afterClosed()
          .subscribe((doClose: boolean) => {
            if (doClose) {
              this.createNewProgram(
                this.selectedAccount,
                carrierAccount.carrier_id
              )
            }
          })
      }
  }

  carrierYearAlreadyExists(
    carrierAccount: CarrierAccount,
    carrierYear: ClientYear
  ): void {
    // If Program does not exist, dispatch action to create it
    this.confirmationDialog
      .open({
        message: `This action will create a new SAGE Program for Salesforce Opportunity: ${this.selectedAccount?.opportunityName}.`,
        submitLabel: 'Confirm',
      })
      .afterClosed()
      .subscribe((doClose: boolean) => {
        if (doClose) {
          this.createNewProgram(
            this.selectedAccount,
            carrierAccount.carrier_id,
            parseInt(carrierYear.id, 10)
          )
        }
      })
  }

  // Dispatch appropriate action to create new Program, or new Year and Program
  createNewProgram(
    selectedAccount: Partial<AccountOpportunity> | undefined,
    carrierID: number,
    carrierYearID?: number
  ): void {
    // If CarrierYear exists, create new program
    if (carrierYearID) {
      this.newProgramCreate.emit({
        name: this.newProgramName,
        description: `From Opportunity ${selectedAccount!.opportunityName}`,
        opportunity_id: `${selectedAccount!.oppId}`,
        carrier_year_id: carrierYearID,
      })
    }
    // If it doesn't exist, we need to create a CarrierYear first
    else {
      const inceptionYear =
        this.selectedAccount?.opportunityInceptionDate?.substring(0, 4)!
      this.newYearProgramCreate.emit({
        name: this.newProgramName,
        description: `From Opportunity ${selectedAccount!.opportunityName}`,
        opportunity_id: `${selectedAccount!.oppId}`,
        inceptionYear,
        carrier_id: carrierID,
      })
    }
  }

  // Dispatch action to create new Carrier, Year and Program
  createNewCarrierYearProgram(
    selectedAccount: Partial<AccountOpportunity> | undefined,
    _newCarrierName: string | undefined,
    _existingCarrierId: number | null,
    carrierLogoFile: FormData | null
  ): void {
    // Use default values for most Carrier properties
    let carrier: Partial<CarrierData>
    if (_existingCarrierId) {
      carrier = {
        id: _existingCarrierId,
        name: _newCarrierName,
        sf_acctid: selectedAccount?.accountId,
      }
    } else {
      carrier = {
        name: _newCarrierName,
        eps_volatility: 0.5,
        esp_volatility_sector: -0.016208245,
        intercept: 0.679440077,
        prospective_roe: 0.09,
        roe_sector: 0.139592613,
        executive_summary: undefined,
        eula: 'standard-eula.html',
        tax_rate: 0.21,
        surplus: 0,
        roe_target: 0,
        book_value: 50000000000.0,
        sf_acctid: selectedAccount?.accountId,
      }
    }

    const inceptionYear = selectedAccount?.opportunityInceptionDate?.substring(
      0,
      4
    )!
    const program: Partial<StudyDataWithYear> = {
      name: this.newProgramName,
      description: `From Opportunity ${selectedAccount!.opportunityName}`,
      opportunity_id: `${selectedAccount!.oppId}`,
      inceptionYear,
    }

    this.newCarrierYearProgramCreate.emit({ carrier, program, carrierLogoFile })
  }

  onSelectedChange($event: SelectionsChangeEvent): void {
    this.selectedOpportunityID = Object.keys($event.selections.dictionary)[0]
    this.selectedAccount = this.accountOpportunities.find(
      opp => opp.oppId === this.selectedOpportunityID
    )
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }
}
