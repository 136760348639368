import { SimpleChanges } from '@angular/core'
import { sortBy } from 'ramda'
import {
  BenchmarkCompany,
  BenchmarkPeerSet,
} from 'src/app/benchmark/model/benchmark.model'
import { HasID, HasName } from 'src/app/core/model/general.model'
import { AggregateMetricMethod } from 'src/app/core/model/metric.model'

type MetricLike = HasName & {
  aggregateMethod?: AggregateMetricMethod
}

export const makeFilterByName =
  <T extends MetricLike>(options?: T[] | null) =>
  (value: string | null | undefined): T[] =>
    options != null
      ? value
        ? options.filter(startsWithName(value))
        : [...options]
      : []

export function toName(value: string | MetricLike): string
export function toName(value: null | undefined): null
export function toName(
  value: string | MetricLike | null | undefined
): string | null {
  if (value == null || typeof value === 'string') {
    // @ts-ignore
    return value || null
  }

  return value.name
}

export function toID(value: null | undefined): null
export function toID(value: string): string
export function toID(value: number): number
export function toID(value: HasID): string | number
export function toID(
  value: HasID | string | number | null
): string | number | null
export function toID(
  value: string | number | HasID | null | undefined
): string | number | null {
  // @ts-ignore
  return value == null || typeof value === 'string' || typeof value === 'number'
    // @ts-ignore
    ? value || null
    // @ts-ignore
    : value.id
}

export const startsWithName =
  (name: string) =>
  <T extends MetricLike>(valueToTest: T): boolean =>
    toName(valueToTest).toLowerCase().startsWith(name.toLowerCase())

export const sortByName = sortBy((it: MetricLike) => toName(it))

export const didOptionalKeyChange =
  <T>(...keys: (keyof T)[]) =>
  (a?: T | null, b?: T | null): boolean => {
    if (a == null && b == null) {
      return true
    }
    if (!a || !b) {
      return false
    }
    return keys.every(k => a[k] === b[k])
  }

export function buildBenchmarkMetricsLabel(
  metrics: (MetricLike | undefined)[],
  existingNames?: string[],
  n = 0
): string {
  const metricNames = metrics.reduce((acc, m) => {
    if (m && acc.length <= 3) {
      acc.push(m.name)
    }
    return acc
  }, [] as string[])

  let name = metricNames[0]
  if (metricNames[1]) {
    name += ` vs ${metricNames[1]}`
  }
  if (metricNames[2]) {
    name += ` (${metricNames[2]})`
  }

  if (existingNames && n > 0) {
    name += ` (${n})`
  }
  while (existingNames && existingNames.includes(name)) {
    return buildBenchmarkMetricsLabel(metrics, existingNames, n + 1)
  }
  return name
}

export function getBenchmarkMetricID(
  metric?: { id?: string | number; estimateYear?: number } | null
): string | number {
  const id = metric?.id ?? '__na'
  if (metric?.estimateYear) {
    return `${id}_${metric.estimateYear}`
  }
  return id
}

export function didBenchmarkChartInputsChange(
  changes: SimpleChanges,
  chartViewPropName = 'view'
): boolean {
  // If downloading chart in light mode do not let the charts redraw to keep zoom level.
  if (changes?.downloadingChart) {
    return false
  }

  const keys = Object.keys(changes)
  if (keys.length === 1 && keys[0] === chartViewPropName) {
    const a = changes[chartViewPropName]?.currentValue?.hash
    const b = changes[chartViewPropName]?.previousValue?.hash
    if (a === b) {
      return false
    }
  }

  return true
}

export function areThereSelectedPeersets(
  changes: SimpleChanges,
  chartViewPropName = 'view',
  peersetsPropName = 'peerSets'
): boolean {
  const currentPeersetsCount = changes[chartViewPropName]?.currentValue
    ? changes[chartViewPropName]?.currentValue[peersetsPropName]?.length
    : 0

  return (
    changes[chartViewPropName]?.firstChange === true &&
    currentPeersetsCount !== 0
  )
}

export function isBenchmarkPeerSet(
  entity: BenchmarkCompany | BenchmarkPeerSet
): entity is BenchmarkPeerSet {
  return 'peers' in entity
}
