import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/core/store'
import * as fromActions from 'src/app/pricingcurve/store/pricing-curve.actions'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { SelectionsChangeEvent } from '@shared/util/selections'
import {
  IControl,
  PricingCurveContextTypes,
  PricingCurveStatus,
  SavedCurveSelectors,
  SavedPricingCurveEntry,
} from 'src/app/pricingcurve/model/pricing-curve.model'
import {
  SavedCurvesRowDef,
  SAVED_CURVES_COLUMNS,
} from 'src/app/pricingcurve/model/pricing-curve-table.model'
import { PricingCurveSaveAddDialogComponent } from 'src/app/pricingcurve/components/layout/save-add-dialog/pricing-curve-save-add-dialog.component'
import { FormControl } from '@angular/forms'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-pricing-curve-saved-curves',
  styleUrls: ['pricing-curve-saved-curves.component.scss'],
  templateUrl: 'pricing-curve-saved-curves.component.html',
})
export class PricingCurveSavedCurvesComponent implements OnInit, OnChanges {
  @Input() savedCurves: SavedPricingCurveEntry[]
  @Input() savedCurveSelectors: SavedCurveSelectors
  @Input() usageContext: PricingCurveContextTypes
  @Input() status: PricingCurveStatus | undefined
  @Input() addedCurveIds: number[]
  @Input() readonly: boolean
  @Input() isAdmin: boolean

  allRows: SavedCurvesRowDef[] = []
  activeRow: SavedPricingCurveEntry | undefined
  savedCurveColumns = SAVED_CURVES_COLUMNS
  savedCurveRows: SavedCurvesRowDef[] = []
  filterCtrl = new FormControl()
  lastFilter: string
  resetSelectorsActive = false

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    public matDialogRef: MatDialogRef<PricingCurveSavedCurvesComponent>
  ) {}

  ngOnInit(): void {
    this.store.dispatch(
      fromActions.fetchSavedCurves({
        useSavedCurveSelectors: this.usageContext === 'technical-premium',
      })
    )
    this.filterCtrl.valueChanges.subscribe(this.filterLayers.bind(this))
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.savedCurves) {
      this.activeRow = undefined
      this.clearFilter()
      const rows = this.savedCurves.map(entry => ({
        id: entry.id,
        pc_name: entry.pc_name,
        audit_updated_by: entry.audit_updated_by,
        pcClass:
          entry.active_filters?.selectors.pcClass.selectedValues.join(', ') ??
          '',
        pcSubClass:
          entry.active_filters?.selectors.pcSubClass.selectedValues.join(
            ', '
          ) ?? '',
        layerCategory:
          entry.active_filters?.selectors.layerCategory.selectedValues.join(
            ', '
          ) ?? '',
        audit_update_dt: new Date(entry.audit_update_dt).toISOString(),
      }))
      this.savedCurveRows = rows
      this.allRows = rows
    }
    if (changes.savedCurveSelectors) {
      const { updateDate, ...restFilters } = this.savedCurveSelectors
      this.resetSelectorsActive =
        Object.values(restFilters).some(
          (val: IControl) => !!val.selectedValues.length
        ) ||
        !!updateDate.minValue ||
        !!updateDate.maxValue
    }
    if (changes.status && this.status) {
      if (this.status.isLoading || this.status.dialogLoading) {
        this.filterCtrl.disable()
      } else {
        this.filterCtrl.enable()
      }
    }
  }

  clearFilter(): void {
    this.filterCtrl.setValue('')
  }

  filterLayers(value: string): void {
    this.savedCurveRows = this.allRows.filter(
      row => row.pc_name.toLowerCase().indexOf(value.toLowerCase()) >= 0
    )
  }

  selectedRowChanged(event: SelectionsChangeEvent): void {
    const selectedRowId = Number(Object.keys(event.selections.dictionary))
    const selectedRow = selectedRowId
      ? this.savedCurves.find(curve => curve.id === selectedRowId)
      : undefined
    this.activeRow = selectedRow
  }

  toggleImmutable(): void {
    if (this.isAdmin && this.activeRow) {
      const actionText = this.activeRow.isImmutable ? 'demote' : 'promote'
      const allowText = this.activeRow.isImmutable
        ? 'allowing anyone to update/delete'
        : 'preventing anyone but admins from updating/deleting'
      const dialogRef = this.dialog.open(PricingCurveSaveAddDialogComponent, {
        width: '40vw',
        height: '30vh',
        data: {
          message: `This action will ${actionText} the selected curve, ${allowText}. Would you like to continue?`,
          confirmMessage: 'Yes',
          cancelMessage: 'No',
          disableCloseButton: true,
        },
        disableClose: true,
        backdropClass: 'backdrop-background',
        panelClass: 'pricing-curve-add-dialog-panel',
      })
      dialogRef.afterClosed().subscribe(confirmed => {
        if (confirmed) {
          this.store.dispatch(
            fromActions.toggleCurveImmutability({ id: this.activeRow.id })
          )
        }
      })
    }
  }

  loadSelectedRow(): void {
    if (
      this.activeRow &&
      this.addedCurveIds &&
      !this.addedCurveIds.includes(this.activeRow.id)
    ) {
      this.store.dispatch(
        fromActions.loadSavedPricingCurve({ id: this.activeRow.id })
      )
      this.matDialogRef.close()
    }
  }

  get deleteDisabledTooltip(): string | undefined {
    if (!this.activeRow) {
      return 'A curve must be selected'
    }
    if (this.activeRow?.isImmutable && !this.isAdmin) {
      return 'Insufficient permissions to modify immutable curve'
    }
  }

  get addDisabledTooltip(): string | undefined {
    if (!this.activeRow) {
      return 'A curve must be selected'
    }
    if (
      this.activeRow &&
      this.addedCurveIds &&
      this.addedCurveIds.includes(this.activeRow.id)
    ) {
      return 'The selected curve is already added'
    }
  }

  get immutableButtonText(): string {
    if (this.activeRow?.isImmutable) {
      return 'Demote'
    }
    return 'Promote'
  }

  addPricingCurveToTechnicalPremiumCalculation(): void {
    if (
      this.activeRow &&
      this.activeRow &&
      this.addedCurveIds &&
      !this.addedCurveIds.includes(this.activeRow.id)
    )
      this.matDialogRef.close(this.activeRow)
  }

  resetSelectors(): void {
    if (this.resetSelectorsActive) {
      this.store.dispatch(fromActions.resetSavedCurveSelectors())
    }
  }

  confirmAndDeleteCurve() {
    // Only internal users should be able to delete pricing curves for credit sage
    // Only defined admins should be able to delete immutable pricing curves
    if (
      (this.usageContext === 'credit' && this.readonly) ||
      (this.activeRow?.isImmutable && !this.isAdmin)
    ) {
      return
    }
    if (this.activeRow) {
      const dialogRef = this.dialog.open(PricingCurveSaveAddDialogComponent, {
        width: '40vw',
        height: '30vh',
        data: {
          message:
            'This action will delete this curve permanently! Would you like to continue?',
          submessage: this.activeRow.isImmutable
            ? ' This Curve is Immutable!'
            : '',
          isSubmessageWarning: this.activeRow.isImmutable,
          confirmMessage: 'Yes',
          cancelMessage: 'No',
          disableCloseButton: true,
        },
        disableClose: true,
        backdropClass: 'backdrop-background',
        panelClass: 'pricing-curve-add-dialog-panel',
      })
      dialogRef.afterClosed().subscribe(confirmed => {
        if (confirmed && this.activeRow) {
          this.store.dispatch(
            fromActions.deleteSavedPricingCurve({
              name: this.activeRow.pc_name,
              id: this.activeRow.id,
            })
          )
        }
      })
    }
  }
}
