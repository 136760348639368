import { getCurrencySymbol } from "@angular/common"
import { Cell, ValueType } from "exceljs"
import { MetricValueType } from "../../../core/model/metric-value-type.model"
import { analyzereConstants } from "../../constants/analyzere"
import { SortTableColumnDef } from "../../sort-table/sort-table.model"
import { TableOptions } from '../services.model/excel-export.model'


const NUMERIC_TYPES: MetricValueType[] = [
  'numeric',
  'percentage',
  'currency',
]

function isNumber(valueType: MetricValueType): boolean {
  return NUMERIC_TYPES.includes(valueType)
}

export function transformValue(value: string | number, valueType?: MetricValueType): string | number {
  if (value === undefined) {
    return ''
  }
  if (typeof value === 'number'){
    if (isNaN(value)){
      return ''
    }
  }
  switch (valueType) {
    case 'ratio':
      return Math.round(100 * (1 / (100 * Number(value))) * 100) / 100 // from metric-text ln 201
    case 'percentage':
      return Math.round((value as number) * 100 * 100) / 100
    case 'currency':
      return Math.round((value as number) * 100) / 100
    default:
      return value
  }
}

/** This is the default formatting for all tables, be careful about changing this code
  * If you really need to change a formatting, consider using the styleFunction inside @param Options
  */
export function formatCell(columnDef: SortTableColumnDef, currencyCode: string, cell: Cell, options?: TableOptions): void{
  const valueType = columnDef.valueType
  let value = cell.value

  if (valueType){
    cell.model = {
      ...cell.model,
      type: isNumber(valueType) ? ValueType.Number : ValueType.String,
    }
  }

  const currencySymbol = getCurrencySymbol(currencyCode, 'narrow')
  // if needed, apply values' variations and exceptions to this cell
  if (valueType === 'percentage') {
    if (value === Infinity) {
      cell.model.type = ValueType.String
      cell.alignment = { horizontal: 'right' }
      value = '∞%'
    } else {
      if (columnDef.decimals === 2) {
        cell.numFmt = '###,##0.00"%"'
      } else {
        cell.numFmt = '#0"%"'
      }
    }
  }

  if (valueType === 'ratio') {
    if (value === 0) {
      value = '-'
    } else {
      value = '1 in ' + value
    }
    cell.model.type = ValueType.String
  }

  if (valueType === 'numeric') {
    if (value === Infinity) {
      cell.model.type = ValueType.String
      cell.alignment = { horizontal: 'right' }
      value = '∞'
    } else {
      cell.numFmt = `###########0.000`
    }
  }

  if (valueType === 'formatted-onedecimal-numeric' && typeof value === 'number') {
    if (isNaN(value)) {
      value = '-'
      cell.model.type = ValueType.String
    } else {
      cell.numFmt = `###,###,###,##0.0`
    }
  }

  if (valueType === 'currency') {
    if ((value || 0) >= analyzereConstants.unlimitedValue) {
      value = 'Unlimited'
      cell.model.type = ValueType.String
      cell.alignment = { horizontal: 'right' }
    } else {
      cell.model.type = ValueType.Number
      if (options && options.currencyShownInThousands){
        cell.numFmt = `"${currencySymbol}"###,###,###,##0,`
      }
      else {
        cell.numFmt = `"${currencySymbol}"###,###,###`
      }
    }
  }

  if (!value) {
    cell.model.type = ValueType.String
  }

  if (typeof value === 'number') {
    // Round extremely small positive values to 0
    if (value < 0.0001 && value > 0) {
      value = 0
    }
  }

  cell.value = value
}

