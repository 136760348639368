import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { AppState } from 'src/app/core/store'
import {
  PricingCurveStatus,
  SavedCurveSelectors,
  SavedPricingCurveEntry,
} from 'src/app/pricingcurve/model/pricing-curve.model'
import {
  selectDialogSavedCurves,
  selectIsUserPricingCurveAdmin,
  selectPricingCurveStatus,
  selectSavedCurveSelectors,
} from 'src/app/pricingcurve/store/pricing-curve.selectors'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-add-curve-dialog',
  styleUrls: ['./add-curve-dialog.component.scss'],
  templateUrl: './add-curve-dialog.component.html',
})
export class AddCurveDialogComponent implements OnInit {
  usageContext = 'technical-premium'
  addedCurveIds: number[] = []
  savedCurves$: Observable<SavedPricingCurveEntry[]>
  savedCurveSelectors$: Observable<SavedCurveSelectors>
  status$: Observable<PricingCurveStatus>
  isAdmin$: Observable<boolean>

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { addedCurveIds: number[] },
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<AddCurveDialogComponent>
  ) {}

  destroyDialog(): void {
    this.dialogRef.close()
  }

  ngOnInit(): void {
    this.addedCurveIds = this.data.addedCurveIds
    this.savedCurves$ = this.store.pipe(select(selectDialogSavedCurves))
    this.savedCurveSelectors$ = this.store.pipe(
      select(selectSavedCurveSelectors)
    )
    this.status$ = this.store.pipe(select(selectPricingCurveStatus))
    this.isAdmin$ = this.store.pipe(select(selectIsUserPricingCurveAdmin))
  }
}
