<div class="chart-controls">
  <section class="left">
    <button appButtonIcon big class="max" [tooltip]="isMaximized ? 'Restore (Show All)' : 'Maximize'"
      tooltipShowDelay="500" (click)="maximizeClick.emit(_chartOption.index)">
      {{ isMaximized ? 'fullscreen_exit' : 'fullscreen' }}
    </button>

    <div>{{ _chartOption.metric.name }}</div>
  </section>

  <section>
    <mat-slide-toggle *ngIf="canHideMetrics" class="reinsurer-toggle" matTooltip="Hide Identifiable Data Points"
      matTooltipPosition="below" [checked]="hideMetrics" (change)="onHideMetricsChange($event.checked)">
      <span class="reinsurer-toggle-label">Hide Details</span>
    </mat-slide-toggle>
    <button *ngIf="hasComparisonValue && !isMaximized" class="info-button" transparent center
      matTooltip="Custom Comparison Line Value">
      <mat-icon inline class="info-icon">info</mat-icon>
    </button>
    <div *ngIf="hasComparisonValue && isMaximized" class="comparison-label">Custom Comparison Value:
    </div>
    <div *ngIf="hasComparisonValue" class="comparison-input-wrapper">
      <input class="comparison-input" type="number" name="comparisonValue" placeholder="Comparison line"
        [(ngModel)]="comparisonValue" [formControl]="comparisonValueControl" />
    </div>
    <div class="chart-menu-options">
      <app-explore-summary-chart-option-menu *ngIf="_split !== null && _splitOptions.length > 1"
        [currentOption]="_split" [menuOptions]="_splitOptions"
        (optionSelectChange)="onSplitTypeChange($event)"></app-explore-summary-chart-option-menu>
      <app-explore-summary-chart-option-menu *ngIf="_size !== null && _sizeOptions.length > 1" [currentOption]="_size"
        [menuOptions]="_sizeOptions"
        (optionSelectChange)="onSizeTypeChange($event)"></app-explore-summary-chart-option-menu>
      <app-explore-summary-chart-option-menu [currentOption]="_chartType" [menuOptions]="selectableChartsForMetric"
        (optionSelectChange)="onChartTypeChange($event)"></app-explore-summary-chart-option-menu>
    </div>
    <ng-container *ngIf="isMaximized">
      <button class="export-button" matTooltipPosition="before" matTooltip="Click to export PNG of displayed chart."
        appButtonIcon accent (click)="onExportClick.emit()">
        cloud_download
      </button>
    </ng-container>
  </section>
</div>