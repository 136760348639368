import { UserPermissions } from '../../model/auth.model'
import {
  BlobResponse,
  CarrierResponse,
  FeatureFlag,
  StudyResponse,
} from '../../../api/model/backend.model'
import {
  SharedLimit,
  SharedLimitMember,
} from '../../../analysis/store/grouper/program-group.model'
import { CompareView } from '../../../analysis/model/compare-view.model'

export interface State {
  username: string | null
  name: string | null
  authenticated: boolean
  redirectURL: string | null
  analyzeReToken: string | null
  marketToken: string | null
  userPermissions: UserPermissions | null
  security: string[] | null
  blobs: {
    thumbnail?: BlobResponse
    carrier?: BlobResponse
    reinsurer?: BlobResponse
    market?: BlobResponse
  }
  carriers: CarrierResponse[] | null
  studies: StudyResponse[] | null
  userPermissionsError: string | null
  token: string | null
  selectedApp: string | null
  authenticatingUser: string | null
  sageLogicalRole: string | null
  featureFlags: FeatureFlag[] | null
  sharedLimits: SharedLimit[] | null
  sharedLimitMembers: SharedLimitMember[] | null
  compareViews: CompareView[] | null
  hasWhitespaceAccess: boolean | null
  usaBasedUser: boolean | null
  facSageUrl: string | null
  salesforceUserRole: string | null
}

export const initialState: State = {
  username: null,
  name: null,
  authenticated: false,
  redirectURL: null,
  analyzeReToken: null,
  marketToken: null,
  userPermissions: null,
  security: null,
  blobs: {},
  carriers: null,
  studies: null,
  userPermissionsError: null,
  token: null,
  selectedApp: null,
  authenticatingUser: null,
  sageLogicalRole: null,
  featureFlags: null,
  sharedLimits: null,
  sharedLimitMembers: null,
  compareViews: null,
  hasWhitespaceAccess: false,
  usaBasedUser: null,
  facSageUrl: null,
  salesforceUserRole: null
}
