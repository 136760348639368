<app-animated-scenarios
  *ngIf="delay$ | async"
  [scenarioEvents]="scenarioEvents$ | async"
  [selectEventResultsForDisplay]="selectEventResultsForDisplay$ | async"
  [lossSets]="lossSets$ | async"
  [scenarioEventsResult]="scenarioEventsResult$ | async"
  [structure]="scenarioStructure$ | async"
  [sharedIDPortfolio]="sharedIDPortfolio$ | async"
  [executing]="executing$ | async"
  [originalLayers]="originalLayers$ | async"
  [originalStructure]="originalStructure$ | async"
  [animating]="animating$ | async"
  [studies]="studies$ | async"
  [name]="name$ | async"
  [currentCurrency]="currentCurrency$ | async"
  (scenarioEventChange)="onScenarioEventChange($event)"
  (addScenarioEvent)="onAddScenarioEvent($event)"
  (scenarioEventDelete)="onScenarioEventDelete($event)"
  (processEvents)="onProcessEvents()"
  (animationEnd)="onAnimationEnd()"
  (reAnimate)="onReAnimate()"
></app-animated-scenarios>
