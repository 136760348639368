import { TreeDatum } from '@shared/checkbox-tree/checkbox-tree.model'
import { UserReinsurer } from '../../api/model/backend.model'
import { CreditPortfolio } from './credit-portfolio.model'
import {
  CreditCalculationStructure,
  CreditStructure,
} from './credit-structure.model'

export type CreditSubmissionStatusCombined =
  | CreditSubmissionStatus
  | CreditSubmissionReinsurerStatus

export interface CreditSubmissionStructure {
  id?: number
  credit_structure_id: number // id of CreditStructure that submission was generated from
  credit_submission_structure_id: string // credit_calculation_structure_id of snapshot structure generated for submission
  program_id: number
  label: string
  description: string
  reinsurer_id: number
  transaction_id: string
  status: CreditSubmissionStatus
  selected_gls: string // comma separated _id from CreditGrossLossScenario values
  portfolio_id: string
  doc_link: string
  reinsurer_ids?: number[]
  removed_reinsurers?: number[]
  // below used to manage UI state / not saved to db
  checked?: boolean
  imageSrc?: string
  year?: number
  audit_insert_dt?: string
  client_id?: number
  carrier_id?: number
  effective_date?: string
  statusClass?: CreditSubmissionStatusClass
  confirmedReinsurerUsers?: Record<number, UserReinsurer[]>
}

export enum CreditSubmissionStatus {
  Draft = 'Draft',
  Final = 'Final', // Approved
  Released = 'Released', // Sent to Market
  Cancelled = 'Cancelled',
}

export const CREDIT_SUBMISSION_STATUS_LIST: CreditSubmissionStatus[] = [
  CreditSubmissionStatus.Draft,
  CreditSubmissionStatus.Final,
  CreditSubmissionStatus.Released,
  CreditSubmissionStatus.Cancelled,
]

export enum CreditSubmissionStatusClass {
  Draft = 'app-palette-orange',
  Final = 'app-palette-green',
  Released = 'app-palette-blue',
  Cancelled = 'app-palette-pink',
}

export interface CreditSubmissionReinsurer {
  id: number
  credit_submission_id: number // id of CreditSubmissionStructure reinsurer associated with
  reinsurer_id: number
  status: CreditSubmissionReinsurerStatus
  viewed_by: string
  viewed_at: string
}

export enum CreditSubmissionReinsurerStatus {
  Pending = 'Pending',
  Viewed = 'Viewed',
  Quoted = 'Quoted',
  Cancelled = 'Cancelled',
  FOT = 'FOT',
  Authorized = 'Authorized',
  Signed = 'Signed',
}

export const CREDIT_SUBMISSION_REINSURER_STATUS_LIST: CreditSubmissionReinsurerStatus[] =
  [
    CreditSubmissionReinsurerStatus.Pending,
    CreditSubmissionReinsurerStatus.Viewed,
    CreditSubmissionReinsurerStatus.Quoted,
    CreditSubmissionReinsurerStatus.FOT,
    CreditSubmissionReinsurerStatus.Authorized,
    CreditSubmissionReinsurerStatus.Signed,
  ]

export interface CreditSubmissionStructureWithProgramData {
  year: number
  portfolio_id: string
  carrier_id: number
  credit_submission_structure: CreditSubmissionStructure
  effective_date: string
}

export interface CreditSubmissionYears {
  year: string
  ids: string[]
}

export interface ClientSearch {
  clients: string[]
  yearIds: string[]
  status?: CreditSubmissionStatusCombined[] // dependent on internal vs external user
}

export interface SimplifiedClient {
  id: string
  name: string
  img: string
}

export interface SubmissionResults {
  initialStructures: CreditSubmissionStructure[][]
  imageIds: Object
}

export interface CreateSubmissionDraftProps {
  portfolio: CreditPortfolio
  creditStructure: CreditStructure
  calculationStructure: CreditCalculationStructure
  transactionId: string
  description: string
}

// TODO: add this when releasing submission ?
// reinsurerUserMap: Record<number, number[]> // reinsurer_id : user_id[]

export interface CreateSubmissionSuccessProps {
  submissionCount: number
  submissionUserTrancheCount: number
}

export interface SubmissionReinsurer {
  reinsurerId: number
  reinsurerName: string
}

export interface CreditSubmissionUserTranche {
  submission_id: number
  user_id: number
  tranche_id: string
  rol: number // N/A for QS
  reinsurer_participation_percent: number
  collateral_percent: number
  ceding_commission: number // N/A for XOL & ILN
  profit_commission: number // N/A for XOL & ILN
}

export interface CreditSubmissionUserTrancheInputs {
  transactionId: string
  trancheId: string // transactionId for QS
  defaultRol: number
  defaulReinsurerParticipationPercent: number
  defaultCollateralPercent: number
  defaultCedingComission: number
  defaultProfitComission: number
}

export interface CreditSubmissionAuthorizeQuoteDialogData {
  reinsurer_participation_percent: number
  rol: number
  tranche_id: string
  comment: string
}

export interface CreditSubmissionAuthorizationRequest {
  submission_id: number
  tranche_id: string
  rol: number
  reinsurer_participation_percent: number
  comments: string
}

export type CreditSubmissionTreeNode = {
  nodeId: number
  nodeType: CreditSubmissionTreeNodeType
  nodeTypeId: number // reinsurerId or userId
  isChecked: boolean | 'mixed'
}

export type CreditSubmissionTreeNodeType = 'reinsurer' | 'user'
