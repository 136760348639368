import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import * as Papa from 'papaparse'
import { uniq } from 'ramda'
import { forkJoin, Observable, of, throwError } from 'rxjs'
import {
  delay,
  map,
  mergeMap,
  retryWhen,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'
import {
  buildFromPreviousEvent,
  EventCatalogDataRow,
  EventLayer,
  LayersViewsYELT,
  LossSetDataRow,
  PortfoliosViewYELT,
  processEvent,
  ScenarioEvent,
  ScenarioEventResult,
  ViewYeltRow,
} from 'src/app/analysis/animated-scenarios/animated-scenarios.model'
import {
  filterAndMapForClone,
  filterLayers,
} from 'src/app/analysis/animated-scenarios/util/filters'
import {
  isIndexedLayer,
  isIndexedVisibleLayer,
} from 'src/app/analysis/layers/indexed-layer'
import { IndexedLayerService } from 'src/app/analysis/layers/indexed-layer.service'
import {
  isMultiSectionLayer,
  isMultiSectionMainLayer,
} from 'src/app/analysis/layers/multi-section-layer'
import { isSwingLayer } from 'src/app/analysis/layers/swing-layer'
import { convertFromLogicalPortfolioLayers } from 'src/app/analysis/model/layers.converter'
import { Layer, LayerRef } from 'src/app/analysis/model/layers.model'
import {
  createCededLayerRefs,
  isLayerActualRisk,
  isLayerActualTopAndDrop,
  isLayerAgg,
  isLayerAggFeeder,
  isLoadedLossSet,
  partitionLayersByTopAndDrop,
  updateActaulFHCFLogicalLayerRefs,
  updateTopAndDropLogicalLayerRefs,
} from 'src/app/analysis/model/layers.util'
import {
  isLossSet,
  LossSetLayer as stateLossSetLayer,
} from 'src/app/analysis/model/loss-set-layers.model'
import { PortfoliosIDAndName } from 'src/app/analysis/model/portfolio-set.model'
import {
  selectAllGrouperProgramCededLayers,
  selectCededLayers,
  selectLossSetLayers,
  selectMapLossSets,
} from 'src/app/analysis/store/analysis.selectors'
import { LayerState } from 'src/app/analysis/store/ceded-layers/layers.reducer'
import { SharedLimitLayerSelection } from 'src/app/analysis/store/grouper/program-group.model'
import {
  asRef,
  Data,
  EventCatalog,
  isData,
  LayerViewResponse,
  LoadedLossSet,
  LogicalPortfolioLayer,
  LossSet,
  LossSetLayer,
  PhysicalPortfolioLayer,
  Portfolio,
  PortfolioViewsResponse,
  Ref,
  Update,
} from 'src/app/api/analyzere/analyzere.model'
import { AnalyzreService } from 'src/app/api/analyzere/analyzre.service'
import { SharedLimitRef } from 'src/app/api/animated-scenarios/model'
import { parseCsvResponse } from 'src/app/api/animated-scenarios/util'
import { UtilService } from 'src/app/api/animated-scenarios/util.service'
import { BackendService } from 'src/app/api/backend/backend.service'
import { InuranceService } from 'src/app/api/inurance/inurance.service'
import { ApiResponse, MaybeData, MaybeError } from 'src/app/api/model/api.model'
import { StructureLayerDataResponse } from 'src/app/api/model/backend.model'
import { ProgramService } from 'src/app/api/program/program.service'
import { SharedLimitService } from 'src/app/api/shared-limit/shared-limit.service'
import {
  executeSequentially,
  mapAssoc,
  mapResponse,
  switchMapAssoc,
} from 'src/app/api/util'
import { Program } from 'src/app/core/model/program.model'
import { AppState } from 'src/app/core/store'
import { selectProgramGroupsByID } from '../../core/store/program-group/program-group.selectors'
import {
  selectDirtyProgram,
  selectPrograms,
  selectProgramsByID,
} from '../../core/store/program/program.selectors'
import { errorPayload } from 'src/app/error/model/error'
import { selectDesignProgramIDS } from '../../core/store/broker/broker.selectors'

export interface IndexHiddenLayers {
  id: string
  settlementRefs: Ref[]
  lossLayerRef: Ref
}

export interface CloneStructureOptions {
  /**
   * Save Structure to backend.
   */
  persistStructure?: boolean
  /**
   * Save work in progress layers. Usually from the Edit page.
   */
  handleDirty?: boolean
  /**
   * Optional. Override Gross LossSet Data CSV for Loss Scenarios. Deep copy of Gross Portfolio.
   */
  scenarioEvents?: ScenarioEvent[]
  eventIDs?: number[]
  asScenario?: boolean
  asOptimization?: boolean

  // In the case of libRE temaplates we need to switch the null study_id to whatever the current study_id is.
  studyID?: string
}
// tslint:disable: no-non-null-assertion
@Injectable({
  providedIn: 'root',
})
export class AnimatedScenariosService {
  constructor(
    private analyzereService: AnalyzreService,
    private store: Store<AppState>,
    private programService: ProgramService,
    private backendService: BackendService,
    private utilService: UtilService,
    private sharedLimitService: SharedLimitService,
    private inuranceService: InuranceService,
    private indexedService: IndexedLayerService
  ) {}

  processStructureGroupWithSharedLimits(
    structureGroupID: string, // Structure Group ID that needs to be cloned.
    scenarioEvents: ScenarioEvent[]
  ): ApiResponse<{
    cededLayers: LogicalPortfolioLayer[]
    portfolioIDs: PortfoliosIDAndName
    eventIDs: number[]
  }> {
    return of(1).pipe(
      withLatestFrom(
        this.store.pipe(select(selectProgramGroupsByID)),
        this.store.pipe(select(selectPrograms))
      ),
      switchMap(([_, structureGroupsByID, structures]) => {
        const group = structureGroupsByID[structureGroupID]
        if (group) {
          const cPOrtfolioID = group.cededPortfolioID as string
          return this.analyzereService.fetchPortfolio(cPOrtfolioID).pipe(
            mergeMap(res => {
              if (res.error) {
                return of({ error: res.error }) as ApiResponse<SharedLimitRef[]>
              }
              return this.utilService.getSharedLimitRelationships(
                convertFromLogicalPortfolioLayers(
                  res.data!.layers as LogicalPortfolioLayer[]
                ),
                structures
              )
            }),
            mergeMap(res => {
              if (res.error) {
                return of({ error: res.error })
              }
              const sharedLimitRefs = res.data!
              if (sharedLimitRefs.length > 0) {
                const originalStructureSaveAs = this.processStructureGroup(
                  structureGroupID,
                  scenarioEvents
                )
                const otherClonedStructureRecord: Record<
                  string,
                  ApiResponse<{
                    program: Program
                    cededLayers: LogicalPortfolioLayer[]
                    portfolioIDs: PortfoliosIDAndName
                  }>
                > = {}
                sharedLimitRefs.forEach(sharedLimitRef => {
                  sharedLimitRef.layersWithStructure.forEach(sl => {
                    if (
                      !sl.fromSaveAsStructure &&
                      !otherClonedStructureRecord[sl.structureID]
                    ) {
                      otherClonedStructureRecord[sl.structureID] =
                        this.cloneStructure(
                          sl.structureID,
                          sl.label + `- ${new Date().getTime()}`,
                          sl.description || '',
                          { persistStructure: false, handleDirty: false }
                        )
                    }
                  })
                })
                return forkJoin([
                  originalStructureSaveAs,
                  ...Object.keys(otherClonedStructureRecord).map(
                    k => otherClonedStructureRecord[k]
                  ),
                ]).pipe(
                  map(forkJoinResults => {
                    for (const forkJoinResult of forkJoinResults) {
                      if (forkJoinResult.error) {
                        return { error: forkJoinResult.error }
                      }
                    }
                    const oldToNewReferenceRecord: Record<
                      string,
                      {
                        program: Program
                        cededLayers: LogicalPortfolioLayer[]
                        portfolioIDs: PortfoliosIDAndName
                      }
                    > = {}
                    const otherPrograms = forkJoinResults
                      .map(f => f.data!)
                      .slice(1)
                      .map(
                        (f: {
                          program: Program
                          cededLayers: LogicalPortfolioLayer[]
                          portfolioIDs: PortfoliosIDAndName
                        }) => f.program
                      )
                    const oldStructureKeys = Object.keys(
                      otherClonedStructureRecord
                    )
                    forkJoinResults
                      .map(f => f.data!)
                      .slice(1)
                      .forEach((fj, i) => {
                        oldToNewReferenceRecord[oldStructureKeys[i]] = fj as {
                          program: Program
                          cededLayers: LogicalPortfolioLayer[]
                          portfolioIDs: PortfoliosIDAndName
                        }
                      })
                    return {
                      ...forkJoinResults[0],
                      data: {
                        ...forkJoinResults[0].data!,
                        otherPrograms,
                        oldToNewReferenceRecord,
                      },
                    } as MaybeError &
                      MaybeData<{
                        cededLayers: LogicalPortfolioLayer[]
                        eventIDs: number[]
                        portfolioIDs: PortfoliosIDAndName
                        otherPrograms?: Program[]
                        oldToNewReferenceRecord: Record<
                          string,
                          {
                            program: Program
                            cededLayers: LogicalPortfolioLayer[]
                            portfolioIDs: PortfoliosIDAndName
                          }
                        >
                      }>
                  }),
                  mergeMap(results => {
                    if (results.error) {
                      return of({ error: results.error }) as Observable<
                        MaybeError &
                          MaybeData<{
                            netPortfolios: Portfolio[]
                            eventIDs: number[]
                            cededLayers: LogicalPortfolioLayer[]
                            portfolioIDs: PortfoliosIDAndName
                            otherPrograms?: Program[]
                            oldToNewReferenceRecord: Record<
                              string,
                              {
                                program: Program
                                cededLayers: LogicalPortfolioLayer[]
                                portfolioIDs: PortfoliosIDAndName
                              }
                            >
                          }>
                      >
                    } else {
                      const allNetPortfolios = [
                        results.data!.portfolioIDs.netPortfolioID,
                      ]
                      Object.keys(
                        results.data!.oldToNewReferenceRecord
                      ).forEach(key => {
                        if (results.data!.oldToNewReferenceRecord[key]) {
                          allNetPortfolios.push(
                            results.data!.oldToNewReferenceRecord[key].program
                              .netPortfolioID
                          )
                        }
                      })
                      return this.analyzereService
                        .fetchPortfolios(allNetPortfolios)
                        .pipe(
                          map(r => {
                            if (r.error) {
                              return { error: r.error }
                            }
                            return {
                              ...results,
                              data: {
                                ...results.data!,
                                netPortfolios: r.data!,
                              },
                            }
                          })
                        ) as Observable<
                        MaybeError &
                          MaybeData<{
                            netPortfolios: Portfolio[]
                            eventIDs: number[]
                            cededLayers: LogicalPortfolioLayer[]
                            portfolioIDs: PortfoliosIDAndName
                            otherPrograms?: Program[]
                            oldToNewReferenceRecord: Record<
                              string,
                              {
                                program: Program
                                cededLayers: LogicalPortfolioLayer[]
                                portfolioIDs: PortfoliosIDAndName
                              }
                            >
                          }>
                      >
                    }
                  }),
                  mergeMap(results => {
                    if (results.error) {
                      return of({ error: results.error })
                    }
                    const actions: Observable<MaybeError>[] = []
                    sharedLimitRefs.forEach(sharedLimitRef => {
                      const sharedLayer: Layer = {
                        ...sharedLimitRef.sharedLayer,
                        layerRefs: [],
                        meta_data: {
                          ...sharedLimitRef.sharedLayer.meta_data,
                          nestedLayersCededPortfolioRecord: undefined,
                          nestedLayersNetPortfolioRecord: undefined,
                        },
                      }
                      const selectedLayerEntities: SharedLimitLayerSelection[] =
                        []

                      sharedLimitRef.layersWithStructure.forEach(sl => {
                        let newLayer: LogicalPortfolioLayer | undefined
                        if (sl.fromSaveAsStructure) {
                          newLayer = results.data!.cededLayers.find(
                            l => l.meta_data.fromLayerID === sl.layerID
                          )
                        } else {
                          newLayer = results.data!.oldToNewReferenceRecord[
                            sl.structureID
                          ].cededLayers.find(
                            l => l.meta_data.fromLayerID === sl.layerID
                          )
                        }
                        const cededLayers = convertFromLogicalPortfolioLayers(
                          sl.fromSaveAsStructure
                            ? results.data!.cededLayers
                            : results.data!.oldToNewReferenceRecord[
                                sl.structureID
                              ].cededLayers
                        ).map(l => ({
                          layer: l,
                          dirty: false,
                          new: false,
                          deleted: false,
                          hash: '',
                        }))
                        const cededPortfolioID = sl.fromSaveAsStructure
                          ? results.data!.portfolioIDs.cededPortfolioID
                          : results.data!.oldToNewReferenceRecord[
                              sl.structureID
                            ].program.cededPortfolioID
                        const netPortfolioID = sl.fromSaveAsStructure
                          ? results.data!.portfolioIDs.netPortfolioID
                          : results.data!.oldToNewReferenceRecord[
                              sl.structureID
                            ].program.netPortfolioID
                        const netPortfolioLayersIDs = (
                          results.data!.netPortfolios.find(
                            p => p.id === netPortfolioID
                          )!.layers as LogicalPortfolioLayer[]
                        ).map(l => l.id)
                        if (newLayer) {
                          selectedLayerEntities.push({
                            layerID: newLayer.id,
                            entityID: '',
                            analysisID: sharedLimitRef.analysisProfileID,
                            cededLayers,
                            cededPortfolioID,
                            netPortfolioLayersIDs,
                            netPortfolioID,
                          })
                        }
                      })
                      actions.push(
                        this.sharedLimitService.addSharedLimit(
                          {
                            ...sharedLayer,
                            layerRefs: selectedLayerEntities.map(
                              s => s.layerID
                            ),
                          },
                          selectedLayerEntities
                        )
                      )
                    })
                    return executeSequentially(actions).pipe(
                      mergeMap(seqResponse => {
                        if (seqResponse.error) {
                          return of({
                            error: seqResponse.error,
                          }) as ApiResponse<{
                            cededLayers: LogicalPortfolioLayer[]
                            eventIDs: number[]
                            portfolioIDs: PortfoliosIDAndName
                            otherPrograms?: Program[]
                          }>
                        } else {
                          return this.analyzereService
                            .fetchPortfolio(
                              results.data!.portfolioIDs.cededPortfolioID
                            )
                            .pipe(
                              map(r => {
                                if (r.error) {
                                  return { error: r.error }
                                } else {
                                  return {
                                    data: {
                                      ...results.data,
                                      cededLayers: r.data!.layers,
                                    },
                                  }
                                }
                              })
                            ) as ApiResponse<{
                            cededLayers: LogicalPortfolioLayer[]
                            eventIDs: number[]
                            portfolioIDs: PortfoliosIDAndName
                            otherPrograms?: Program[]
                          }>
                        }
                      })
                    )
                  })
                )
              } else {
                return this.processStructureGroup(
                  structureGroupID,
                  scenarioEvents
                )
              }
            })
          )
        } else {
          return of({
            error: errorPayload(
              `Structure Group ${structureGroupID} not found`
            ),
          })
        }
      })
    )
  }

  processStructureGroup(
    structureGroupID: string, // Structure Group ID that needs to be cloned.
    scenarioEvents: ScenarioEvent[]
  ): ApiResponse<{
    cededLayers: LogicalPortfolioLayer[]
    portfolioIDs: PortfoliosIDAndName
    eventIDs: number[]
  }> {
    return of(1).pipe(
      withLatestFrom(
        this.store.pipe(select(selectProgramGroupsByID)),
        this.store.pipe(select(selectAllGrouperProgramCededLayers))
      ),
      switchMap(([_, structureGroupsByID, groupCededLayers]) => {
        const layerRefs = groupCededLayers.flatMap(layer => layer.lossSetLayers)
        const group = structureGroupsByID[structureGroupID]
        if (group) {
          const grossPortfolioID = group.grossPortfolioID
          const cededPortfolioID = group.cededPortfolioID
          const netPortfolioID = group.netPortfolioID
          const eventCatalogDataID =
            this.getLossSetDataIdWithMinSizeForGroups(layerRefs)
          return this.getEventIds(
            scenarioEvents.length,
            eventCatalogDataID
          ).pipe(
            mergeMap(response => {
              if (response.error) {
                return of({ error: response.error })
              } else {
                const eventIDs = response.data!
                return this.cloneGrossPortfolio(
                  grossPortfolioID || '',
                  `Animated Loss Group Portfolio ${new Date().getTime()}`,
                  scenarioEvents,
                  eventIDs
                ).pipe(
                  switchMap(res => {
                    if (res.error) {
                      return of({ error: res.error })
                    } else {
                      const grossPortfolio = res.data!.portfolio as Portfolio
                      return this.finalizeProcessStructureGroup(
                        cededPortfolioID || '',
                        res.data!.lossSetLayersRecord,
                        res.data!.lossSetsRecord,
                        res.data!.loadedLossSetsRecord,
                        `Animated Loss Group Portfolio ${new Date().getTime()}`,
                        grossPortfolio,
                        netPortfolioID || ''
                      ).pipe(
                        map(r => {
                          if (!r.error && r.data) {
                            return {
                              ...r,
                              data: {
                                ...r.data,
                                eventIDs,
                              },
                            }
                          } else {
                            return { ...r }
                          }
                        })
                      ) as ApiResponse<{
                        cededLayers: LogicalPortfolioLayer[]
                        portfolioIDs: PortfoliosIDAndName
                        eventIDs: number[]
                      }>
                    }
                  })
                )
              }
            })
          )
        } else {
          return of({
            error: errorPayload(
              `Structure Group ${structureGroupID} not found`
            ),
          })
        }
      })
    )
  }

  cloneStructureWithSharedLimit(
    structureID: string, // Structure ID that needs to be cloned.
    newName: string,
    description: string,
    opts?: CloneStructureOptions,
    inuranceCededLayers?: LogicalPortfolioLayer[]
  ): ApiResponse<{
    program: Program
    cededLayers: LogicalPortfolioLayer[]
    portfolioIDs: PortfoliosIDAndName
    otherPrograms?: Program[]
  }> {
    let obsLayers: Observable<Layer[]>
    if (opts?.handleDirty) {
      obsLayers = of(1).pipe(
        withLatestFrom(this.store.pipe(select(selectCededLayers))),
        map(([_, layers]) => layers.filter(l => !l.deleted).map(l => l.layer))
      )
    } else {
      obsLayers = of(1).pipe(
        withLatestFrom(this.store.pipe(select(selectPrograms))),
        mergeMap(([_, structures]) => {
          const structure = structures.find(s => s.id === structureID)
          return this.analyzereService
            .fetchPortfolio(structure!.cededPortfolioID)
            .pipe(
              map(res => {
                if (res.error) {
                  return []
                } else {
                  return convertFromLogicalPortfolioLayers(
                    res.data!.layers as LogicalPortfolioLayer[]
                  )
                }
              })
            )
        })
      )
    }
    return obsLayers.pipe(
      withLatestFrom(this.store.pipe(select(selectPrograms))),
      mergeMap(([layers, structures]) => {
        return this.utilService.getSharedLimitRelationships(layers, structures)
      }),

      mergeMap(response => {
        if (response.error) {
          return of({ error: response.error })
        }
        const sharedLimitRefs = response.data!
        if (sharedLimitRefs.length > 0) {
          const originalStructureSaveAs = this.cloneStructure(
            structureID,
            newName,
            description,
            opts,
            inuranceCededLayers
          )
          const otherClonedStructureRecord: Record<
            string,
            ApiResponse<{
              program: Program
              cededLayers: LogicalPortfolioLayer[]
              portfolioIDs: PortfoliosIDAndName
            }>
          > = {}
          sharedLimitRefs.forEach(sharedLimitRef => {
            sharedLimitRef.layersWithStructure.forEach(sl => {
              if (
                !sl.fromSaveAsStructure &&
                !otherClonedStructureRecord[sl.structureID]
              ) {
                otherClonedStructureRecord[sl.structureID] =
                  this.cloneStructure(
                    sl.structureID,
                    sl.label + `- ${new Date().getTime()}`,
                    sl.description || '',
                    opts ? { ...opts, handleDirty: false } : undefined
                  )
              }
            })
          })
          return forkJoin([
            originalStructureSaveAs,
            ...Object.keys(otherClonedStructureRecord).map(
              k => otherClonedStructureRecord[k]
            ),
          ]).pipe(
            map(forkJoinResults => {
              for (const forkJoinResult of forkJoinResults) {
                if (forkJoinResult.error) {
                  return { error: forkJoinResult.error }
                }
              }
              const oldToNewReferenceRecord: Record<
                string,
                {
                  program: Program
                  cededLayers: LogicalPortfolioLayer[]
                  portfolioIDs: PortfoliosIDAndName
                }
              > = {}
              const otherPrograms = forkJoinResults
                .map(f => f.data!)
                .slice(1)
                .map(f => f.program)
              const oldStructureKeys = Object.keys(otherClonedStructureRecord)
              forkJoinResults
                .map(f => f.data!)
                .slice(1)
                .forEach((fj, i) => {
                  oldToNewReferenceRecord[oldStructureKeys[i]] = fj
                })
              return {
                ...forkJoinResults[0],
                data: {
                  ...forkJoinResults[0].data!,
                  otherPrograms,
                  oldToNewReferenceRecord,
                },
              } as MaybeError &
                MaybeData<{
                  program: Program
                  cededLayers: LogicalPortfolioLayer[]
                  portfolioIDs: PortfoliosIDAndName
                  otherPrograms?: Program[]
                  oldToNewReferenceRecord: Record<
                    string,
                    {
                      program: Program
                      cededLayers: LogicalPortfolioLayer[]
                      portfolioIDs: PortfoliosIDAndName
                    }
                  >
                }>
            }),
            mergeMap(results => {
              if (results.error) {
                return of({ error: results.error }) as Observable<
                  MaybeError &
                    MaybeData<{
                      program: Program
                      netPortfolios: Portfolio[]
                      cededLayers: LogicalPortfolioLayer[]
                      portfolioIDs: PortfoliosIDAndName
                      otherPrograms?: Program[]
                      oldToNewReferenceRecord: Record<
                        string,
                        {
                          program: Program
                          cededLayers: LogicalPortfolioLayer[]
                          portfolioIDs: PortfoliosIDAndName
                        }
                      >
                    }>
                >
              } else {
                const allNetPortfolios = [results.data!.program.netPortfolioID]
                Object.keys(results.data!.oldToNewReferenceRecord).forEach(
                  key => {
                    if (results.data!.oldToNewReferenceRecord[key]) {
                      allNetPortfolios.push(
                        results.data!.oldToNewReferenceRecord[key].program
                          .netPortfolioID
                      )
                    }
                  }
                )
                return this.analyzereService
                  .fetchPortfolios(allNetPortfolios)
                  .pipe(
                    map(r => {
                      if (r.error) {
                        return { error: r.error }
                      }
                      return {
                        ...results,
                        data: { ...results.data!, netPortfolios: r.data! },
                      }
                    })
                  ) as Observable<
                  MaybeError &
                    MaybeData<{
                      program: Program
                      netPortfolios: Portfolio[]
                      cededLayers: LogicalPortfolioLayer[]
                      portfolioIDs: PortfoliosIDAndName
                      otherPrograms?: Program[]
                      oldToNewReferenceRecord: Record<
                        string,
                        {
                          program: Program
                          cededLayers: LogicalPortfolioLayer[]
                          portfolioIDs: PortfoliosIDAndName
                        }
                      >
                    }>
                >
              }
            }),
            mergeMap(results => {
              if (results.error) {
                return of({ error: results.error })
              }
              const actions: Observable<MaybeError>[] = []
              sharedLimitRefs.forEach(sharedLimitRef => {
                const sharedLayer: Layer = {
                  ...sharedLimitRef.sharedLayer,
                  layerRefs: [],
                  meta_data: {
                    ...sharedLimitRef.sharedLayer.meta_data,
                    nestedLayersCededPortfolioRecord: undefined,
                    nestedLayersNetPortfolioRecord: undefined,
                  },
                }
                const selectedLayerEntities: SharedLimitLayerSelection[] = []

                sharedLimitRef.layersWithStructure.forEach(sl => {
                  let newLayer: LogicalPortfolioLayer | undefined
                  if (sl.fromSaveAsStructure) {
                    newLayer = results.data!.cededLayers.find(
                      l => l.meta_data.fromLayerID === sl.layerID
                    )
                  } else {
                    newLayer = results.data!.oldToNewReferenceRecord[
                      sl.structureID
                    ].cededLayers.find(
                      l => l.meta_data.fromLayerID === sl.layerID
                    )
                  }
                  const cededLayers = convertFromLogicalPortfolioLayers(
                    sl.fromSaveAsStructure
                      ? results.data!.cededLayers
                      : results.data!.oldToNewReferenceRecord[sl.structureID]
                          .cededLayers
                  ).map(l => ({
                    layer: l,
                    dirty: false,
                    new: false,
                    deleted: false,
                    hash: '',
                  }))
                  const cededPortfolioID = sl.fromSaveAsStructure
                    ? results.data!.program.cededPortfolioID
                    : results.data!.oldToNewReferenceRecord[sl.structureID]
                        .program.cededPortfolioID
                  const netPortfolioID = sl.fromSaveAsStructure
                    ? results.data!.program.netPortfolioID
                    : results.data!.oldToNewReferenceRecord[sl.structureID]
                        .program.netPortfolioID
                  const netPortfolioLayersIDs = (
                    results.data!.netPortfolios.find(
                      p => p.id === netPortfolioID
                    )!.layers as LogicalPortfolioLayer[]
                  ).map(l => l.id)
                  if (newLayer) {
                    selectedLayerEntities.push({
                      layerID: newLayer.id,
                      entityID: '',
                      analysisID: sharedLimitRef.analysisProfileID,
                      cededLayers,
                      cededPortfolioID,
                      netPortfolioLayersIDs,
                      netPortfolioID,
                    })
                  }
                })
                actions.push(
                  this.sharedLimitService.addSharedLimit(
                    {
                      ...sharedLayer,
                      layerRefs: selectedLayerEntities.map(s => s.layerID),
                    },
                    selectedLayerEntities
                  )
                )
              })
              return executeSequentially(actions).pipe(
                mergeMap(res => {
                  if (res.error) {
                    return of({ error: res.error }) as ApiResponse<{
                      program: Program
                      cededLayers: LogicalPortfolioLayer[]
                      portfolioIDs: PortfoliosIDAndName
                      otherPrograms?: Program[]
                    }>
                  } else {
                    return this.analyzereService
                      .fetchPortfolio(results.data!.program.cededPortfolioID)
                      .pipe(
                        map(r => {
                          if (r.error) {
                            return { error: r.error }
                          } else {
                            return {
                              data: {
                                ...results.data,
                                cededLayers: r.data!.layers,
                              },
                            }
                          }
                        })
                      ) as ApiResponse<{
                      program: Program
                      cededLayers: LogicalPortfolioLayer[]
                      portfolioIDs: PortfoliosIDAndName
                      otherPrograms?: Program[]
                    }>
                  }
                })
              )
            })
          )
        } else {
          return this.cloneStructure(structureID, newName, description, opts, inuranceCededLayers)
        }
      })
    )
  }

  cloneStructure(
    structureID: string, // Structure ID that needs to be cloned.
    newName: string,
    description: string,
    opts?: CloneStructureOptions,
    inuranceLayers?: LogicalPortfolioLayer[]
  ): ApiResponse<{
    program: Program
    cededLayers: LogicalPortfolioLayer[]
    portfolioIDs: PortfoliosIDAndName
    otherPrograms?: Program[]
  }> {
    return of(1).pipe(
      withLatestFrom(
        this.store.pipe(select(selectProgramsByID)),
        this.store.pipe(select(selectDirtyProgram)),
        this.store.pipe(select(selectLossSetLayers))
      ),
      switchMap(([_, structuresByID, dirtyProgram, lossSetLayers]) => {
        let originalStructure = structuresByID[structureID]
        if (opts?.handleDirty && dirtyProgram?.id === structureID) {
          originalStructure = dirtyProgram
        }
        let obsCededLayers: Observable<LayerState[]>
        if (opts?.handleDirty) {
          obsCededLayers = of(1).pipe(
            withLatestFrom(this.store.pipe(select(selectCededLayers))),
            map(([__, cededLayers]) => cededLayers)
          )
        } else {
          obsCededLayers = of([])
        }
        if (originalStructure) {
          const grossPortfolioID = originalStructure.grossPortfolioID
          const cededPortfolioID = originalStructure.cededPortfolioID
          const netPortfolioID = originalStructure.netPortfolioID
          const layerData = originalStructure.layerData
          const parentGrossPortfolioID =
            originalStructure.parentGrossPortfolioID
          return this.cloneGrossPortfolio(
            grossPortfolioID,
            newName,
            opts?.scenarioEvents,
            opts?.eventIDs,
            opts?.handleDirty ? lossSetLayers : undefined
          ).pipe(
            switchMap(res => {
              if (res.error) {
                return of({ error: res.error })
              } else {
                const grossPortfolio = res.data!.portfolio as Portfolio
                return this.finalizeClone(
                  cededPortfolioID,
                  res.data!.lossSetLayersRecord,
                  res.data!.lossSetsRecord,
                  res.data!.loadedLossSetsRecord,
                  newName,
                  description,
                  grossPortfolio,
                  netPortfolioID,
                  originalStructure!,
                  obsCededLayers,
                  layerData,
                  parentGrossPortfolioID,
                  undefined,
                  opts,
                  inuranceLayers
                )
              }
            })
          )
        } else {
          return of({
            error: errorPayload(`Structure ${structureID} not found`),
          })
        }
      })
    )
  }
  mapLossSets(
    groupLossSets: Record<string, LogicalPortfolioLayer[]>,
    cededLossSets: LogicalPortfolioLayer[][]
  ): any[][] {
    const otherLossSetsResult = []
    for (const layer of cededLossSets) {
      const otherLossSets = new Set()
      for (const lossSet of layer) {
        const lossSetName = lossSet.description || ''
        if (groupLossSets[lossSetName]) {
          groupLossSets[lossSetName].forEach(
            (otherLossSet: { description: unknown }) =>
              otherLossSets.add(otherLossSet)
          )
        }
      }
      otherLossSetsResult.push([...otherLossSets])
    }
    return otherLossSetsResult
  }
  groupLossSets(
    mappedLossSets: any[][],
    lossSetNames: string[]
  ): Record<string, any[]> {
    const groupedLossSets: Record<string, any[]> = {}

    for (const mappedLayer of mappedLossSets) {
      for (const lossSet of mappedLayer) {
        if (!lossSet.description.startsWith(lossSetNames[1])) {
          const key = lossSet.description

          const otherLossSets: LossSet[] = mappedLayer.filter(item =>
            item.description.startsWith(lossSetNames[1])
          )

          if (!groupedLossSets[key]) {
            groupedLossSets[key] = otherLossSets
          } else {
            groupedLossSets[key].push(...otherLossSets)
          }
        }
      }
    }

    return groupedLossSets
  }

  mappedLossSetLayers: any[][]

  cloneBulkStructure(
    structureID: string,
    newName: string,
    description: string,
    parentGrossPortfolioId: string,
    analysisProfileID: string,
    layers?: stateLossSetLayer[] | LayerRef[],
    cededLayers?: LogicalPortfolioLayer[],
    lossSetNames?: string[],
    opts?: CloneStructureOptions,
    autobuildID?: string
  ): ApiResponse<{
    program: Program
    cededLayers: LogicalPortfolioLayer[]
    portfolioIDs: PortfoliosIDAndName
    otherPrograms?: Program[]
  }> {
    return of(1).pipe(
      withLatestFrom(
        this.store.pipe(select(selectProgramsByID)),
        this.store.pipe(select(selectDesignProgramIDS)),
        this.store.pipe(select(selectMapLossSets))
      ),
      switchMap(([_, structuresByID, designPrograms, mappedLossSets]) => {
        const obsCededLayers: Observable<LayerState[]> = of([])
        let originalStructure = structuresByID[structureID]

        if (designPrograms[0]?.id === structureID) {
          originalStructure = designPrograms[0]
        }

        if (!originalStructure) {
          return of({
            error: errorPayload(`Structure ${structureID} not found`),
          })
        }

        const grossPortfolioID = originalStructure.grossPortfolioID
        const cededPortfolioID = originalStructure.cededPortfolioID
        const netPortfolioID = originalStructure.netPortfolioID
        const layerData = originalStructure.layerData
        const parentGrossPortfolioID = parentGrossPortfolioId
          ? parentGrossPortfolioId
          : undefined

        if (cededLayers && lossSetNames) {
          const cededLossSets = cededLayers.map(layer => layer.sources)
          this.mappedLossSetLayers = this.mapLossSets(
            this.groupLossSets(mappedLossSets, lossSetNames),
            cededLossSets as LogicalPortfolioLayer[][]
          )
        }

        return this.cloneGrossPortfolio(
          grossPortfolioID,
          newName,
          opts?.scenarioEvents,
          opts?.eventIDs,
          originalStructure?.libRE ? undefined : layers
        ).pipe(
          switchMap(res => {
            if (res.error) {
              return of({ error: res.error })
            }
            const inuranceLayers : LogicalPortfolioLayer[] = [] // Pass empty array. We are passing it for calculating animated scenarios
            return this.finalizeClone(
              cededPortfolioID,
              res.data!.lossSetLayersRecord,
              res.data!.lossSetsRecord,
              res.data!.loadedLossSetsRecord,
              newName,
              description,
              res.data!.portfolio as Portfolio,
              netPortfolioID,
              originalStructure!,
              obsCededLayers,
              layerData,
              parentGrossPortfolioID,
              analysisProfileID,
              opts,
              inuranceLayers,
              true,
              this.mappedLossSetLayers,
              autobuildID
            )
          })
        )
      })
    )
  }

  private cloneGrossPortfolio(
    grossPortfolioID: string,
    newName: string,
    scenarioEvents?: ScenarioEvent[],
    eventIDs?: number[],
    layers?: stateLossSetLayer[] | LayerRef[]
  ): ApiResponse<{
    portfolio: Portfolio
    lossSetLayersRecord: Record<string, string>
    lossSetsRecord: Record<string, string>
    loadedLossSetsRecord: Record<string, string>
  }> {
    if (scenarioEvents && scenarioEvents.length > 0 && eventIDs !== undefined) {
      return this.analyzereService.fetchPortfolio(grossPortfolioID).pipe(
        switchMap(grossPortfolio => {
          if (grossPortfolio.error) {
            return of({ error: grossPortfolio.error }) as ApiResponse<{
              lossSetLayersRecord: Record<string, string>
              layers: LossSetLayer[]
              lossSetsRecord: Record<string, string>
              loadedLossSetsRecord: Record<string, string>
            }>
          } else {
            const lossSetLayers = (grossPortfolio.data as Portfolio)
              .layers as LossSetLayer[]
            const createActions = lossSetLayers.map(l =>
              this.createLossSetLayers(l, scenarioEvents, eventIDs)
            )
            return forkJoin(createActions).pipe(
              map(responses => {
                for (const response of responses) {
                  if (response.error) {
                    return { error: response.error }
                  }
                }
                const lossSetLayersRecord = responses.reduce((acc, next) => {
                  const oldLossSetLayer = next.data!.lossSetLayer
                  const newLossSetLayer = next.data!.newLossSetLayer
                  acc[oldLossSetLayer.id] = newLossSetLayer.id
                  return acc
                }, {} as Record<string, string>)
                const lossSetsRecord = responses.reduce((acc, next) => {
                  const oldLossSetLayer = next.data!.lossSetLayer
                  const newLossSetLayer = next.data!.newLossSetLayer
                  if (!isLoadedLossSet(oldLossSetLayer.loss_sets[0])) {
                    acc[(oldLossSetLayer.loss_sets[0] as LossSet).id] = (
                      newLossSetLayer.loss_sets[0] as LossSet
                    ).id
                  }
                  return acc
                }, {} as Record<string, string>)
                const loadedLossSetsRecord = responses.reduce((acc, next) => {
                  const oldLossSetLayer = next.data!.lossSetLayer
                  const newLossSetLayer = next.data!.newLossSetLayer
                  if (isLoadedLossSet(oldLossSetLayer.loss_sets[0])) {
                    acc[(oldLossSetLayer.loss_sets[0] as LoadedLossSet).id] = (
                      newLossSetLayer.loss_sets[0] as unknown as LoadedLossSet
                    ).id
                  }
                  return acc
                }, {} as Record<string, string>)
                return {
                  data: {
                    lossSetLayersRecord,
                    layers: responses.map(r => r.data!.newLossSetLayer),
                    lossSetsRecord,
                    loadedLossSetsRecord,
                  },
                }
              })
            ) as ApiResponse<{
              lossSetLayersRecord: Record<string, string>
              layers: LossSetLayer[]
              lossSetsRecord: Record<string, string>
              loadedLossSetsRecord: Record<string, string>
            }>
          }
        }),
        switchMap(response => {
          if (response.error) {
            return of({ error: response.error }) as ApiResponse<{
              portfolio: Portfolio
              lossSetLayersRecord: Record<string, string>
              lossSetsRecord: Record<string, string>
              loadedLossSetsRecord: Record<string, string>
            }>
          }
          return this.updatePortfolio(
            grossPortfolioID,
            newName,
            response.data!.layers as unknown as LogicalPortfolioLayer[]
          ).pipe(
            map(res => {
              if (res.error) {
                return { error: res.error }
              } else {
                return {
                  data: {
                    portfolio: res.data!,
                    lossSetLayersRecord: response.data!.lossSetLayersRecord,
                    lossSetsRecord: response.data!.lossSetsRecord,
                    loadedLossSetsRecord: response.data!.loadedLossSetsRecord,
                  },
                }
              }
            })
          ) as ApiResponse<{
            portfolio: Portfolio
            lossSetLayersRecord: Record<string, string>
            lossSetsRecord: Record<string, string>
            loadedLossSetsRecord: Record<string, string>
          }>
        })
      )
    }

    return this.updatePortfolio(
      grossPortfolioID,
      newName,
      layers as unknown as LogicalPortfolioLayer[]
    ).pipe(
      map(r => {
        if (r.error) {
          return r
        }

        return {
          ...r,
          data: {
            portfolio: r.data!,
            lossSetLayersRecord: {},
            lossSetsRecord: {},
            loadedLossSetsRecord: {},
          },
        }
      })
    ) as ApiResponse<{
      portfolio: Portfolio
      lossSetLayersRecord: Record<string, string>
      lossSetsRecord: Record<string, string>
      loadedLossSetsRecord: Record<string, string>
    }>
  }

  createLossSetLayers(
    layer: LossSetLayer,
    scenarioEvents?: ScenarioEvent[],
    eventIDs?: number[]
  ): ApiResponse<{
    lossSetLayer: LossSetLayer
    newLossSetLayer: LossSetLayer
  }> {
    const lossSetProp = layer.loss_sets[0]
    let lossSet: LossSet
    if (isLoadedLossSet(lossSetProp)) {
      lossSet = lossSetProp.source as LossSet
    } else {
      lossSet = layer.loss_sets[0] as LossSet
    }
    const dataRows: LossSetDataRow[] = []
    if (scenarioEvents && scenarioEvents.length > 0 && eventIDs !== undefined) {
      scenarioEvents.forEach((event, i) => {
        const loss =
          event[`${layer.meta_data.ls_dim1} - ${layer.meta_data.ls_dim2}`]
        if (loss !== undefined) {
          dataRows.push({
            eventid: eventIDs[i],
            loss,
            trialid: 1,
            day: i + 1,
          })
        }
      })
    }
    let dataContentObs: ApiResponse<Data>
    if (dataRows.length > 0) {
      dataContentObs = this.postDataContent(dataRows)
    } else {
      dataContentObs = this.analyzereService.fetchData(
        (lossSet.data as Data).id
      )
    }
    return dataContentObs.pipe(
      switchMap(response => {
        if (response.error) {
          return of({ error: response.error }) as ApiResponse<LossSet>
        } else {
          // tslint:disable-next-line: no-non-null-assertion
          return this.analyzereService.postLossSet({
            ...lossSet,
            data: { ref_id: response.data!.id },
            event_catalogs: (
              (lossSet as LossSet).event_catalogs as EventCatalog[]
            ).map(e => ({ ref_id: e.id })),
          })
        }
      }),
      switchMap(response => {
        if (response.error) {
          return of(response)
        }
        return this.analyzereService.fetchLossSet(response.data!.id).pipe(
          switchMap(l => {
            if (l.error) {
              return of(l)
            }
            if (l.data!.status === 'processing') {
              return throwError('processing')
            } else if (l.data!.status === 'processing_failed') {
              return of({
                ...l,
                error: errorPayload(
                  'LossSet creation failed',
                  l.data!.status_message
                ),
              })
            } else {
              return of(l)
            }
          }),
          retryWhen(errors => {
            return errors.pipe(
              mergeMap(e => {
                if (e === 'processing') {
                  return of(e).pipe(delay(1000))
                } else {
                  return throwError(e)
                }
              })
            )
          })
        )
      }),
      switchMap(response => {
        if (response.data) {
          return this.analyzereService.postLossSetLayer({
            ...layer,
            loss_sets: [{ ref_id: response.data.id }],
          })
        } else {
          return of({ error: response.error }) as ApiResponse<LossSetLayer>
        }
      }),
      map(response => {
        if (response.error) {
          return { error: response.error } as MaybeError &
            MaybeData<{
              lossSetLayer: LossSetLayer
              newLossSetLayer: LossSetLayer
            }>
        } else {
          return {
            data: {
              lossSetLayer: layer,
              newLossSetLayer: response.data!,
            },
          } as MaybeError &
            MaybeData<{
              lossSetLayer: LossSetLayer
              newLossSetLayer: LossSetLayer
            }>
        }
      })
    )
  }

  private finalizeProcessStructureGroup(
    cededPortfolioID: string,
    newLossSetLayers: Record<string, string>,
    newLossSets: Record<string, string>,
    newLoadedLossSets: Record<string, string>,
    name: string,
    grossPortfolio: Portfolio,
    netPortfolioID: string
  ): ApiResponse<{
    cededLayers: LogicalPortfolioLayer[]
    portfolioIDs: PortfoliosIDAndName
  }> {
    return this.analyzereService
      .fetchPortfolioWithAggFeederAndRiskVisible(cededPortfolioID)
      .pipe(
        map(response => {
          if (response.error) {
            return { error: response.error }
          } else {
            const portfolio = response.data as Portfolio
            let layers: Layer[] = convertFromLogicalPortfolioLayers(
              portfolio.layers as LogicalPortfolioLayer[]
            )
            layers = filterAndMapForClone(layers)
            return {
              data: { portfolio, layers },
            } as MaybeError &
              MaybeData<{
                portfolio: Portfolio
                layers: Layer[]
              }>
          }
        }),
        map(response => {
          if (response.error) {
            return { error: response.error }
          } else {
            const [tndLayers, nonTndLayers] = partitionLayersByTopAndDrop(
              response.data!.layers
            )
            return {
              nonTndLayers,
              tndLayers,
              portfolio: response.data!.portfolio,
            }
          }
        }),
        switchMapAssoc(
          'createdLayers',
          ({ error, nonTndLayers, tndLayers }) => {
            if (error) {
              return of({ error })
            }
            nonTndLayers = nonTndLayers!.map(l => {
              let lossSetLayers = l.lossSetLayers
              lossSetLayers = lossSetLayers.map(ls => {
                if (newLossSetLayers[ls.id]) {
                  return { ...ls, id: newLossSetLayers[ls.id] }
                } else {
                  return { ...ls }
                }
              })
              return { ...l, lossSetLayers }
            })
            tndLayers = tndLayers!.map(l => {
              let lossSetLayers = l.lossSetLayers
              lossSetLayers = lossSetLayers.map(ls => {
                if (newLossSetLayers[ls.id]) {
                  return { ...ls, id: newLossSetLayers[ls.id] }
                } else {
                  return { ...ls }
                }
              })
              return { ...l, lossSetLayers }
            })
            return this.createLayers(
              nonTndLayers,
              tndLayers,
              [],
              newLossSets,
              newLoadedLossSets
            )
          }
        ),
        mapAssoc(
          'cededLayers',
          ({ createdLayers: { logical, logicalTnd, logicalFHCF } }) =>
            createCededLayerRefs(logical, logicalTnd, logicalFHCF)
        ),
        switchMapAssoc('portfolioIDs', ({ cededLayers }) =>
          this.updateAllPortfolios(
            cededPortfolioID,
            netPortfolioID,
            grossPortfolio,
            name,
            cededLayers
          )
        ),
        switchMap(({ error, cededLayers, portfolioIDs }) => {
          if (error) {
            return of({ error })
          } else {
            return this.inuranceService.reconcileAnimatedLoss(cededLayers).pipe(
              map(r => {
                if (r.error) {
                  return { error: r.error }
                } else {
                  return {
                    data: {
                      cededLayers,
                      portfolioIDs,
                    },
                  }
                }
              })
            )
          }
        })
      )
  }

  private finalizeClone(
    cededPortfolioID: string,
    newLossSetLayers: Record<string, string>,
    newLossSets: Record<string, string>,
    newLoadedLossSets: Record<string, string>,
    name: string,
    description: string,
    grossPortfolio: Portfolio,
    netPortfolioID: string,
    structure: Program,
    cededLayersObs: Observable<LayerState[]>,
    layersData?: StructureLayerDataResponse[],
    parentGrossPortfolioID?: string,
    analysisProfileID?: string,
    opts?: CloneStructureOptions,
    inuranceLayers?: LogicalPortfolioLayer[],
    bulkClone?: boolean,
    mappedLossSetLayers?: any[][],
    autobuildID?: string,
  ): ApiResponse<{
    program: Program
    cededLayers: LogicalPortfolioLayer[]
    portfolioIDs: PortfoliosIDAndName
  }> {
    return this.analyzereService
      .fetchPortfolioWithAggFeederAndRiskVisible(cededPortfolioID)
      .pipe(
        withLatestFrom(cededLayersObs,
          this.store.pipe(select(selectCededLayers))
        ),
        map(([response, dirtyLayers, cededLayers]) => {
          if (response.error) {
            return { error: response.error }
          }
          const portfolio = response.data as Portfolio
          let layers: Layer[] = []

          if (dirtyLayers.length > 0) {
            layers = dirtyLayers.filter(l => !l.deleted).map(l => l.layer)
          } else {
            layers = convertFromLogicalPortfolioLayers(
              portfolio.layers as LogicalPortfolioLayer[]
            )
          }
          if(inuranceLayers && inuranceLayers.length > 0) {
            const inurancePortfolioLayers = convertFromLogicalPortfolioLayers(
              inuranceLayers as LogicalPortfolioLayer[]
            )
            layers.push(...inurancePortfolioLayers)
          }
          layers = filterAndMapForClone(layers)

          if (bulkClone && mappedLossSetLayers) {
            let i = 0
            for (const layer of layers) {
              if (
                !mappedLossSetLayers[i] ||
                mappedLossSetLayers[i].length === 0
              ) {
                layer.lossSetLayers =
                  grossPortfolio.layers as LogicalPortfolioLayer[]
              } else {
                layer.lossSetLayers = mappedLossSetLayers[i]
              }
              i += 1
            }
          }

          return {
            data: { portfolio, layers, layersData },
          } as MaybeError &
            MaybeData<{
              portfolio: Portfolio
              layers: Layer[]
              layersData?: StructureLayerDataResponse[]
            }>
        }),
        map(({ error, data }) => {
          if (error) {
            return { error }
          }

          const [tndLayers, nonTndLayers] = partitionLayersByTopAndDrop(
            data!.layers
          )
          return {
            startingCession: data!.layersData,
            nonTndLayers,
            tndLayers,
            portfolio: data!.portfolio,
          }
        }),
        switchMapAssoc(
          'createdLayers',
          ({ error, startingCession, nonTndLayers, tndLayers }) => {
            if (error) {
              return of({ error })
            }

            nonTndLayers = nonTndLayers!.map(l => {
              let lossSetLayers = l.lossSetLayers
              lossSetLayers = lossSetLayers.map(ls => {
                if (newLossSetLayers[ls.id]) {
                  return { ...ls, id: newLossSetLayers[ls.id] }
                } else {
                  return { ...ls }
                }
              })
              return { ...l, lossSetLayers }
            })

            tndLayers = tndLayers!.map(l => {
              let lossSetLayers = l.lossSetLayers
              lossSetLayers = lossSetLayers.map(ls => {
                if (newLossSetLayers[ls.id]) {
                  return { ...ls, id: newLossSetLayers[ls.id] }
                } else {
                  return { ...ls }
                }
              })
              return { ...l, lossSetLayers }
            })

            return this.createLayers(
              nonTndLayers,
              tndLayers,
              startingCession ?? [],
              newLossSets,
              newLoadedLossSets
            )
          }
        ),
        mapAssoc(
          'cededLayers',
          ({ createdLayers: { logical, logicalTnd, logicalFHCF } }) => {
            if (bulkClone && mappedLossSetLayers) {
              const cededLayers = createCededLayerRefs(
                logical,
                logicalTnd,
                logicalFHCF
              )
              let i = 0
              for (const layer of cededLayers) {
                layer.sources = mappedLossSetLayers[i]
                i += 1
              }
              return cededLayers
            }
            return createCededLayerRefs(logical, logicalTnd, logicalFHCF)
          }
        ),
        switchMapAssoc('portfolioIDs', ({ cededLayers }) =>
          this.updateAllPortfolios(
            cededPortfolioID,
            netPortfolioID,
            grossPortfolio,
            name,
            cededLayers,
            parentGrossPortfolioID
          )
        ),
        switchMapAssoc('program', ({ portfolioIDs }) => {
          const program: Program = {
            ...structure,
            ...portfolioIDs,
            studyID: opts?.studyID || structure.studyID,
            label: name,
            description,
            programType: 'Save As Program',
            analysisID: analysisProfileID
              ? analysisProfileID
              : structure.analysisID,
            isScenario: opts?.asScenario,
            isOptimization: opts?.asOptimization,
            libRE:
              structure.libRE === 'T' || structure.libRE === 'Y'
                ? 'Y'
                : undefined,
            autobuildID,
            fotCount: 0,
            quoteCount: 0,
          }
          if (opts?.asScenario) {
            program.isScenario = true
            // If structure is being cloned as a scenario and is already a
            // scenario itself, set the scenario parent ID to the original's
            program.parentScenarioID =
              structure.parentScenarioID ?? structure.id
          } else if (opts?.asOptimization) {
            program.isOptimization = true
            program.parentOptimizationID =
              structure.parentOptimizationID ?? structure.id
          }
          if (opts?.persistStructure) {
            return this.programService.add(program)
          } else {
            return of({ data: { ...program, id: '' } }) as ApiResponse<Program>
          }
        }),
        switchMapAssoc('uploadCession', ({ cededLayers, program }) => {
          const stLayerData: StructureLayerDataResponse[] = []
          const patchLayerResponses: ApiResponse<LogicalPortfolioLayer>[] = []
          cededLayers.forEach((item: LogicalPortfolioLayer) => {
            const layer = item.sink as PhysicalPortfolioLayer
            if (item.startingCession !== undefined) {
              stLayerData.push({
                id: 0,
                structure_id: program.id ? parseFloat(program.id) : 0,
                layer_id: (isLayerAgg(item) ? 'LAgg' : 'LOcc') + layer.id,
                starting_cession: item.startingCession,
                color: item.color ? item.color : '',
              })
            }
            // Update structureID on layer meta_data
            if (opts?.persistStructure) {
              const update: Update<LogicalPortfolioLayer> = {
                id: item.id,
                change: {
                  meta_data: {
                    ...item.meta_data,
                    structureID: program.id,
                  },
                },
              }
              patchLayerResponses.push(
                this.analyzereService.patchLogicalPortfolioLayer(update)
              )
            }
          })
          program.layerData = stLayerData
          const layerData = stLayerData.map(
            ({ id, structure_id, ...item }) => item
          )
          if (opts?.persistStructure) {
            return forkJoin([
              ...patchLayerResponses,
              this.backendService.postLayerData(program.id, layerData),
            ])
          } else {
            return of({})
          }
        })
      )
      .pipe(
        withLatestFrom(
          this.store.pipe(select(selectProgramsByID)),
          this.store.pipe(select(selectProgramGroupsByID))
        ),
        mergeMap(
          ([
            { error, program, cededLayers, portfolioIDs },
            structureByID,
            structureGroupByID,
          ]) => {
            if (error) {
              return of({ error })
            }
            // recon inurance only if the structures are persisted
            if (opts?.persistStructure) {
              return this.inuranceService
                .reconcileSaveAs(
                  program,
                  cededLayers,
                  structureByID,
                  structureGroupByID
                )
                .pipe(
                  map(r => {
                    if (r.error) {
                      return { error: r.error }
                    }
                    return {
                      data: {
                        program,
                        cededLayers,
                        portfolioIDs,
                      },
                    }
                  })
                )
            } else {
              return this.inuranceService
                .reconcileAnimatedLoss(cededLayers)
                .pipe(
                  map(r => {
                    if (r.error) {
                      return { error: r.error }
                    } else {
                      return {
                        data: {
                          program,
                          cededLayers: this.getUpdatedCeded(
                            cededLayers,
                            r.data
                          ),
                          portfolioIDs,
                        },
                      }
                    }
                  })
                )
            }
          }
        )
      )
  }

  updatePortfolio(
    id: string,
    name: string,
    layers?: LogicalPortfolioLayer[]
  ): ApiResponse<Portfolio> {
    return this.analyzereService.fetchPortfolio(id).pipe(
      switchMap(res => {
        if (res.error) {
          return of(res)
        }
        const _layers = layers || (res.data!.layers as LogicalPortfolioLayer[])
        const portfolio = {
          ...res.data!,
          layers: _layers.map(l => ({ ref_id: l.id })),
          name,
          meta_data: { ...res.data!.meta_data },
        }
        return this.analyzereService.postPortfolio(portfolio)
      })
    )
  }
  updateAllPortfolios(
    cededPortfolioID: string,
    netPortfolioID: string,
    grossPortfolio: Portfolio,
    name: string,
    cededLayers: LogicalPortfolioLayer[],
    parentGrossPortfolioID?: string
  ): ApiResponse<PortfoliosIDAndName> {
    const isValidLayer = (layer: LogicalPortfolioLayer) =>
      !isMultiSectionLayer(layer) || isMultiSectionMainLayer(layer)
    const filteredCededLayers = cededLayers.filter(isValidLayer)
    return this.updatePortfolio(
      cededPortfolioID!,
      name,
      filteredCededLayers
    ).pipe(
      map(({ error, data }) => {
        if (data?.layers) {
          for (const layer of data!.layers as LogicalPortfolioLayer[]) {
            layer.sources = grossPortfolio.layers as LogicalPortfolioLayer[]
          }
        }
        return { error, cededPortfolio: data! }
      }),
      mapAssoc('layers', ({ cededPortfolio }) => {
        return [
          ...(grossPortfolio.layers as LogicalPortfolioLayer[]),
          ...(cededPortfolio.layers as LogicalPortfolioLayer[]),
        ]
      }),
      switchMapAssoc('netPortfolio', ({ layers }) => {
        const filteredLayers = layers.filter(isValidLayer)
        return this.updatePortfolio(netPortfolioID!, name, filteredLayers)
      }),
      mapAssoc('data', ({ cededPortfolio, netPortfolio }) => {
        return {
          name: netPortfolio.name,
          cededPortfolioID: cededPortfolio.id,
          grossPortfolioID: grossPortfolio.id,
          netPortfolioID: netPortfolio.id,
          parentGrossPortfolioID,
        }
      })
    )
  }

  private getUpdatedCeded(
    cededLayers: LogicalPortfolioLayer[],
    updatedLayers: LogicalPortfolioLayer[] | undefined
  ): LogicalPortfolioLayer[] {
    if (updatedLayers && updatedLayers.length > 0) {
      cededLayers.forEach(c => {
        const found = updatedLayers.find(uc => uc.id === c.id)
        if (found && found.sources) {
          c.sources = found.sources
        }
      })
    }
    return cededLayers
  }

  private updateFHCFHidden(
    fhcfHidden: LogicalPortfolioLayer[],
    newLossSetsRecord: Record<string, string>,
    newLoadedLossSets: Record<string, string>
  ): ApiResponse<LogicalPortfolioLayer[]> {
    const updates: ApiResponse<LogicalPortfolioLayer>[] = []
    if (fhcfHidden.length === 0) {
      return of({ data: [] })
    }
    for (const layer of fhcfHidden) {
      const lossSetLayer = layer.sources[0] as LossSetLayer
      const newLossSetLayer = {
        ...lossSetLayer,
        loss_sets: (lossSetLayer.loss_sets as LossSet[]).map(l => {
          if (newLossSetsRecord[l.id]) {
            return { ref_id: newLossSetsRecord[l.id] }
          } else if (newLoadedLossSets[l.id]) {
            return { ref_id: newLoadedLossSets[l.id] }
          } else {
            return {
              ref_id: l.id,
            }
          }
        }),
      }
      updates.push(
        this.analyzereService.postLossSetLayer(newLossSetLayer).pipe(
          mergeMap(res => {
            if (res.error) {
              return of({ error: res.error })
            } else {
              return this.analyzereService.patchLogicalPortfolioLayer({
                id: layer.id,
                change: { sources: [{ ref_id: res.data!.id }] },
              })
            }
          })
        )
      )
    }
    return forkJoin(updates).pipe(
      map(responses => {
        for (const res of responses) {
          if (res.error) {
            return { error: res.error }
          }
        }
        return { data: responses.map(r => r.data!) }
      })
    )
  }

  private updateRiskHidden(
    riskHiddenCat: LogicalPortfolioLayer[],
    riskHiddenLarge: PhysicalPortfolioLayer[],
    newLossSetsRecord: Record<string, string>,
    newLoadedLossSets: Record<string, string>
  ) {
    const observable: (
      | ApiResponse<LogicalPortfolioLayer>
      | ApiResponse<PhysicalPortfolioLayer>
    )[] = []
    if (riskHiddenCat.length === 0 && riskHiddenLarge.length === 0) {
      return of({ data: [] })
    }
    for (const layer of riskHiddenCat) {
      const lossSetLayer = layer.sources[0] as LossSetLayer
      const newLossSetLayer = {
        ...lossSetLayer,
        loss_sets: (lossSetLayer.loss_sets as LossSet[]).map(l => {
          if (newLossSetsRecord[l.id]) {
            return { ref_id: newLossSetsRecord[l.id] }
          } else if (newLoadedLossSets[l.id]) {
            return { ref_id: newLoadedLossSets[l.id] }
          } else {
            return {
              ref_id: l.id,
            }
          }
        }),
      }
      observable.push(
        this.analyzereService.postLossSetLayer(newLossSetLayer).pipe(
          mergeMap(res => {
            if (res.error) {
              return of({ error: res.error })
            } else {
              return this.analyzereService.patchLogicalPortfolioLayer({
                id: layer.id,
                change: { sources: [{ ref_id: res.data!.id }] },
              })
            }
          })
        )
      )
    }
    for (const layer of riskHiddenLarge) {
      const lossSet = layer.loss_sets[0] as LossSetLayer
      const newLossSets = []
      if (newLossSetsRecord[lossSet.id]) {
        newLossSets.push({ ref_id: newLossSetsRecord[lossSet.id] })
      } else if (newLoadedLossSets[lossSet.id]) {
        newLossSets.push({ ref_id: newLoadedLossSets[lossSet.id] })
      } else {
        newLossSets.push({ ref_id: lossSet.id })
      }

      observable.push(
        this.analyzereService.patchPhysicalPortfolioLayer({
          id: layer.id,
          change: { loss_sets: newLossSets },
        })
      )
    }
    return forkJoin(observable).pipe(
      map(responses => {
        for (const res of responses) {
          if (res.error) {
            return { error: res.error }
          }
        }
        return { data: responses.map(r => r.data!) }
      })
    )
  }

  private createIndexedHiddenLayers(mainLayer: LogicalPortfolioLayer) {
    const lossLayerId = mainLayer.meta_data.loss_layer_id
    if (!lossLayerId) {
      throw new Error('loss_layer_id is missing.')
    }

    return this.indexedService.cloneLossLayer(lossLayerId).pipe(
      switchMap(newLossSetRef => {
        const oldSources = mainLayer.sources as LogicalPortfolioLayer[]

        return forkJoin(
          oldSources.map(oldSettlementLayer =>
            this.indexedService.cloneSettlementLayer(
              oldSettlementLayer.id,
              newLossSetRef
            )
          )
        ).pipe(
          map(
            newSettlementRefs =>
              ({
                id: mainLayer.id,
                settlementRefs: newSettlementRefs,
                lossLayerRef: newLossSetRef,
              } as IndexHiddenLayers)
          )
        )
      })
    )
  }

  private updateIndexedHidden(mainIndexedLayers: LogicalPortfolioLayer[]) {
    if (mainIndexedLayers.length === 0) {
      return of({ data: [] })
    }

    const allIndexedHiddenLayers = mainIndexedLayers.map(mainLayer =>
      this.createIndexedHiddenLayers(mainLayer)
    )

    return forkJoin(allIndexedHiddenLayers).pipe(
      map(indexedHiddenLayers => {
        return { data: indexedHiddenLayers }
      })
    )
  }

  private createLayers(
    nonTndLayers: Layer[],
    tndLayers: Layer[],
    layerData: StructureLayerDataResponse[],
    newLossSets: Record<string, string>,
    newLoadedLossSets: Record<string, string>
  ): ApiResponse<{
    logical: LogicalPortfolioLayer[]
    logicalTnd: LogicalPortfolioLayer[]
    logicalFHCF: LogicalPortfolioLayer[]
    aggFeederID: string
  }> {
    const fHCFActualLayers = nonTndLayers.filter(l => l.meta_data.isFHCFFinal)
    const nonTndAndFHCFLayers = nonTndLayers.filter(
      l => !l.meta_data.isFHCFFinal
    )
    return this.analyzereService
      .createPhysicalLayers(
        nonTndAndFHCFLayers.map(l => ({
          ...l,
          physicalLayer: {
            ...l.physicalLayer,
            meta_data: {
              ...l.physicalLayer.meta_data,
              fromLayerID: l.physicalLayer.id,
            },
          },
        }))
      )
      .pipe(
        map(({ error, data }) => ({ error, physical: data! })),
        switchMapAssoc('logical', ({ physical: newPhysical }) => {
          const filteredLayers = nonTndAndFHCFLayers.filter(
            layer => !layer.meta_data.isRiskLargeHidden
          )

          const filteredPhysical = newPhysical.filter(
            physical => !physical.meta_data.isRiskLargeHidden
          )

          return this.analyzereService.createLogicalLayers(
            filteredLayers.map(layer => ({
              ...layer,
              meta_data: { ...layer.meta_data, fromLayerID: layer.id },
            })),
            filteredPhysical.map(physical => physical.id),
            layerData
          )
        }),
        switchMapAssoc('physicalTnd', ({ logical }) => {
          updateTopAndDropLogicalLayerRefs(tndLayers, logical)
          return this.analyzereService.createPhysicalLayers(
            tndLayers.map(layer => ({
              ...layer,
              physicalLayer: {
                ...layer.physicalLayer,
                meta_data: {
                  ...layer.physicalLayer.meta_data,
                  fromLayerID: layer.physicalLayer.id,
                },
              },
            }))
          )
        }),
        switchMapAssoc('logicalTnd', ({ physicalTnd }) =>
          this.analyzereService.createLogicalLayers(
            tndLayers.map(layer => ({
              ...layer,
              meta_data: { ...layer.meta_data, fromLayerID: layer.id },
            })),
            physicalTnd.map(l => l.id),
            layerData
          )
        ),
        switchMapAssoc('physicalFHCF', ({ logical }) => {
          updateActaulFHCFLogicalLayerRefs(fHCFActualLayers, logical)
          return this.analyzereService.createPhysicalLayers(
            fHCFActualLayers.map(layer => ({
              ...layer,
              physicalLayer: {
                ...layer.physicalLayer,
                meta_data: {
                  ...layer.physicalLayer.meta_data,
                  fromLayerID: layer.physicalLayer.id,
                },
              },
            }))
          )
        }),
        switchMapAssoc('logicalFHCF', ({ physicalFHCF }) =>
          this.analyzereService.createLogicalLayers(
            fHCFActualLayers.map(layer => ({
              ...layer,
              meta_data: { ...layer.meta_data, fromLayerID: layer.id },
            })),
            physicalFHCF.map(layer => layer.id),
            layerData
          )
        ),
        switchMapAssoc('hiddenFHCFUpdate', ({ logical }) => {
          const hiddenFHCFUpdate = logical.filter(
            layer =>
              layer.meta_data.isFHCFHidden1 || layer.meta_data.isFHCFHidden2
          )
          return this.updateFHCFHidden(
            hiddenFHCFUpdate,
            newLossSets,
            newLoadedLossSets
          )
        }),
        switchMapAssoc('hiddenRiskUpdate', ({ logical, physical }) => {
          const hiddenRiskUpdateCat = logical.filter(
            layer => layer.meta_data.isRiskCatHidden
          )
          const hiddenRiskUpdateLarge = physical.filter(
            layer => layer.meta_data.isRiskLargeHidden
          )
          return this.updateRiskHidden(
            hiddenRiskUpdateCat,
            hiddenRiskUpdateLarge,
            newLossSets,
            newLoadedLossSets
          )
        })
      )
      .pipe(
        switchMapAssoc('hiddenIndexed', ({ logical }) => {
          const mainIndexedLayers = logical.filter(layer =>
            isIndexedLayer(layer, 'main-layer')
          )

          return this.updateIndexedHidden(mainIndexedLayers)
        }),
        switchMapAssoc(
          'data',
          ({
            logical,
            logicalTnd,
            logicalFHCF,
            hiddenRiskUpdate,
            hiddenIndexed,
          }) => {
            const updates: Update<LogicalPortfolioLayer>[] = []

            const aggFeederLayers = logical.find(layer =>
              isLayerAggFeeder(layer)
            )
            const riskActualLayers = logical.filter(layer =>
              isLayerActualRisk(layer)
            )
            const mainIndexedLayers = logical.filter(layer =>
              isIndexedLayer(layer, 'main-layer')
            )

            const visibleIndexedLayers = logical.filter(layer =>
              isIndexedVisibleLayer(layer)
            )

            const combinedSwingLayers = logical.filter(layer =>
              isSwingLayer(layer, 'combined-layer')
            )
            const mainMultiSectionLayers = logical.filter(layer =>
              isMultiSectionLayer(layer, 'main-layer')
            )

            if (aggFeederLayers) {
              const aggLayers = logical.filter(l => isLayerAgg(l))
              if (aggLayers.length > 0 && aggFeederLayers) {
                const sources = aggLayers[0].sources as LogicalPortfolioLayer[]
                const oldAggFeederID = sources.find(s =>
                  isLayerAggFeeder(s)
                )!.id
                aggLayers.map(agg => {
                  const sourceRefs = (agg.sources as LogicalPortfolioLayer[])
                    .map(s => ({ ref_id: s.id }))
                    .filter(s => s.ref_id !== oldAggFeederID)
                  updates.push({
                    id: agg.id,
                    change: {
                      sources: [...sourceRefs, { ref_id: aggFeederLayers.id }],
                    },
                  })
                })
              }
            }

            if (riskActualLayers.length > 0) {
              riskActualLayers.forEach(actualLayer => {
                const oldSources = actualLayer.sources
                const newSources: Ref[] = []

                oldSources.forEach((s: any) => {
                  const newLogicalSource = hiddenRiskUpdate.find(
                    hiddenLayer =>
                      hiddenLayer.meta_data.fromLayerID === s.id &&
                      hiddenLayer.meta_data.sage_layer_type === 'noncat_risk'
                  )
                  if (newLogicalSource) {
                    newSources.push({ ref_id: newLogicalSource.id })
                  } else {
                    newSources.push({ ref_id: s.id })
                  }
                })

                const oldVisibleLayer = actualLayer.meta_data.riskVisibleLayerID
                const newVisibleLayer = logical.find(
                  layer =>
                    layer.meta_data.fromLayerID === oldVisibleLayer &&
                    layer.meta_data.sage_layer_type === 'noncat_risk'
                )!
                updates.push({
                  id: actualLayer.id,
                  change: {
                    sources: newSources,
                    meta_data: {
                      ...actualLayer.meta_data,
                      riskVisibleLayerID: newVisibleLayer.id,
                    },
                  },
                })
              })
            }

            const toClonedId = (id: string | undefined): string => {
              if (id === undefined) {
                throw new Error(
                  `ERROR: Unable to find the new id for undefined`
                )
              }

              const newLayer = logical.find(
                layer => layer.meta_data.fromLayerID === id
              )
              if (newLayer === undefined) {
                throw new Error(`ERROR: Unable to find the new id for ${id}`)
              }

              return newLayer.id
            }

            visibleIndexedLayers.forEach(visibleLayer => {
              const updatedVisibleLayer: Update<LogicalPortfolioLayer> = {
                id: visibleLayer.id,
                change: {
                  meta_data: {
                    ...visibleLayer.meta_data,
                    main_layer_id: toClonedId(
                      visibleLayer.meta_data.main_layer_id
                    ),
                  },
                },
              }
              updates.push(updatedVisibleLayer)
            })

            mainIndexedLayers.forEach(mainLayer => {
              const hiddenLayers = hiddenIndexed.find(
                hiddenLayer => hiddenLayer.id === mainLayer.id
              )

              const updatedMainLayer: Update<LogicalPortfolioLayer> = {
                id: mainLayer.id,
                change: {
                  sources: hiddenLayers?.settlementRefs,
                  meta_data: {
                    ...mainLayer.meta_data,
                    loss_layer_id: hiddenLayers?.lossLayerRef.ref_id,
                    visible_layer_id: toClonedId(
                      mainLayer.meta_data.visible_layer_id
                    ),
                  },
                },
              }

              updates.push(updatedMainLayer)
            })

            if (combinedSwingLayers.length > 0) {
              combinedSwingLayers.forEach(combinedLayer => {
                let oldSources =
                  combinedLayer.sources as LogicalPortfolioLayer[]

                oldSources = oldSources.filter(l => !l.meta_data.inuranceSource)
                const newSources = oldSources.map(source => ({
                  ref_id: toClonedId(source.id),
                }))

                const meta = combinedLayer.meta_data
                const newlossId = toClonedId(meta.loss_layer_id)
                const newPremiumId = toClonedId(meta.premium_layer_id)
                const newAdjustmentId = toClonedId(meta.adjustment_layer_id)
                const newVisibleLayerId = toClonedId(meta.visible_layer_id)

                updates.push({
                  id: combinedLayer.id,
                  change: {
                    sources: newSources,
                    meta_data: {
                      ...meta,
                      loss_layer_id: newlossId,
                      premium_layer_id: newPremiumId,
                      adjustment_layer_id: newAdjustmentId,
                      visible_layer_id: newVisibleLayerId,
                    },
                  },
                })
              })
            }

            mainMultiSectionLayers.forEach(mainLayer => {
              // Each main-layer has section-layers
              let oldSections = mainLayer.sources as LogicalPortfolioLayer[]
              oldSections = oldSections.filter(l => !l.meta_data.inuranceSource)
              const newSections = oldSections.map(src =>
                asRef(toClonedId(src.id))
              )

              const meta = mainLayer.meta_data
              const newVisibleLayerId = toClonedId(meta.visible_layer_id)

              updates.push({
                id: mainLayer.id,
                change: {
                  sources: newSections,
                  meta_data: {
                    ...meta,
                    visible_layer_id: newVisibleLayerId,
                  },
                },
              })
            })

            logical
              .filter(layer => isMultiSectionLayer(layer, 'section-layer'))
              .forEach(sectionLayer => {
                const oldSources =
                  sectionLayer.sources as LogicalPortfolioLayer[]
                const newSources = oldSources.map(src =>
                  isMultiSectionLayer(src, 'flipper-layer')
                    ? asRef(toClonedId(src.id))
                    : asRef(src.id)
                )

                updates.push({
                  id: sectionLayer.id,
                  change: {
                    sources: newSources,
                  },
                })
              })

            // Also handle multisection flipper layers
            logical
              .filter(layer => isMultiSectionLayer(layer, 'flipper-layer'))
              .forEach(flipperLayer => {
                const oldSources =
                  flipperLayer.sources as LogicalPortfolioLayer[]
                const newSources = oldSources.map(src =>
                  asRef(toClonedId(src.id))
                )

                updates.push({
                  id: flipperLayer.id,
                  change: {
                    sources: newSources,
                  },
                })
              })

            if (updates.length > 0) {
              return this.analyzereService
                .patchLogicalPortfolioLayers(updates)
                .pipe(
                  mapResponse(res => ({
                    logical: logical
                      .filter(layer => !res.some(r => r.id === layer.id))
                      .concat(...res),
                    logicalTnd,
                    logicalFHCF,
                    aggFeederID: aggFeederLayers ? aggFeederLayers.id : '',
                  }))
                ) as ApiResponse<{
                logical: LogicalPortfolioLayer[]
                logicalTnd: LogicalPortfolioLayer[]
                logicalFHCF: LogicalPortfolioLayer[]
                aggFeederID: string
              }>
            } else {
              return of({
                data: {
                  logical,
                  logicalTnd,
                  logicalFHCF,
                  aggFeederID: '',
                },
              })
            }
          }
        )
      )
  }

  fetchPortfolioViewYELT(viewID: string): ApiResponse<ViewYeltRow[]> {
    return this.analyzereService
      .fetchPortfolioViewYELT(viewID)
      .pipe(parseCsvResponse<ViewYeltRow>())
  }

  fetchLayersViewsYELT(
    layerViewsResponse: Array<LayerViewResponse & { layerID: string }>
  ): ApiResponse<LayersViewsYELT> {
    const actions: Array<ApiResponse<ViewYeltRow[]>> = []
    layerViewsResponse.forEach(l => {
      actions.push(this.fetchLayerViewYELT(l.id))
    })
    return forkJoin(actions).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }
        const returnRecord: LayersViewsYELT = {}
        results.forEach((r, i) => {
          returnRecord[layerViewsResponse[i].layerID] = r.data!
        })
        return { data: returnRecord }
      })
    )
  }

  fetchPortfolioViewsYELT(
    viewResponse: PortfolioViewsResponse
  ): ApiResponse<PortfoliosViewYELT> {
    const cededYELT = this.fetchPortfolioViewYELT(
      viewResponse.cededPortfolioView.id
    )
    const grossYELT = this.fetchPortfolioViewYELT(
      viewResponse.grossPortfolioView.id
    )
    const netYELT = this.fetchPortfolioViewYELT(
      viewResponse.netPortfolioView.id
    )
    return forkJoin([cededYELT, grossYELT, netYELT]).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }
        return {
          data: {
            ceded: results[0].data!,
            gross: results[1].data!,
            net: results[2].data!,
          },
        }
      })
    )
  }

  fetchLayerViewYELT(viewID: string): ApiResponse<ViewYeltRow[]> {
    return this.analyzereService
      .fetchLayerViewYELT(viewID)
      .pipe(parseCsvResponse<ViewYeltRow>())
  }

  postDataContent(dataRows: LossSetDataRow[]): ApiResponse<Data> {
    return this.analyzereService.postData(
      Papa.unparse(dataRows, { header: true, quotes: true }),
      'loss_scenario.csv'
    )
  }

  fetchDataContent<T>(dataID: string): ApiResponse<T[]> {
    return this.analyzereService.fetchData(dataID).pipe(
      switchMap(response => {
        if (response.error) {
          return of({ error: response.error })
        } else {
          const content = response.data!.content
          const name = content.substring(content.lastIndexOf('/') + 1)
          return this.analyzereService
            .fetchContent(name)
            .pipe(parseCsvResponse<T>())
        }
      })
    )
  }

  processScenarioEvents(
    cededLayers: LogicalPortfolioLayer[],
    portfolioIDs: PortfoliosIDAndName,
    analysisProfileID: string,
    eventIDs: number[],
    events: ScenarioEvent[]
  ): ApiResponse<ScenarioEventResult[]> {
    const layers = convertFromLogicalPortfolioLayers(cededLayers)
    const layerViewsObs = this.analyzereService.postLayersViews(
      layers.map(c => c.id),
      analysisProfileID
    )
    const portfolioViewsObs = this.analyzereService.postPortfolioView(
      portfolioIDs.cededPortfolioID,
      portfolioIDs.grossPortfolioID,
      portfolioIDs.netPortfolioID,
      analysisProfileID
    )
    return forkJoin([layerViewsObs, portfolioViewsObs]).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }
        return {
          data: {
            layerViews: results[0].data!,
            portfolioViews: results[1].data!,
          },
        }
      }),
      mergeMap(res => {
        if (res.error) {
          return of({ error: res.error })
        }
        const portfoliosYELTObs = this.fetchPortfolioViewsYELT(
          res.data!.portfolioViews
        )
        const layersYELTObs = this.fetchLayersViewsYELT(res.data!.layerViews)
        return forkJoin([portfoliosYELTObs, layersYELTObs]).pipe(
          map(results => {
            for (const result of results) {
              if (result.error) {
                return { error: result.error }
              }
            }
            return {
              data: {
                portfoliosYELT: results[0].data!,
                layersYELT: results[1].data!,
                eventIDs,
              },
            }
          })
        )
      }),
      // TODO: fix any res type
      map((res: any) => {
        if (res.error) {
          return { error: res.error }
        }
        const eventResults: ScenarioEventResult[] = []
        let previousEventLayers: EventLayer[] = []
        const backAllocatedLayers = layers.filter(
          l =>
            l.meta_data.sage_layer_type === 'shared_limit' &&
            l.meta_data.sage_layer_subtype === 'backallocated'
        )
        const tdHiddenLayers = layers.filter(isLayerActualTopAndDrop)
        const riskXOLActuals = layers.filter(isLayerActualRisk)
        const filteredLayersState = filterLayers(
          layers.map(l => ({
            layer: l,
            hash: '',
            dirty: false,
            new: false,
            deleted: false,
          }))
        )
        const filteredLayers = filteredLayersState.map(l => l.layer)
        const ceded: ViewYeltRow[] = res.data!.portfoliosYELT.ceded.filter((c: ViewYeltRow) =>
          eventIDs.includes(c.EventId)
        )
        const gross: ViewYeltRow[] = res.data!.portfoliosYELT.gross.filter((c: ViewYeltRow) =>
          eventIDs.includes(c.EventId)
        )
        const net: ViewYeltRow[] = res.data!.portfoliosYELT.net.filter((c: ViewYeltRow) =>
          eventIDs.includes(c.EventId)
        )

        events.forEach((event, i) => {
          const cededPortfolioLoss = this.getValue(
            ceded.find(
              (y: ViewYeltRow) =>
                y.EventId === eventIDs[i] && y.Sequence === gross[i].Sequence
            )
          )
          const grossPortfolioLoss = this.getValue(
            gross.find(
              (y: ViewYeltRow) =>
                y.EventId === eventIDs[i] && y.Sequence === gross[i].Sequence
            )
          )
          const netPortfolioLoss = this.getValue(
            net.find(
              (y: ViewYeltRow) =>
                y.EventId === eventIDs[i] && y.Sequence === gross[i].Sequence
            )
          )
          if (i === 0) {
            const eventLayers: EventLayer[] = filteredLayers.map(l => {
              const yeltRow = this.getYeltRow(
                backAllocatedLayers,
                riskXOLActuals,
                tdHiddenLayers,
                l.id,
                res.data!.layersYELT,
                eventIDs[i],
                gross[i].Sequence
              )
              return processEvent(
                {
                  layer: l,
                  hash: '',
                  dirty: false,
                  new: false,
                  deleted: false,
                },
                this.getValue(yeltRow)
              )
            })
            previousEventLayers = eventLayers
            eventResults.push({
              id: event.id,
              layers: eventLayers,
              ceded: cededPortfolioLoss,
              gross: grossPortfolioLoss,
              net: netPortfolioLoss,
            })
          } else {
            previousEventLayers = previousEventLayers.map(e => {
              const yeltRow = this.getYeltRow(
                backAllocatedLayers,
                riskXOLActuals,
                tdHiddenLayers,
                e.layer.id,
                res.data!.layersYELT,
                eventIDs[i],
                gross[i].Sequence
              )
              return buildFromPreviousEvent(e, this.getValue(yeltRow))
            })
            eventResults.push({
              id: event.id,
              layers: previousEventLayers,
              ceded: cededPortfolioLoss,
              gross: grossPortfolioLoss,
              net: netPortfolioLoss,
            })
          }
        })
        return { data: eventResults }
      })
    )
  }

  getLossSetDataIdWithMinSize(lossSetLayers: stateLossSetLayer[]): string {
    return lossSetLayers.reduce(
      (acc, lossSetLayer) => {
        lossSetLayer.loss_sets.forEach(
          (lossSet: Ref | LossSet | LayerRef | LoadedLossSet) => {
            if (
              isLoadedLossSet(lossSet) &&
              isLossSet(lossSet.source) &&
              isData(lossSet.source.data)
            ) {
              const data = lossSet.source.data
              if (acc.minSize === -1 || data.size < acc.minSize) {
                acc.minSizeId = data.id
                acc.minSize = data.size
                acc.lossSetId = lossSetLayer.id
              }
            } else if (isLossSet(lossSet) && isData(lossSet.data)) {
              const data = lossSet.data
              if (acc.minSize === -1 || data.size < acc.minSize) {
                acc.minSizeId = data.id
                acc.minSize = data.size
                acc.lossSetId = lossSetLayer.id
              }
            }
          }
        )
        return acc
      },
      { minSize: -1, minSizeId: '', lossSetId: '' }
    ).minSizeId
  }

  getLossSetDataIdWithMinSizeForGroups(layerRefs: LayerRef[]): string {
    return layerRefs.reduce(
      (acc, layerRef) => {
        layerRef.loss_sets?.forEach(
          (lossSet: Ref | LossSet | LayerRef | LoadedLossSet) => {
            if (
              isLoadedLossSet(lossSet) &&
              isLossSet(lossSet.source) &&
              isData(lossSet.source.data)
            ) {
              const data = lossSet.source.data
              if (acc.minSize === -1 || data.size < acc.minSize) {
                acc.minSizeId = data.id
                acc.minSize = data.size
                acc.lossSetId = layerRef.id
              }
            } else if (isLossSet(lossSet) && isData(lossSet.data)) {
              const data = lossSet.data
              if (acc.minSize === -1 || data.size < acc.minSize) {
                acc.minSizeId = data.id
                acc.minSize = data.size
                acc.lossSetId = layerRef.id
              }
            }
          }
        )
        return acc
      },
      { minSize: -1, minSizeId: '', lossSetId: '' }
    ).minSizeId
  }

  getEventIds(
    count: number,
    eventCatalogDataID: string
  ): ApiResponse<number[]> {
    return this.fetchDataContent<EventCatalogDataRow>(eventCatalogDataID).pipe(
      map(response => {
        if (response.error) {
          return { error: response.error }
        } else {
          const eventIds = this.getDistinctEventIds(count, response.data ?? [])
          return { data: eventIds }
        }
      })
    )
  }

  private getValue(yelt?: ViewYeltRow) {
    if (yelt) {
      return yelt.Loss || 0
    } else {
      return 0
    }
  }

  private getYeltRow(
    backAllocatedLayers: Layer[],
    riskXOLActuals: Layer[],
    tdHiddenLayers: Layer[],
    layerID: string,
    layersYELT: LayersViewsYELT,
    eventID: number,
    sequenceID: number
  ) {
    const backAllocatedLayer = backAllocatedLayers.find(
      ba => ba.meta_data.backAllocatedForID === layerID
    )
    const riskXOLActualLayer = riskXOLActuals.find(
      r => r.meta_data.riskVisibleLayerID === layerID
    )
    const tdHiddenLayer = tdHiddenLayers.find(l =>
      l.layerRefs.includes(layerID)
    )
    let yeltRow: ViewYeltRow | undefined
    if (tdHiddenLayer) {
      const sharedTDHiddenLayer = backAllocatedLayers.find(
        l => l.meta_data.backAllocatedForID === tdHiddenLayer.id
      )
      const currentLayerYeltRow = layersYELT[layerID].find(
        y => y.EventId === eventID && y.Sequence === sequenceID
      )
      const otherLayerYelotRow = layersYELT[
        tdHiddenLayer.layerRefs.find(l => l !== layerID) || ''
      ].find(y => y.EventId === eventID && y.Sequence === sequenceID)
      const hiddenYeltRow = sharedTDHiddenLayer
        ? layersYELT[sharedTDHiddenLayer.id].find(
            y => y.EventId === eventID && y.Sequence === sequenceID
          )
        : layersYELT[tdHiddenLayer.id].find(
            y => y.EventId === eventID && y.Sequence === sequenceID
          )
      yeltRow = {
        ...currentLayerYeltRow,
        Loss:
          (hiddenYeltRow?.Loss ?? 0) *
          ((currentLayerYeltRow?.Loss ?? 0) /
            ((currentLayerYeltRow?.Loss ?? 0) +
              (otherLayerYelotRow?.Loss ?? 0))),
      } as ViewYeltRow | undefined
    } else if (backAllocatedLayer) {
      yeltRow = layersYELT[backAllocatedLayer.id].find(
        y => y.EventId === eventID && y.Sequence === sequenceID
      )
    } else if (riskXOLActualLayer) {
      yeltRow = layersYELT[riskXOLActualLayer.id].find(
        y => y.EventId === eventID && y.Sequence === sequenceID
      )
    } else {
      yeltRow = layersYELT[layerID].find(
        y => y.EventId === eventID && y.Sequence === sequenceID
      )
    }
    return yeltRow
  }

  private getDistinctEventIds(
    count: number,
    data: EventCatalogDataRow[]
  ): number[] {
    const distinct = uniq(data.map(r => Number(r.eventid))).slice(0, count)
    return distinct.concat(Array(count - distinct.length).fill(distinct[0]))
  }
}
