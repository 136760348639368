import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { Study } from '../../../core/model/study.model'
import { selectAccountOpportunities } from '../../../core/store/accountopportunity.selectors'
import { selectProgramGroupMembers } from '../../../core/store/program-group-member.selectors'
import { AccountOpportunity } from '../../../api/model/backend.model'
import { ClientYear } from '../../../core/model/client.model'
import { Program } from '../../../core/model/program.model'
import { AppState } from '../../../core/store'
import {
  setGroupFilterByAssoc,
  setStructureFilter,
} from '../../../core/store/broker/broker.actions'
import {
  selectCurrencyList,
  selectCurrentClientID,
  selectCurrentClientYears,
  selectCurrentStudyID,
  selectCurrentYearID,
  selectCurrentYearStudies,
  selectGroupFilterByAssoc,
  selectStructureFilter,
} from '../../../core/store/broker/broker.selectors'
import { selectCurrentClientProgramGroups } from '../../../core/store/program-group/program-group.selectors'
import { selectCurrentStudyPrograms } from '../../../core/store/program/program.selectors'
import { extractPortfolioSetID } from '../../model/portfolio-set-id.util'
import { saveAnalysis } from '../../store/analysis.actions'
import {
  selectCededLayers,
  selectCededLayersSaving,
  selectCompareCurrency,
  selectCompareProgramGroups,
  selectCompareProgramIDs,
  selectComparePrograms,
  selectCompareSlidesPerView,
  selectGrouperAnalysisProfileID,
  selectCompareViews,
  selectSelectedCompareView,
  selectCompareTabIndex,
  selectGrouperSlidesPerView,
  selectCompareMetricSettingsEntities,
  selectCompareStructureOptions,
} from '../../store/analysis.selectors'
import {
  deleteCompareView,
  fetchCompareViews,
  refreshCompareTowerSizePercentage,
  saveCompareView,
  setCompareSlidesPerView,
  updateSelectedCompareView,
} from '../../store/compare/compare-view/compare-view.actions'
import * as CompareActions from '../../store/compare/compare.actions'
import * as CompareGroupActions from '../../store/compare/program-group-compare/program-group-compare.actions'
import {
  ProgramGroup,
  ProgramGroupMember,
} from '../../store/grouper/program-group.model'
import { fetchPortfolioView } from '../../store/views/portfolio-view.actions'
import { CurrencyCode } from '../../../api/analyzere/analyzere.model'
import { CompareView } from '../../model/compare-view.model'
import { CompareMetricSetting } from '../../model/compare-metrics.model'
import { StructureOptions } from '../../store/compare/compare.reducer'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-compare-container',
  templateUrl: './compare.container.html',
})
export class CompareContainerComponent implements OnInit {
  clientID$: Observable<string | null>
  analysisProfileID$: Observable<string | null>
  years$: Observable<readonly ClientYear[]>
  programGroups$: Observable<readonly ProgramGroup[]>
  programs$: Observable<readonly Program[]>
  programIDs$: Observable<readonly string[]>
  selectedYearID$: Observable<string | null>
  selectedClientID$: Observable<string | null>
  selectedProgramGroupIDs$: Observable<string[]>
  structureFilter$: Observable<string | null>
  programGroupMembers$: Observable<readonly ProgramGroupMember[]>
  groupFilterByAssoc$: Observable<boolean>
  selectedCompareView$: Observable<CompareView | null>
  compareViews$: Observable<readonly CompareView[]>
  slidesPerView$: Observable<number>
  props$: Observable<CompareMetricSetting[]>
  state$: Observable<StructureOptions>
  saving$: Observable<boolean>
  dirty$: Observable<boolean>
  studyID$: Observable<string | null>

  selectedProgramID$: Observable<string | null>
  studies$: Observable<readonly Study[]>
  accountOpportunities$: Observable<AccountOpportunity[] | null>

  clientID: string
  currencyList$: Observable<CurrencyCode[]>
  compareCurrency$: Observable<string | any>
  tabSelected$: Observable<number>

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.props$ = this.store.pipe(select(selectCompareMetricSettingsEntities))
    this.state$ = this.store.pipe(select(selectCompareStructureOptions))

    this.studyID$ = this.store.pipe(select(selectCurrentStudyID))
    this.programGroupMembers$ = this.store.pipe(
      select(selectProgramGroupMembers)
    )
    this.groupFilterByAssoc$ = this.store.pipe(select(selectGroupFilterByAssoc))
    this.structureFilter$ = this.store.pipe(select(selectStructureFilter))
    this.analysisProfileID$ = this.store.pipe(
      select(selectGrouperAnalysisProfileID)
    )
    this.years$ = this.store.pipe(select(selectCurrentClientYears))
    this.programGroups$ = this.store.pipe(
      select(selectCurrentClientProgramGroups)
    )
    this.programs$ = this.store.pipe(select(selectCurrentStudyPrograms))
    this.programIDs$ = this.store.pipe(select(selectCompareProgramIDs))

    this.compareViews$ = this.store.pipe(select(selectCompareViews))
    this.selectedCompareView$ = this.store.pipe(
      select(selectSelectedCompareView)
    )

    this.selectedYearID$ = this.store.pipe(select(selectCurrentYearID))
    this.selectedClientID$ = this.store.pipe(select(selectCurrentClientID))
    this.store
      .pipe(select(selectCurrentClientID), take(1))
      .subscribe(clientID =>
        clientID ? (this.clientID = clientID) : undefined
      )
    this.selectedProgramGroupIDs$ = this.store.pipe(
      select(selectCompareProgramGroups)
    )

    this.slidesPerView$ = this.store.pipe(select(selectGrouperSlidesPerView))

    this.saving$ = this.store.pipe(select(selectCededLayersSaving))
    this.dirty$ = this.store.pipe(
      select(selectCededLayers),
      map(layers => {
        return layers.filter(l => l.deleted || l.dirty || l.new).length > 0
      })
    )

    this.selectedProgramID$ = this.store.pipe(select(selectCurrentStudyID))
    this.studies$ = this.store.pipe(select(selectCurrentYearStudies))
    this.accountOpportunities$ = this.store.pipe(
      select(selectAccountOpportunities)
    )

    // Reload metrics on load
    this.store
      .pipe(select(selectComparePrograms), take(1))
      .subscribe(programs => {
        programs.forEach(program => {
          // tslint:disable-next-line: no-non-null-assertion
          const portfolioSetID = extractPortfolioSetID(program)!
          const portfolioSetAndStudyID = {
            ...portfolioSetID,
            clientID: this.clientID,
            yearID: program.yearID,
            studyID: program.studyID,
            isGroupCompare: true,
            currency: program.structureCurrency,
          }
          this.store.dispatch(fetchPortfolioView(portfolioSetAndStudyID))
        })
      })

    this.store.pipe(select(selectComparePrograms)).subscribe(programs => {
      if (programs.length === 0) {
        this.store.dispatch(
          CompareActions.compareCurrency({ compareCurrency: null })
        )
      }
    })

    this.store.dispatch(fetchCompareViews())
    this.store.dispatch(refreshCompareTowerSizePercentage())
    this.currencyList$ = this.store.pipe(select(selectCurrencyList))
    this.compareCurrency$ = this.store.pipe(select(selectCompareCurrency))
    this.tabSelected$ = this.store.pipe(select(selectCompareTabIndex))
  }

  onProgramAdd(programs: Program | Program[]): void {
    const ps = Array.isArray(programs) ? programs : [programs]
    ps.map(p => ({ ...p, checkCompare: false })).forEach(program => {
      this.store.dispatch(CompareActions.addProgramToCompare({ program }))
    })
  }

  onProgramRemove(programs: Program | Program[]): void {
    const ps = Array.isArray(programs) ? programs : [programs]
    ps.forEach(program => {
      if (program.programType === '') {
        this.onProgramGroupRemove(program.id)
      } else {
        this.store.dispatch(
          CompareActions.removeProgramFromCompare({ id: program.id })
        )
      }
    })
  }

  onProgramGroupAdd(programGroup: ProgramGroup): void {
    this.store.dispatch(
      CompareGroupActions.addCompareProgramGroup({ id: programGroup.id })
    )
  }

  onProgramGroupRemove(id: string): void {
    this.store.dispatch(CompareGroupActions.removeCompareProgramGroup({ id }))
    this.store.dispatch(CompareActions.removeCompareProgramGroup({ id }))
  }

  onSaveClick(): void {
    this.store.dispatch(saveAnalysis())
  }

  onSlidesPerViewChange(value: number): void {
    this.store.dispatch(setCompareSlidesPerView({ value }))
  }

  onStructureFilterChange(val: string): void {
    this.store.dispatch(setStructureFilter({ filter: val }))
  }
  onGroupFilterByAssocChange(value: boolean): void {
    this.store.dispatch(setGroupFilterByAssoc({ groupFilterByAssoc: value }))
  }

  onExport(event: 'pdf' | 'xlsx'): void {
    if (event === 'xlsx') {
      this.store.dispatch(CompareActions.exportCompareAsExcel())
    }
  }

  getSelectedCurrency(selCurrency: string): void {
    this.store.dispatch(
      CompareActions.compareCurrency({ compareCurrency: selCurrency })
    )
    this.store
      .pipe(select(selectComparePrograms), take(1))
      .subscribe(programs => {
        programs.forEach(program => {
          // tslint:disable-next-line: no-non-null-assertion
          const portfolioSetID = extractPortfolioSetID(program)!
          const portfolioSetAndStudyID = {
            ...portfolioSetID,
            clientID: this.clientID,
            yearID: program.yearID,
            studyID: program.studyID,
            isGroupCompare: true,
            currency:
              selCurrency === 'Default'
                ? program.structureCurrency
                : selCurrency,
          }
          this.store.dispatch(fetchPortfolioView(portfolioSetAndStudyID))
        })
      })
  }

  getSelectedConversion(selConversion: string): void {
    this.store.dispatch(
      CompareActions.compareConversion({ compareConversion: selConversion })
    )
    this.store
      .pipe(select(selectComparePrograms), take(1))
      .subscribe(programs => {
        programs.forEach(program => {
          // tslint:disable-next-line: no-non-null-assertion
          const portfolioSetID = extractPortfolioSetID(program)!
          const portfolioSetAndStudyID = {
            ...portfolioSetID,
            clientID: this.clientID,
            yearID: program.yearID,
            studyID: program.studyID,
            isGroupCompare: true,
            currency: program.structureCurrency,
            conversion: selConversion,
          }
          this.store.dispatch(fetchPortfolioView(portfolioSetAndStudyID))
        })
      })
  }

  onCompareViewSaveAsClick($event: CompareView): void {
    this.store.dispatch(saveCompareView({ compareView: $event }))
    this.onUpdateSelectedCompareView($event)
  }

  onCompareViewSaveClick($event: CompareView): void {
    this.store.dispatch(saveCompareView({ compareView: $event }))
    this.onUpdateSelectedCompareView($event)
  }

  onCompareViewDeleteClick($event: CompareView): void {
    this.store.dispatch(deleteCompareView({ compareView: $event }))
    this.onUpdateSelectedCompareView(null)
  }

  onUpdateSelectedCompareView($event: CompareView | null): void {
    this.store.dispatch(updateSelectedCompareView({ compareView: $event }))
  }

  setTabIndex($event: number): void {
    this.store.dispatch(CompareActions.setTabIndex({ tabIndex: $event }))
  }
}
