<app-tracking-header [name]="name"></app-tracking-header>
<div class="wrapper">
  <div class="filters">
    <app-tracking-sidebar
      [isLoading]="false"
      [selectors]="selectors"
      [inceptionInterval]="inceptionInterval"
      [userPreferences]="userPreferences"
      (filtersChanged)="this.filtersChange($event)"
      (inceptionDateChanged)="this.inceptionDateChanged.emit($event)"
      (saveTrackingPreferences)="this.saveTrackingPreferences.emit()"
      (setTrackingPreferences)="this.setTrackingPreferences.emit($event)"
    ></app-tracking-sidebar>
  </div>

  <div class="table-container">
    <app-tracking-tabs *ngIf="filteredDataSource.data.length > 0 && !isLoading"
      [dataCounts]= "dataCounts"
    ></app-tracking-tabs>
    <div *ngIf="isLoading" class="spinner">
      <mat-progress-spinner
        mode="indeterminate"
        diameter="100"
        color="accent"
      ></mat-progress-spinner>
    </div>
    <div
      *ngIf="filteredDataSource.data.length === 0 && !isLoading"
      class="inner-table"
    >
      No data found.
    </div>
    <div class="table-data">
      <table
        *ngIf="filteredDataSource.data.length > 0 && !isLoading"
        #outerTable
        mat-table
        #outerSort="matSort"
        [dataSource]="filteredDataSource"
        multiTemplateDataRows
        class="outer-table mat-elevation-z8"
        matSort
      >
        <ng-container
          [matColumnDef]="column.id"
          *ngFor="let column of columnDefs"
        >
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ column.label }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element[column.id] }}
            <mat-icon
              class="icon"
              *ngIf="column.id === 'complete' && element[column.id] !== 'Yes'"
              inline
              (click)="updateCompete(element); $event.stopPropagation()"
            >
              edit
            </mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="columnDefs.length"
          >
            <div
              class="tracking-element-detail"
              *ngIf="element.details?.length"
              [@detailExpand]="
                element === expandedElement ? 'expanded' : 'collapsed'
              "
            >
              <div class="inner-table mat-elevation-z8" *ngIf="expandedElement">
                <div *ngFor="let detailsData of element.details">
                  <mat-form-field>
                    <input
                      matInput
                      (keyup)="applyFilter($event.target.value)"
                      placeholder="Filter"
                    />
                    <span class="structure-name">{{
                      detailsData.structureName
                    }}</span>
                  </mat-form-field>
                  <table
                    #innerTables
                    mat-table
                    #innerSort="matSort"
                    [dataSource]="detailsData.layerDetails"
                    matSort
                  >
                    <ng-container
                      matColumnDef="{{ innerColumn.id }}"
                      *ngFor="let innerColumn of innerColumnDefs"
                    >
                      <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        [ngStyle]="getCellStyle(innerColumn)"
                      >
                        {{ innerColumn.label }}
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        [ngStyle]="getCellStyle(innerColumn)"
                      >
                        {{ element[innerColumn.id] }}
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="innerColIds"></tr>
                    <tr mat-row *matRowDef="let row; columns: innerColIds"></tr>
                  </table>
                </div>
              </div>
            </div>
            <div
              class="tracking-element-detail"
              *ngIf="!element.details?.length"
              [@detailExpand]="
                element === expandedElement ? 'expanded' : 'collapsed'
              "
            >
              <div *ngIf="expandedElement && isDetailsLoading">
                <mat-progress-spinner
                  mode="indeterminate"
                  diameter="40"
                  color="accent"
                ></mat-progress-spinner>
              </div>
              <div
                class="inner-table mat-elevation-z8"
                *ngIf="expandedElement && !isDetailsLoading"
              >
                No details available.
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="colIds; sticky: true"></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: colIds"
          [class.tracking-element-row]="element"
          [class.tracking-expanded-row]="expandedElement === element"
          [class.complete-class]="element.complete === 'Yes'"
          (click)="toggleRow(element)"
          class="outer-row"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          class="tracking-details-row"
        ></tr>
      </table>
    </div>
    <mat-paginator
      #paginator
      [pageSizeOptions]="[15, 30, 45, 60]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </div>
</div>
