import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { SharedModule } from '@shared/shared.module'
import { AutoBuildProgressDialogComponent } from './auto-build-progress-dialog/auto-build-progress-dialog.component'
import { ClientGridComponent } from './client-grid.component'
import { ClientImageContainerComponent } from './client-select/client-image.container'
import { ClientSelectComponent } from './client-select/client-select.component'
import { CloneDialogComponent } from './clone-dialog/clone-dialog.component'
import { DesignFromOTDialogComponent } from './design-from-ot-dialog/design-from-ot-dialog'
import { NewFolderDialogComponent } from './new-folder-dialog/new-folder-dialog.component'
import { NewFolderDialogContainerComponent } from './new-folder-dialog/new-folder-dialog.container'
import { OpportunityDetailsDialogComponent } from './opportunity-info/opportunity-details-dialog.component'
import { OpportunityDetailsDialogService } from './opportunity-info/opportunity-details-dialog.service'
import { ProgramOpportunityInfoComponent } from './opportunity-info/program-opportunity-info.component'
import { SelectedClientContainerComponent } from './client-select/selected-client.container'
import { StructureCardComponent } from './structure-card/structure-card.component'
import { StructureCardContainerComponent } from './structure-card/structure-card.container'
import { StructureFolderComponent } from './structure-folder.component/structure-folder.component'
import { StructureFolderContainerComponent } from './structure-folder.container/structure-folder.container'
import { SwapLossSetsDialogComponent } from './swap-loss-sets-dialog/swap-loss-sets-dialog.component'
import { TierBarContainerComponent } from './tier-bar-container/tier-bar.container'
import { TierBarComponent } from './tier-bar.component/tier-bar.component'
import { TierStructuresDialogComponent } from './tier-structures-dialog.component/tier-structures-dialog.component'
import { TierStructuresMenuComponent } from './tier-structures-menu.component/tier-structures-menu.component'
import { TierStructuresComponent } from './tier-structures.component/tier-structures.component'
import { TierService } from './tier.service'
import { CreditTierStructuresMenuComponent } from './credit/credit-tier-structures-menu/credit-tier-structures-menu.component'
import { CreditTierStructuresDialogComponent } from './credit/credit-tier-structures-dialog/credit-tier-structures-dialog.component'
import { CreditTierStructuresContainerComponent } from './credit/credit-tier-structures/credit-tier-structures.container'
import { CreditTierStructuresComponent } from './credit/credit-tier-structures/credit-tier-structures.component'
import { CreditTierBarMultiselectContainerComponent } from './credit/credit-tier-bar-multiselect/credit-tier-bar-multiselect.container'
import { CreditTierBarMultiselectComponent } from './credit/credit-tier-bar-multiselect/credit-tier-bar-multiselect.component'
import { CreditStructureCardContainerComponent } from './credit/credit-structure-card/credit-structure-card.container'
import { CreditStructureCardComponent } from './credit/credit-structure-card/credit-structure-card.component'

const CREDIT_COMPONENTS = [
  CreditTierStructuresDialogComponent,
  CreditTierStructuresContainerComponent,
  CreditTierStructuresComponent,
  CreditTierBarMultiselectContainerComponent,
  CreditTierBarMultiselectComponent,
  CreditStructureCardContainerComponent,
  CreditStructureCardComponent,
]

const CREDIT_EXPORTS = [CreditTierStructuresMenuComponent]

const COMPONENTS = [
  AutoBuildProgressDialogComponent,
  ClientGridComponent,
  DesignFromOTDialogComponent,
  NewFolderDialogComponent,
  NewFolderDialogContainerComponent,
  OpportunityDetailsDialogComponent,
  StructureCardComponent,
  StructureFolderComponent,
  StructureFolderContainerComponent,
  TierStructuresDialogComponent,
  ...CREDIT_COMPONENTS,
]

const EXPORTS = [
  CloneDialogComponent,
  ClientImageContainerComponent,
  ProgramOpportunityInfoComponent,
  SelectedClientContainerComponent,
  StructureCardContainerComponent,
  SwapLossSetsDialogComponent,
  TierBarComponent,
  TierBarContainerComponent,
  TierStructuresComponent,
  TierStructuresMenuComponent,
  ClientSelectComponent,
  ...CREDIT_EXPORTS,
]

@NgModule({
  declarations: [...COMPONENTS, ...EXPORTS],
  imports: [SharedModule, FormsModule, RouterModule],
  exports: EXPORTS,
  providers: [OpportunityDetailsDialogService, TierService],
})
export class TierModule {}
