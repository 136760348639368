import {
  Component,
  ViewChild,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource, MatTable } from '@angular/material/table'
import {
  DataCounts,
  TRACKING_COLUMNS,
  TRACKING_DETAILS_COLUMNS,
  TrackingData,
  TrackingDetailsData,
  TrackingDetailsLayerData,
  TrackingSelectors,
} from '../tracking.model'
import { MatPaginator } from '@angular/material/paginator'
import { parseDate } from '../tracking.utils'
import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'
import { BasicControl } from 'src/app/management-information/model/management-information.model'
import { ConfirmationDialogService } from '@shared/services/confirmation-dialog.service'
import { SelectedUserPreferences } from 'src/app/core/model/reinsurer.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class TrackingComponent implements OnInit, OnChanges {
  name = 'Tracking Module'

  @ViewChild('outerSort') sort: MatSort
  @ViewChildren('innerSort') innerSort: QueryList<MatSort>
  @ViewChildren('innerTables') innerTables: QueryList<
    MatTable<TrackingDetailsLayerData>
  >
  @ViewChild('outerTable') outerTable: MatTable<TrackingData>
  @ViewChild('paginator') paginator: MatPaginator

  @Input() trackingData: TrackingData[] | null
  @Input() selectors: TrackingSelectors | null
  @Input() isLoading: boolean
  @Input() isDetailsLoading: boolean
  @Input() inceptionInterval: BasicControl
  @Input() userPreferences: SelectedUserPreferences[]

  @Output() filtersChanged = new EventEmitter<{
    filters: Record<string, string[]>
  }>()
  @Output() fetchTrackingDetails = new EventEmitter<number>()
  @Output() inceptionDateChanged = new EventEmitter<{
    minInceptionDate: string
    maxInceptionDate: string
  }>()
  @Output() updateComplete = new EventEmitter<number>()
  @Output() saveTrackingPreferences = new EventEmitter()
  @Output() setTrackingPreferences = new EventEmitter<{ id: number }>()

  dataSource: MatTableDataSource<TrackingData>
  trackingTableData: TrackingData[] = []
  expandedElement: TrackingData | null
  filteredtrackingData: TrackingData[] | null
  filteredDataSource: MatTableDataSource<TrackingData>
  filtersData: Record<string, string[]>
  columnDefs: SortTableColumnDef[] = TRACKING_COLUMNS
  colIds: string[]
  innerColumnDefs: SortTableColumnDef[] = TRACKING_DETAILS_COLUMNS
  innerColIds: string[]
  dataCounts: any
  
  constructor(
    private cd: ChangeDetectorRef,
    private confirmationDialog: ConfirmationDialogService
  ) {}

  ngOnInit(): void {
    this.filteredtrackingData = this.trackingData
  }

  ngOnChanges(): void {
    this.trackingTableData = []
    this.filteredtrackingData = this.trackingData
    if (this.filtersData) {
      if (
        this.filtersData.clientName &&
        this.filtersData.clientName.length > 0
      ) {
        this.filteredtrackingData = this.filteredtrackingData.filter(item =>
          this.filtersData.clientName.includes(item.clientName)
        )
      }
      if (this.filtersData.year && this.filtersData.year.length > 0) {
        this.filteredtrackingData = this.filteredtrackingData.filter(item =>
          this.filtersData.year.includes(item.year)
        )
      }
      if (
        this.filtersData.businessUnit &&
        this.filtersData.businessUnit.length > 0
      ) {
        this.filteredtrackingData = this.filteredtrackingData.filter(item =>
          this.filtersData.businessUnit.includes(item.businessUnit)
        )
      }
      if (this.filtersData.complete && this.filtersData.complete.length > 0) {
        this.filteredtrackingData = this.filteredtrackingData.filter(item =>
          this.filtersData.complete.includes(item.complete)
        )
      }
      if (
        this.filtersData.reinsurerNames &&
        this.filtersData.reinsurerNames.length > 0
      ) {
        this.filteredtrackingData = this.filteredtrackingData.filter(item => {
          return this.filtersData.reinsurerNames.some(
            name => item.reinsurerNames && item.reinsurerNames.includes(name)
          )
        })
      }
    } else {
      this.filteredtrackingData = this.filteredtrackingData.filter(item => {
        return item.year === this.selectors.year.selectedValues[0]
      })
      if (
        this.selectors.businessUnit.selectedValues.length != 0 &&
        this.selectors.businessUnit.selectedValues[0]
      ) {
        this.filteredtrackingData = this.filteredtrackingData.filter(item => {
          return (
            item.businessUnit === this.selectors.businessUnit.selectedValues[0]
          )
        })
      }
    }
    if (
      this.inceptionInterval &&
      this.inceptionInterval.minValue &&
      this.inceptionInterval.maxValue
    ) {
      let minDate = new Date(this.inceptionInterval.minValue)
      let maxDate = new Date(this.inceptionInterval.maxValue)
      this.filteredtrackingData = this.filteredtrackingData.filter(data => {
        const dataDate = new Date(data.inceptionDate)
        return dataDate >= minDate && dataDate <= maxDate
      })
    }

    this.filteredtrackingData.forEach(data1 => {
      let data = JSON.parse(JSON.stringify(data1))
      if (data.details && Array.isArray(data.details) && data.details.length) {
        let trackingDetailsData: TrackingDetailsData[] = []
        for (let i = 0; i < data.details.length; i++) {
          if (
            data.details[i].layerDetails &&
            Array.isArray(data.details[i].layerDetails)
          ) {
            trackingDetailsData = [
              ...trackingDetailsData,
              {
                ...data.details[i],
                layerDetails: new MatTableDataSource(
                  data.details[i].layerDetails
                ),
              },
            ]
          } else {
            trackingDetailsData = [...trackingDetailsData, data.details[i]]
          }
        }
        if (
          this.expandedElement &&
          this.expandedElement.programId === data.programId
        ) {
          this.expandedElement = { ...data, details: trackingDetailsData }
        }
        this.trackingTableData = [
          ...this.trackingTableData,
          {
            ...data,
            details: trackingDetailsData,
          },
        ]
      } else {
        this.trackingTableData = [...this.trackingTableData, data]
      }
    })
    this.dataSource = new MatTableDataSource(this.trackingTableData)
    this.dataSource.sort = this.sort
    this.dataSource.sortingDataAccessor = (
      item: TrackingData,
      property: keyof Omit<TrackingData, 'details' | 'reinsurerNames'>
    ) => {
      if (
        property === 'inceptionDate' ||
        property === 'marketingList' ||
        property === 'submittedOT'
      ) {
        // Special sorting logic for Dates
        const parsedDate = parseDate(item[property])
        return parsedDate ? parsedDate.getTime() : 0
      }
      return item[property]
    }
    this.filteredDataSource = this.dataSource
    this.colIds = this.columnDefs.map(c => c.id as string)
    this.innerColIds = this.innerColumnDefs.map(c => c.id as string)
    this.filteredDataSource.paginator = this.paginator

    if (this.expandedElement) {
      const index = this.trackingTableData.findIndex(
        item => item.programId === this.expandedElement.programId
      )
      if (index >= 0) {
        this.expandedElement = this.trackingTableData[index]
      } else {
        this.expandedElement = null // Reset if the expanded element is no longer in the data
      }
    }
    this.calculateCount();
  }

  calculateCount() {
    let trackingAfterFilter = this.filteredtrackingData.filter(
      id =>
        id.opportunityStage !== 'Prospect' &&
        id.opportunityStage !== 'RFP/Pitch' &&
        id.opportunityStage !== 'NTU' &&
        id.fotCount !== 0 ||
        id.quoteCount !== 0
    )
    let dataCount: DataCounts = {
      distinctClients: [...new Set(trackingAfterFilter.map(item => item.clientId))].length,
      distinctPrograms: [...new Set(trackingAfterFilter.map(item => item.programId))].length,
      distinctLayers: trackingAfterFilter.reduce((n, {layerCount}) => n + layerCount, 0),
      distinctQuotes: trackingAfterFilter.reduce((n, {quoteCount}) => n + quoteCount, 0),
      distinctFots: trackingAfterFilter.reduce((n, {fotCount}) => n + fotCount, 0),
    }
    this.dataCounts = dataCount
  }

  toggleRow(element: TrackingData): void {
    if (!element) {
      return
    }
    this.expandedElement = this.expandedElement === element ? null : element
    this.cd.detectChanges()
    this.innerTables.forEach(
      (table, index) =>
        ((
          table.dataSource as MatTableDataSource<TrackingDetailsLayerData>
        ).sort = this.innerSort.toArray()[index])
    )
    if (this.expandedElement && !this.expandedElement.details) {
      // Load details here
      this.fetchTrackingDetails.emit(element.programId)
    }
  }

  applyFilter(filterValue: string): void {
    this.innerTables.forEach(
      table =>
        ((
          table.dataSource as MatTableDataSource<TrackingDetailsLayerData>
        ).filter = filterValue.trim().toLowerCase())
    )
  }

  filtersChange(props: {
    filters: Record<string, string[]>
    isSet: boolean
  }): void {
    this.filtersData = props.filters
    if (props.isSet) {
      this.filtersChanged.emit(props)
    }
  }

  getCellStyle(col: SortTableColumnDef): Record<string, string> {
    let style: Record<string, string> = {}
    if (col?.width != null) {
      style.width = col.width
    }
    return style
  }

  updateCompete(element: TrackingData): void {
    if (!element) {
      return
    }
    this.confirmationDialog
      .open({
        message: 'Do you want to manually complete a Program?',
        submitLabel: 'Yes',
      })
      .afterClosed()
      .subscribe(confirm => {
        if (confirm) {
          this.updateComplete.emit(element.programId)
        }
      })
  }
}
