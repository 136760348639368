import { MetricValueType } from '../../core/model/metric-value-type.model'
import { LayerViewValues } from '../model/layer-view'
import { LAYER_PALETTE, layerIds } from './layer-palette.model'

type TypeApplicabilityLevel = 'program' | 'layer' | 'subtype' | 'type'

export type TypeApplicability =
  | string
  | {
      level?: TypeApplicabilityLevel
      type: string
    }

export interface LayerMetricDef {
  label: string | ((values?: LayerViewValues) => string)
  valueType?: MetricValueType
  decimals?: number
  editable?: boolean | ((values?: LayerViewValues) => boolean)
  hide?: boolean | ((values?: LayerViewValues) => boolean)
  unlimitable?:
    | LayerMetricDefKey
    | false
    | ((
        values?: LayerViewValues
      ) => LayerMetricDefKey | undefined | null | false)
  whitelist?: TypeApplicability[]
  blacklist?: TypeApplicability[]
  childFieldId?: string
  fotOnly?: boolean
}

type LayerMetricDefKey = keyof LayerViewValues

export type LayerMetricDefMap = Record<LayerMetricDefKey, LayerMetricDef>

export interface LayerMetricDefAndID extends LayerMetricDef {
  id: keyof LayerViewValues
}

const resolveProps = ['label', 'hide', 'editable', 'unlimitable'] as const

export interface LayerMetricDefResolved
  extends Omit<LayerMetricDef, (typeof resolveProps)[number]> {
  label: string
  editable: boolean
  hide: boolean
  unlimitable?: false | LayerMetricDefKey
  valueType?: MetricValueType
  decimals?: number
}

export const resolveLayerMetricDef = <T extends LayerMetricDef>(
  def: T,
  values?: LayerViewValues
): LayerMetricDefResolved & T => {
  let resolved = { ...def } as LayerMetricDefResolved & T

  function resolve(prop: (typeof resolveProps)[number]): void {
    const valueOrFn = def[prop]
    const val =
      valueOrFn === undefined
        ? undefined
        : typeof valueOrFn === 'string' || typeof valueOrFn === 'boolean'
          ? valueOrFn
          : valueOrFn(values)

    if (val !== undefined) {
      resolved = Object.assign({}, resolved, { [prop]: val })
    }
  }

  resolveProps.forEach(resolve)

  return resolved
}

const hasLayerPalette = (values?: LayerViewValues): boolean =>
  values
    ? LAYER_PALETTE.some(
        p => p.id === values.type && p.subtype === values.subtype
      )
    : true

const isNotAggFeeder = (values?: LayerViewValues): boolean =>
  values ? values.layerType !== 'ag' || values.subtype != null : true

const isAggFeeder = (values?: LayerViewValues): boolean =>
  values ? values.layerType === 'ag' && values.subtype === 'feeder' : false

const isTopAndDrop = (values?: LayerViewValues): boolean =>
  values && values.type ? topAndDropTypes.includes(values.type) : false

// Hidden Layer
const isTopAndDropActual = (values?: LayerViewValues): boolean =>
  isTopAndDrop(values) && values != null && values.subtype === 'actual'

// Top Layer
const isTopAndDropVirtual = (values?: LayerViewValues): boolean =>
  isTopAndDrop(values) && values != null && values.subtype === 'virtual'

// Drop Layer
const isDropLayer = (values?: LayerViewValues): boolean =>
  values != null && values.type === 'drop' ? true : false

const isMultiSection = (values?: LayerViewValues): boolean =>
  values?.type === layerIds.catMultisection ||
  values?.type === layerIds.noncatMultisection

const isFHCF = (values?: LayerViewValues): boolean =>
  values ? values.layerType === 'fhcf' : false

const topAndDropTypes = ['cat_td', 'top_and_drop', 'drop']
const cascadeTypes = ['cat_ca', 'cat_cascading']

// TODO Layer properties panel configured here
const layerMetricDefs: LayerMetricDefMap = {
  layers: {
    label: 'Layers',
    hide: true,
    editable: false,
  },
  description: {
    label: 'Description',
    editable: false,
  },
  name: {
    label: 'Layer Name',
    editable: true,
  },
  sectionNarrative: {
    label: 'Narrative',
    hide: true,
  },
  layerDetailsType: {
    label: 'Layer Type',
    editable: false,
  },
  sharedLayerID: { label: 'Shared ID', hide: true },
  id: { label: 'ID', hide: true },
  type: { label: 'Type' },
  subtype: { label: 'Subtype' },
  programType: { label: 'Program Type', hide: hasLayerPalette },
  layerType: { label: 'Layer Type', hide: hasLayerPalette },
  currency: { label: 'Currency', hide: true },
  inuringBenefit: { label: 'Inuring Benefit' },

  occurrenceLimit: {
    label: values =>
      isTopAndDropActual(values)
        ? 'Shared Limit'
        : `${isNotAggFeeder(values) ? '' : 'Feeder '}Occurrence Limit`,
    valueType: 'currency',
    editable: isNotAggFeeder,
    unlimitable: _ => 'isOccurrenceUnlimited',
    hide: values => isTopAndDrop(values) && !isTopAndDropActual(values),
    blacklist: ['cat_multisection', 'noncat_multisection', 'cat_ilw_bin'],
  },
  isOccurrenceUnlimited: {
    label: 'Is Occurrence Unlimited',
    hide: true,
    valueType: 'text',
  },
  contractOccurrenceLimit: {
    label: 'Contract Occurrence Limit',
    valueType: 'currency',
    editable: true,
    unlimitable: _ => 'isContractOccurrenceUnlimited',
    whitelist: ['cat_multisection', 'noncat_multisection'],
  },
  isContractOccurrenceUnlimited: {
    label: 'Is Contract Occurrence Unlimited',
    hide: true,
    valueType: 'text',
  },
  occurrenceAttachment: {
    label: values => {
      if (values && values.type && cascadeTypes.includes(values.type)) {
        return 'Cascading Floor'
      }
      const feeder = values
        ? values.occurrenceAttachment != null && isNotAggFeeder(values)
        : true
      return `${feeder ? '' : 'Feeder '}Occurrence Attachment`
    },
    valueType: 'currency',
    editable: values =>
      values
        ? values.occurrenceAttachment !== null && isNotAggFeeder(values)
        : true,
    blacklist: [
      ...topAndDropTypes,
      { level: 'layer', type: 'qs' },
      'cat_multisection',
      'noncat_multisection',
      'cat_ilw_bin',
      'cat_ilw_pro_rata',
    ],
  },

  franchiseDeductible: {
    label: values => {
      const feeder = values
        ? values.franchiseDeductible != null && isNotAggFeeder(values)
        : true
      return `${feeder ? '' : 'Feeder '}Franchise Deductible`
    },
    valueType: 'currency',
    editable: values =>
      values
        ? values.franchiseDeductible !== null && isNotAggFeeder(values)
        : true,
    whitelist: [
      ...cascadeTypes,
      { level: 'layer', type: 'ag' },
      'noncat_indxl',
    ],
  },

  aggregateLimit: {
    label: 'Aggregate Limit',
    valueType: 'currency',
    editable: values => !isAggFeeder(values) && !isFHCF(values),
    blacklist: [...topAndDropTypes, 'cat_ilw_bin'],
    unlimitable: _ => 'isAggregateUnlimited',
  },
  isAggregateUnlimited: {
    label: 'Is Aggregate Unlimited',
    hide: true,
    valueType: 'text',
  },
  aggregateAttachment: {
    label: 'Aggregate Attachment / AAD',
    valueType: 'currency',
    editable: values => !isAggFeeder(values),
    blacklist: [
      // ...topAndDropTypes,
      { level: 'layer', type: 'qs' },
      'cat_ilw_bin',
    ],
  },
  limitApplication: {
    label: 'Limit Application',
    valueType: 'text',
    editable: true,
  },
  indexation: {
    label: 'Indexation',
    valueType: 'dropdown',
    editable: true,
    whitelist: ['noncat_indxl'],
  },

  fixedIndexValue: {
    label: 'Index Percentage',
    valueType: 'custom',
    decimals: 2,
    editable: true,
    whitelist: ['noncat_indxl'],
  },
  isCustomIndex: { label: 'isCustomIndex', hide: true },
  customIndexValues: { label: 'customIndexValues', hide: true },
  sicOrFranchise: {
    label: 'SIC / Franchise',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
    whitelist: ['noncat_indxl'],
  },

  reinstatements: {
    label: 'Reinstatements',
    valueType: 'number-list',
    editable: true,
    blacklist: [
      { level: 'layer', type: 'qs' },
      { level: 'layer', type: 'swing' },
      'ahl_xl',
      'ahl_ag',
      'cat_ag',
      'noncat_ag',
      'cat_ilw_bin',
    ],
  },

  cessionPercentage: {
    label: 'Cession Percentage',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
    blacklist: ['cat_ilw_bin', 'cat_ilw_pro_rata','cat_ilw_ag'],
  },

  orderedLimit: {
    label: 'Ordered Limit',
    valueType: 'currency',
    decimals: 0,
    editable: false,
  },

  reinstatementsSummary: {
    label: 'Reinstatements',
    valueType: 'text',
    editable: false,
  },

  expectedLoss: {
    label: 'Expected Loss',
    valueType: 'currency',
    decimals: 0,
    editable: false,
  },

  expectedLossPercentLimit: {
    label: 'Expected Loss (% of Limit)',
    valueType: 'percentage',
    decimals: 2,
    editable: false,
  },

  contractROL: {
    label: 'Contract ROL',
    valueType: 'percentage',
    decimals: 2,
    editable: false,
  },

  totalROL: {
    label: 'Total ROL',
    valueType: 'percentage',
    decimals: 2,
    editable: false,
  },

  premiumMultiple: {
    label: 'Premium Multiple',
    valueType: 'numeric',
    decimals: 3,
    editable: false,
  },

  probabilityExhaust: {
    label: 'Probability of Exhaust',
    valueType: 'ratio',
    blacklist: [{ level: 'layer', type: 'qs' }],
  },

  cedingCommission: {
    label: 'Ceding Commission',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
    whitelist: [{ level: 'layer', type: 'qs' }, 'noncat_xl', 'noncat_indxl'],
    blacklist: [
      { level: 'layer', type: 'ag' },
      'ahl_xl',
      'cat_multisection',
      'noncat_multisection',
      'cat_xl',
    ],
  },

  reinsurerExpenseProvision: {
    label: 'Reinsurer Expense Provision',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
    whitelist: [
      { level: 'layer', type: 'qs' },
      { level: 'layer', type: 'xl' },
      'noncat_indxl',
      { level: 'layer', type: 'ag' },
      'cat_multisection',
      'noncat_multisection',
      'ahl_swing',
    ],
  },

  profitCommission: {
    label: 'Profit Commission',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
    whitelist: [
      { level: 'layer', type: 'qs' },
      { level: 'layer', type: 'xl' },
      'noncat_indxl',
      { level: 'layer', type: 'ag' },
      'cat_multisection',
      'noncat_multisection',
      'ahl_swing',
    ],
  },

  rolPercentage: {
    label: values => (values ? values.rolType : 'Rate-on-Line, Occurrence'),
    valueType: 'percentage',
    decimals: 2,
    editable: true,
    blacklist: [
      { level: 'layer', type: 'qs' },
      { level: 'subtype', type: 'feeder' },
      'noncat_xl',
      'ahl_xl',
      'ahl_ag',
      'noncat_indxl',
      'noncat_risk',
      'noncat_swing',
      'ahl_swing',
      'cat_ilw_bin',
      'cat_ilw_pro_rata',
    ],
  },
  rolType: { label: 'Rate-on-Line Type', hide: true },

  premium: {
    label: 'Premium',
    valueType: 'currency',
    editable: true,
    blacklist: [
      { level: 'layer', type: 'swing' },
      { level: 'subtype', type: 'feeder' },
    ],
  },

  subjectPremiumChecked: {
    label: 'Subject Premium checked',
    hide: true,
  },

  technicalPremium: {
    label: 'Technical Premium',
    valueType: 'custom',
    blacklist: [
      { level: 'layer', type: 'swing' },
      { level: 'subtype', type: 'feeder' },
    ],
  },

  technicalPremiumChecked: {
    label: 'Technical Premium checked',
    hide: true,
  },

  cedingCommissionChecked: {
    label: 'Ceding Commission Checked',
    hide: true,
  },

  currencyChange: {
    label: 'Currency',
    valueType: 'custom',
    blacklist: [{ level: 'layer', type: 'swing' }],
  },

  rateOnLineSubject: {
    label: 'Rate, % of Subject',
    valueType: 'percentage',
    decimals: 3,
    editable: true,
    whitelist: ['noncat_xl', 'noncat_indxl', 'noncat_risk'],
  },

  pmpm: {
    label: 'PMPM',
    valueType: 'currency',
    decimals: 4,
    editable: true,
    blacklist: [{ level: 'subtype', type: 'feeder' }],
    whitelist: ['ahl_xl', 'ahl_qs', 'ahl_ag'],
  },

  cascadeLowerLayerID: {
    label: 'Cascade Floor',
    valueType: 'dropdown',
    editable: true,
    whitelist: cascadeTypes,
  },

  riskLimit: {
    label: 'Risk Limit',
    valueType: 'currency',
    editable: true,
    whitelist: ['noncat_risk'],
  },

  riskAttachment: {
    label: 'Risk Attachment',
    valueType: 'currency',
    editable: true,
    whitelist: ['noncat_risk'],
  },

  grossPortfolioCovariance: { label: 'Gross Portfolio Covariance' },

  depositPremium: {
    label: 'Deposit Premium',
    valueType: 'currency',
    blacklist: [{ level: 'layer', type: 'qs' }],
  },

  depositPremiumNoParticipation: {
    label: 'Deposit Premium No Participation',
    valueType: 'currency',
    hide: true,
  },

  expectedReinstatementPremium: {
    label: 'Expected Reinst Prem',
    valueType: 'currency',
  },
  expectedCededPremium: {
    label: 'Expected Ceded Premium',
    valueType: 'currency',
  },

  expectedCededPremiumNoParticipation: {
    label: 'Expected Ceded Premium No Participation',
    valueType: 'currency',
    hide: true,
  },

  pmpmExpectedCededPremium: {
    label: 'PMPM Expected Ceded Premium',
    decimals: 4,
    valueType: 'numeric',
    blacklist: [{ level: 'subtype', type: 'feeder' }],
    whitelist: ['ahl_xl', 'ahl_qs', 'ahl_ag', 'ahl_swing'],
  },
  purePremium: {
    label: 'Expected Ceded Loss (Pure Premium)',
    valueType: 'currency',
  },
  lossOnLine: {
    label: 'Loss-on-Line',
    valueType: 'percentage',
    whitelist: ['cat_xl', 'cat_ag', 'cat_multisection', 'noncat_multisection'],
    blacklist: [{ level: 'subtype', type: 'feeder' }],
  },
  standardDeviationExpectedLoss: {
    label: 'Std Dev of Ceded Loss',
    valueType: 'currency',
  },
  expectedCededExpenses: {
    label: 'Expected Ceded Expenses',
    valueType: 'currency',
  },
  expectedCededMargin: {
    label: 'Expected Ceded Margin',
    valueType: 'currency',
  },
  expectedCededLossRatio: {
    label: 'Expected Ceded Loss Ratio',
    valueType: 'percentage',
    decimals: 2,
    whitelist: [
      { level: 'program', type: 'noncat' },
      { level: 'layer', type: 'xl' },
      'noncat_indxl',
      { level: 'program', type: 'cas' },
      'cat_multisection',
      'noncat_multisection',
    ],
  },
  cededMarginToStandardDeviation: {
    label: 'Ceded Margin to Std Dev',
    valueType: 'percentage',
    decimals: 2,
    whitelist: [{ level: 'program', type: 'cat' }],
    blacklist: [{ level: 'layer', type: 'qs' }],
  },
  puremiumELMultiple: {
    label: 'Premium / El Multiple',
    valueType: 'numeric',
    whitelist: [{ level: 'program', type: 'cat' }],
    blacklist: [{ level: 'layer', type: 'qs' }],
  },

  entryProbability: {
    label: 'Probability of Attach',
    valueType: 'ratio',
  },
  exitProbability: {
    label: 'Probability of Exhaust (Occ)',
    valueType: 'ratio',
    blacklist: [{ level: 'layer', type: 'ag' }],
  },
  exitAggProbability: {
    label: 'Probability of Exhaust (Agg)',
    valueType: 'ratio',
    whitelist: [{ level: 'layer', type: 'ag' }],
  },

  aeptVar100: {
    label: 'TVaR',
    valueType: 'currency',
  },
  aepVar100: {
    label: 'VaR',
    valueType: 'currency',
  },
  aeptVar250: {
    label: 'TVaR',
    valueType: 'currency',
  },
  aepVar250: {
    label: 'VaR',
    valueType: 'currency',
  },
  aepWindowVar: {
    label: 'AEP Window Volatility',
    valueType: 'currency',
  },
  oepWindowVar: {
    label: 'OEP Window Volatility',
    valueType: 'currency',
  },

  efficiencyTVaR: {
    label: 'TVaR',
    valueType: 'formatted-onedecimal-numeric',
  },
  efficiencyVolatility: {
    label: 'VaR',
    valueType: 'formatted-onedecimal-numeric',
  },

  grossCovariance: {
    label: 'Gross Covariance',
    valueType: 'numeric',
  },
  grossVolatilityCeded: {
    label: 'Gross Volatility Ceded',
    valueType: 'percentage',
  },

  purePremiumForTP: {
    label: 'Expected Ceded Loss (Pure Premium For Technical Premium)',
    valueType: 'currency',
  },

  standardDeviationExpectedLossForTP: {
    label: 'Std Dev of Expected Ceded Loss (For Technical Premium)',
    valueType: 'currency',
  },

  portfolioEfficiencyScore: {
    label: 'Efficiency Score',
    valueType: 'numeric',
    decimals: 3,
  },

  subjectPremiumQS: {
    label: 'Subject Premium',
    valueType: 'currency',
    hide: true,
  },

  payout: {
    label: 'Payout',
    valueType: 'currency',
    editable: true,
    whitelist: ['cat_ilw_bin', 'cat_ilw_pro_rata','cat_ilw_ag'],
    blacklist: [
      { level: 'subtype', type: 'feeder' },
    ]
  },

  trigger: {
    label: 'Trigger',
    valueType: 'currency',
    editable: true,
    whitelist: ['cat_ilw_bin', 'cat_ilw_pro_rata'],
  },

  nth: {
    label: 'Nth',
    valueType: 'nondecimal-numeric',
    editable: true,
    whitelist: ['cat_ilw_bin'],
  },

  inception_date: {
    label: 'Inception Date',
    valueType: 'date',
    editable: true,
    whitelist: ['cat_ilw_bin', 'cat_ilw_pro_rata','cat_ilw_ag'],
  },

  expiry_date: {
    label: 'Expiry Date',
    valueType: 'date',
    editable: true,
    whitelist: ['cat_ilw_bin', 'cat_ilw_pro_rata','cat_ilw_ag'],
  },

  el: {
    label: 'EL %',
    valueType: 'percentage',
    decimals: 2,
    whitelist: ['cat_ilw_bin', 'cat_ilw_pro_rata'],
  },

  quoteOccurrenceLimit: {
    label: values => {
      if (isMultiSection(values) && values?.subtype === 'visible-layer') {
        return 'Contract Occurrence Limit'
      }
      if (isTopAndDropActual(values)) {
        return 'Shared Per Occurrence Limit'
      }
      if (isDropLayer(values)) {
        return 'Drop Occurrence Limit'
      }
      if (isTopAndDropVirtual(values)) {
        return 'Top Occurrence Limit'
      }
      return 'Occurrence Limit'
    },
    valueType: 'currency',
    editable: isNotAggFeeder,
    unlimitable: _ => 'isQuoteOccurrenceUnlimited',
    // hide: values => isTopAndDrop(values) && !isTopAndDropActual(values),
    // blacklist: ['cat_multisection', 'noncat_multisection'],
  },

  isQuoteOccurrenceUnlimited: {
    label: 'Is Occurrence Unlimited',
    hide: true,
    valueType: 'text',
    // blacklist: ['cat_multisection', 'noncat_multisection'],
  },

  quoteOccurrenceAttachment: {
    label: values => {
      if (values && values.type && cascadeTypes.includes(values.type)) {
        return 'Cascading Floor'
      }
      if (isTopAndDropActual(values)) {
        return 'Shared Per Occurrence Attachment'
      }
      if (isDropLayer(values)) {
        return 'Drop Occurrence Attachment'
      }
      if (isTopAndDropVirtual(values)) {
        return 'Top Occurrence Attachment'
      }
      return 'Occurrence Attachment'
    },
    valueType: 'currency',
    editable: values =>
      values
        ? values.quoteOccurrenceLimit !== null && isNotAggFeeder(values)
        : true,
    blacklist: [
      // ...topAndDropTypes,
      { level: 'layer', type: 'qs' },
      // 'cat_multisection',
      // 'noncat_multisection',
    ],
  },

  quoteTopOccurrenceLimit: {
    label: values => (isTopAndDrop(values) ? 'Top Occurrence Limit' : ''),
    valueType: 'currency',
    editable: false,
    unlimitable: _ => 'isQuoteTopOccurrenceUnlimited',
    hide: true,
  },

  isQuoteTopOccurrenceUnlimited: {
    label: 'Is Top Occurrence Unlimited',
    hide: true,
    valueType: 'text',
    // blacklist: ['cat_multisection', 'noncat_multisection'],
  },
  quoteTopOccurrenceAttachment: {
    label: values => {
      if (values && values.type && cascadeTypes.includes(values.type)) {
        return 'Cascading Floor'
      }
      return 'Top Occurrence Attachment'
    },
    valueType: 'currency',
    editable: values =>
      values
        ? values.quoteTopOccurrenceLimit !== null && isNotAggFeeder(values)
        : true,
    blacklist: [
      { level: 'layer', type: 'qs' },
      // 'cat_multisection',
      // 'noncat_multisection',
    ],
    hide: true,
  },
  quoteDropOccurrenceLimit: {
    label: values => (isTopAndDrop(values) ? 'Drop Occurrence Limit' : ''),
    valueType: 'currency',
    editable: false,
    unlimitable: _ => 'isQuoteDropOccurrenceUnlimited',
    hide: true,
  },

  isQuoteDropOccurrenceUnlimited: {
    label: 'Is Drop Occurrence Unlimited',
    hide: true,
    valueType: 'text',
    // blacklist: ['cat_multisection', 'noncat_multisection'],
  },
  quoteDropOccurrenceAttachment: {
    label: values => {
      if (values && values.type && cascadeTypes.includes(values.type)) {
        return 'Cascading Floor'
      }
      return 'Drop Occurrence Attachment'
    },
    valueType: 'currency',
    editable: values =>
      values
        ? values.quoteDropOccurrenceLimit !== null && isNotAggFeeder(values)
        : true,
    blacklist: [
      { level: 'layer', type: 'qs' },
      // 'cat_multisection',
      // 'noncat_multisection',
    ],
    hide: true,
  },

  quoteRiskLimit: {
    label: 'Risk Limit',
    valueType: 'currency',
    editable: true,
  },

  quoteRiskAttachment: {
    label: 'Risk Attachment',
    valueType: 'currency',
    editable: true,
    whitelist: ['noncat_risk'],
  },

  quoteFranchiseDeductible: {
    label: 'Franchise Deductible',
    valueType: 'currency',
    editable: values =>
      values
        ? values.quoteFranchiseDeductible !== null && isNotAggFeeder(values)
        : true,
    whitelist: [...cascadeTypes, { level: 'layer', type: 'ag' }],
  },

  quoteReinstatements: {
    label: 'Reinstatements',
    valueType: 'number-list',
    editable: true,
    blacklist: [
      { level: 'layer', type: 'qs' },
      { level: 'layer', type: 'ag' },
    ],
  },

  quoteAggregateLimit: {
    label: values => {
      if (isTopAndDropActual(values)) {
        return 'Shared Aggregate Limit'
      }
      if (isDropLayer(values)) {
        return 'Drop Aggregate Limit'
      }
      if (isTopAndDropVirtual(values)) {
        return 'Top Aggregate Limit'
      }
      return 'Aggregate Limit'
    },
    valueType: 'currency',
    editable: values => !isAggFeeder(values) && !isFHCF(values),
    unlimitable: _ => 'isQuoteAggregateUnlimited',
    // blacklist: topAndDropTypes,
  },

  isQuoteAggregateUnlimited: {
    label: 'Is Aggregate Unlimited',
    hide: true,
    valueType: 'text',
  },

  quoteAggregateAttachment: {
    label: values => {
      if (isTopAndDropActual(values)) {
        return 'Shared Aggregate Attachment'
      }
      if (isDropLayer(values)) {
        return 'Drop Aggregate Attachment'
      }
      if (isTopAndDropVirtual(values)) {
        return 'Top Aggregate Attachment'
      }
      return 'Aggregate Attachment / AAD'
    },
    valueType: 'currency',
    editable: values => !isAggFeeder(values),
    blacklist: [{ level: 'layer', type: 'qs' }],
  },

  quoteAggregateLimitTop: {
    label: values => (isTopAndDrop(values) ? 'Aggregate Limit Top' : ''),
    valueType: 'currency',
    editable: values => !isAggFeeder(values) && !isFHCF(values),
    unlimitable: _ => 'isQuoteAggregateUnlimitedTop',
    hide: true,
  },

  isQuoteAggregateUnlimitedTop: {
    label: 'Is Aggregate Unlimited Top',
    hide: true,
    valueType: 'text',
  },

  quoteAggregateLimitDrop: {
    label: values => (isTopAndDrop(values) ? 'Aggregate Limit Drop' : ''),
    valueType: 'currency',
    editable: values => !isAggFeeder(values) && !isFHCF(values),
    unlimitable: _ => 'isQuoteAggregateUnlimitedDrop',
    hide: true,
  },

  isQuoteAggregateUnlimitedDrop: {
    label: 'Is Aggregate Unlimited Drop',
    hide: true,
    valueType: 'text',
  },

  quoteCessionPercentage: {
    label: 'Cession Percentage',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
  },

  quoteCedingCommission: {
    label: 'Ceding Commission',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
    whitelist: [
      { level: 'layer', type: 'qs' },
      { level: 'layer', type: 'xl' },
      'noncat_indxl',
      { level: 'layer', type: 'ag' },
      'cat_multisection',
      'noncat_multisection',
    ],
  },

  quoteReinsurerExpenseProvision: {
    label: 'Reinsurer Expense Provision',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
    whitelist: [
      { level: 'layer', type: 'qs' },
      { level: 'layer', type: 'xl' },
      'noncat_indxl',
      { level: 'layer', type: 'ag' },
    ],
  },

  quoteProfitCommission: {
    label: 'Profit Commission',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
    whitelist: [
      { level: 'layer', type: 'qs' },
      { level: 'layer', type: 'xl' },
      'noncat_indxl',
      { level: 'layer', type: 'ag' },
      'cat_multisection',
      'noncat_multisection',
      'ahl_swing',
    ],
  },

  quoteRolPercentage: {
    label: values => (values ? values.rolType : 'Rate-on-Line, Occurrence'),
    valueType: 'percentage',
    decimals: 4,
    editable: true,
    blacklist: [
      { level: 'layer', type: 'qs' },
      { level: 'subtype', type: 'feeder' },
      'noncat_swing',
    ],
  },

  quoteRateOnLineSubject: {
    label: 'Rate, % of Subject',
    valueType: 'percentage',
    decimals: 4,
    editable: true,
    blacklist: [
      { level: 'layer', type: 'qs' },
      { level: 'layer', type: 'ag' },
      'ahl_xl', 
      'ahl_qs', 
      'ahl_ag', 
      'ahl_swing'
    ]
  },

  quotePmpm: {
    label: 'PMPM',
    valueType: 'currency',
    decimals: 4,
    editable: true,
    whitelist: ['ahl_xl', 'ahl_qs', 'ahl_ag', 'ahl_swing'],
  },

  quoteCessionsBasedPremium: {
    label: 'Cessions Based Premium',
    valueType: 'checkbox',
    editable: true,
  },

  quotePremium: {
    label: 'Premium',
    valueType: 'currency',
    editable: true,
  },

  quotePayout: {
    label: 'Payout',
    valueType: 'currency',
    editable: true,
    whitelist: ['cat_ilw_bin', 'cat_ilw_pro_rata', 'cat_ilw_ag'],
  },

  quoteTrigger: {
    label: 'Trigger',
    valueType: 'currency',
    editable: true,
    whitelist: ['cat_ilw_bin', 'cat_ilw_pro_rata'],
  },

  quoteNth: {
    label: 'Nth',
    valueType: 'nondecimal-numeric',
    editable: true,
    whitelist: ['cat_ilw_bin'],
  },

  quoteEffectiveDate: {
    label: 'Inception Date',
    valueType: 'date',
    editable: true,
    whitelist: ['cat_ilw_bin', 'cat_ilw_pro_rata','cat_ilw_ag'],
  },
  quoteExpirationDate: {
    label: 'Expiration Date',
    valueType: 'date',
    editable: true,
    whitelist: ['cat_ilw_bin', 'cat_ilw_pro_rata','cat_ilw_ag'],
  },
  quoteExpiryDate: {
    label: 'Quote Expiration Date',
    valueType: 'date',
    editable: true,
  },
  underwriter: {
    label: 'Underwriter',
    valueType: 'text',
    editable: true,
  },
  maolLimit: {
    label: 'MAOL',
    valueType: 'currency',
    editable: true,
  },
  terrorismAggSubLimit: {
    label: 'Terrorism Agg Sublimit',
    valueType: 'currency',
    editable: true,
  },
  lossRatioCap: {
    label: 'Loss Cap $',
    valueType: 'currency',
    editable: true,
  },
  lossRatioCapPercentage: {
    label: 'Loss Ratio Cap %',
    valueType: 'percentage',
    editable: true,
  },
  lossCapApplication: {
    label: 'Loss Cap Application',
    valueType: 'text',
    editable: true,
  },
  laeCap: {
    label: 'LAE Cap',
    valueType: 'currency',
    editable: true,
  },
  ecoCoveragePct: {
    label: 'ECO Coverage Pct',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
  },
  xplCoveragePct: {
    label: 'XPL Coverage Pct',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
  },
  subjectPremium: {
    label: 'Subject Premium',
    valueType: 'currency',
    editable: true,
  },
  minimumPremiumPercentage: {
    label: 'Minimum Premium Percentage',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
  },
  depositPremiumPercentage: {
    label: 'Deposit Premium Percentage',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
  },
  minimumPremium: {
    label: 'Minimum Premium',
    valueType: 'currency',
    editable: true,
  },
  clashPremium: {
    label: 'Clash Premium',
    valueType: 'currency',
    editable: true,
  },
  profitShareCommission: {
    label: 'Profit Share Commission',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
  },
  profitShareMinRate: {
    label: 'Profit Commission Min Rate',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
  },
  profitShareMaxRate: {
    label: 'Profit Commission Max Rate',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
  },

  brokerageCommission: {
    label: 'Brokerage Commission',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
  },

  brokerageType: {
    label: 'Brokerage Gross/ Net of Ceding Commission',
    valueType: 'dropdown',
    editable: true,
  },

  brokerageRIPCommission: {
    label: 'Reinstatement Brokerage %',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
  },
  orderPercent: {
    label: 'Order %',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
    fotOnly: true,
  },
  otherFeatures: {
    label: 'Other Features',
    valueType: 'text',
    editable: true,
  },
  coverageBasis: {
    label: 'Coverage Basis',
    valueType: 'text',
    editable: true,
  },
  indexationtext: {
    label: 'Indexation Clause Language',
    valueType: 'text',
    editable: true,
  },

  laeTreatment: {
    label: 'LAE Treatment',
    valueType: 'dropdown',
    editable: true,
  },

  adjustmentBasis: {
    label: 'Adjustment Basis',
    valueType: 'dropdown',
    editable: true,
  },

  quoteSignedPercentage: {
    label: 'Signed Percentage',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
  },

  quoteMinPercentage: {
    label: 'Minimum Share',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
  },

  quoteOfferedPercentage: {
    label: 'Offered Percentage',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
  },

  quoteOfferedLimit: {
    label: 'Offered Limit',
    valueType: 'currency',
    editable: true,
    blacklist: [{ level: 'layer', type: 'qs' }],
  },

  xplEcoDropdown: {
    label: 'ECO / XPL',
    valueType: 'dropdown',
    editable: true,
  },

  xplEcoConditions: {
    label: 'XPL / ECO Conditions',
    valueType: 'text',
    editable: true,
  },

  minRateSubject: {
    label: 'Min Rate, % of Subject',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
    whitelist: ['noncat_swing'],
  },

  maxRateSubject: {
    label: 'Max Rate, % of Subject',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
    whitelist: ['noncat_swing'],
  },

  minRatePmpm: {
    label: 'Min Rate, PMPM',
    valueType: 'currency',
    decimals: 4,
    editable: true,
    whitelist: ['ahl_swing'],
  },

  maxRatePmpm: {
    label: 'Max Rate, PMPM',
    valueType: 'currency',
    decimals: 4,
    editable: true,
    whitelist: ['ahl_swing'],
  },

  swingRate: {
    label: 'Swing Rate, % of Layer Loss',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
    whitelist: ['noncat_swing', 'ahl_swing'],
  },

  swingBasis: {
    label: 'Swing Basis',
    valueType: 'dropdown',
    editable: true,
    whitelist: ['noncat_swing', 'ahl_swing'],
  },

  quoteDepositPremium: {
    label: 'Deposit Premium',
    valueType: 'currency',
    editable: true,
  },

  quoteMinRateSubject: {
    label: 'Min Rate, % of Subject',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
  },
  quoteMaxRateSubject: {
    label: 'Max Rate, % of Subject',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
  },
  quoteMinRatePmpm: {
    label: 'Min Rate, PMPM',
    valueType: 'currency',
    decimals: 4,
    editable: true,
  },
  quoteMaxRatePmpm: {
    label: 'Max Rate, PMPM',
    valueType: 'currency',
    decimals: 4,
    editable: true,
  },
  quoteSwingRate: {
    label: 'Swing Rate, % of Layer Loss',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
  },
  quoteSwingBasis: {
    label: 'Swing Basis',
    valueType: 'dropdown',
    editable: true,
  },
  quoteIndexation: {
    label: 'Indexation',
    valueType: 'dropdown',
    editable: true,
  },
  quoteFixedIndexValue: {
    label: 'Index Percentage',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
  },
  quoteSicOrFranchise: {
    label: 'SIC / Franchise',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
  },
  quoteLossOnLine: {
    label: 'Loss On Line',
    valueType: 'percentage',
    editable: true,
  },
  quoteExpectedCededLoss: {
    label: 'Expected Ceded Loss',
    valueType: 'currency',
    editable: true,
  },
  quoteExpectedCededPremium: {
    label: 'Expected Ceded Premium',
    valueType: 'currency',
    editable: true,
  },

  totalQuoteExpectedCededLoss: {
    label: 'Total Expected Ceded Loss',
    valueType: 'currency',
    editable: true,
  },

  totalQuoteExpectedCededPremium: {
    label: 'Total Expected Ceded Premium',
    valueType: 'currency',
    editable: true,
  },

  quoteDepositPremiumCalc: {
    label: 'Deposit Premium Calculated',
    valueType: 'currency',
    editable: true,
  },

  slidingComm: {
    label: 'Sliding Scale Commission',
    editable: true,
  },
  cededLossCV: {
    label: 'Ceded Loss CV',
    valueType: 'currency',
  },
  cededYearTVar: {
    label: 'Ceded year 250 TVaR',
    valueType: 'currency',
  },
  cededYearVar: {
    label: 'Ceded year 250 VaR',
    valueType: 'currency',
  },
  cededLossCVForTP: {
    label: 'Ceded Loss CV Technical Premium',
    valueType: 'currency',
  },
  structureFX: {
    label: 'Structure FX',
    valueType: 'text',
    editable: true,
  },
  premiumFX: {
    label: 'Premium FX',
    valueType: 'autocomplete',
    editable: true,
  },
  premiumFXToUSD: {
    label: 'Premium FX to USD',
    valueType: 'numeric',
    editable: true,
  },
  territorialScope: {
    label: 'Territories',
    valueType: 'multiselect',
    editable: true,
  },
  vendor: {
    label: 'Vendor',
    valueType: 'autocomplete',
    editable: true,
  },
  modelVersion: {
    label: 'Model Version',
    valueType: 'numeric',
    decimals: 1,
    editable: true,
  },
  feeOrBrokerage: {
    label: 'Fee/Brokerage',
    valueType: 'dropdown',
    editable: true,
  },
  layerCategory: {
    label: 'Layer Category',
    valueType: 'dropdown',
    editable: true,
  },
  layerClass: {
    label: 'Layer Class/Subclass',
    valueType: 'dependent',
    childFieldId: 'layerSubClass',
    editable: true,
  },
  layerSubClass: {
    label: 'Layer Subclass',
    valueType: 'dependent',
    hide: true,
    editable: true,
  },
  excludeFromPricingCurve: {
    label: 'Exclude From Pricing Curve',
    valueType: 'checkbox',
    editable: true,
  },
  perils: {
    label: 'Perils',
    valueType: 'multiselect',
    editable: true,
  },
  lossImpactedFromPreviousYear: {
    label: 'Loss Impacted from Previous Year',
    valueType: 'dropdown',
    editable: true,
  },
  cedingCommissionBasis: {
    label: 'Ceding Commission Basis',
    valueType: 'dropdown',
    editable: true,
  },
  fee: {
    label: 'Fee',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
  },
  rebate: {
    label: 'Rebate',
    valueType: 'percentage',
    decimals: 2,
    editable: true,
  },
  quoteProbabilityOfAttach: {
    label: 'Probability of Attachment',
    valueType: 'numeric',
    decimals: 4,
    editable: true,
  },
  quoteProbabilityOfExhaust: {
    label: 'Probability of Exhaust',
    valueType: 'numeric',
    decimals: 4,
    editable: true,
  },
  quoteIndex: {
    label: 'Index',
    valueType: 'dropdown',
    editable: true,
  },
  parentLayerId: {
    label: 'Parent Layer ID',
    editable: false,
    hide: true,
  },
  isParent: {
    label: 'Parent Layer',
    editable: false,
    hide: true,
  },
}

export default layerMetricDefs
