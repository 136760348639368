import { Dictionary } from '@ngrx/entity'
import { produce } from 'immer'
import { sortBy, values } from 'ramda'
import { BenchmarkChartView } from 'src/app/benchmark/model/benchmark-chart-view'
import { HasID, HasName } from '../../core/model/general.model'
import { GraphingColorPalette } from '@graphing/utils/graphing-color-palette'
import { GraphingExtentsDef } from '@graphing/utils/graphing-extents'
import {
  GraphingValueFormat,
  GraphingValueFormatPreset,
} from '@graphing/utils/graphing-value-formatter'
import { GridThreeItemSpanPosition } from '@shared/grid-icon.component'
import { toArray } from '@shared/util/operators'
import { DeepPartial } from '@shared/util/types'
import { BenchmarkTableOptions } from '../table/benchmark-table.model'
import { BenchmarkDataType } from './benchmark-chart'
import { BenchmarkChartLabelsConfig } from './benchmark-chart-label'
import {
  BenchmarkDimensionMetricConfig,
  BenchmarkMetricDimensionKey,
  BenchmarkMetricDimensionsMap,
  toBenchmarkDimensionMetricConfig,
} from './benchmark-metric-dimension'
import { BenchmarkOption } from './benchmark-options'
import { BenchmarkPeriod } from './benchmark-period'
import { BenchmarkTimeframe } from './benchmark-timeframe'
import {
  BenchmarkEntityFilterType,
  BenchmarkMetric,
  BenchmarkTargetValuesByColumn,
} from './benchmark.model'
import { getBenchmarkMetricID } from './benchmark.util'
import { BenchmarkMetricResponse } from 'src/app/api/benchmark/benchmark.converter'

export interface BenchmarkSubmodeGridName {
  useMetrics?: boolean
  useTitle?: boolean
}

export const benchmarkControlTypes = [
  'timeframe',
  'period',
  'metrics',
  'lob',
  'states',
  'options',
  'entity',
  'sublines',
] as const

export type BenchmarkControlType = typeof benchmarkControlTypes[number]

export interface BenchmarkControlConfig {
  type: BenchmarkControlType
  forGridIndices?: number[]
}

export interface BenchmarkGridItemState {
  index: number
  key: string
  show: boolean
}

export interface BenchmarkMetricGroup {
  name: string
  dimensions: BenchmarkMetricDimensionsMap
  topTargetQuantity?: number
  editableStartIndex?: number
  lineIndexFromEnd?: number
  lobAllowList?: (string | number)[]
  lobLinkGroupIndex?: number[]
}

export interface BenchmarkMetricGroupSelectControl {
  /** Label of the select control. */
  name: string
  /** Option names of the select control. */
  options: string[]
}

export interface BenchmarkMetricGroupSelectOptions {
  /** Represents the two select (dropdown) controls used to determine which
   * `BenchmarkMetricGroup` is currently selected.
   */
  selectors: [
    BenchmarkMetricGroupSelectControl,
    BenchmarkMetricGroupSelectControl?
  ]
  /** Mapping of select control index selection (i.e. the index of which option
   * is selected in the two select controls) to the `BenchmarkMetricGroup`
   * index.
   *
   * @example
   *
   * Given:
   *
   * ```ts
   * {
   *   selectControls: [
   *     { name: 'Primary', options: ['I', 'II'],
   *     { name: 'Secondary', options: ['A', 'B'] }
   *   ],
   *   indexBySelectionIndices: [[0, 2], [1, 3]]
   * }
   * ```
   *
   * Two select controls shown, 'Primary' & 'Secondary'. If user selects 'II' &
   * 'A' respectively, this represents metric group index `1` since the lookup
   * on `indexBySelectionIndices` is `indexBySelectionIndices[1][0] // === 1`
   * since 'II' is index 1 of the 'Primary' control & 'A' is index 0 of the
   * 'Secondary' control. Similarly, selecting 'I' & 'A' resuls in metric
   * group index `2`.
   */
  indexMap: number[][]
}

export interface BenchmarkGridLink {
  to: 'parent' | 'child'
  index: number
}

export type BenchmarkSubheader = HasID &
  HasName & {
    hideProduction?: boolean
  }

export interface BenchmarkControlsSubmodeProps {
  submode: string
  gridIndex: number
  value: boolean
  mutuallyExclusive: boolean
}

export interface BenchmarkControlsSelectionProps {
  submode: string
  value: number
  mutuallyExclusive: boolean
}

export type BenchmarkSubmodeEndPoint =
  | 'metrics/geo'
  | 'metrics/geo-market-share'
  | 'metrics/financial-overview'
  | 'reinsurance-summary'
  | 'reinsurance-summary/spend-and-efficiency'
  | 'reinsurance-summary/limit-and-retention/search'

export type BenchmarkMetricsDefinition = {
  metric: string
  definition: string
  calculation?: string
}

export type BenchmarkSubmode = HasName & {
  id: string
  description?: string
  dataType?: BenchmarkDataType
  dataTypeOptions?: Record<string, string | number | boolean>
  endpoint?: BenchmarkSubmodeEndPoint
  extents?: GraphingExtentsDef
  isOverview?: boolean
  subheaders?: BenchmarkSubheader[]
  labels?: BenchmarkChartLabelsConfig
  entityFilter?: BenchmarkEntityFilterType
  entityId?: string
  industryId?: number
  showEntityFilter?: boolean
  restrictToPeerSetPeers?: boolean
  useMetricViews?: boolean
  useManyMetrics?: boolean
  showMetricsLegend?: boolean
  dimensions?: BenchmarkMetricDimensionsMap
  metricGroups?: BenchmarkMetricGroup[]
  metricGroupSelectOptions?: BenchmarkMetricGroupSelectOptions
  format?: GraphingValueFormat | GraphingValueFormatPreset
  grid?: DeepPartial<BenchmarkSubmode>[]
  gridName?: string | BenchmarkSubmodeGridName
  gridControls?: (BenchmarkControlType | BenchmarkControlConfig)[]
  gridDeselect?: boolean
  gridSubmodeLink?: string
  gridLink?: BenchmarkGridLink | BenchmarkGridLink[]
  gridMutuallyExclusive?: boolean
  gridDisableSelect?: boolean
  gridForceSelectIndex?: number
  gridExtentDataLink?: number
  gridThreeItemSpanPosition?: GridThreeItemSpanPosition
  gridDefaultMetrics?: BenchmarkMetric[]
  bands?: string[]
  timeframe?: number
  timeframes?: BenchmarkTimeframe[]
  growthTimeframes?: BenchmarkTimeframe[]
  lossRatioTimeframes?: BenchmarkTimeframe[]
  timeframesMultiple?: boolean
  timeframesMultipleMax?: number
  useAllTimeframeOptions?: boolean
  hideAlternativeTimeframeOption?: boolean
  useGrowthTimeframe?: boolean
  periods?: BenchmarkPeriod[]
  options?: Partial<BenchmarkOption>[]
  tableOptions?: BenchmarkTableOptions
  useControlsPeers?: boolean
  disablePeerSetColors?: boolean
  showMoreOptionsAtTopLevel?: boolean
  showPeerSetQuantileSelect?: boolean
  showAbbreviationsTable?: boolean
  showCustomFormatExport?: boolean
  excludeTargetCompany?: boolean
  hideTargetCompany?: boolean
  hidePeerSets?: boolean
  hidePeers?: boolean
  hideManagePeers?: boolean
  hideTable?: boolean
  hideDatasetExport?: boolean
  hideLob?: boolean
  hideTimeframeEndOffset?: boolean
  hideGridSelect?: boolean
  hideMaximize?: boolean
  hideComposite?: boolean
  lobAllLinesID?: number
  defaultLob?: string | number | (string | number)[] | null
  enableMultipleLob?: boolean
  showStates?: boolean
  enableMultipleStates?: boolean
  enableHover?: boolean
  targetValuesTableColumns?: BenchmarkTargetValuesByColumn[]
  targetValueMetricID?: string | number | (string | number)[]
  targetValueLegendColors?: Record<string | number, GraphingColorPalette>
  allowTargetValuesAllLinesSelection?: boolean
  useSubmodeLOBs?: string
  includeTerritories?: boolean
  includeCountrywideData?: boolean
  metricsDefinitions?: BenchmarkMetricsDefinition[]
  hideToolbarButton?: boolean
  hideCompositeTarget?: boolean
  totalsEntityIds?: string[]
}

export function getBenchmarkSubmodeDataType(
  submode: BenchmarkSubmode
): BenchmarkDataType {
  if (!submode.dataType) {
    throw new Error('Unexpected error: No submode data type found.')
  }
  return submode.dataType
}

export function everyEndpointOf(
  views: BenchmarkChartView[],
  endpoint: BenchmarkSubmodeEndPoint
) {
  return views.every(view => view.submode.endpoint === endpoint)
}

export function getBenchmarkSubmodeGridParentIndex(
  submode?: BenchmarkSubmode | null
): number | undefined {
  return toArray(submode?.gridLink).find(link => link.to === 'parent')?.index
}

export function getBenchmarkSubmodeGridChildrenIndices(
  submode?: BenchmarkSubmode | null
): number[] {
  return toArray(submode?.gridLink)
    .filter(link => link.to === 'child')
    .map(link => link.index)
}

export function getBenchmarkSubmodeShownGridCount(
  submodes: BenchmarkSubmode[],
  gridItems: BenchmarkGridItemState[]
): number {
  return gridItems.reduce((acc, state, i, states) => {
    const parentIndex = getBenchmarkSubmodeGridParentIndex(submodes[i])
    if (parentIndex == null) {
      if (state.show) {
        return acc + 1
      }
    } else {
      if (states[parentIndex]?.show) {
        return acc + 1
      }
    }
    return acc
  }, 0)
}

const sortByEstimateYear = sortBy(
  (metric: BenchmarkMetric) => metric.estimateYear ?? 0
)

function getSortedEstimateMetricDictionary(
  metricDict: Dictionary<BenchmarkMetric>
): Record<string | number, BenchmarkMetric[]> {
  const estimateMetricsByID = values(metricDict).reduce((acc, metric) => {
    if (metric?.estimateYear) {
      if (!acc[metric.id]) {
        acc[metric.id] = []
      }
      acc[metric.id].push({ ...metric })
    }
    return acc
  }, {} as Record<string | number, BenchmarkMetric[]>)

  Object.keys(estimateMetricsByID).forEach(id => {
    const sortedMetrics = sortByEstimateYear(estimateMetricsByID[id])
    estimateMetricsByID[id] = sortedMetrics.reverse()
  })

  return estimateMetricsByID
}

export function parseBenchmarkSubmode(
  submode: BenchmarkSubmode,
  metricDict: Dictionary<BenchmarkMetric>
): BenchmarkSubmode {
  let estimateMetricsByID:
    | Record<string | number, BenchmarkMetric[]>
    | undefined

  function getEstimateMetric(
    cfg?: BenchmarkDimensionMetricConfig
  ): BenchmarkMetric | undefined {
    if (!estimateMetricsByID) {
      estimateMetricsByID = getSortedEstimateMetricDictionary(metricDict)
    }
    if (cfg?.estimateYearLatestIndex != null && !cfg.estimateYear) {
      const metrics = estimateMetricsByID[cfg.id]
      if (metrics) {
        return metrics[cfg.estimateYearLatestIndex]
      }
    }
  }

  const parsedSubmode = produce(submode, draft => {
    Object.keys(draft.dimensions ?? {}).forEach(key => {
      if (draft.dimensions) {
        const dimension = draft.dimensions[key as BenchmarkMetricDimensionKey]
        if (dimension) {
          // Resolve any metric configurations using the
          // `estimateYearLatestIndex` attribute
          dimension.ids.forEach((id, i) => {
            const cfg = toBenchmarkDimensionMetricConfig(id)
            if (cfg.estimateYear || cfg.estimateYearLatestIndex != null) {
              const estimateMetric = getEstimateMetric(cfg)
              if (estimateMetric) {
                cfg.estimateYear = estimateMetric.estimateYear
                dimension.ids[i] = cfg
              }

              if (cfg.name) {
                cfg.name = cfg.name.replace('{}', String(cfg.estimateYear))
              }
            }
          })

          // Resolve any metric dimensions using the `defaultIndex` attribute
          if (dimension.defaultIndex != null && !dimension.defaultID) {
            const id = dimension.ids[dimension.defaultIndex]
            if (id) {
              const cfg = toBenchmarkDimensionMetricConfig(id)
              dimension.defaultID = getBenchmarkMetricID(cfg)
            }
          }
        }
      }
    })
  })

  return parsedSubmode
}
