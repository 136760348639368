<mat-form-field
  [ngClass]="getIndentationClass(childNumber)"
  [class.loading]="loading"
  class="field control app-bar-field app-bar-field-button app-field-bold-label"
  [class.field-less-width]="useLessWidth"
  [formGroup]="form"
  [id]="filter.columnName"
  floatLabel="auto"
  appearance="fill"
  subscriptSizing="dynamic"
>
  <mat-label
    [class.disabled]="!filter?.values?.length"
    [class.loading]="loading"
  >
    {{ name }}
  </mat-label>

  <mat-chip-grid #chipList aria-label="Selected values">
    <mat-chip-row
      [class.hidden]="triggerPanel?.panelOpen"
      [class.app-chip-palette]="includedInFilterValues(option)"
      [class.app-palette-orange]="includedInFilterValues(option)"
      *ngFor="let option of selecteds"
      (removed)="remove(option, filter)"
      [matTooltip]="applyOverride(option)"
    >
      <div class="chip-name-wrapper">
        {{ applyOverride(option) }}
      </div>
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-row>

    <input
      matInput
      class="input-field"
      #filterInput
      (keyup)="openAutosuggest()"
      (click)="openAutosuggest()"
      [formControl]="filterCtrl"
      placeholder="Click & type to add..."
      disableRipple
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (keydown.enter)="$event.preventDefault(); $event.stopPropagation()"
      [class.loading]="loading"
    />

    <mat-icon
      class="suffix-icon"
      matSuffix
      (click)="openOptions()"
      [class.hidden]="!filter?.values?.length || loading"
    >
      arrow_drop_down
    </mat-icon>
    <mat-icon
      *ngIf="(form?.controls)[filter.columnName]?.value?.length"
      class="suffix-icon"
      [class.hidden]="hideClearFilter"
      matSuffix
      (click)="clearFilter.emit($event)"
      >cancel</mat-icon
    >
  </mat-chip-grid>

  <mat-autocomplete
    #auto="matAutocomplete"
    (closed)="panelClosed(filter)"
    panelWidth="500px"
    hideSingleSelectionIndicator
  >
    <h2>Applicable</h2>
    <ng-container *ngFor="let option of filtered | async">
      <mat-option *ngIf="option?.length > 0" [value]="option">
        <mat-checkbox
          [checked]="isSelected(option)"
          (click)="$event.stopPropagation()"
          (change)="toggleOption(option)"
          [matTooltip]="applyOverride(option)"
          matTooltipClass="metric-tooltip"
          matTooltipShowDelay="250"
          matTooltipPosition="after"
        >
          {{ applyOverride(option) }}
        </mat-checkbox>
      </mat-option>
      <mat-divider *ngIf="option?.length <= 0"></mat-divider>
      <h2 class="other" *ngIf="option?.length <= 0">Other</h2>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
