import { EntityState, createEntityAdapter } from '@ngrx/entity'
import { ScenarioEventResult } from '../animated-scenarios.model'
import { createReducer, on, Action } from '@ngrx/store'
import * as fromScenarioEventResultsActions from './scenario-event-result.actions'
import { Program } from '../../../core/model/program.model'
import { saveSuccess } from '../../store/ceded-layers/layers.actions'

interface ExtendedState {
  scenarioStructure: Program | null
  eventResultsForDisplay: ScenarioEventResult[]
}

export type ScenarioEventResultState = EntityState<ScenarioEventResult> &
  ExtendedState

function sortComparer(a: ScenarioEventResult, b: ScenarioEventResult) {
  return a.id - b.id
}

export const adapter = createEntityAdapter<ScenarioEventResult>({
  sortComparer,
})

export const initialState = adapter.getInitialState<ExtendedState>({
  scenarioStructure: null,
  eventResultsForDisplay: []
})

const scenarioEventResultReduer = createReducer(
  initialState,
  on(
    fromScenarioEventResultsActions.addAllScenarioEventResults,
    (state, { eventResults, scenarioStructure, eventResultsForDisplay }) => {
      return adapter.setAll(eventResults, { ...state, scenarioStructure, eventResultsForDisplay })
    }
  ),

  on(
    fromScenarioEventResultsActions.removeAllScenarioEventResults,
    saveSuccess,
    state => {
      return adapter.removeAll({ ...state, scenarioStructure: null })
    }
  )
)

export function reducer(
  state: ScenarioEventResultState | undefined,
  action: Action
) {
  return scenarioEventResultReduer(state, action)
}
