import { clone } from "ramda"
import { SummaryChartAxis, SummaryChartDatum, SummaryChartGridOption, SummaryChartInfo } from "./summary-charts-model"
import { SummaryDataResponse } from "../explore.model"
import { currencySymbol } from "../../model/layers.util"

export const getChartSplitAndSize = (
  chartInfo: SummaryChartInfo,
  groupBy: SummaryChartAxis
): {
  split: SummaryChartAxis | null
  size: SummaryChartAxis | null
} => {
  const applicableSplitFound = chartInfo.applicableSplits.find(
    split => split.columnName !== groupBy.columnName
  )
  return {
    size:
      chartInfo.applicableSizes.length > 0
        ? chartInfo.applicableSizes[0]
        : null,
    split:
      chartInfo.applicableSplits.length > 0 && applicableSplitFound
        ? applicableSplitFound
        : null,
  }
}

export function buildChartData(
  chartOptions: SummaryChartGridOption[],
  groupSummaryData: SummaryDataResponse[],
  summaryRP: number[],
  currency: string,
  roundedToAbrev?: string,
  method?: string
): SummaryChartGridOption[] {
  const options = clone(chartOptions)
  const levelCount = Math.max(
    ...groupSummaryData.map(d => getLevelCount(d.lossName))
  )
  for (const option of options) {
    if (
      option.metric.columnName === 'contributionToModeledGroupVolatility' ||
      option.metric.columnName === 'contributionToOverallVolatility'
    ) {
      const data: SummaryChartDatum[] = []
      for (const row of groupSummaryData) {
        if (getLevelCount(row.lossName) === levelCount) {
          const lossName = row.lossName.replace(/_/g, ' ')
          data.push({
            groupBy: lossName,
            metricPrimary: row.contributionToGroupVolatility,
          })
        }
        // data.push({
        //   groupBy: `${lossName} Large`,
        //   metricPrimary: row.contributionToGroupVolatilityLarge
        // })
        // data.push({
        //   groupBy: `${lossName} Cat`,
        //   metricPrimary: row.contributionToGroupVolatilityCat
        // })
        // data.push({
        //   groupBy: `${lossName} Attritional`,
        //   metricPrimary: row.contributionToGroupVolatilityAttr
        // })
      }
      option.data = {
        data,
      }
      option.metric.format = 'percent'
    } else if (option.metric.columnName === 'grossCombinedRatioSummary') {
      const data: SummaryChartDatum[] = []
      for (const row of groupSummaryData) {
        const lossName = row.lossName.replace(/_/g, ' ')
        const premium = row.subjectPremiumAmt ?? 0
        const lossAttr = row.expectedLossAttr ?? 0
        const lossLarge = row.expectedLossLarge ?? 0
        const lossCat = row.expectedLossCat ?? 0
        const expense = row.expense ?? 0
        const metrics = {
          metricPrimary: calculateGrossCombinedRatio(lossAttr, premium),
          metricSecondary: calculateGrossCombinedRatio(
            lossLarge,
            premium
          ),
          metricTertiary: calculateGrossCombinedRatio(lossCat, premium),
          metricQuaternary: calculateGrossCombinedRatio(
            expense,
            premium
          ),
        }
        data.push({
          groupBy: lossName,
          ...metrics,
        })
      }
      const splitNames = {
        metricPrimaryName: 'Attritional Loss',
        metricSecondaryName: 'Large Loss',
        metricTertiaryName: 'Cat Loss',
        metricQuaternaryName: 'Expense',
      }
      option.data = {
        data,
      }
      option.split = {
        ...option.split,
        ...splitNames,
      }
      option.metric = {
        ...option.metric,
        ...splitNames,
      }
    } else if (option.metric.columnName === 'writtenPremiumByProduct') {
      const data: SummaryChartDatum[] = []
      let total = 0
      for (const row of groupSummaryData) {
        if (getLevelCount(row.lossName) === levelCount) {
          const lossName = row.lossName.replace(/_/g, ' ')
          total += row.subjectPremiumAmt
          data.push({
            groupBy: lossName,
            metricPrimary: row.subjectPremiumAmt,
          })
        }
      }
      const name = `${currencyFormat(total, currency)} Written Premium by Product (${roundedToAbrev ?? 'M'})`
      option.metric = {
        ...option.metric,
        name,
      }
      option.data = {
        data,
      }
    } else if (
      option.metric.columnName === 'compositionofLossCurveByReturnPeriod'
    ) {
      const data: SummaryChartDatum[] = []
      let rawDatum: number[] = []
      const filteredRows = groupSummaryData.filter(row => {
        const onLevel = getLevelCount(row.lossName) === levelCount
        let rp = row.largeRiskDistributionOEP?.data
          ?.map(d => d?.min || 0)
          .slice(0, summaryRP.length)
        if (!method || method === 'agg') {
          rp = row.largeRiskDistributionAEP?.data
            ?.map(d => d?.min || 0)
            .slice(0, summaryRP.length)
        }
        return onLevel && rp[0] !== 0
      })
      const totalsArray = summaryRP.map((_, i) => {
        return filteredRows
          .map(row => {
            if (getLevelCount(row.lossName) === levelCount) {
              let rp = row.largeRiskDistributionOEP?.data
                ?.map(d => d?.min || 0)
                .slice(0, summaryRP.length)
                if (!method || method === 'agg') {
                rp = row.largeRiskDistributionAEP?.data
                  ?.map(d => d?.min || 0)
                  .slice(0, summaryRP.length)
              }
              return rp[i]
            }
          })
          .reduce((a, c) => a + c, 0)
      })
      for (const row of filteredRows) {
        if (getLevelCount(row.lossName) === levelCount) {
          if (!method || method === 'agg') {
            rawDatum = row.largeRiskDistributionAEP?.data
              ?.map(d => d?.min || 0)
              .slice(0, summaryRP.length)
          } else {
            rawDatum = row.largeRiskDistributionOEP?.data
              ?.map(d => d?.min || 0)
              .slice(0, summaryRP.length)
          }
          const values = summaryRP.map((rp, i) => {
            let y = 0
            if (rawDatum[i] !== 0 && totalsArray[i] !== 0) {
              y = (rawDatum[i] / totalsArray[i]) * 100
            }
            return {
              x: rp,
              y,
              raw: currencyFormat(rawDatum[i], currency, 'M'),
            }
          })
          const metricPrimary = values.reduce((a, c) => a + c.y, 0)
          const lossName = row.lossName.replace(/_/g, ' ')
          data.push({
            groupBy: lossName,
            metricPrimary,
            values,
          })
        }
      }
      option.data = {
        data,
      }
      option.metric.format = 'percent'
      option.vertSplits = summaryRP.map(d => String(d))
    } else if (
      option.metric.columnName === 'combinedRatioVSGroupVolatility'
    ) {
      const data: SummaryChartDatum[] = []

      for (const row of groupSummaryData) {
        if (getLevelCount(row.lossName) === levelCount) {
          const lossName = row.lossName.replace(/_/g, ' ')
          const expectedCombinedRatio = calculateGrossCombinedRatio(row.expectedUWResult, row.subjectPremiumAmt)

          data.push({
            groupBy: lossName,
            metricPrimary: expectedCombinedRatio,
            metricSecondary: row.contributionToGroupVolatility,
            size: row.subjectPremiumAmt
          })
        }
      }

      const avgX = calculateAverage(data.map(v => v.metricPrimary * 100))
      const avgY = calculateAverage(data.map(v => v.metricSecondary * 100))

      option.metric = {
        ...option.metric,
        avgX,
        avgY,
        format: 'percent',
        name: 'Contribution to Group Volatility (Downside) vs Expected Combined Ratio'
      }

      option.data = { data }

      option.size = {
        ...option.size,
        name: 'Premium'
      }

      option.chart = {
        ...option.chart,
        xLabel: 'Expected Combined Ratio',
        yLabel: 'Contribution to Group Volatility (Downside)',
      }
    }
  }
  return options
}

export function getLevelCount(name: string): number {
  return (name.match(/_/g) || []).length
}

export function calculateAverage(numbers: number[]): number {
  if (numbers.length === 0) {
    return 0
  }
  const sum = numbers.reduce((acc, curr) => acc + curr, 0)
  return sum / numbers.length
}

export function calculateGrossCombinedRatio(value: number, premium: number): number {
  if (value === 0 || premium === 0) {
    return 0
  } else {
    return value / premium
  }
}

export function currencyFormat(value: number, currency: string, roundedToAbrev?: string): string {
  const factor = !roundedToAbrev || roundedToAbrev === 'M' ? 1e6 : 1e3
  const roundedValue = Math.round(value / factor)
  const formattedValue = Number(roundedValue).toLocaleString(undefined, {
    maximumFractionDigits: 0,
  })
  const abrev = roundedToAbrev ?? ''
  return `${currencySymbol(currency)}${formattedValue}${abrev}`
}
