import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { TrackingService } from 'src/app/api/tracking/tracking.service'
import { AppState } from 'src/app/core/store'
import * as fromTrackingActions from './tracking.actions'
import { concatMap, map, switchMap, withLatestFrom } from 'rxjs'
import { rejectError } from 'src/app/api/util'
import { sortByInceptionDate } from '../tracking.utils'
import { ProgramEventsService } from 'src/app/api/program-events/program-events.service'
import { ProgramEvents } from 'src/app/program-events/model/program-events.model'
import { SelectedUserPreferences } from 'src/app/core/model/reinsurer.model'
import {
  selectInceptionTimeInterval,
  selectTrackingSelectors,
} from './tracking.selectors'
import { TrackingPreferences } from '../tracking.model'
import { UserPreferencesService } from 'src/app/api/user-preferences/user-preferences.service'
import { selectSalesforceUserRole } from 'src/app/core/store/auth/auth.selectors'

@Injectable()
export class TrackingEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private service: TrackingService,
    private programEventsService: ProgramEventsService,
    private userPreferencesService: UserPreferencesService
  ) {}

  fetchTracking$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromTrackingActions.fetchTracking),
      switchMap(() => {
        return this.service.getTracking()
      }),
      rejectError(error =>
        this.store.dispatch(fromTrackingActions.fetchTrackingFailure({ error }))
      ),
      withLatestFrom(this.store.pipe(select(selectSalesforceUserRole))),
      map(([trackingData, userRole]) => {
        const currentMonth = new Date().toLocaleDateString('en-US', {
          month: '2-digit',
        })
        const currentYear = new Date().toLocaleDateString('en-US', {
          year: 'numeric',
        })
        let month = Number(currentMonth)
        let year = Number(currentYear)
        year = month <= 9 ? year : year + 1
        return fromTrackingActions.fetchTrackingSuccess({
          trackingData: sortByInceptionDate(trackingData),
          salesforceUserRole: userRole,
          selectedYear: String(year)
        })
      })
    )
  })

  getTrackingPreferences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromTrackingActions.fetchTrackingSuccess),
      switchMap(() => {
        return this.userPreferencesService.getTrackingUserPreferences()
      }),
      rejectError(error =>
        this.store.dispatch(
          fromTrackingActions.getTrackingPreferencesFailure({ error })
        )
      ),
      map(userPreferences => {
        return fromTrackingActions.getTrackingPreferencesSuccess({
          userPreferences,
        })
      })
    )
  })

  fetchTrackingDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromTrackingActions.fetchTrackingDetails),
      concatMap(props => {
        return this.service.getTrackingDetails(props.programId)
      }),
      rejectError(error =>
        this.store.dispatch(
          fromTrackingActions.fetchTrackingDetailsFailure({ error })
        )
      ),
      map(trackingDetailsData => {
        return fromTrackingActions.fetchTrackingDetailsSuccess({
          trackingDetailsData,
        })
      })
    )
  })

  updateCompleteProgram$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromTrackingActions.updateCompleteProgram),
      concatMap(props => {
        const programEvents: ProgramEvents = {
          eventType: 'Complete',
          programId: props.programId.toString(),
        }
        return this.programEventsService.saveOrUpdateProgramEvents(
          programEvents
        )
      }),
      rejectError(error =>
        this.store.dispatch(
          fromTrackingActions.updateCompleteProgramFailure({
            error,
          })
        )
      ),
      map(data => {
        return fromTrackingActions.updateCompleteProgramSuccess({
          programEvents: data,
        })
      })
    )
  })

  saveTrackingPreferences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromTrackingActions.saveTrackingPreferences),
      withLatestFrom(
        this.store.pipe(select(selectTrackingSelectors)),
        this.store.pipe(select(selectInceptionTimeInterval))
      ),
      concatMap(([props, selectors, timeInterval]) => {
        let preferences: TrackingPreferences = {
          label: props.label,
          clientName: selectors.clientName.selectedValues,
          year: selectors.year.selectedValues,
          businessUnit: selectors.businessUnit.selectedValues,
          reinsurerNames: selectors.reinsurerNames.selectedValues,
          complete: selectors.complete.selectedValues,
          maxDateValue: timeInterval.maxValue,
          minDateValue: timeInterval.minValue,
        }

        const userPreference: SelectedUserPreferences = {
          preferenceValue: JSON.stringify(preferences),
          preferenceType: 'Tracking',
        }
        return this.userPreferencesService.saveOrUpdateUserPreferences(
          userPreference
        )
      }),
      rejectError(error =>
        this.store.dispatch(
          fromTrackingActions.saveTrackingPreferencesFailure({
            error,
          })
        )
      ),
      map(data => {
        return fromTrackingActions.saveTrackingPreferencesSuccess({
          userPreferences: data,
        })
      })
    )
  })
}
