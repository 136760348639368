import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core'
import { TierNames } from '../tier/tier.model'
import { State as AuthState } from '../core/store/auth/auth.state.facade'
import { environment } from 'src/environments/environment'
import { Router } from '@angular/router'
import { BenchmarkDisplayWarningSettings } from '../benchmark/model/benchmark.model'
import { BenchmarkMode } from '../benchmark/model/benchmark-mode'
import { MatDialog } from '@angular/material/dialog'
import { BenchmarkDataAvailabilityDialogContainerComponent } from '../benchmark/toolbar/benchmark-data-availability-dialog/benchmark-data-availability-dialog.container'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-header-title',
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
      }

      .links {
        display: flex;
        flex: 0 auto;
        margin-right: var(--inset-big);
        padding: 0;
        align-items: center;
      }

      :host a {
        margin-right: 0;
      }

      h2 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-height: 26px;
      }

      h2.breadcrumb {
        margin: 0;
        cursor: pointer;
        transition: color 250ms;
      }

      h2.breadcrumb.breadcrumb-ancestor {
        font-weight: var(--font-weight);
        color: var(--primary);
      }

      h2.breadcrumb:hover,
      h2.breadcrumb:focus {
        color: var(--primary-lit);
      }

      .breadcrumb-separator,
      h2.breadcrumb-ancestor {
        margin-right: var(--inset-small);
      }

      :host.big h2 {
        font-size: var(--font-size-gigantic);
      }

      .breadcrumb-separator {
        position: relative;
        top: -3px;
        font-size: var(--font-size-huge);
        font-weight: var(--font-weight);
        color: var(--hint);
      }

      .title {
        display: flex;
        align-items: center;
      }

      :host.has-subtitle .title {
        margin-bottom: var(--stack-tiny);
      }

      .warning {
        margin-top: 8px;
        background-color: white;
        color: black;
        padding: 10px;
        border-radius: 8px;
      }

      .warning h4 {
        color: black;
        font-size: var(--font-size-small);
      }

      .warning h4 span {
        font-weight: var(--font-link-weight);
        cursor: pointer;
        text-decoration: underline;
      }

      .subtitle {
        display: flex;
        align-items: center;
        padding-right: 1rem;
      }

      h4 {
        max-width: 100%;
        font-weight: var(--font-weight);
        font-family: var(--font-family);
        font-size: var(--font-size-tiny);
        line-height: 15px;
        color: var(--subtle);
      }

      h4.nowrap {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        white-space: nowrap;
      }

      .tier-separator {
        color: var(--hint);
        margin: 0 var(--inset-tiny);
        font-weight: var(--font-weight);
      }

      .last-tier {
        color: var(--body);
        font-size: var(--font-size);
        font-weight: var(--font-header-weight);
        font-family: var(--font-header-family);
      }

      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      mat-button-toggle-group {
        width: fit-content;
        margin-top: 4px;
      }

      mat-chip {
        margin-left: var(--inset);
        height: fit-content;
      }

      @media screen and (max-width: 1180px) {
        :host {
          flex-direction: row;
        }

        .subtitle {
          padding-left: 1rem;
          padding-right: 0;
        }

        :host.has-subtitle .title {
          margin-bottom: 0;
        }

        h2 {
          font-size: var(--font-size-huge);
        }

        h2.breadcrumb,
        .breadcrumb-separator {
          font-size: var(--font-size-big);
        }

        :host.big h2 {
          font-size: var(--font-size-huge);
        }

        h4 {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          white-space: nowrap;
        }
      }
    `,
  ],
  template: `
    <div class="title">
      <h2 *ngIf="!breadcrumbs; else breadcrumbTemplate">{{ title }}</h2>
    </div>

    <div *ngIf="subtitle" class="subtitle">
      <h4 [ngClass]="subtitleClasses">{{ subtitle }}</h4>
    </div>

    <div *ngIf="showWarningMessage" class="subtitle warning">
      <h4>
        {{ displayHeaderWarningSettings?.warningMessage }}
        <span (click)="openDataAvailabilityDialog()"
          >{{ displayHeaderWarningSettings?.currDataYear }} Data
          Availability</span
        >.
      </h4>
    </div>

    <!-- Prove toggle to change between main prove and heatmap. -->
    <mat-button-toggle-group
      *ngIf="heatmapAllowed"
      [value]="proveMode"
      (change)="onProveToggleChange($event.value)"
      class="app-mat-theme"
      name="mode"
      aria-label="Mode"
    >
      <mat-button-toggle value="prove">Prove</mat-button-toggle>
      <mat-button-toggle value="heatmap">Heatmap</mat-button-toggle>
    </mat-button-toggle-group>

    <section class="links">
      <a *ngIf="tierNames && !noLink" appButton link (click)="onClick($event)">
        <span>{{ tierNames?.client }}</span>
        <span *ngIf="tierNames?.year" class="tier-separator"> / </span>
        <span *ngIf="tierNames?.year">{{ tierNames?.year || 'No Year' }}</span>
        <span *ngIf="tierNames?.program" class="tier-separator"> / </span>
        <span *ngIf="tierNames?.program">{{ tierNames?.program }}</span>
      </a>

      <a
        *ngIf="hasParent && !noLink"
        appButton
        link
        (click)="onClick($event, 'parent')"
      >
        <span class="tier-separator"> / </span>
        <span>{{ tierNames?.structure }}</span>
      </a>

      <a
        *ngIf="lastTier && !noLink"
        appButton
        link
        body
        unclickable
        class="last-tier"
      >
        <span class="tier-separator"> / </span>
        <span>{{ lastTier }}</span>
      </a>

      <mat-chip *ngIf="chip" class="app-chip-palette" [class]="chipClass">{{
        chip
      }}</mat-chip>
    </section>

    <ng-template #breadcrumbTemplate>
      <ng-container *ngFor="let bc of breadcrumbs; let i = index">
        <h2
          class="breadcrumb"
          [ngClass]="{ 'breadcrumb-ancestor': i !== breadcrumbs?.length - 1 }"
          (click)="breadcrumbClick.emit(i)"
        >
          {{ bc }}
        </h2>
        <span
          *ngIf="i !== breadcrumbs?.length - 1"
          class="breadcrumb-separator"
        >
          /
        </span>
      </ng-container>
    </ng-template>
  `,
})
export class HeaderTitleComponent {
  @Input() name?: string
  @Input() breadcrumbs?: string[]
  @Input() tierNames?: TierNames
  @Input() subtitle: string
  @Input() page?: string
  @Input() authState: AuthState
  @Input() displayHeaderWarningSettings: BenchmarkDisplayWarningSettings
  @Input() mode: BenchmarkMode | null
  @Input() chip?: string
  @Input() chipClass?: string

  proveMode: 'prove' | 'heatmap'
  currentYear = new Date().getFullYear()

  @Input() set big(value: any) {
    this._big = coerceBooleanProperty(value)
  }
  get big() {
    return this._big
  }
  @HostBinding('class.big') _big = false

  @Input() set noLink(value: any) {
    this._noLink = coerceBooleanProperty(value)
  }
  get noLink() {
    return this._noLink
  }
  _noLink = false

  @Output() backClick = new EventEmitter()
  @Output() parentClick = new EventEmitter()
  @Output() breadcrumbClick = new EventEmitter<number>()
  @Output() proveToggleChange = new EventEmitter<string>()

  @HostBinding('class.has-subtitle') get hasSubtitle(): boolean {
    return !!this.subtitle
  }

  constructor(
    public router: Router,
    public dialog: MatDialog
  ) {
    this.proveMode = router.url.includes('heatmap') ? 'heatmap' : 'prove'
  }

  get isDesignPage(): boolean {
    return this.page === 'Design'
  }

  get title(): string | undefined {
    return this.isDesignPage ? this.page : this.name || ''
  }

  get subtitleClasses() {
    return { nowrap: this.isDesignPage }
  }

  get hasParent(): boolean {
    return (
      this.tierNames?.structure != null &&
      this.tierNames?.scenarioOrOptimization != null
    )
  }

  get lastTier(): string | undefined {
    return this.tierNames?.scenarioOrOptimization ?? this.tierNames?.structure
  }

  get heatmapAllowed(): boolean {
    if (this.name !== 'PROVE') {
      return false
    }
    const username = this.authState?.username?.toUpperCase()
    const isLocktonUser = username?.endsWith('lockton.com'.toUpperCase())
    const isPerficientUser = username?.endsWith('@perficient.com'.toUpperCase())
    return isLocktonUser === true || isPerficientUser === true
  }

  get showWarningMessage(): boolean {
    // ? TODO: Show header warning for 'us-health'?
    return this.mode?.id === 'us' && this.displayHeaderWarningSettings?.show
  }

  onClick($event: MouseEvent | TouchEvent, type: 'back' | 'parent' = 'back') {
    $event.preventDefault()
    switch (type) {
      case 'back':
        this.backClick.emit()
        break
      case 'parent':
        this.parentClick.emit()
        break
    }
  }

  onProveToggleChange(proveMode: string) {
    this.proveToggleChange.emit(proveMode)
  }

  openDataAvailabilityDialog(): void {
    this.dialog.open(BenchmarkDataAvailabilityDialogContainerComponent, {
      width: '75%',
      autoFocus: false,
    })
  }
}
