import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {
  ConfirmationDialogOptions,
  ConfirmationDialogComponent,
} from '../confirmation-dialog.component'

@Injectable()
export class ConfirmationDialogService {
  constructor(public dialog: MatDialog) {}

  open(opts?: ConfirmationDialogOptions) {
    return this.dialog.open(ConfirmationDialogComponent, {
      width: '30vw',
      disableClose: true,
      data: opts,
    })
  }
}
