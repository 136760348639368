<div class="bar" [ngClass]="{ 'client-select': clientSelect }">
  <div class="tier-bar-opportunity">
    <app-tier-bar-container
      *ngIf="showDialogClone !== true"
      [contextPath]="contextPath"
      [clientSelect]="clientSelect"
      [enableHover]="clientSelect"
      [title]="title"
      [size]="size"
      [hover]
      [clients]="clients"
      [years]="years"
      [programs]="programs"
      [structures]="structures"
      [programGroups]="filterStructureGroups"
      [isQuote]="isQuote"
      [isSlipT]="isSlipT"
      [isSignature]="isSignature"
      [isDesign]="isDesign"
      [selectedClientID]="selectedClientID"
      [selectedYearID]="selectedYearID"
      [selectedProgramID]="selectedProgramID"
      [selectedStructureID]="selectedStructureID"
      [selectedCededLayerID]="selectedCededLayerID"
      [quoteLayers]="quoteLayers"
      [quoteDirty]="quoteDirty"
      [sharedLimits]="sharedLimits"
      [sectionList]="sectionList"
      (tierChange)="onTierChange($event)"
      (groupOrStructureSelectionChange)="
        onGroupOrStructureSelectionChange($event)
      "
      (structureChange)="structureSelectionChange.emit($event)"
      (layerSelect)="layerSelect.emit($event)"
      (groupSelect)="groupSelect.emit($event)"
      (slSelect)="slSelect.emit($event)"
    ></app-tier-bar-container>
    <label
      *ngIf="title === 'dialog' && showDialogClone === true"
      appInfoText
      huge
      strong
    >
      →
    </label>
    <app-tier-bar-container
      *ngIf="title === 'dialog' && showDialogClone === true"
      [contextPath]="contextPath"
      [clientSelect]="clientSelect"
      [enableHover]="clientSelect"
      [title]="title"
      [size]="size"
      [hover]
      [clients]="clients"
      [years]="years2"
      [programs]="programs2"
      [secondBar]="secondBar"
      [structures]="structures"
      [selectedClientID]="selectedClientID2"
      [selectedYearID]="selectedYearID2"
      [selectedProgramID]="selectedProgramID2"
      [selectedStructureID]="selectedStructureID"
      [selectedCededLayerID]="selectedCededLayerID"
      [quoteLayers]="quoteLayers"
      [quoteDirty]="quoteDirty"
      [sharedLimits]="sharedLimits"
      (tierChange)="tierChange2.emit($event)"
      (groupOrStructureSelectionChange)="
        onGroupOrStructureSelectionChange($event)
      "
      (slSelect)="slSelect.emit($event)"
    ></app-tier-bar-container>
    <div *ngIf="selectedFolderName" class="folder-name-container">
      <h4 class="label">Folder</h4>
      <h2 class="folder-name">{{ selectedFolderName }}</h2>
    </div>
    <button
      type="submit"
      appButton
      accentStrong
      *ngIf="title === 'dialog' && showDialogClone"
      (click)="onCloneClick()"
      [disabled]="selectedStructureIDs?.length === 0"
      class="clone-button"
    >
      Clone
    </button>
    <button
      type="submit"
      appButton
      accent
      *ngIf="title === 'dialog' && showDialogClone"
      (click)="onSwapLossSetClick()"
      [disabled]="disabledLossSetsSwap || selectedStructureIDs?.length === 0"
    >
      Swap Loss Sets
    </button>
    <button
      type="submit"
      appButton
      accent
      *ngIf="title === 'dialog' && showDialogClone"
      (click)="onAddLossSetClick()"
      [disabled]="selectedStructureIDs?.length === 0"
    >
      Add/Replace Loss Sets
    </button>
    <mat-checkbox
      class="select-all-toggle"
      *ngIf="title === 'dialog' && showDialogClone === true"
      [checked]="selectAllSelected"
      (change)="onUpdateSelectAll($event)"
      ><span>Select All</span>
    </mat-checkbox>
    <app-program-opportunity-info
      [programs]="programs"
      [selectedProgramID]="selectedProgramID"
      [accountOpportunities]="accountOpportunities"
    ></app-program-opportunity-info>
  </div>
  <div
    *ngIf="isQuote && selectedCededLayerID && !showAssignedLines"
    class="quote-summary"
  >
    <p>
      Total offered %:
      {{ quoteSummary.offeredPercentageTotal | number: '1.1-4' }}%
    </p>
    <p *ngIf="showWeightedAvgRateOnLine">
      Weighted Avg. Rate On-Line, Occ:
      {{ quoteSummary.weightedAvgRateOnLine | number: '1.1-4' }}%
    </p>
    <p *ngIf="showWeightedAvgRatePercentageOfSubject">
      Weighted Avg. Rate, % of Subject:
      {{ quoteSummary.weightedAvgRatePercentageOfSubject | number: '1.1-4' }}%
    </p>
    <p *ngIf="showWeightedAvgCedingCommission">
      Weighted Avg. Ceding Commission:
      {{ quoteSummary.weightedAvgCedingCommission | number: '1.1-4' }}%
    </p>
    <p *ngIf="showWeightedAvgPMPM">
      Weighted Avg. PMPM:
      {{ quoteSummary.weightedAvgPMPM | number: '1.1-4' }}%
    </p>
  </div>
  <div *ngIf="showClientInfo" class="client-info">
    <label appInfoText huge strong>
      ← Welcome to SAGE! Please select a company to begin.
    </label>
  </div>
  <div *ngIf="showBarRight" class="right">
    <button
      *ngIf="isDesign && !!selectedFolderID"
      appButton
      big
      primary
      accent
      (click)="selectFolderID(null, null)"
    >
      Back To Program
    </button>

    <button
      *ngIf="isDesign && !executiveSummaryHidden"
      appButton
      big
      primary
      accent
      (click)="onExecutiveSummaryClick()"
    >
      Executive Summary
      <mat-icon inline class="icon"> insert_chart </mat-icon>
    </button>

    <button
      *ngIf="isDesign"
      appButton
      class="more"
      link
      big
      primary
      [matMenuTriggerFor]="moreMenu"
    >
      <mat-icon inline>more_horiz</mat-icon>
    </button>
    <mat-menu
      #moreMenu="matMenu"
      class="menu app-popover-items-compact app-menu-panel-no-min-height"
    >
      <div>
        <mat-form-field
          *ngIf="!clientSelect && layerViewAllowed"
          class="app-bar-field field views"
          subscriptSizing="dynamic"
          [ngClass]="{
            'app-bar-field-big': size === 'big',
            'app-bar-field-button': isButton,
          }"
        >
          <mat-label>
            <span>Views</span>
          </mat-label>
          <mat-select
            appBackdropClass="app-tier-select-backdrop"
            panelClass="app-bar-panel"
            placeholder="View..."
            disableRipple
            [value]="selectedViewId"
            (selectionChange)="onViewChange($event)"
          >
            <mat-select-trigger *ngIf="selectedViewId">
              <span>{{ selectedView?.name }}</span>
            </mat-select-trigger>
            <mat-option *ngFor="let view of views" [value]="view.id">
              <div class="item">
                <span>{{ view.name }}</span>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button
          mat-menu-item
          (click)="onBulkCloneClick()"
          class="field mat-menu-item"
        >
          <span>Bulk Clone</span>
        </button>
        <button
          mat-menu-item
          [ngClass]="{ field: hasLibreTemplate }"
          *ngIf="allowAutoBuild && isDesign && salesForce"
          (click)="onAutobuildClick()"
        >
          <span>Autobuild</span>
        </button>
        <button
          mat-menu-item
          *ngIf="hasLibreTemplate"
          (click)="onCreateStructureClick()"
          class="field mat-menu-item new-structure"
        >
          <span>Create New Structure</span>
          <p class="no-modeling">No Modeling</p>
        </button>
        <button
          mat-menu-item
          *ngIf="hasLibreTemplate"
          (click)="onCreateNewFolder()"
        >
          <span>Create New Folder</span>
        </button>
      </div>
    </mat-menu>
    <mat-slide-toggle
      *ngIf="showStructureGroups"
      class="group-filter-association"
      matTooltip="Filter Groups by Program"
      matTooltipPosition="below"
      [checked]="groupFilterByAssoc"
      (change)="onGroupAssocChange()"
    >
      <span class="filter-groups">Filter Groups by Program</span>
    </mat-slide-toggle>

    <mat-slide-toggle
      class="edit-toggle"
      *ngIf="showEditMode"
      [checked]="editModeSelected"
      (change)="onUpdateEditModeSelection($event)"
      matTooltip="Select to use Drag and drop portfolio feature"
      matTooltipPosition="below"
      ><span>Edit mode</span></mat-slide-toggle
    >

    <app-filter-input
      #filterInput
      [filter]="filter"
      [handleTab]="showStructureGroups"
      (debouncedChange)="onFilterUpdate($event)"
      (navKeydown)="onNavKeydown($event)"
    ></app-filter-input>

    <button
      *ngIf="showCloseButton"
      appButtonIcon
      link
      gigantic
      (click)="closeClick.emit()"
    >
      expand_less
    </button>
  </div>
  <div *ngIf="isCreditDesign">
    <button
      *ngIf="selectedProgramID"
      appButton
      big
      primary
      accent
      (click)="createNewCreditStructureClick.emit()"
    >
      Create Structure
    </button>
  </div>
</div>
<div class="content">
  <section *ngIf="showOverview" class="overview">
    <label appInfoText huge strong>
      <div>
        <mat-icon>arrow_upward</mat-icon>
        <span>
          Explore our unique risk and capital management capabilities through
          the content menu
          <mat-icon class="menu">menu</mat-icon>
          top left. Use the Menu to explore:
        </span>
      </div>
    </label>
  </section>

  <section *ngIf="showSelectMessage">
    <h2 #df>{{ selectMessage }}</h2>
  </section>
  <section *ngIf="showCompareViews && !isQuote && !isSignature" class="group">
    <h2>{{ compareViewsHeader }}</h2>
    <div *ngIf="compareViews!.length > 0" class="group-list">
      <cdk-virtual-scroll-viewport itemSize="50" class="virtual-viewport">
        <div
          #structureGroupItem
          *cdkVirtualFor="
            let compareView of compareViews;
            let i = index;
            trackBy: trackByID
          "
          class="group-item"
        >
          <div
            class="group-item-content"
            [ngClass]="{ checked: isCompareViewSelected(compareView) }"
            (click)="onCompareViewSelectionChange($event, compareView)"
          >
            <mat-checkbox
              *ngIf="selectMultiple"
              class="checkbox"
              [checked]="isCompareViewSelected(compareView)"
            ></mat-checkbox>
            <span>{{ compareView.view_name }}</span>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </section>
  <section
    *ngIf="
      (showStructureGroups || showCreditStructureGroups) &&
      !isQuote &&
      !isSignature
    "
    class="group"
  >
    <h2>{{ structureGroupsHeader }}</h2>
    <div *ngIf="structureGroups?.length > 0" class="group-list">
      <cdk-virtual-scroll-viewport itemSize="50" class="virtual-viewport">
        <div
          #structureGroupItem
          *cdkVirtualFor="
            let group of filterStructureGroups;
            let i = index;
            trackBy: trackByID
          "
          class="group-item"
        >
          <div
            class="group-item-content"
            [ngClass]="{
              checked: isStructureGroupSelected(group),
              'indicate-focus': indicateFocus('structureGroups', group.id),
            }"
            (click)="onStructureGroupSelectionChange($event, group)"
            (mouseover)="onItemMouseover('structureGroups', i)"
          >
            <mat-checkbox
              *ngIf="selectMultiple"
              class="checkbox"
              [checked]="isStructureGroupSelected(group)"
            ></mat-checkbox>
            <span>{{ group.label }}</span>
          </div>
        </div>
        <app-info-text
          *ngIf="
            (groupFilterByAssoc || filter) &&
            structureGroups?.length !== filterStructureGroups?.length
          "
        >
          <div>
            {{ structureGroups?.length - filterStructureGroups?.length }}
            groups are filtered.
          </div>
          <button
            *ngIf="filter"
            appButton
            link
            accent
            (click)="onFilterClear($event)"
          >
            Clear Filter
          </button>
        </app-info-text>
      </cdk-virtual-scroll-viewport>
    </div>
  </section>
  <section
    *ngIf="(showStructures || showCreditStructures) && !isQuote && !isSignature"
    class="structure"
  >
    <h2 *ngIf="showStructuresHeader || showCreditStructuresHeader">
      {{ structuresHeader }}
    </h2>
    <div
      *ngIf="
        (structures?.length > 0 || updatedFolders?.length > 0) &&
        !editModeSelected
      "
      class="structure-edit-list"
      cdkDropListGroup
    >
      <div
        *ngIf="updatedFolders?.length > 0"
        class="folders"
        cdkDropList
        [cdkDropListData]="folders"
        [cdkDropListEnterPredicate]="dropListEnterPredicate"
        (cdkDropListDropped)="dropFolder($event)"
        (mouseover)="onMouseOver($event)"
      >
        <div *ngFor="let folders of folderSections">
          <div class="folder-list">
            <div *ngFor="let folder of folders" id="{{ folder.id }}">
              <app-structure-folder-container
                [folder]="folder"
                [editMode]="true"
                (updateFolders)="onUpdateFolders()"
                (folderClick)="selectFolderID(folder.id, folder.name)"
                (deleteFolder)="onDeleteFolder($event)"
              ></app-structure-folder-container>
            </div>
          </div>
        </div>
      </div>
      <div
        cdkDropList
        [cdkDropListEnterPredicate]="dropListEnterPredicate"
        (cdkDropListDropped)="dropFolder($event)"
      ></div>
      <div class="structures">
        <div
          cdkDropList
          *ngFor="
            let structure of filterStructures;
            let i = index;
            trackBy: trackByID
          "
          class="structure-edit-container"
          [cdkDropListEnterPredicate]="dropListEnterPredicate"
          (cdkDropListDropped)="dropFolder($event)"
        >
          <app-structure-card-container
            class="structure-edit-box"
            cdkDrag
            [editFolderMode]="
              editFolderMode && !selectedFolderID && updatedFolders.length > 0
            "
            [canCheck]="editFolderMode"
            [cdkDragData]="structure"
            [size]="size"
            [index]="i"
            [structure]="structure"
            [scenarios]="getScenarios(structure)"
            [scenariosSelected]="getScenariosSelected(structure)"
            [allowScenarioOrOptimizationSelection]="
              allowScenarioOrOptimizationSelection
            "
            [optimizations]="getOptimizations(structure)"
            [optimizationsSelected]="getOptimizationsSelected(structure)"
            [selectMultiple]="selectMultiple"
            [checked]="isFolderStructureSelected(structure.id)"
            [indicateFocus]="indicateFocus('structures', structure.id)"
            [elevation]="elevation"
            [isEditMode]="true"
            [selectedStructureIDs]="selectedStructureIDs"
            [checkboxTooltip]="checkboxTooltip"
            (structureClick)="onStructureSelectionChange(structure)"
            (editClick)="onEditClick(structure)"
            (removeFromFolder)="onRemoveFromFolder($event)"
            (addStructure)="onSetRoute($event, structure)"
            (scenarioOrOptimizationSelectChange)="
              scenarioOrOptimizationSelectChange.emit($event)
            "
            (mouseover)="onItemMouseover('structures', i)"
            (structureCheckChange)="onStructureCheckChange($event)"
          ></app-structure-card-container>
        </div>
      </div>
      <app-info-text
        *ngIf="filter && structures?.length !== filterStructures.length"
      >
        <div>
          {{ structures?.length - filterStructures.length }} structures are
          filtered.
        </div>
        <button appButton link accent (click)="onFilterClear($event)">
          Clear Filter
        </button>
      </app-info-text>
    </div>
    <div
      *ngIf="structures?.length > 0 && editModeSelected"
      class="structure-edit-list"
    >
      <div cdkDropListGroup class="edit-structures">
        <mat-grid-list cols="3" rowHeight="150px" gutterSize="15px">
          <mat-grid-tile
            *ngFor="let structure of filterStructures; let i = index"
            colSpan="1"
            rowSpan="1"
            cdkDropList
            [cdkDropListData]="structure"
            (cdkDropListEntered)="dragEntered($event)"
            (cdkDropListDropped)="dropStructure($event)"
          >
            <app-structure-card-container
              class="structure-edit-box"
              cdkDrag
              [cdkDragData]="structure"
              [size]="size"
              [index]="i"
              [structure]="structure"
              [scenarios]="getScenarios(structure)"
              [scenariosSelected]="getScenariosSelected(structure)"
              [allowScenarioOrOptimizationSelection]="
                allowScenarioOrOptimizationSelection
              "
              [optimizations]="getOptimizations(structure)"
              [optimizationsSelected]="getOptimizationsSelected(structure)"
              [selectMultiple]="selectMultiple"
              [checked]="isStructureSelected(structure.id)"
              [indicateFocus]="indicateFocus('structures', structure.id)"
              [elevation]="elevation"
              [isEditMode]="!selectedFolderID"
              (structureClick)="structureSelectionChange.emit(structure)"
              (editClick)="onEditClick(structure)"
              (removeFromFolder)="onRemoveFromFolder($event)"
              (addStructure)="onSetRoute($event, structure)"
              (scenarioOrOptimizationSelectChange)="
                scenarioOrOptimizationSelectChange.emit($event)
              "
              (mouseover)="onItemMouseover('structures', i)"
            ></app-structure-card-container>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
      <app-info-text
        *ngIf="filter && structures?.length !== filterStructures.length"
      >
        <div>
          {{ structures?.length - filterStructures.length }} structures are
          filtered.
        </div>
        <button appButton link accent (click)="onFilterClear($event)">
          Clear Filter
        </button>
      </app-info-text>
    </div>
    <div *ngIf="creditStructures?.length > 0" class="structure-edit-list">
      <div class="structures">
        <div
          cdkDropList
          *ngFor="
            let structure of creditStructures;
            let i = index;
            trackBy: trackByID
          "
          class="structure-edit-container"
        >
          <app-credit-structure-card-container
            class="structure-edit-box"
            cdkDrag
            [cdkDragData]="structure"
            [size]="size"
            [index]="i"
            [structure]="structure"
            [selectMultiple]="selectMultiple"
            [canCheck]="
              routerUrl?.includes('group') || routerUrl?.includes('compare')
            "
            [canOptimize]="routerUrl?.includes('design')"
            (structureClick)="onCreditStructureSelectionChange(structure)"
            (optimizeClick)="onCreditStructureOptimizeClick(structure)"
          ></app-credit-structure-card-container>
        </div>
      </div>
    </div>
    <div
      *ngIf="creditSubmissionStructures?.length > 0"
      class="structure-edit-list"
    >
      <div class="structures">
        <div
          cdkDropList
          *ngFor="
            let submission of creditSubmissionStructures;
            let i = index;
            trackBy: trackByID
          "
          class="structure-edit-container"
        >
          <app-credit-structure-card-container
            class="structure-edit-box"
            cdkDrag
            [cdkDragData]="submission"
            [size]="size"
            [index]="i"
            [structure]="submission"
            [selectMultiple]="selectMultiple"
            (structureClick)="onCreditSubmissionSelectionChange(submission)"
          ></app-credit-structure-card-container>
        </div>
      </div>
    </div>
  </section>
</div>
