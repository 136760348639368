import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Reference } from '@shared/layer-property/layer-property.component'
import {
  CreditCalculationStructure,
  CreditStructure,
  CreditTransaction,
} from '../../model/credit-structure.model'
import { CreditSubmissionService } from '../../services/credit-submission.service'
import { CreateSubmissionDraftProps } from '../../model/credit-submission.model'
import { CreditPortfolio } from '../../model/credit-portfolio.model'

@Component({
  selector: 'app-credit-create-submission-dialog',
  templateUrl: './credit-create-submission-dialog.component.html',
  styleUrls: ['./credit-create-submission-dialog.component.scss'],
})
export class CreditCreateSubmissionDialogComponent implements OnChanges {
  transactionId: string
  references: Reference[] = []
  label = 'Unlabled Submission'
  description = 'No Description'

  creatingSubmissions = false
  @Input() creditStructure: CreditStructure
  @Input() calculationStructure: CreditCalculationStructure
  @Input() currentPortfolio: CreditPortfolio
  @Output() close = new EventEmitter()

  constructor(
    private submissionService: CreditSubmissionService,
    private snackbar: MatSnackBar
  ) {}

  ngOnChanges(): void {
    this.setTransaction()
  }

  onCreateClick(): void {
    // Create submission for selected transaction only
    const calculationStructure = this.prepStructureForSubmission()
    const props: CreateSubmissionDraftProps = {
      portfolio: this.currentPortfolio,
      creditStructure: this.creditStructure,
      calculationStructure,
      transactionId: this.transactionId,
      description: this.description,
    }
    this.creatingSubmissions = true
    this.submissionService
      .createSubmissionDraft(props)
      .subscribe(submissionStructure => {
        this.creatingSubmissions = false
        this.close.emit()
        this.snackbar.open(
          `Successfully Created Submission: ${submissionStructure.label}`,
          'Dismiss',
          {
            duration: 3000,
          }
        )
      })
  }

  private setTransaction(): void {
    if (!this.calculationStructure) {
      return
    }
    const transactions = this.calculationStructure.transactions
    this.transactionId = transactions[transactions.length - 1]._id
    this.references = transactions.map(transaction => {
      return {
        value: transaction._id,
        viewValue: transaction.name,
      }
    })
  }

  private prepStructureForSubmission(): CreditCalculationStructure {
    const transactions = this.prepTransactionsForSubmission()
    const { _id, ...calculationStructureWithoutId } = this.calculationStructure
    return {
      ...calculationStructureWithoutId,
      transactions,
      collateral_percent: 0.2,
      name: this.label,
    }
  }

  private prepTransactionsForSubmission(): CreditTransaction[] {
    const transaction = this.calculationStructure.transactions.find(
      t => t._id === this.transactionId
    )
    if (!transaction) {
      return []
    }
    return [transaction].map(transaction => {
      const { saved_pricing_curve_id, ...transactionWithoutPricingCurve } =
        transaction
      return {
        ...transactionWithoutPricingCurve,
        tranches: transaction.tranches.map(tranche => {
          return { ...tranche, rol: 1 }
        }),
      }
    })
  }
}
